const Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        textArea.readOnly = true;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
    }

    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        if (navigator.clipboard) {
            return navigator.clipboard.writeText(text);
        }
        createTextArea(text);
        selectText();
        copyToClipboard();
        return Promise.resolve();
    };

    return {
        copy: copy
    };
})(window, document, navigator);

export default Clipboard;
