import React, {Input, Component, useState } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {UISelectionList} from "../DeviceSetup";
import Err from "../../assets/icons/Error.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CurrencyInput from "react-currency-input";
import Cleave from 'cleave.js/react'
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us';
import { ReactSVG } from 'react-svg'
import {UIProfileIcon} from "../ProfileIcon";
import TeTeLogo from "../../assets/Assets/TeteLogo00.svg";
import TeTeChat from '../../assets/WebAssets/TeTeChat.svg';
import BackgroundGFX from "../../assets/Assets/BackgroundGFX.svg"
import Lock from "../../assets/icons/Padlock.svg";
import Email from "../../assets/icons/Email.svg";
import User from "../../assets/icons/ProfileSml.svg";
import Phone from "../../assets/icons/EndCall.svg";
import RightArrow from "../../assets/icons/Forward.svg";
import Checkbox from "../../assets/icons/Checkbox.svg";
import CheckboxOn from "../../assets/icons/CheckboxOn.svg";
import Check from "../../assets/icons/CheckMark.svg";
import SpinnerShape from "../../assets/icons/SpinnerShape.svg";
import Caduceus from "../../assets/icons/Caduceus.svg";
import DatePicker from 'react-mobile-datepicker';
import TimeInputOrig from 'react-time-input-polyfill';
import Arrow from "../../assets/icons/PointerRight.svg";
import Back from "../../assets/icons/Back.svg";
import Google from "../../assets/icons/Google.svg";
import {UIOKCancel} from "../OKCancel";
import {isDesktop} from "../../Platform";
import moment from 'moment';
import mobiscroll from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import 'date-input-polyfill-react';
import {isMobile, isSafari} from "../../Platform";
import autofill from "react-autofill";
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import './index.css'
const owasp = require("owasp-password-strength-test");

const Util = require('cleave.js/src/utils/Util');

function isInert(node) {
	// See https://www.w3.org/TR/html5/editing.html#inert
	let sty = getComputedStyle(node);
	return node.offsetHeight <= 0 || /hidden/.test(sty.getPropertyValue('visibility'));
}
function focusNext(e) {
	// Selector lifted from `jkup/focusable.git`
	let focusable = Array.from(document.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable], audio[controls], video[controls]')),
		step = e && e.shiftKey ? -1 : 1,
		activeIndex = focusable.indexOf(document.activeElement),
		nextActiveIndex = activeIndex + step,
		nextActive = focusable[nextActiveIndex];

	// Skip inert elements.
	while (nextActive && isInert(nextActive)) { nextActive = focusable[(nextActiveIndex += step)]; }

	
	if (nextActive) {
		nextActive.focus();
		e && e.preventDefault();
	}
	else {
		// Allow focus to leave the document when there is no nextActive
		document.activeElement.blur();
	}
}

const countryCodeOptions = getCountries().map(country => {
    const opt = {
        name: en[country],
        value: getCountryCallingCode(country),
    }
    return opt;
});

countryCodeOptions.sort((a, b) => {
    if (a.name == "United States") return -1; else if (b.name == "United States") return 1;
    return a.name.localeCompare(b.name);
});

// hacks for sorry npm fails
class MyCurrencyInput extends CurrencyInput {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        return;
    }

    setInput = input => {
        if (!this.theInput && input) {
            this.theInput = input;
            const f = input.setSelectionRange;
            input.setSelectionRange = (start, end) => document.activeElement == input ? f.apply(input, [start, end]) : null;
        }
    }

    render() {
        return <input ref={this.setInput} type={this.props.inputType} value={this.state.maskedValue} onChange={this.handleChange} onFocus={this.handleFocus} {...this.state.customProps}/>
    }
}

// hacks for another npm package fail
class Cleave1 extends Cleave {
    constructor(props) {
        super(props);
        const cls = Cleave1;
        const self = this;
        self.onFocus = event => {
            var owner = this, pps = owner.properties;
            
            if (pps.prefix && pps.noImmediatePrefix && !event.target.value) {
                owner.onInput(pps.prefix);
            }
            
            //event.target.rawValue = owner.getRawValue();
            event.target.value = pps.result;
            owner.registeredEvents.onFocus(event);
            Util.fixPrefixCursor(owner.element, pps.prefix, pps.delimiter, pps.delimiters);
        }
        self.onChange = event => {
            console.log("onChange: ", event);
            var owner = this,
            pps = owner.properties;
	    owner.onInput(event.target.value);
	    //event.target.rawValue = owner.getRawValue();
	    event.target.value = pps.result;
	    owner.registeredEvents.onChange(event);
        }
        self.onBlur = event => {
            var owner = this, pps = owner.properties;
            
            //event.target.rawValue = owner.getRawValue();
            event.target.value = pps.result;
            
            owner.registeredEvents.onBlur(event);
        }
    }

    //componentWillReceiveProps(nextProps) {
    //debugger;
//}
}


const forceUseTimeInputPolyfill = false;
// total hacks for sad broken npm fun
let polyfill;

class TimeInput extends TimeInputOrig {
    constructor(props) {
        super(props);
        console.log("time input props: ", props);
    }
    
    onPolyfillLoad(loadedPolyfill) {
        if (this.state.usePolyfill) {
            polyfill = loadedPolyfill;
        }
        super.onPolyfillLoad(loadedPolyfill);
        if (this.state.usePolyfill) {
	    this.set_time(this.props.value);
        }
    }
    
    componentDidMount() {
        if (!this.polyfill && polyfill) {
            this.onPolyfillLoad(polyfill);
        }
    }
    
    set_time(time24hr) {
	const [hrs, min] = time24hr
	      .split(':')
	      .map(value => (isNaN(value) ? value : +value))
        
	const newTimeValues = this.state.usePolyfill
	      ? {
		  hrs: this.polyfill.convert_hours_to_12hr_time(hrs),
		  min,
		  mode: hrs > 11 ? 'PM' : 'AM', // the original has hrs > 12 
	      }
	      : null
	    this.setState({
	        time: this.state.usePolyfill
		    ? time24hr
		    ? newTimeValues
		    : this.state.time
	        : null,
	        value24hr: time24hr,
	    })
    }
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.usePolyfill) return super.componentDidUpdate(prevProps, prevState);
	const hasNewPropsValue = prevProps.value !== this.props.value
	const hasNewStateValue = prevState.value24hr !== this.state.value24hr
        if (hasNewPropsValue) {
	    this.set_time(this.props.value)
	}
        if (this.lastChange != this.state.value24hr) {
            this.lastChange = this.state.value24hr;
	    this.props.onChange({target: {value: this.state.value24hr}});
	    setTimeout(() => {
		this.update_a11y(['update'])
	    }, 0)
        }
    }
}

let id = 0;

export class UIInputField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMenu: true,
            searchResults: [],
            displayName: ""
        }
        if (props.type == 'contact') {
            if (props.value) this.state.displayName = props.value.displayName;
        }
        this.id = ++id;
    }

    componentDidMount = () => {
        if (this.props.onCreate) {
            this.props.onCreate(this);
        }
    }


    clearDisplayName = () => {
        this.setState({
            displayName: ''
        }, this.doSearch);
    }


    componentDidUpdate(prevProps) {
        if (this.props.type == 'date') {
            if (prevProps.value != this.props.value) {
                if (this.date.value != this.props.value) {
                    //this.date.setVal(this.props.value);
                }
            }
        } else if (this.props.type == 'time-duration') {
            const {start, end } = prevProps.value;
            const {newStart, newEnd } = this.props.value;
            if (newStart != start) {
                //if (this.startTime.value != newStart) this.startTime.setVal(newStart);
            }
            if (newEnd != end) {
                //if (this.endTime.value != newEnd) this.endTime.setVal(newEnd);
            }
        }
    }

    renderContact(className, inputStyle) {
        const id = this.id;
        const props = this.props;
        const autoFocus = isDesktop() && props.autoFocus;
        const [showMenu, setShowMenu] = [this.state.showMenu, x=> this.setState({showMenu: x})];
        const [displayName, setDisplayName] = [this.state.displayName, x=> this.setState({displayName: x})];
        const [searchResults, setSearchResults] = [this.state.searchResults, x=> this.setState({searchResults: x, showMenu: true})];
        const changeContact = (e) => {
            const searchValue = e.target.value;
            doSearch(searchValue);
        }

        const hideMenu = () => {
            if (this.state.showMenu) {
                //console.log("hide menu");
                this.state.showMenu = false;
                this.forceUpdate();
            }
        }

        const onFocus = () => {
            //console.log("on focus");
            doSearch(displayName);
        }
        
        const toggleMenu = () => {
            //console.log("toggleMenu: ", this.state.showMenu);
            this.state.showMenu = !this.state.showMenu;
            this.forceUpdate();
        }

        const onBlur = () => {
            hideMenu();
        }

        const clickAway = () => {
            //console.log("click away");
            if (this.state.showMenu) {
                hideMenu();
            }
        }
        
        const onClick = (e, c) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                displayName: c.displayName,
                searchResults: [c],
                showMenu: false,
            })
            props.onChange(props.name, c)
        }

        const doSearch = searchValue => {
            searchValue = searchValue || "";
            setDisplayName(searchValue);
            const searchResults = props.search(searchValue.trim());
            setSearchResults(searchResults);
            if (!searchValue || !searchResults.length) {
                if (props.value) {
                    props.onChange(props.name, null);
                }
            } else {
                if (!props.value) {
                    searchResults.map(x => {
                        if (x.displayName.toLowerCase() == searchValue.toLowerCase()) {
                            props.onChange(props.name, x);
                            setDisplayName(x.displayName);
                        }
                    });
                } else {
                    if (props.value.displayName.toLowerCase() != searchValue.toLowerCase()) {
                        props.onChange(props.name, null);
                    }
                    console.log("props.value: ", props.value ? props.value.displayName: null);
                }
            }
        }
        
        return <ClickAwayListener onClickAway={clickAway}><div className='uiInputFieldContactContainer'>
            <div className='uiInputFieldContactField'>
            <div className='uiInputFieldContactSearchResultIcon'><UIProfileIcon contact={props.value}/></div>
            <input onMouseDown={toggleMenu} onBlur={onBlur} onFocus={onFocus} style={inputStyle} readOnly={props.readOnly} ref={this.setInputRef} type='fun' autoComplete='off' autoFocus={autoFocus} className={className} value={displayName} onChange={changeContact}/>
            </div>
            <div className='uiInputFieldContactSearchResultsContainer' style={showMenu && searchResults.length ? null: {display: "none"}}>
            <div className='uiInputFieldContactSearchResults'>
            {searchResults.map(c => {
                return <div tabIndex={-1} className='uiInputFieldContactSearchResult' onMouseDown={e=> onClick(e, c)}>
                    <div key='icon' className='uiInputFieldContactSearchResultIcon'><UIProfileIcon contact={c}/></div>
                    <div key='name' className='uiInputFieldContactSearchResultName'>{c.displayName}</div>
                    {c == props.value && <div key='check' className="uiInputFieldContactSearchResultCheckMark"><ReactSVG src={Check}/></div>}
                </div>
            })}
        </div>
            </div>
            </div></ClickAwayListener>
            
    }

    onChangeDate = (e, instance) => {
        const props = this.props;
        const val = instance.getVal();
        if (this.props.value != val) {
            console.log("date change: ", val);
            props.onChange(props.name, val);
        }
    }

    onChangeTimeStart = (e, instance) => {
        console.log("on change time start: ", e);
        const props = this.props;
        const {start, end } = props.value;
        const value = instance.getVal();
        //const date = doOnChangeTime(start, value);
        const date = value;
        if (date) {
            let newEnd;
            if (date > end) {
                newEnd = new Date(date);
            }
            this.props.onChange(this.props.name,
                                {'start': date,
                                 //'end': newEnd
                                })
        }
    }
    onChangeTimeEnd = (e, instance) => {
        const props = this.props;
        const {start, end } = props.value;
        const value = instance.getVal();
        //const date = doOnChangeTime(end, value);
        const date = value;
        if (date) {
            let newStart;
            if (date < start) {
                newStart = new Date(date);
            }
            this.props.onChange(this.props.name,
                                {
                                    'end': date,
                                    //'start': newStart
                                });
        }
    }

    lastTap = null;

    itemTap = (e, inst) => {
        if (isDesktop()) {
            console.log("item tap: ", e.target);
            if (document.activeElement != e.target && e.target.getAttribute("aria-selected") == "true") {
                e.target.focus();
            }
        }
    }

    setTimeStartInput = ref => {
        if (ref && this.timeStartInput != ref) {
            this.timeStartInput = ref;
        }
    }

    initTimeStart = (e, i) => {
        this.timeStart = i;
    }

    
    initDate = (e, i)=>{
        if (this.date != i) {
            console.log("init date: ", this.props.value);
            this.date = i;
            i.setVal(this.props.value);
        }
    }

    onShow = (e, i) => {
        const scrollCont = e.target.querySelectorAll('.mbsc-sc-whl');
        for (let i = 0; i < scrollCont.length; i++) {
            scrollCont[i].addEventListener('scroll', (e) => e.target.scrollTop = 0);
        }
    }

    renderDateTime(className, inputStyle) {

        const props = this.props
        let timeVal = ()=>getTimeValue(props.value);

        const onChange = e => {
            props.onChange(props.name, e.target.value);        
        }
        
        const formatTimeDigits  = n => {
            if (n < 10) {
                return "0"+n;
            }
            return n;
        }
        
        const getTimeValue = (value) => {
            let hrs = value.getHours();
            let mins = value.getMinutes();
            return formatTimeDigits(hrs) +":" + formatTimeDigits(mins);
        }
        
        const getDateVal = ()=> {
            let result = moment(props.value).format("YYYY-MM-DD");
            return result;
        }

            

        const onChangeDateOld = str => {
            if (!str) {
                return;
            }
            console.log("date: ", str);
            const date = moment(str).toDate();
            console.log("date => ", date);
            date.setHours(props.value.getHours());
            date.setMinutes(props.value.getMinutes());
            return props.onChange(props.name, date);
        }
        
        const onChangeTime = (value) => {
            const date = doOnChangeTime(props.value, value);
            if (date) {
                return props.onChange(props.name, date);
            }
        }

        const doOnChangeTime = (prev, value) => {
            if (value.target) {
                value = value.target.value;
            } else if (value.value) {
                value = value.value;
            }
            let [h, m] = value.split(':');
            let hrs = parseInt(h, 10);
            let mins = parseInt(m, 10);
            if (!isNaN(hrs)) {
                if (isNaN(mins)) {
                    mins = 0;
                }
                const date = new Date(prev);
                date.setHours(hrs)
                date.setMinutes(mins);
                console.log("onChangeTime: ", value, " => ", date);
                return date;
            }
            console.log("onChangeTime failed: ", value);
            return null;
        }
        let dateVal = getDateVal;
        if (props.type == 'time-duration') {
            const {start, end } = props.value;
                return <div key='duration-field' className='uiInputFieldDurationField'>
                <div key='timeStart' className='uiInputFieldTimePicker'>
                <div key='timeStartClip' className='uiInputFieldTimePickerClip'>
                <mobiscroll.Time rows={3} theme={'mobiscroll'} onInit={this.initTimeStart} onShow={this.onShow} onItemTap={this.itemTap} key='timeStart' height={45} value={start} display='inline' onSet={this.onChangeTimeStart} timeWheels={'hhiiA'}><input tabIndex={-1}/>
                </mobiscroll.Time>
                <div className='uiInputFieldTimeInputGradient'/>
                <div className='uiInputFieldTimeInputOutline'/>
            </div>
                    </div>
                    <div key='timeEnd' className={'uiInputFieldTimePicker'+ (end <= start ? " uiInputFieldTimePickerInvalid" : "")}>
                <div key='timeStartClip' className='uiInputFieldTimePickerClip'>
                <mobiscroll.Time key='timeEnd' theme={'mobiscroll'} rows={3} height={45} value={end} onShow={this.onShow} onItemTap={this.itemTap} onSet={this.onChangeTimeEnd} display='inline' timeWheels={'hhiiA'}><input tabIndex={-1}/></mobiscroll.Time>
                <div className='uiInputFieldTimeInputGradient'/>
                <div className='uiInputFieldTimeInputOutline'/>
            </div>
                </div>
                </div>
            }
            if (props.type == 'date') {
                //const input = <input required={true} min='2020-01-01' max='2030-12-31' style={inputStyle} name={props.name} readOnly={props.readOnly} step={1} ref={x => setInputRef(x)} autoFocus={props.autoFocus} placeholder={placeholder} className={className} type={props.type} autoComplete={props.autoComplete} value={dateVal()} onChange={e=>onChangeDate(e.target.value)}/>;
                return <div key='date' className='uiInputFieldDatePicker'>
                    <div key='timeStartClip' className='uiInputFieldTimePickerClip'>
                    <mobiscroll.Date  theme={'mobiscroll'} height={45}  rows={3} onInit={this.initDate} onShow={this.onShow} onItemTap={this.itemTap} onChange={this.onChangeDate} display={'inline'} dateWheels={'Mddyy'}><input tabIndex={-1}/></mobiscroll.Date>
                <div className='uiInputFieldTimeInputGradient'/>
                <div className='uiInputFieldTimeInputOutline'/>
                    </div>
                </div>
            }
    }


    setInputRef = x => {
        const props = this.props;
        if (x && this.ref != x) {
            this.ref = x;
            if (props.type == 'time' || props.type == 'time-duration' || props.type == 'currency') {
                return;
            }
            let type = props.type;
            if (type != 'button') {
                x.type = type;
            }
            if (props.autoComplete) {
                x.autocomplete = props.autoComplete;
            }
            if (this.props.type == 'password') {
                //debugger;
                this.props.onChange(this.props.name, x.value);
            }
        }
    }

    onClickCountryCode = () => {
        this.setState({selectingCountryCode: !this.state.selectingCountryCode});
    }

    dismissCountryCodeSelection = () => {
        this.setState({selectingCountryCode: false});
    }

    onChangeCountryCode = value => {
        this.props.onChange(this.props.countryCodeName, value);
        this.setState({selectingCountryCode: false});        
    }

    handlePhoneNumberInput = e => {
        const props = this.props;
        props.onChange(props.name, e.target.value); 
    }

    render() {
        const onChange = e => {
            props.onChange(props.name, e.target.value);        
        }

        const setInputRef = this.setInputRef;
        

        const props = this.props;
        const showLabel = !isMobile();

        let placeholder = isMobile() ? props.label : props.placeholder;


        const input = (className, inputStyle) => {
            let type = props.type;
            let readOnly = props.readOnly;
            let onClick;
            if (type == "button") {
                type = "text";
                readOnly = true;
                onClick = this.props.onClick;
                className = className + " uiInputFieldButton";
            }
            if (props.type == 'contact') {
                return this.renderContact(className, inputStyle);
            }
            if (props.type == 'date' || props.type == 'time-duration') {
                return this.renderDateTime(className, inputStyle);
            }
            //if (props.type == 'time') {
            //return <TimeInput style={inputStyle} name={props.name} readOnly={props.readOnly} step={60} ref={x => setInputRef(x)} autoFocus={props.autoFocus} placeholder={placeholder} className={className} type={props.type} autoComplete={props.autoComplete} value={timeVal()} onChange={onChangeTime}/>;
                
            //}
            if (props.type == "tel") {
                return <div className='uiInputFieldPhoneInputField'>
                    <ClickAwayListener onClickAway={this.dismissCountryCodeSelection}>
                    <div className='uiInputFieldPhoneInputCountryCodeInput'>
                    <div className='uiInputFieldPhoneInputCountryCode' onClick={this.onClickCountryCode}>
                    +{this.props.countryCode}
                    </div>
                    <UISelectionList select={this.onChangeCountryCode} options={countryCodeOptions} selected={this.props.countryCode} visible={this.state.selectingCountryCode} value={this.props.countryCode}/>
                    </div>
                    </ClickAwayListener>
                    <Cleave1 autoFocus={this.props.autoFocus} htmlRef={setInputRef} className={'uiInputFieldInput uiInputFieldPhoneInput'} placeholder={placeholder} value={props.value || ''} options={{phone: true, phoneRegionCode: "Us"}} onChange={this.handlePhoneNumberInput}/>
                    </div>
            }
            if (props.type == 'currency') {
                return <MyCurrencyInput autoFocus={false} allowEmpty={false} precision={0} value={props.value || '0'} placeholder={placeholder} prefix={'$'} className={className} currency='USD'
                onChangeEvent={(e, maskedvalue, floatvalue) => {
                    props.onChange(props.name, floatvalue)
                }}/>
            }
            const autoFocus = isDesktop() && props.autoFocus;
            const input =  <input style={inputStyle} name={props.name} readOnly={readOnly} ref={x => setInputRef(x)} autoFocus={autoFocus} placeholder={placeholder} className={className} type={type} autoComplete={props.autoComplete} value={props.value} onChange={onChange}/>;
            return input;
        }
        let inputClassName = 'uiInputFieldInput';
        const wrap = x => {
            return x;
        }
        let style;
        let inputStyle = {
            '-webkit-text-fill-color:': 'rgb(109, 109, 109)',
            'fontSize': '14px',
            'backgroundColor': '#00000000',
            '-webkit-box-shadow': '0 0 0 1000px #00000000 inset'
        }
        inputStyle = null;
        if (props.readOnly && !props.value) style =  {display: "none"};
        let fieldClassName = 'uiInputFieldField';
        if (props.error && props.error.field == props.name) fieldClassName += ' uiInputFieldErr';
        if (props.type == 'date') {
            inputClassName += " uiInputFieldDateInput";
        }
        if (props.type == 'tel') {
            fieldClassName += " uiInputFieldFieldPhone";
        }
        const formatLabel = label => {
            if (props.type == 'time-duration') {
                const {start, end} = label;
                return <div className='uiInputFieldDurationFieldLabels'>
                    <label htmlFor={'startTime-'+id} className='uiInputFieldDurationFieldLabel'>{start}</label>
                    <label htmlFor={'endTime-'+id} className='uiInputFieldDurationFieldLabel'>{end}</label>
                    </div>
            }
            return label;
        }
        let inputFieldClass = 'uiInputField';
        if (props.readOnly) {
            inputFieldClass += ' uiInputFieldReadOnly';
        }
        if (this.props.type == 'button') {
            inputFieldClass += " uiInputFieldButtonField";
        }
        return wrap(<div className={inputFieldClass} style={style}>
                    <div key='fieldAndLabel' className='uiInputFieldAndLabel'>
                    {showLabel && <div key='label' className='uiInputFieldLabel'>{formatLabel(props.label)}</div>}
                    <div className='uiInputFieldAndIcon' onClick={this.props.onClick}>
                    <div key='input' className={fieldClassName}>{input(inputClassName, inputStyle)}
                    <div key='icon' className='uiInputFieldIcon'><ReactSVG src={props.icon}/></div></div>
                    </div>
                    </div>
                    </div>);
    }
}


class UICheckBoxField extends Component {
    constructor(props) {
        super(props);
        this.uncheckedIcon = Checkbox;
        this.checkedIcon = CheckboxOn;
    }
    render() {
        return <div className='uiCheckBoxField' onClick={()=>this.props.onChange(this.props.name, !this.props.value)}>
        <div className='uiCheckBoxCheck'><ReactSVG src={this.props.value ? this.checkedIcon : this.uncheckedIcon}/></div>
        <div className='uiCheckBoxLabel'>{this.props.label}</div>
        </div>
    }
}

class UINiceButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    onClick = () => {
        this.setState({
            busy: true,
        })
        const p = this.props.action();
        if (!p || !p.then) {
            console.log("didn't return a promise: ", this.props.action);
            //debugger;
            this.setState({
                busy: false
            })
        } else {
            p.then(result => {
                this.setState({
                    busy: false
                })
                return result;
            }).catch(err => {
                this.setState({
                    busy: false,
                    error: true
                })
                return Promise.reject(err);
            });
        }
    }
    resetError = () => {
        this.setState({error: false});
    }
    render() {
        if (this.state.error) {
                return <div className='uiNiceButton uiNiceButtonOof'>
                <div className="uiOKCancelOof" onClick={this.resetError}>
                <div className="uiOKCancelOofIcon">
                <ReactSVG src={Err}/>
                </div>
                <div className="uiOKCancelOofLabel">
                {"Oof, Sorry that didn't work"}
             </div>
             </div>
            </div>
        }
        const busy = this.state.busy || this.props.lookBusy;
        return <div className='uiNiceButton' onClick={this.onClick}>
            <div className={'uiNiceButtonIcon' + (busy ? ' uiNiceButtonIconBusy' : '')}><ReactSVG src={busy ? SpinnerShape : (this.props.icon || RightArrow)}/></div>
            <div className='uiNiceButtonLabel'>{this.props.label}</div>
            </div>
    }
}


class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onChange = (field, value) => {
        this.props.onChange(field, value);
    }

    continueAction = ()=> {
        if (this.props.form.onPasswordInput) {
            return this.props.form.onPasswordInput(this.props.form);
        }
        if (this.props.form.isGoogleSignUp) {
            return this.props.validateForm(this.props.form).then(err => {
                debugger;
                if (!err) {
                    const resolve = this.props.form.googleSignUpResolve;
                    return resolve(this.props.form);
                }
            });
        }
        return this.props.action(this.props.form);
    }

    renderPasswordInput() {
        const err = this.state.formError || this.props.error;
        return <div className='uiHomeViewFields'>
            <UIInputField autoFocus={true} error={err} icon={Lock} label={'Password'} name='password' value={this.props.form.password} type='password' autoComplete='current-password' onChange={this.onChange}/>
            <div className='uiFormError'>{err.err}</div>
            <div className='uiHomeViewButtonContainer uiHomeViewButtonContainerBackContinue'>
            <div className='uiHomeViewSignUpBackButton' onClick={this.back}><ReactSVG src={Back}/></div>
            <UINiceButton lookBusy={this.state.busy || this.props.form.lookBusy} label="Continue" action={this.continueAction}/></div>
            </div>            
    }
    
    renderPhoneInput() {
        return <div className='uiHomeViewFields'>
            <UIInputField autoFocus={true} countryCodeName='countryCode' countryCode={this.props.form.countryCode} error={this.props.error} icon={Phone} label={'Phone Number'} name='phoneNumber' value={this.props.form.phoneNumber} type='tel' autoComplete='tel' onChange={this.onChange}/>
            </div>
    }

    renderVerificationCodeInput() {
        return <div className='uiHomeViewFields'>
             <UIInputField autoFocus={isDesktop()} error={this.props.error} icon={Lock} label={'Verification Code'} name='verificationCode' value={this.props.form.verificationCode} type='text' autoComplete='one-time-code' onChange={this.onChange}/>
            </div>
    }

    renderFull(isGoogle) {
            return <div className='uiHomeViewFields'>
            <UIInputField autoFocus={true} error={this.props.error} icon={User} label={'Display Name'} name='name' value={this.props.form.name} type='text' autoComplete='name' onChange={this.onChange}/>
            <UIInputField readOnly={isGoogle} error={this.props.error} icon={Email} label={'Email Address'} name='email' value={this.props.form.email} type='email' autoComplete='username' onChange={this.onChange}/>
            <UIInputField error={this.props.error} icon={Lock} label={'Password'} name='password' value={this.props.form.password} type='password' autoComplete='new-password' onChange={this.onChange}/>
            <UIInputField readOnly={!isGoogle} countryCodeName='countryCode' countryCode={this.props.form.countryCode} error={this.props.error} icon={Phone} label={'Phone Number'} name='phoneNumber' value={this.props.form.phoneNumber} type='tel' autoComplete='tel' onChange={this.onChange}/>
            </div>
    }

    renderFields() {
        if (this.props.form.isGoogleSignUp) {
            return this.renderFull(true);
        }
        if (!this.props.form.user && !this.props.form.onVerificationCodeInput) {
            return this.renderPhoneInput();
        }
        if (this.props.form.onVerificationCodeInput) {
            return this.renderVerificationCodeInput();
        }
        if (this.props.form.onPasswordInput) {
            return this.renderPasswordInput();
        }
        return this.renderFull(false);
    }

    back = () => {
        this.props.back();
    }

    signUpWithGoogle = () => {
        return this.props.signUpWithGoogle(this.props.form,
                                           invalidPassword  => {
                                               window.unblockInput();
                                               if (invalidPassword) this.setState({
                                                   formError: {field: "password", err: "Invalid password"}
                                               });
                                               return new Promise((resolve, reject) => {
                                                   this.props.form.onPasswordInput = form => {
                                                       return this.props.validateForm(form, true).then(err => {
                                                           if (!err) {
                                                               form.lookBusy = true;
                                                               window.blockInput();
                                                               this.forceUpdate();
                                                               resolve(form.password);
                                                           } else {
                                                               this.setState({
                                                                   formError: {field: "password", err: err}
                                                               });
                                                           }
                                                       });
                                                   };
                                                   this.forceUpdate();
                                               });
                                           });
        
    }
    

    render() {
        let className = 'uiSignUp uiHomeView';
        if (this.props.form.onPasswordInput) {
            return <div className={className}>
                {this.renderPasswordInput()}
            </div>;
        }
        let paramEmailCode = false;
        if (!this.props.form.googleSignUpResolve) {
            const searchParams =  new URLSearchParams(window.location.search);
            const code = searchParams.get('email-verify');
            const email = searchParams.get('email');
            paramEmailCode = code && email && code == this.props.form.verificationCode && email == this.props.form.email;
            if (this.props.needsEmail) {
                className += " uiSignUpNeedsEmail";
            } else if (this.props.needsVerificationCode) {
                className += " uiSignUpNeedsVerificationCode";
            }
        }

        let needsPhoneNumber = !this.props.form.onVerificationCodeInput && !this.props.form.user;
        
        return <Tooltip title={false && !this.props.needsEmail && this.props.needsVerificationCode && !paramEmailCode ? 
"Please double check your \“Spam\” or \“Junk\” folders, because sometimes emails get there.": ""}><div className={className}>
            <div className='uiHomeViewFields'>
            {this.renderFields()}
            <div className='uiFormError'>{this.props.error.err}</div>
            <div className='uiHomeViewButtonContainer'>
            <div style={!needsPhoneNumber ? null: {display: 'none'}} className='uiHomeViewSignUpBackButton' onClick={this.back}><ReactSVG src={Back}/></div>
            <UINiceButton busy={this.state.busy} label="Continue" action={this.continueAction}/>
            </div>
            {!window.disableGoogleSignIn && (needsPhoneNumber && !this.props.form.googleSignUpResolve) && <div className='uiHomeViewButtonContainer'>
             <UIOKCancel okIcon={Google} label="Sign Up With Your Google Account" ok={this.signUpWithGoogle}/>
             </div>}
            </div>
            </div></Tooltip>
    }
}

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    onChange = (field, value) => {
        this.props.onChange(field, value);
    }

    continueAction = ()=> {
        if (this.props.form.onPasswordInput) {
            debugger;
            return this.props.form.onPasswordInput(this.props.form).then(result => {
                debugger;
            });
        }
        return this.props.action(this.state)
    }

    back = () => {
        this.props.back();
    }

    renderPasswordInput() {
            return <div className='uiSignIn uiHomeView'>
            <div className='uiHomeViewFields'>
            <UIInputField autoFocus={true} error={this.props.error} icon={Lock} label={'Password'} name='password' value={this.props.form.password} type='password' autoComplete='current-password' onChange={this.onChange}/>
            <div className='uiFormError'>{this.props.error.err}</div>
            <div className='uiHomeViewButtonContainer uiHomeViewButtonContainerBackContinue'>
            <div className='uiHomeViewSignUpBackButton' onClick={this.back}><ReactSVG src={Back}/></div>
            <UINiceButton lookBusy={this.props.form.lookBusy} label="Continue" action={this.continueAction}/></div>
             </div>            
            </div>
    }

    render() {
            if (this.props.form.onPasswordInput) return this.renderPasswordInput();
            return <div className='uiSignIn uiHomeView'>
            <div className='uiHomeViewFields'>
            <UIInputField autoFocus={true} error={this.props.error} icon={Email} label={'Email Address'} name='email' value={this.props.form.email} type='email' autoComplete='username' onChange={this.onChange}/>
            <UIInputField error={this.props.error} icon={Lock} label={'Password'} name='password' value={this.props.form.password} type='password' autoComplete='current-password' onChange={this.onChange}/>
            {false && <UICheckBoxField label={'Remember me'} value={this.props.form.rememberMe} name='rememberMe' onChange={this.onChange}/>}
            {isMobile() ? <div className='uiSignUpSpacer1'/> : <div className='uiFormError'>{this.props.error.err}</div>}
            <div className='uiHomeViewButtonContainer'><UINiceButton busy={this.state.busy} label="Continue" action={this.continueAction}/></div>
            {isMobile() && <div className='uiFormError'>{this.props.error.err}</div>}
            </div>
            </div>
    }
}

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
    }
    onChange = (field, value) => {
        this.props.onChange(field, value);
    }
    render() {
        return <div className='uiHomeForgotPassword uiHomeView'>
            <UIInputField error={this.props.error} icon={Email} label={'Email Address'} name='email' value={this.props.form.email} type='email' autoComplete='username' onChange={this.onChange}/>
            <div className='uiFormError'>{this.props.error.err}</div>
            <div className='uiHomeViewButtonContainer'><UINiceButton label="Continue" action={()=>this.props.action(this.state)}/></div>
            </div>
    }
}

class PhoneNumberSignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onChange = (field, value) => {
        console.log(field, " => ", value);
        this.state.err = null;
        this.props.onChange(field, value);
    }

    back = () => {
        return this.props.back();
    }

    renderPasswordInput = () => {
        let autoComplete;
        let label;
        if (this.props.forgotPassword) {
            autoComplete = 'new-password';
            label = 'New Password'
        } else {
            label = 'Password'
            autoComplete = 'current-password';
        }
        const action = () => {
            if (this.props.form.onPasswordInput) {
                return this.props.form.onPasswordInput(this.props.form.password).then(result => {
                    debugger;
                });
            }
            return this.props.resolvePassword(this.props.form.password).then(result => {
                debugger;
            });
        }
        return <div className='uiHomePhoneNumberSignInInput'>
            <UIInputField autoFocus={isDesktop()} error={this.props.error} icon={Lock} label={label} name='password' value={this.props.form.password} type='password' autoComplete={autoComplete} onChange={this.onChange}/>
            <div className='uiFormError'>{this.props.error.err}</div>
            <div className='uiHomeViewButtonContainer'>
            <UINiceButton lookBusy={this.props.form.lookBusy} label="Continue" action={action}/>
           </div>
          </div>
    }


    renderVerificationCodeInput = () => {
        const action = () => {
            if (!this.props.form.verificationCode) {
                this.setState({
                    err: "Verification Code is required"
                });
                return Promise.resolve();
            } else {
                this.setState({
                    err: null,
                })
            }
            debugger;
            return this.props.resolveVerificationCode(this.props.form.verificationCode).catch(err => {
                debugger;
                this.setState({
                    err: "Invalid code"
                });
                return Promise.resolve();
            }).then(result => {
                debugger;
            });
        }
        let formErr;
        if (this.state.err) {
            formErr = {
                field: "verificationCode",
                err: this.state.err,
            }
        }
        return <div className='uiHomePhoneNumberSignInInput'>
            <UIInputField autoFocus={isDesktop()} error={formErr || this.props.error} icon={Lock} label={'Verification Code'} name='verificationCode' value={this.props.form.verificationCode} type='text' autoComplete='one-time-code' onChange={this.onChange}/>
            <div className='uiFormError'>{this.props.error.err || this.state.err}</div>
            <div className='uiHomeViewButtonContainer uiHomeViewButtonContainerBackContinue'>
            <div className='uiHomeViewSignUpBackButton' onClick={this.back}><ReactSVG src={Back}/></div>
            <UINiceButton label="Continue" action={action}/>
           </div>
          </div>
    }

    renderPhoneNumberInput = () => {
        return <div className='uiHomePhoneNumberSignInInput'><UIInputField autoFocus={true} countryCodeName='countryCode' countryCode={this.props.form.countryCode} error={this.props.error} icon={Phone} label={'Phone Number'} name='phoneNumber' value={this.props.form.phoneNumber} type='tel' autoComplete='tel' onChange={this.onChange}/>
            <div className='uiFormError'>{this.props.error.err}</div>
            <div className='uiHomeViewButtonContainer'><UINiceButton label="Continue" action={()=>this.props.action(this.state)}/></div>
        {!window.disableGoogleSignIn && <div className='uiHomeViewButtonContainer'>
         <UIOKCancel okIcon={Google} label="Sign In With Your Google Account" ok={()=>this.props.signInWithGoogle(this.props.form)}/>
         </div>}
        </div>
    }

    
    render() {
        return <div className='uiHomePhoneNumberSignIn'>
            {this.props.resolveVerificationCode ?
             this.renderVerificationCodeInput() :
             (this.props.resolvePassword || this.props.form.onPasswordInput) ? 
             this.renderPasswordInput() :
             this.renderPhoneNumberInput()}            
            </div>
    }
}


class Sidebar extends Component {
    constructor(props) {
        super(props);
    }
    
    selectView = value => {
        this.props.selectView(value);
    }
    
    renderViewSelector = (value, name, select) => {
        if (isMobile() &&  value =='mobile-sign-in') {
            return null;
        }
        const major = name.toUpperCase() == name;
        return <div className={'uiHomeViewSelector' +
                               (major ? " uiHomeViewSelectorMajor"  : "") +
                               (value == this.props.selectedView ? " uiHomeViewSelectorSelected" : "")} onClick={()=>this.selectView(value)}>
            <div className='uiHomeViewSelectorLabel'>{name}</div>
            <div className='uiHomeViewSelectorArrow'><ReactSVG src={Arrow}/></div>
            </div>;
    }
    
    render() {
          return <div className='uiHomeSidebar'>
            <div className='uiHomeSideBarViewSelection'>
              {this.renderViewSelector('sign-up', "SIGN UP")}
              {this.renderViewSelector('mobile-sign-in', "SIGN IN")}
              <div className='uiHomeViewSelectionSpacer1'/>
              {this.renderViewSelector('sign-in', "Email sign-in")}
              {this.renderViewSelector('forgot-password', "Forgot password?")}
            </div>
          </div>
    }
}


export class UIHome extends Component {

    constructor(props) {
        super(props);
        let start = 'mobile-sign-in';
        if (this.props.start == 'sign-up') {
            start = 'sign-up';
        }
        this.state = {
            selectedView: start,
            form:
            {
                countryCode: 1,
                needsSignUpEmail: true,
                needsSignUpVerificationCode: true,
                signUpEmailVerified: false,
            }
        }
        if (!this.props.start) {
            const lastChoice = localStorage.getItem("ui-home-view");
            if (lastChoice) {
                this.state.selectedView = lastChoice;
            }
        } 
    }

    componentDidMount() {
        const searchParams =  new URLSearchParams(window.location.search);
        const code = searchParams.get("email-verify");
        const email = searchParams.get("email");
        if (code && email) {
            this.state.form.verificationCode = code;
            this.state.form.email = email;
            this.state.form.name = '';
            this.state.form.needsSignUpEmail = false;
            this.forceUpdate();
        }
        this.props.onCreate(this);
    }
    componentDidUpdate() {
        if (this.state.selectedView != 'sign-up') {
            this.state.form.isGoogleSignUp = false;
            this.state.form.googleSignUpResolve = null;
        }
    }

    signOut = ()=> {
        this.state.form = 
        {
            countryCode: 1,
        }
    }

    selectView = view => {
        switch (this.state.selectedView) {
        case 'mobile-sign-in':
        case 'forgot-password':
            this.state.form.password = '';
            break;
        }
        this.setState({
            selectedView: view,
            onGetPassword: null,
            resolvePassword: null,
            resolveVerificationCode: null,
            onDone: null,
            onFail: null,
        });
        const form = this.state.form;
        form.needsSignUpEmail = true;
        form.needsSignUpVerificationCode = true;
        form.signUpEmailVerified = false;
        localStorage.setItem("ui-home-view", view);
        this.props.onViewChange(view);
    }

    onFormChange = (field, value) => {
        if (field == 'password') {
            if (this.state.formError) {
                this.state.formError = null;
                this.forceUpdate();
            }
        }
        if (this.state.form[field] != value) {
            this.state.form[field] = value;
            this.props.onFormChange();
            this.forceUpdate();
        }
    }

    signInWithPhoneNumber = (forgotPassword) => {
        return this.props.signInWithPhoneNumber(this.state.form, this.getCode, this.getPassword, forgotPassword, ()=>window.unblockInput());
    }

    getCode = (err) => {
        if (err) {
            if (this.state.onGetPassword) {
                this.state.onGetPassword();
                this.state.onGetPasword = null;
            }
            this.state.formError = {field: "verificationCode", err: this.state.form.verificationCode ? "Invalid code" : "Verification code is required"};
            this.forceUpdate();
        } else {
            this.state.formError = null;
        }
        return new Promise((resolve, reject) => {
            this.setState({
                resolveVerificationCode: code => {
                    return new Promise((resolve1, reject1) => {
                        this.setState({
                            onGetPassword: resolve1,
                        }, () => {
                            resolve(code);
                        });
                    });
                }
            });
        });
    }

    getPassword = (wasInvalid) => {
        if (this.state.onGetPassword) this.state.onGetPassword();
        this.state.form.lookBusy = false;
        window.unblockInput();
        return new Promise((resolve, reject) => {
            this.setState({
                onGetPassword: null,
                formError: wasInvalid ? {field: "password", err: "Invalid password"} : {},
                resolveVerificationCode: null,
                resolvePassword: (password) => {
                    return this.props.validateForm({password: password}, true).then(err => {
                        if (err) {
                            this.state.formError = {field: 'password', err: err};
                            this.forceUpdate();
                            return;
                        }
                        this.state.form.lookBusy = true;
                        window.blockInput();
                        this.forceUpdate();
                        resolve(password);
                    });
                }
            });
        });
    }

    signUp = () => {
        return this.props.signUp(this.state.form);
    }

    signUpBack = () => {
        this.state.form.onVerificationCodeInput = null;
        this.state.form.user = null;
        this.state.form.onPasswordInput = null;
        this.props.onViewChange();
        this.forceUpdate();
        return Promise.resolve();
    }

    signInBack = () => {
        this.signOut();
        this.state.resolveVerificationCode = null;
        this.props.onViewChange();
        this.forceUpdate();
        return Promise.resolve();
    }


    signInWithGoogle = () => {
        return this.props.signInWithGoogle(this.state.form, () => {
            return new Promise((resolve, reject) => {
                this.setState({selectedView: 'sign-up'}, () => {
                    window.unblockInput();
                    debugger;
                    resolve();
                })
            });
        }, invalidPassword  => {
            debugger;
            window.unblockInput();
            this.state.form.lookBusy = false;
            if (invalidPassword) this.setState({
                formError: {field: "password", err: "Invalid password"}
            });
            return new Promise((resolve, reject) => {
                this.state.form.onPasswordInput = password => {
                    const form = this.state.form;
                    form.password = password;
                    debugger;
                    return this.props.validateForm(form, true).then(err => {
                        debugger;
                        if (!err) {
                            form.lookBusy = true;
                            window.blockInput();
                            this.forceUpdate();
                            resolve(form.password);
                        } else {
                            this.setState({
                                formError: {field: "password", err: err}
                            });
                        }
                    });
                };
                this.forceUpdate();
            });
        });
    }

    renderSignUp() {
        return <SignUp back={this.signUpBack} needsEmail={this.state.form.needsSignUpEmail} needsVerificationCode={this.state.form.needsSignUpVerificationCode} emailVerified={this.state.form.signUpEmailVerified} onChange={this.onFormChange} form={this.state.form} error={this.props.signInError || this.state.formError} action={this.signUp} signUpWithGoogle={this.props.signUpWithGoogle} validateForm={this.props.validateForm}/>
    }

    renderSignIn() {
        let err = this.props.signInError;
        if (this.state.formError && this.state.formError.err) {
            err = this.state.formError;
        }
        return <SignIn back={this.signInBack} signInWithGoogle={this.signInWithGoogle} onChange={this.onFormChange} form={this.state.form} error={err} action={()=>this.props.signIn(this.state.form)}/>
    }
    
    renderMobileSignIn() {
        let err = this.props.signInError;
        if (this.state.formError && this.state.formError.err) {
            err = this.state.formError;
        }
        return <div key='mobileSignIn' className='uiHomeMobileSignIn'><PhoneNumberSignIn back={this.signInBack} signInWithGoogle={this.signInWithGoogle} resolvePassword={this.state.resolvePassword} resolveVerificationCode={this.state.resolveVerificationCode} onChange={this.onFormChange} form={this.state.form} error={this.state.formError || this.props.phoneSignInError} action={()=>this.signInWithPhoneNumber(false)}/></div>
    }

    renderForgotPassword() {
        let err = this.props.signInError;
        if (this.state.formError && this.state.formError.err) {
            err = this.state.formError;
        }
        return <div key='forgotPassword' className='uiHomeForgotPassword2'><PhoneNumberSignIn signInWithGoogle={this.signInWithGoogle} resolvePassword={this.state.resolvePassword} resolveVerificationCode={this.state.resolveVerificationCode} forgotPassword={true} onChange={this.onFormChange} form={this.state.form} error={err} action={()=>this.signInWithPhoneNumber(true)}/></div>
        //return <ForgotPassword onChange={this.onFormChange} form={this.state.form} error={this.props.resetPasswordError} action={()=>this.props.resetPassword(this.state.form)}/>
    }
    
    renderSelectedView() {
        switch (this.state.selectedView)
        {
            case "sign-up":
            {
                return this.renderSignUp();
            }
            case "sign-in": 
            {
                return this.renderSignIn();
            }
            case "mobile-sign-in": 
            {
                return this.renderMobileSignIn();
            }
            case "forgot-password": 
            {
                return this.renderForgotPassword();
            }
        }
        return null;
    }
    
    render() {
        const mobile = isMobile(true);
        const blurb = mobile ? "Coming soon to mobile" : "";
        const mainBlurb = false && window.isBusiness == 't' ? "TeTe for Teletherapy" : "TeTe Beta";
        return <div className={isMobile(true) ? "uiHomeMobile" : "uiHomeDesktop"}>
            <div className='uiHomeBackground'><ReactSVG src={BackgroundGFX}/></div>
            {<div className='uiHomeBackground2'/>}
            <div className='uiHomeMain'>
            <div className='uiHomeSelectedViewContainer'>{this.renderSelectedView()}</div>
            </div>
            {isMobile() && <div className='uiHomeMobileBoxShadow'/>}
            {<Sidebar selectedView={this.state.selectedView} selectView={this.selectView} />}
            <div className='uiHomeTeTe' onClick={this.props.openWebsite}><ReactSVG src={TeTeChat}/></div>
            <div className='uiHomeSecurity'>
            <div className='uiHomeHipaaCompliant'><div className='hipaa'>HIPAA</div><div className='compliant'>Compliant</div></div>
            <div className='uiHomeCaduceus'><ReactSVG src={Caduceus}/></div>
            <div className='uiHomeE3'><div>End-to-End</div><div>Encrypted</div></div>

            </div>
            {false && <div className='uiHomeBlurb'><div className='uiHomeBlurbTeTe'>{mainBlurb}</div>&nbsp;&nbsp;{blurb}</div>}
            </div>
    }
}


