import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import {UIPage} from "../Page";
import {UIProfileIcon} from "../ProfileIcon";
import {UIInputField} from "../Home";
import {UIOKCancel} from "../OKCancel";
import Cross from "../../assets/icons/Cross.svg";
import StripeS from "../../assets/icons/StripeS.svg";
import Save from "../../assets/icons/Save.svg";
import Clock from "../../assets/icons/Clock.svg";
import Close from "../../assets/icons/Cross.svg";
import CreditCard from "../../assets/icons/CreditCard.svg";
import Plus from "../../assets/icons/Plus.svg";
import Trash from "../../assets/icons/Trash.svg";
import CalSml from "../../assets/icons/CalendarSml.svg";
import Profile from "../../assets/icons/Profile.svg";
import ProfileSml from "../../assets/icons/ProfileSml.svg";
import Back from "../../assets/icons/Back.svg";
import Fwd from "../../assets/icons/Forward.svg";
import Update from "../../assets/icons/Update.svg";
import Refund from "../../assets/icons/Refund.svg";
import Check from "../../assets/icons/CheckMark.svg";
import Edit from "../../assets/icons/ChatEdit.svg";
import Schedule from "../../assets/icons/ScheduleClock.svg";
import moment from 'moment';
import "./index.css";
import {Elements} from '@stripe/react-stripe-js';
import {ElementsConsumer, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

export class UICreditCardInput extends Component {

    constructor(props) {
        super(props);
    }
    
    removePaymentMethod = () => {
        this.props.setPaymentMethod(null);
    }

    componentDidMount() {
    }
            
    render() {
        const paymentMethod = this.props.paymentMethod;
        if (paymentMethod) {
            return <div className='uiStripeClientConnectForm'>
                <div className='uiStripeConnectCardName'><p>{paymentMethod.card.brand} ending in {paymentMethod.card.last4}</p>
                {this.props.paymentStatus != 'succeeded' && <div className='uiScheduleAppointmentRemoveCard' onClick={this.removePaymentMethod}>
                <ReactSVG src={Cross}/>
                 </div>}
                </div>
                </div>;
        }
        const CARD_OPTIONS = {
            iconStyle: 'solid',
            style: {
                base: {
                    iconColor: 'white',
                    color: 'white',
                    fontWeight: 500,
                    fontSize: '14px',
                    caretColor: 'white',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                        color: 'white',
                    },
                    '::placeholder': {
                        color: 'white'
                    },
                },
                webkitAutoFill: {
                    color: 'white',
                    caretColor: 'white',
                    backgroundColor: 'rgb(33, 161, 196)'
                },
                invalid: {
                    iconColor: '#ffc7ee',
                    color: '#ffc7ee',
                },
            },
        };
        return <div className='uiStripeClientConnectForm'>
            <div className='uiStripeConnectFormCard'><CardElement options={CARD_OPTIONS}/></div>
            </div>        
    }

}



export class UIScheduleAppointment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentStatus: this.props.paymentStatus,
            paymentMethod: this.props.finalPaymentMethod,
            formError: {},
            form:  {
                date: props.date,
                on: props.on,
                with: props.with,
                start: props.start,
                end: props.end,
                title: props.title,
                invoiceDescription: props.invoiceDescription,
                invoiceAmount: props.invoiceAmount,
                attendees: {}
            }
        }
        console.log("Form: ", this.state.form);
    }

    componentDidMount() {
        if (this.props.client) {
            //debugger;
            if (!this.props.finalPaymentMethod) {
                this.props.me.getPaymentMethod().then(paymentMethod => {
                    console.log(paymentMethod);
                    if (paymentMethod) {
                        console.log("got payment method: ", paymentMethod.id);
                        this.setState({
                            paymentMethod: paymentMethod,
                        });
                    }
                })
                if (this.props.paymentIntentId && !this.props.paymentStatus) {
                    this.getPaymentIntent();
                }
            }
        } else {
            this.autofill();
        }
    }

    getPaymentIntent = () => {
        if (this.state.clientSecret) Promise.resolve(this.state.clientSecret);
        return this.props.me.getPaymentIntent(this.props.appointmentId).then(data => {
            console.log("got payment intent: ", data);
            this.setState({
                clientSecret: data.id,
                paymentStatus: data.status,
                account: data.account,
            });
            return data;
        });
    }
    

    update = async () => {
        if (!this.props.paymentMethod) {
            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            //debugger;
            const cardElement = this.elements.getElement(CardElement);
            
            const {error, paymentMethod} = await this.stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            if (error) {
                console.log('[error]', error);
                this.props.setPaymentMethodError(error.message);
            } else {
                console.log('[PaymentMethod]', paymentMethod);
                this.props.setPaymentMethod(paymentMethod);
            }
        }
    }

    createPaymentMethod = () =>{
        const cardElement = this.elements.getElement(CardElement);
        console.log("got card element: ", cardElement);
        //debugger;
        return this.stripe.createToken(cardElement, {currency: "usd"}).then(result => {
            //debugger;
            if (result.error) {
                this.setState({
                    error: result.error.message.replace("postal code", "zip code")
                });
            } else {
                console.log("saving payment method: ", result);
                return this.props.me.savePaymentMethod(result.token.id).then(() => {
                    console.log("saved payment method: " , result);
                    this.setState({
                        paymentMethod: result.token
                    });
                    return result.token;
                });
                return result.token;
            }
        });
    }

    paymentAction = () => {
        this.setState({
            error: ""
        });
        if (this.props.client && this.state.form.invoiceAmount > 0) {
            if (this.state.paymentStatus != "succeeded") {
                window.showProgressIndicator("Saving Payment Method");
                let p = Promise.resolve(this.state.paymentMethod);
                if (!this.state.paymentMethod) {
                    p = this.createPaymentMethod().then(paymentMethod => {
                        //debugger;
                        this.setState({
                            paymentMethod: paymentMethod,
                        });
                        return paymentMethod;
                    });
                }
                return p.then(paymentMethod => {
                    if (!paymentMethod) {
                        window.hideProgressIndicator();
                        return;
                    }
                    window.showProgressIndicator("Making Payment");
                    return this.getPaymentIntent().then(data => {
                        console.log("got payment intent data: ", data);
                        const clientSecret = data.id;
                        let p = Promise.resolve();
                        if (data.status == "requires_confirmation") {
                            const stripe = window.Stripe(window.stripe_key, {stripeAccount: data.account});
                            p = stripe.confirmCardPayment(clientSecret).then(result => {
                                console.log(result);
                                if (result.error) {
                                    this.setState({
                                        error: result.error.message,
                                    })
                                } else {
                                    this.setState({
                                        paymentStatus: result.paymentIntent.status
                                    });
                                }
                            }).catch(err => {
                                //debugger;
                            });
                        }
                        return p.then(window.hideProgressIndicator);
                    });
                });
            } else {
                return this.props.downloadInvoice();
            }
        }
    }

    onChange= (field, value) => {
        const e = this.state.formError;
        this.setState({error: "", formError: {}});
        if (this.state.form[field] != value) {
            console.log("onChange: ", field, " => ", value);
            this.props.onChange(field, value);
            this.state.form[field] = value;
            this.forceUpdate();
            if (field == 'with' && value) {
                this.autofill();
            }
        }
    }

    getWith = () => {
        return this.props.with || this.state.form.with;
    }

    autofill = () => {
        if (!this.props.isNew) return;
        const value = this.getWith();
        if (value) {
            this.props.me.getAppointments(value).then(appts => {
                console.log("got appointments: ", appts);
                let appt;
                if (appts.length > 0) {
                    appt = appts[0];
                } else {
                    appt = this.props.me.getAppointmentAutofill();
                }
                if (appt) {
                    let update = false;
                    if (appt.invoiceAmount) {
                        this.onChange('invoiceAmount', appt.invoiceAmount);
                    }
                    if (appt.title && appt.title != "Video Conference" && this.state.form.title != appt.title) {
                        this.onChange('title', appt.title);
                    }
                    if (appt.invoiceDescription) {
                        this.onChange('invoiceDescription', appt.invoiceDescription);
                    }
                }
            });
        }
    }

    searchContacts = searchValue => {
        const searchTerms = searchValue.toLowerCase().split(/\s+/);
        const matches = {};
        const filter = x => {
            if (!searchValue) return true;
            let matched = 0;
            const name = x.displayName.toLowerCase();
            const terms = name.split(/\s+/);
            searchTerms.map(searchTerm => {
                terms.map(term => { if (term.startsWith(searchTerm)) { matched++ } });
            });
            matches[x.uid] = matched;
            return matched > 0;
        }
        const sort = (x, y) => {
            const w1 = matches[x.uid] || 0;
            const w2 = matches[y.uid] || 0;
            const cmp1 = w2-w1;
            if (cmp1 !== 0) {
                return cmp1;
            }
            return x.displayName ? y.displayName ? x.displayName.localeCompare(y.displayName) : 1 : -1;
        }
        let result = this.props.me.getCurrentContacts().filter(filter);
        result.sort(sort);
        return result;
    }

    paymentRefund = () => {
        window.showProgressIndicator("Refunding Payment");
        this.props.me.refundAppointment(this.props.appointmentId).then(()=> {
            window.hideProgressIndicator()
            this.setState({
                paymentStatus: null
            });
        })
    }

    okAction = () => {
        //debugger;
        if (!this.state.form.with) {
            this.setState({
                error: "Who will you be meeting with?",
                formError: {field: 'with'}
            });
            return Promise.resolve();
        }
        if (!this.state.form.title) {
            this.setState({
                error: "Description is required",
                formError: {field: 'title'}
            });
            return Promise.resolve();
        }
        if (this.state.form.end <= this.state.form.start) {
            this.setState({
                error: "End must be after Start",
                formError: {field: 'end'}
            });
            return Promise.resolve();
        }
        if (this.state.form.invoiceAmount > 0) {
            if (this.state.form.invoiceAmount < 5) {
                this.setState({
                    error: "Minimum invoice amount is $5",
                    formError: {field: 'invoiceAmount'}
                });
                return Promise.resolve();
            }
            const with_ = this.getWith();
            if (this.props.me.self.uid == with_.uid) {
                this.setState({
                    error: "Can't schedule a paid appointment with yourself",
                    formError: {field: 'invoiceAmount'}
                });
                return Promise.resolve();
            }
        }
        this.setState({
            error: "",
            formError: {}
        });
        let p = Promise.resolve();
        if (this.props.client) {
            if (this.state.form.invoiceAmount > 0) {
                if (this.state.paymentStatus != 'succeeded') {
                }
            }
        }
        return p.then(() => {
            return this.props.schedule();
        });
    }

    renderPaymentMethod = () => {
        return <UICreditCardInput
        me={this.props.me}
        paymentStatus={this.state.paymentStatus}
        paymentMethod={this.state.paymentMethod}
        setPaymentMethod={paymentMethod => {
            this.setState({paymentMethod: paymentMethod});
        }}
        set={x=>this.creditCardPayment=x}/>
    }

    render() {
        return <Elements stripe={window.stripePromise}>
            <ElementsConsumer>
            {({elements, stripe}) => {
                //debugger;
                // stripe wtfs
                this.elements = elements;
                this.stripe = stripe;
                return this.renderSelf();
            }}
        </ElementsConsumer>
      </Elements>
    }

    showMembers = () => {
        this.setState({
            showMembers: true
        });
        return Promise.resolve();
    }

    hideMembers = () => {
        if (this.state.attendeeDetails) {
            this.setState({
                attendeeDetails: null
            });
        } else {
            this.setState({
                showMembers: false
            });
        }
        return Promise.resolve();
    }

    renderAttendeeFields(form) {
        let needsStripeConnect = !this.props.me.stripeAuth;
        let invoiceAmountErr;
        let stripeLabel = "Enable Payments with Stripe";
        const onChange= (field, value) => {
            const e = this.state.formError;
            this.setState({error: "", formError: {}});
            if (form[field] != value) {
                console.log("onChange: ", field, " => ", value);
                //this.props.onChange(field, value);
                form[field] = value;
                this.forceUpdate();
            }
        }
        if (!needsStripeConnect) {
            const account = this.props.me.accountData.account;
            if (account && account.requirements && (account.requirements.disabled_reason ||
                                                    (account.requirements.errors && account.requirements.errors.length > 0 ))) {
                stripeLabel = "Fix Payment Setup Errors";
                needsStripeConnect = true;
                invoiceAmountErr = {
                    field: "invoiceAmount",
                }
            }
        }
        return <div className='uiScheduleAppointmentBodyInvoiceFields'>
            <div className='uiScheduleAppointmentBodyField'>
            <UIInputField label={'Invoice Description'} placeholder={form.title} value={form.invoiceDescription} icon={Edit} name='invoiceDescription' onChange={onChange} type='text'/>
            </div>
            <div className='uiScheduleAppointmentBodyField'>
            {!needsStripeConnect ?
             <UIInputField error={this.state.formError} label={'Invoice Amount'} value={form.invoiceAmount} icon={CreditCard} name='invoiceAmount' onChange={onChange} type='currency'/> :
             <UIInputField label={'Invoice Amount'} error={invoiceAmountErr} value={stripeLabel} readOnly={true} icon={StripeS} name='invoiceAmount' onChange={this.onChange} onClick={this.props.me.stripeConnect} type='button'/>} 
        </div>
            </div>
    }

    renderContact(attendee) {
        if (!attendee) return null;
            return <div className='uiScheduleAppointmentBodyWith'>
            <div className='uiScheduleAppointmentBodyWithIcon'>
            <UIProfileIcon radius={22.5} contact={attendee}/>
            </div>

            <div className='uiScheduleAppointmentBodyWithName'>
            {attendee.displayName}
             </div>
             <div className='uiScheduleAppointmentBodyWithCreds'>{attendee.creds}</div>
             </div>
    }

    renderAttendeeDetails() {
        let attendee = this.state.attendeeDetails;
        let form = this.state.form.attendees[attendee.uid];
        if (!form) {
            form = this.state.form.attendees[attendee.uid] = {};
            form.invoiceDescription = this.state.form.invoiceDescription;
            form.invoiceAmount = this.state.form.invoiceAmount;
            form.title = this.state.form.title;
        }
        return <div className='uiScheduleAppointmentAttendeeDetails'>
            <div className='uiScheduleAppointmentBody'>
            {this.renderContact(attendee)}
            {this.renderAttendeeFields(form)}
        </div>
        </div>
    }

    

    renderOrganizerPage2() {
        const group = this.props.with.group;
        const getSubpage = () => {
            let subPage;
            console.log("attendeeDetails: ", this.state.attendeeDetails);
            if (this.state.attendeeDetails) {
                subPage = this.renderAttendeeDetails();
            }
            console.log("subPage: ", subPage);
            return subPage;
        }
        return <div className='uiScheduleAppointmentPage2'>
            <UIPage hideHeader={true} subPage={getSubpage()}>
            <div className='uiScheduleAppointmentAttendeesListExpanded'>
            Attendees
            </div>
            <div className='uiScheduleAppointmentAttendeesExpanded'>
            {group.members.filter(x => x != group.organizer).map(member => {
                const c = this.props.me.getContact(member);
                const f = ()=> {
                    this.setState({
                        attendeeDetails: c
                    });
                }
                return <div className='uiScheduleAppointmentAttendee' onClick={f}>
                    <div className='uiScheduleAppointmentAttendeeIcon'><UIProfileIcon contact={c}/></div>
                    <div className='uiScheduleAppointmentAttendeeName'>{c.displayName}</div>
                    <div className='uiScheduleAppointmentAttendeeExpand'><ReactSVG src={Fwd}/></div>
                    </div>
            })}
        </div>
            </UIPage>
            </div>
    }
        

    renderOrganizerPage1() {
        let showInvoice;
        let isGroup = this.props.with && this.props.with.isGroup;
        let needsStripeConnect = !this.props.me.stripeAuth;
        let stripeLabel = "Enable Payments with Stripe";
        let invoiceAmountErr;
        if (!needsStripeConnect) {
            const account = this.props.me.accountData.account;
            if (account && account.requirements && (account.requirements.disabled_reason ||
                                                    (account.requirements.errors && account.requirements.errors.length > 0 ))) {
                stripeLabel = "Fix Payment Setup Errors";
                needsStripeConnect = true;
                invoiceAmountErr = {
                    field: "invoiceAmount",
                }
            }
        }
        let attendeeFields;
        if (isGroup) {
                attendeeFields = <div className='uiScheduleAppointmentAttendeesList'>
                <div className='uiScheduleAppointmentBodyField'>
                <UIInputField label={'Attendees'} error={invoiceAmountErr} value={"You and " +(this.props.with.group.members.length-1) + " others"} readOnly={true} icon={Fwd} name='attendees' onChange={this.onChange} onClick={this.showMembers} type='button'/>
                </div>
                </div>;
        } else {
            attendeeFields = this.renderAttendeeFields(this.state.form);
        }
        return <div className='uiScheduleAppointmentBodyFields'>
            {this.props.isNew && !this.props.withReadOnly ? <div className='uiScheduleAppointmentBodyField'>
            <UIInputField label={'Meeting'} search={this.searchContacts} value={this.state.form.with} error={this.state.formError} icon={ProfileSml} name='with' onChange={this.onChange} type='contact'/>
             </div> : this.renderContact(this.props.with)
            }
            <div className='uiScheduleAppointmentBodyField'>
            <UIInputField label={'Description'} error={this.state.formError} value={this.state.form.title} icon={Edit} name='title' onChange={this.onChange} type='text'/>
            </div>
            <div className='uiScheduleAppointmentBodyField'>
            <UIInputField label={'When'} value={this.state.form.date} icon={CalSml} name='date' onChange={this.onChange} type='date'/>
            </div>

            <div key='startsEnds' className='uiScheduleAppointmentBodyField'>
            <UIInputField key='startsEnds' label={{start: 'Starts', end: 'Ends'}} error={this.state.formError} value={
                {start: this.state.form.start, end: this.state.form.end}} icon={Clock} name='start-end' onChange={(name, value) => {
                const {start, end} = value;
                if (start) {
                    this.onChange('start', start);
                }
                if (end) {
                    this.onChange('end', end);
                }
            }} type='time-duration'/>
            </div>
            {showInvoice && 
             <div key='invoiceFields' className='uiScheduleAppointmentBodyInvoiceFields'>
             <div className='uiScheduleAppointmentBodyField'>
             <UIInputField label={'Invoice Description'} placeholder={this.state.form.title} value={this.state.form.invoiceDescription} icon={Edit} name='invoiceDescription' onChange={this.onChange} type='text'/>
            </div>
              <div className='uiScheduleAppointmentBodyField'>
             {!needsStripeConnect ?
              <UIInputField error={this.state.formError} label={'Invoice Amount'} value={this.state.form.invoiceAmount} icon={CreditCard} name='invoiceAmount' onChange={this.onChange} type='currency'/> :
              <UIInputField label={'Invoice Amount'} error={invoiceAmountErr} value={stripeLabel} readOnly={true} icon={StripeS} name='invoiceAmount' onChange={this.onChange} onClick={this.props.me.stripeConnect} type='button'/> }
             </div>
             </div>}
        {attendeeFields}
            </div>
    }

    renderOrganizerViewFooter() {
        let okIcon = this.props.isNew ? Fwd: Update;
        let actionLabel = this.props.isNew ? "Schedule" : "Update";
        let okAction = this.okAction;
        let cancelAction = this.props.isNew ? null: this.props.trash;
        let backAction;
        let cancelIcon = Trash;
        if (this.state.showMembers) {
            backAction = this.hideMembers;
        }
        return <div className='uiScheduleAppointmentBodyButtons'>
            {actionLabel && <UIOKCancel back={backAction} okIcon={okIcon} cancelIcon={cancelIcon} label={actionLabel} ok={okAction} cancel={cancelAction}/>}
            </div>
    }

    renderOrganizerView() {
        let subPage;
        if (this.state.showMembers) {
            subPage = this.renderOrganizerPage2();
        }
        return <UIPage
        title={"TeTe Appointment"}
        subtitle={moment(this.state.form.date).format("MMM Do YYYY")}
        icon={Schedule}
        subPage={subPage}
        footer={this.renderOrganizerViewFooter()}
        close={this.props.back}>
            {this.renderOrganizerPage1()}
            </UIPage>
    }
    
    renderSelf() {

        let isOrganizer = true;

        if (this.props.client) {
            isOrganizer = false;
        } else if (this.props.with && this.props.with.isGroup) {
            if (this.props.with.group.organizer != this.props.me.self.uid) {
                isOrganizer = false;
            }
        } else {
            isOrganizer = false;
        }

        if (this.props.me.isDev && isOrganizer) {
            return this.renderOrganizerView();
        }
        
        const status = this.props.status;
        const paymentStatus = this.state.paymentStatus;
        let paymentLabel = "Complete Payment";
        let paymentStatusLabel = "Payment Requested";
        let okIcon = this.props.isNew ? Fwd: Update;
        let actionLabel = !this.props.client ?  this.props.isNew ? "Schedule" : "Update"  : "Accept";
        switch (paymentStatus) {
        case "requires_payment_method":
        case "requires_confirmation":
        case "requires_action":
            paymentStatusLabel = "Payment Requested";
            break;
        case "processing":
            paymentStatusLabel = "Payment Processing";
            break;
        case "canceled":
            paymentStatusLabel = "Payment Canceled";
            break;
        case "succeeded":
            paymentStatusLabel = "Payment Complete";
            paymentLabel = "View Receipt";
            if (this.props.client) {
                if (status == 'accepted') {
                    actionLabel = null;
                    okIcon = Save;
                }
            }
            break;
        }
        let okAction;
        let cancelAction;
        if (this.props.client) {
            if (status == 'canceled') {
                okAction = null;
                cancelAction = null;
            } else {
                if (status != "accepted") {
                    okAction = this.props.accept;
                }
                if (paymentStatus != 'succeeded') {
                    cancelAction = this.props.decline;
                }
            }
        } else {
            okAction = this.okAction;
            if (paymentStatus != 'succeeded') {
                cancelAction = this.props.isNew ? null: this.props.trash;
            }
            if (status == 'canceled') {
                okAction = null;
                cancelAction = null;
            }
        }
        console.log("payment status: ", paymentStatus, ", payment Intent: ", this.props.paymentIntentId);
        const paymentIntentId = paymentStatus ? this.props.paymentIntentId : null;
        const invoiceAmount = this.state.form.invoiceAmount || 0;
        let needsStripeConnect = !this.props.me.stripeAuth;
        let stripeLabel = "Enable Payments with Stripe";
        let invoiceAmountErr;
        if (!needsStripeConnect) {
            const account = this.props.me.accountData.account;
            if (account && account.requirements && (account.requirements.disabled_reason ||
                                                    (account.requirements.errors && account.requirements.errors.length > 0 ))) {
                stripeLabel = "Fix Payment Setup Errors";
                needsStripeConnect = true;
                invoiceAmountErr = {
                    field: "invoiceAmount",
                }
            }
        }
        let groupMembers;
        let className = 'uiScheduleAppointment'+ (this.props.editable ? "" : " uiScheduleAppointmentClient");
        if (false && this.props.with && this.props.with.isGroup) {
            className += " uiScheduleGroupAppointment";
            groupMembers = this.props.with.group.members.filter(uid => uid != this.props.me.self.uid).map(uid => this.props.me.getContact(uid));
        }
	return  <div className={className}>
            <div className='uiScheduleAppointmentHeader'>

            <div className='uiScheduleAppointmentHeaderClose' onClick={this.props.back}>
            <ReactSVG src={Close}/>
            </div>

            <div className='uiScheduleAppointmentHeaderInfo'>

            <div className='uiScheduleAppointmentHeaderIcon'>
            <ReactSVG src={Schedule}/>
            </div>

            <div className='uiScheduleAppointmentHeaderTitle'>
            TeTe Appointment
            </div>
            <div className='uiScheduleAppointmentHeaderDate'>
            {moment(this.props.on).format("MMM Do YYYY")}
            </div>
            
            </div>
         </div>
            <div className='uiScheduleAppointmentBody'>
            {!this.props.small && (this.props.client || !this.props.isNew || this.props.withReadOnly) &&
            <div className='uiScheduleAppointmentBodyWith'>

            <div className='uiScheduleAppointmentBodyWithIcon'>
            <UIProfileIcon radius={22.5} contact={this.props.with}/>
            </div>

            <div className='uiScheduleAppointmentBodyWithName'>
            {this.props.with ? this.props.with.displayName: ""}
             </div>
             <div className='uiScheduleAppointmentBodyWithCreds'>{this.props.with.creds}</div>
             </div>
            }

            <div className='uiScheduleAppointmentClientFields' style={this.props.client ? null : {display: "none"}}>
            <div className='uiScheduleAppointmentClientFieldsInfo'>
            <div className='uiScheduleAppointmentClientTitle'>
            {this.props.title}
            </div>
            <div className='uiScheduleAppointmentClientTime'>
            {moment(this.props.start).format('h:mm A') + " - "+ moment(this.props.end).format('h:mm A')}
            </div>


        </div>
            </div>
            {this.props.client && invoiceAmount > 0 && <div className='uiScheduleAppointmentPaymentInfo'>
             <div className='uiScheduleAppointmentPaymentInfoLoading' style={
                 (this.props.paymentIntentId && !this.state.paymentStatus) ? {visibility: "hidden"} : null
             }>
            <div className='uiScheduleAppointmentPaymentInvoice'>
            <div className='uiScheduleAppointmentPaymentStatus'>{paymentStatusLabel}</div>
            <div className='uiScheduleAppointmentPaymentInvoiceAmount'>${invoiceAmount.toFixed(2)}</div>
            <div className='uiScheduleAppointmentPaymentInvoiceDescription'>{this.props.invoiceDescription}</div>
            </div>
           <div  className='uiScheduleAppointmentPaymentMethod'>{this.renderPaymentMethod()}<div className='uiScheduleAppointmentPaymentMethodIcon'><ReactSVG src={CreditCard}/></div></div>
             <div className='uiScheduleAppointmentPaymentControl'><UIOKCancel okIcon={Fwd} label={paymentLabel} ok={this.paymentAction}/></div>
             </div>
            </div>}            
            <div className='uiScheduleAppointmentBodyFields' style={!this.props.client ? null : {display: "none"}}>

        {this.props.isNew && !this.props.withReadOnly &&<div className='uiScheduleAppointmentBodyField'>
            <UIInputField label={'Meeting'} search={this.searchContacts} value={this.state.form.with} error={this.state.formError} icon={ProfileSml} name='with' onChange={this.onChange} type='contact'/>
         </div>}
            <div className='uiScheduleAppointmentBodyField'>
            <UIInputField label={'Description'} error={this.state.formError} value={this.state.form.title} icon={Edit} name='title' onChange={this.onChange} type='text'/>
            </div>
            <div className='uiScheduleAppointmentBodyField'>
            <UIInputField label={'When'} value={this.state.form.date} icon={CalSml} name='date' onChange={this.onChange} type='date'/>
            </div>

            <div key='startsEnds' className='uiScheduleAppointmentBodyField'>
            <UIInputField key='startsEnds' label={{start: 'Starts', end: 'Ends'}} error={this.state.formError} value={
                {start: this.state.form.start, end: this.state.form.end}} icon={Clock} name='start-end' onChange={(name, value) => {
                const {start, end} = value;
                if (start) {
                    this.onChange('start', start);
                }
                if (end) {
                    this.onChange('end', end);
                }
            }} type='time-duration'/>
            </div>             
            {!paymentIntentId && (!this.props.with || !this.props.with.isGroup) && 
            <div className='uiScheduleAppointmentBodyInvoiceFields'>
            <div className='uiScheduleAppointmentBodyField'>
            <UIInputField label={'Invoice Description'} placeholder={this.state.form.title} value={this.state.form.invoiceDescription} icon={Edit} name='invoiceDescription' onChange={this.onChange} type='text'/>
            </div>
              <div className='uiScheduleAppointmentBodyField'>
              {!needsStripeConnect ?
               <UIInputField error={this.state.formError} label={'Invoice Amount'} value={this.state.form.invoiceAmount} icon={CreditCard} name='invoiceAmount' onChange={this.onChange} type='currency'/> :
               <UIInputField label={'Invoice Amount'} error={invoiceAmountErr} value={stripeLabel} readOnly={true} icon={StripeS} name='invoiceAmount' onChange={this.onChange} onClick={this.props.me.stripeConnect} type='button'/> }
              </div>
              </div>}
        {this.props.with && this.props.with.isGroup && <div className='uiScheduleAppointmentAttendeesList'>
         <UIInputField label={'Attendees'} error={invoiceAmountErr} value={this.props.with.group.members.length + " People"} readOnly={true} icon={Fwd} name='attendees' onChange={this.onChange} onClick={this.showMembers} type='button'/> 
         
         </div>}
        {groupMembers &&
            <div className='uiScheduleAppointmentMembersBody'>
            <div className='uiScheduleAppointmentBodyField'>
            <UIInputField label={'Invoice Description'} placeholder={this.state.form.title} value={this.state.form.invoiceDescription} icon={Edit} name='invoiceDescription' onChange={this.onChange} type='text'/>
            </div>
                <div className='uiScheduleAppointmentMembers'>
                   {groupMembers.map(c => {
                    return <div className='uiScheduleAppointmentMember'>
               <UIInputField error={this.state.formError} label={'Invoice Amount - '+c.displayName} value={this.state.form.invoiceAmount} icon={CreditCard} name='invoiceAmount' onChange={this.onChange} type='currency'/> 
                           </div>})}
         </div>
         </div>
        }
            
            {paymentIntentId &&
            <div className='uiScheduleAppointmentPaymentInvoice'>
            <div className='uiScheduleAppointmentPaymentStatusInfo'>
            <div className='uiScheduleAppointmentPaymentStatus'>{paymentStatusLabel}</div>
            <div className='uiScheduleAppointmentPaymentInvoiceAmount'>${invoiceAmount.toFixed(2)}</div>
            </div>
            <div className='uiScheduleAppointmentPaymentControl'><UIOKCancel okIcon={Refund} label={"Refund Payment"} ok={this.paymentRefund}/></div>
            </div>}
            </div> 
            <div className='uiScheduleAppointmentBodyFieldsError'>
            {this.state.error}
            </div>
            <div className='uiScheduleAppointmentBodyButtons'>
            {actionLabel && <UIOKCancel okIcon={okIcon} cancelIcon={this.props.client ? Cross: Trash} label={actionLabel} ok={okAction} cancel={cancelAction}/>}
            </div>
          </div>
       </div>
    }
    
}
