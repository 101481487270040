import React, {useState, useEffect, Component } from 'react';
import {UIProfileIcon} from "../ProfileIcon";
import moment from "moment";
import './index.css'
import { DragPreviewImage, useDrag } from 'react-dnd'

const hashCode = function(s) {
  var h = 0, l = s.length, i = 0;
  if ( l > 0 )
    while (i < l)
      h = (h << 5) - h + s.charCodeAt(i++) | 0;
  return hash_32b_to_16b(h);
};

function hash_32b_to_16b(val32b) {
    var rightBits = val32b & 0xffff; // Left-most 16 bits
    var leftBits = val32b & 0xffff0000; // Right-most 16 bits

    leftBits = leftBits >>> 16; // Shift the left-most 16 bits to a 16-bit value

    return rightBits ^ leftBits; // XOR the left-most and right-most bits
}

export const formatEndTime =  end => moment(end).format('h:mm A')
export const formatDate = start => moment(start).format('MMMM Do YYYY');

export const renderPaymentStatus = (state) => {
    let paymentStatusLabel = "Payment Requested";
    switch (state)  {
    case "offer":
        paymentStatusLabel = "Payment Requested";
        break;
    case "active":
        paymentStatusLabel = "Active";
        break;
    case "client-cancel":
    case "provider-cancel":
        paymentStatusLabel = "Canceled";
        break;
    }
    return paymentStatusLabel;
}


export const UISubscription = props => { 
    const [collectedProps, drag] = useDrag({
        item: { id: props.id || "", type: "appointment" || "", appointment: props.appointment},
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                console.log("You dropped ", item, " into ", dropResult);
            }
        }
    });

    const now = Date.now();
    let className='uiSubscription';
    if (!props.editable) {
        className += " uiSubscriptionClient";
    }
    if (props.invoiceAmount) {
        className += " uiSubscriptionWithPayment";
    }
    if (props.isChat) {
        className += " uiSubscriptionChat";
    }
    let indicator ="";
    if (props.state == "active") {
        indicator = " uiSubscriptionIndicatorAccepted";
    } else if (props.state == "decline") {
        indicator = " uiSubscriptionIndicatorDeclined";
    } else if (props.state == 'client-cancel' || props.state == 'provider-cancel') {
        indicator = "";
    } else {
        indicator = " uiSubscriptionIndicatorInWaiting";
    }
    
    let error;
    if (props.paymentError) {
        error = props.paymentError;
    } else {
        if (props.state == 'client-cancel' || props.state == 'provider-cancel') {
            error = "Canceled"
        }
    }
    const paymentStatusLabel = renderPaymentStatus(props.state);
    const id = props.id;
    return <div draggable={props.editable} key={props.id} className={className} onClick={props.onClick} ref={!props.editable ? null: drag}>
          <div className='uiSubscriptionInfoId'>
          {id}
          </div>
        <div className={'uiSubscriptionIndicator' + indicator} />
        <div className='uiSubscriptionInfo'>
          <div className='uiSubscriptionInfoOrganizer'>
        {props.organizer.displayName} <span className='uiSubscriptionOrganizerCreds'>{props.organizer.creds}</span>
          </div>
          <div className='uiSubscriptionInfoTitle'>
            {props.description}
    </div>
        <div className='uiSubscriptionResponseTime'>
        {props.end ? "Respond by "+formatEndTime(props.end) : "Response within "+props.responseTime+" day(s)"}</div>
          <div className={'uiSubscriptionInfoDate'}>
           {props.end ? formatDate(props.end) : formatDate(props.startDate)}
          </div>
          {props.invoiceAmount ? <div className='uiSubscriptionPaymentInfo'>
           {error ?  <div className='uiSubscriptionStatusCanceled'>
            {error}
            </div>
            :
            <div className='uiSubscriptionPaymentInfoStatus'>
            {paymentStatusLabel}
            </div>}
           <div className={'uiSubscriptionPaymentInfoAmount' + (props.state.endsWith("canceled") ? ' uiSubscriptionPaymentInfoAmountNegative': "")}>
                ${props.invoiceAmount.toFixed(2)} <span className='uiSubscriptionMonthly'>monthly</span>
                </div>
           </div> : null
          }
        </div>
        {!props.hideWith && <div className='uiSubscriptionWith' onClick={(e)=>{
            e.preventDefault();
            e.stopPropagation();
            props.openChat(props.with);
        }}>
          <div className='uiSubscriptionWithIcon'>
            <UIProfileIcon radius={20} contact={props.with}/>
          </div>
          <div className='uiSubscriptionWithName'>
         {props.with.displayName} <span className='uiSubscriptionWithCreds'>{props.with.creds}</span>
          </div>
         </div>}
     </div>;
}



