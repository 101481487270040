import React, { Component } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {UIIcon} from "../Icon"
import './index.css'

export class UIToggleButton extends Component {
    constructor(props) {
        super(props);
    }
    onChange = e => {
        e.preventDefault();
        this.props.onChange();
    }
    render() {
        let className = this.props.className ? this.props.className + " uiToggleButton" : "uiToggleButton";
        if (this.props.selected) {
            className += " "+className+"Selected";
        }
        return <div className={className} onClick={this.onChange}>
        <div className={'uiToggleButtonLabel'}>{this.props.label}</div>
        <UIIcon icon={this.props.icon}/>
        </div>;
    }
}

export class UIButton extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let className = 'uiButton';
        let iconClassName = 'uiButtonIcon';
        let labelClassName = 'uiButtonLabel';
        if (this.props.className) {
            if (this.props.extend) {
                className += ' ' + this.props.className;
            } else {
                className = this.props.className;
                iconClassName = this.props.className + "Icon";
                labelClassName = this.props.className + "Label";
            }
        }
        if (this.props.selected) {
            className += " " + className+"Selected";
        }
        const button = <div className={className} onClick={this.props.action}>
        <UIIcon className={iconClassName} icon={this.props.icon}/>
        <div className={labelClassName}>{this.props.label}</div>
              </div>;
        if (this.props.tooltip) {
            return <Tooltip title={this.props.tooltip}>{button}</Tooltip>
        }
        return button;
    }
}
