import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import {UIOKCancel} from "../OKCancel";
import {UICalendar} from "../Calendar";
import {UISubscription} from "../Subscription";
import {UICreditCardInput, UIScheduleAppointment} from "../ScheduleAppointment";
import {UISubscribeToChat} from "../SubscribeToChat";
import {UICreateGroup} from "../CreateGroup";
import Trash from "../../assets/icons/Trash.svg";
import Update from "../../assets/icons/Update.svg";
import Check from "../../assets/icons/CheckMark.svg";
import Cross from "../../assets/icons/Cross.svg";
import Menu from "../../assets/icons/Menu.svg";
import Cal from "../../assets/icons/CalendarSml.svg";
import Plus from "../../assets/icons/Plus.svg";
import Forward from "../../assets/icons/Forward.svg";
import Pause from "../../assets/icons/Hold.svg";
import Refund from "../../assets/icons/Refund.svg";
import {UIButton} from "../Button";
import {UIIcon} from "../Icon";
import {UIProfileIcon} from "../ProfileIcon";
import {UIAppointment} from "../Appointment";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {emojiIsSupported, unicodeToEmoji, emojiToUnicode} from "../../Emojis";
import {hasSoftKeyboard, isSafari, isApple, isDesktop} from "../../Platform";
import {parse, NodeType } from 'slack-message-parser-imajion';
import mime from 'mime-types';
import moment from 'moment'
import {UploadProgress} from "../..//UploadProgress";
import ResizeObserver from 'resize-observer-polyfill';
import Send from "../../assets/icons/Send.svg";
import Emoji from "../../assets/icons/Emoji.svg";
import TeTeLogo from "../../assets/icons/TeTeLogoSquare.png";
import GiphyAttribution from "../../assets/Assets/GiphyAttribution.png";
import SpinnerShape from "../../assets/icons/SpinnerShape.svg";
import Save from "../../assets/icons/Save.svg";
import FileIcon from "../../assets/icons/File.svg";
import ImageIcon from "../../assets/icons/Image.svg";
import ChatReact from "../../assets/icons/ChatReact.svg";
import Edit from "../../assets/icons/ChatEdit.svg";
import ChatEdit from "../../assets/icons/ChatEdit.svg";
import ChatDelete from "../../assets/icons/Delete.svg";
import ChatSpace from "../../assets/icons/ChatSpace.svg";
import Undo from "../../assets/icons/Undo.svg";
import Giphy from "../../assets/icons/Gif.svg";
import {Grid} from '@giphy/react-components';
import {GiphyFetch} from '@giphy/js-fetch-api';
import Video from "../../assets/icons/VideoOn.svg";
import PointerDown from "../../assets/icons/PointerDown.svg";
import GiphySelect from 'react-giphy-select';
import 'react-giphy-select/lib/styles.css';
//import ChatDelete from "../../assets/icons/ChatDelete.svg";
import Home from "../../assets/icons/Home.svg";
import Arrow from "../../assets/icons/PointerRight.svg";
import {CallButton} from "../RemoteVideo";
import {SideListProductChannel} from "../Sidebar";
import {Button} from "../../Button";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import {isMobile} from "../../Platform";
import {find} from 'linkifyjs';
import {getTime} from "../Calendar";
import {Elements} from '@stripe/react-stripe-js';
import {ElementsConsumer, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import emojiData from 'emoji-mart/data/apple.json';
import {SliderPicker, CompactPicker} from 'react-color';
import FileSaver from 'file-saver';

import './index.css';

let textArea;

const unescape = (text) => {
    if (!text) return text;
    if (!textArea) textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    const value = textArea.value;
    textArea.innerHTML = '';
    return value.replace(/  +/g, match => " " + Array(match.length).join("\u00A0"));
}

export const formatNewContactText = (me, newContact) => {
    const contact = newContact.contact;
    let text;
    switch (newContact.role) {
    default:
        const by = me.getContact(newContact.role);
        if (by) {
            return by.displayName + " has connected you with " + contact.displayName;
        }
    case 'contacted':
        text = "You are now connected with "+contact.displayName;
        break;
    case 'referred':
        text = "You are now connected with "+contact.displayName;
        break;
    case 'client':
        text = "You are now connected with your client "+contact.displayName;
        break;
    case 'contactee':
    case 'referer':
        text = contact.displayName + " accepted your invite"
        break;
    case 'provider':
        text = "You are now connected with "+contact.displayName;
        break;
    }
    return text;
}

const chatControls = {};
// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
//const gf = new GiphyFetch('eSeANz1CYF7bWLNcXsRYzI7MH5QSkKmx');
// configure your fetch: fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)
//const fetchGifs = (offset: number) => gf.trending({ offset, limit: 10 })

const nameToEmoji = name => {
    const data = emojiData.emojis[name];
    ////console.log(name, " => ", data);
    const result = {
        short_name: name,
        unified: data.b,
        name: data.a,
        sheet_x: data.k[0],
        sheet_y: data.k[1],
    }
    return result;
}

const canvas = document.createElement("canvas");
canvas.width = 64;
canvas.height = 64;

const rendered = {};
const sprites = new Image();
sprites.crossOrigin = "Anonymous";
const emojiSet = true  || isApple() ? "apple" : "google";
sprites.src = "https://unpkg.com/emoji-datasource-"+emojiSet+"@5.0.1/img/"+emojiSet+"/sheets-256/64.png";


const emojiToSprite = emoji0 => {
    const emoji = nameToEmoji(emoji0.short_name);
    if (isApple()) {
        const img = <span className='uiChatInlineEmoji'>{emojiToUnicode(emoji)}</span>;
        return img;
    }
    const x = (emoji.sheet_x / 56)
    const y = (emoji.sheet_y / 56);
    const style = {
        display: 'inline-block',
        width: 25,
        height: 25,
        backgroundImage: "url("+sprites.src+")",
        backgroundSize: "5700% 5700%",
        backgroundPosition: x*100 + "% "+y*100+"%"
    };
    return <div className='uiChatEmojiSprite'><span style={style}></span></div>;
}

const emojiToHtmlImg = emoji0 => {
//    //console.log("emoji0: ", emoji0);
    const emoji = nameToEmoji(emoji0.short_name);
//    //console.log("emoji: ", emoji);
    let blob = rendered[emoji.short_name];
    let src;
    if (!blob) {
        const s = 3762/57;
        const u = (emoji.sheet_x * s);
        const v = (emoji.sheet_y * s);
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(sprites, u, v, 64, 64, 0, 0, 64, 64);
        src = canvas.toDataURL();
        canvas.toBlob(blob => {
            rendered[emoji.short_name] = blob;
        });
    } else {
        src = URL.createObjectURL(blob);
    }
    return "<img class='emoji' src='"+src+"' emoji-name=':"+emoji.short_name+":'/>";
}

const normalizeEmoji = emoji => {
    const result = {
        short_name: emoji.id,
        name: emoji.name,
        unified: emoji.unified,
    }
    return result;
}

const clone = x => JSON.parse(JSON.stringify(x));

const nbsp = new RegExp(String.fromCharCode(160), "gi");

function walkDOM(node, func) {
    func(node);
    node = node.firstChild;
    while(node) {
        walkDOM(node, func);
        node = node.nextSibling;
    }
}

export class UIRemoveContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            needsConfirm: false
        }
    }

    onClick = () => {
        this.setState({
            needsConfirm: true,
        });
    }

    onStopHover = () => {
        this.setState({
            needsConfirm: false,
        });
    }

    removeContact = () => {
        return this.props.removeContact();
    }
    
    render() {
        const contact = this.props.contact;
        return <div className='uiRemoveContact'>
            {<div style={this.state.needsConfirm ? {visibility: 'hidden'} : null} key='remove' className='uiRemoveContactRemove'><Tooltip title={"Remove "+contact.displayName}><div className='uiRemoveContactRemove' onClick={()=>this.onClick()}>Remove Contact</div></Tooltip></div>}
        {this.state.needsConfirm && <div key='confirm' className='uiRemoveContactConfirm' onMouseLeave={this.onStopHover}>
             <UIOKCancel ok={this.removeContact} okIcon={Trash} label={"Confirm removing this contact"}/>
             </div>}
            </div>
    }
}


class ChatAppointment extends Component {

    render() {
        const msg = this.props.msg;
        const appt = msg.data.appointment;
        const isOrganizer = () => appt.uid == this.props.localContact.uid;
        const defaultText = () => {
            if (isOrganizer()) {
                msg.text = "You"+ scheduled +"an appointment with "+remoteContact.displayName;
            } else {
                msg.text = remoteContact.displayName + scheduled+ "an appointment with you";
            }
        }
        const organizer = appt.uid == this.props.localContact.uid ? this.props.localContact: this.props.me.getContact(appt.uid);
        let remoteContact = this.props.remoteContact;
        if (!remoteContact) {
            remoteContact = appt.uid == this.props.localContact.uid ? this.props.me.getContact(appt.client) : this.props.me.getContact(appt.uid);
        }
        let button;
        let isLast = msg.data.isActive;
        let isPast = false;
        const now = Date.now();
        if (now > appt.end) {
            isPast = true;
        }
        if (msg.trashed) {
            return null;
        }
        let status;
        const origMsg = msg;
        //msg = clone(msg);
        if (false && msg.canceled || msg.text == "Appointment canceled") {
            status = 'canceled';
            msg.canceled = true;
        } else {
            if (appt.status) {
                status = appt.status;
                if (status == 'accepted') {
                    if (appt.invoiceAmount && appt.paymentStatus != 'succeeded') {
                        status = 'awaiting-payment';
                    } else {
                    }
                }
            } else {
                status = 'awaiting-accept';
            }
        }
        let scheduled = ' scheduled ';
        if (appt.before) {
            if (appt.before.start != appt.start || appt.before.end != appt.end) {
                scheduled = ' rescheduled ';
            } else if (appt.status == 'refunded') {
                scheduled = ' refunded ';
            } else {
                scheduled = ' updated ';
            }
        } 
        if (isPast && false) {
            scheduled = " had "+scheduled;
        }
        ////console.log("chat appointment status: ", status, "msg: ", msg);
        let paymentStatus = appt.paymentStatus;
        switch (status) {
        case "canceled":
            {
                if (isOrganizer()) {
                    msg.text = "You canceled an appointment with "+remoteContact.displayName;
                } else {
                    msg.text = remoteContact.displayName + " canceled an appointment with you";
                }
                break;
            }
        default:
            {
                //debugger;
                defaultText();
            }
            break;
        case 'refunded':
        case 'awaiting-payment':
        case 'accepted':
            {
                ////console.log(appt);
                if (appt.before.status != 'accepted') {
                    if (!isOrganizer()) {
                        msg.text = "You accepted an appointment with "+remoteContact.displayName;
                    } else {
                        msg.text = remoteContact.displayName + " accepted your appointment";
                    }
                } else {
                    if (appt.before.paymentIntentId && !appt.paymentIntentId) {
                        paymentStatus = 'refunded';
                        if (isOrganizer()) {
                            msg.text = "You refunded "+remoteContact.displayName + "'s payment on this appointment";
                        } else {
                            msg.text = remoteContact.displayName + " refunded your payment on this appointment";
                        }
                    } else if (appt.paymentStatus != "succeeded") {
                        if (appt.invoiceAmount) {
                            if (!isOrganizer()) {
                                msg.text = "You must complete payment on your appointment with "+remoteContact.displayName;
                            } else {
                                msg.text = remoteContact.displayName + " must complete payment on your appointment";
                            }
                        } else {
                            defaultText();
                        }
                    } else {
                        if (!isOrganizer()) {
                            msg.text = "You completed payment on your appointment with "+remoteContact.displayName;
                        } else {
                            msg.text = remoteContact.displayName + " completed payment on your appointment";
                        }
                    }
                }
                break;
            }
        case 'declined':                    
            {
                if (isOrganizer()) {
                    msg.text = remoteContact.displayName + " declined your appointment";
                } else {
                    msg.text =  "You declined "+remoteContact.displayName + "'s appointment";
                }
                break;
            }
        case 'schedule':
            {
                if (!isOrganizer()) {
                    msg.text = remoteContact.displayName + scheduled+ "an appointment with you";
                } else {
                    msg.text =  "You"+ scheduled +"an appointment with "+remoteContact.displayName;
                }
                break;
            }
        }
        if (isLast && status != "canceled") {
            const trash = () => {
                this.props.showSystemProgressIndicator(msg.ts, appt.id, "Deleting Appointment");
                const p = this.props.waitForSystemUpdate(appt);
                return this.props.me.deleteAppointment(appt.id).then(result => {
                    /*
                      msg.trashed = true;
                      msg.data.isActive = false;
                      msg.modified = true;
                      this.forceUpdate();
                      ////console.log(result);
                      */
                    return p;                            
                });
                
            }
            const decline = () => {
                this.props.showSystemProgressIndicator(msg.ts, appt.id, "Declining");
                const p = this.props.waitForSystemUpdate(appt);
                return this.props.me.declineAppointment(appt.id).then(() => {
                    /*
                      msg.data.isActive = false;
                      msg.modified = true;
                      if (!isOrganizer()) {
                      msg.trashed = true;
                      }
                      this.forceUpdate();
                    */
                    return p;
                });
            }
            const rescheduleButton = () => {
                const reschedule = () => {
                    if (!this.props.openChat) {
                        return this.props.openAppointment(appt);
                    }
                    return this.props.rescheduleAppointment(appt);
                }
                const label = status == 'declined' ? "Reschedule" : "Edit";
                const icon = status == 'declined' ? Cal : Edit;
                const cancel = appt.paymentStatus != "succeeded" ? trash : null;
                return  <UIOKCancel okIcon={icon} cancelIcon={Trash} ok={reschedule} cancel={cancel} label={label}/>
            }
            const acceptButton = (declineOnly) => {
                if (isPast) return null;
                const accept = () => {
                    this.props.showSystemProgressIndicator(msg.ts, appt.id, "Accepting");
                    const p = this.props.waitForSystemUpdate(appt);
                    return this.props.me.acceptAppointment(appt.id).then(() => {
                        /*
                          appt.before = clone(appt);
                          appt.status = "accepted";
                          msg.data.isActive = false;
                          msg.modified = true;
                          this.forceUpdate();
                        */;
                        return p;
                    });
                }
                const ok = declineOnly ? null: accept;
                return <UIOKCancel okIcon={Check} cancelIcon={Cross} ok={ok} cancel={decline} label="Accept"/>
            }

            switch (status) {
            default:
            case 'declined':
                if (!isOrganizer()) {
                    break;
                }
            case 'refunded':
            case 'schedule':
                {

                    button = isOrganizer() ? rescheduleButton() : acceptButton(status == 'refunded');
                    break
                }
            case 'accepted':
            case 'awaiting-payment':
                {
                    ////debugger;
                    const popupInfo = this.props.popups[appt.id];
                    const downloadInvoice = () => {
                        const closePopup = () => {
                            const popupInfo = this.props.popups[appt.id];
                            if (popupInfo) {
                                delete this.props.popups[appt.id];
                                popupInfo.popup.close();
                                clearInterval(popupInfo.timer);
                                this.props.markDirty(msg);
                                this.forceUpdate();
                            }
                            return Promise.resolve()
                        }
                        if (this.props.popups[appt.id]) {
                            return closePopup();
                        } else {
                            return this.props.me.showReceipt(appt.id).then(popup => {
                                if (popup && !popup.closed) {
                                    const checkPopup = () => {
                                        if (popup.closed) {
                                            closePopup();
                                        }
                                    }
                                    this.props.popups[appt.id] = {
                                        popup: popup,
                                        timer: setInterval(checkPopup, 350)
                                    }
                                    this.props.markDirty(msg);
                                    this.forceUpdate();
                                }
                            });
                        }
                    }
                    const startSession = () => {
                        if (this.props.openChat) {
                            return this.props.openChat(remoteContact, isOrganizer());
                        } else {
                            this.props.toggleCallActive();
                            return Promise.resolve();
                        }
                    }
                    const when = this.props.openChat ? new Date(Date.now()) : new Date(msg.ts);
                    let cancelIcon;
                    let cancel;
                    if (appt.invoiceAmount == 0) {
                        if (isOrganizer()) {
                            cancelIcon = Trash;
                            cancel = trash;
                        } else {
                            cancelIcon = Cross;
                            cancel = decline;
                        }
                    }
                    const startSessionButton = (button) => {
                        if (isPast) return null;
                        let ok = startSession;
                        let okIcon = Forward;
                        if (!this.props.openChat) {
                            if (appt.invoiceAmount > 0 && !isOrganizer()) {
                                okIcon = Save;
                                const label = this.props.popups[appt.id] ? "Close Receipt" : "View Receipt";
                                return <UIOKCancel cancel={null} cancelIcon={null} okIcon={okIcon} ok={downloadInvoice} label={label}/>
                            }
                            ok = null;
                            okIcon = null;
                        }
                        return <div className='uiChatStartSession'>
                            {this.props.openChat && <div className='uiChatStartSessionWhen'>
                             {isOrganizer() ? remoteContact.displayName + "'s" : "Your"} appointment starts <span className='uiChatStartSessionWhenFromNow'>{moment(new Date(appt.start)).from(when)}</span>
                             </div>}
                        {button}
                            <UIOKCancel cancel={cancel} cancelIcon={cancelIcon} okIcon={okIcon} ok={ok} label="Start Now"/>
                            </div>;
                    }
                    if (!appt.invoiceAmount) {
                        button = startSessionButton();
                    } else {
                        if (appt.paymentStatus == 'succeeded') {
                            if (isOrganizer()) {
                                const refund = () => {
                                    this.props.showSystemProgressIndicator(msg.ts, appt.id, "Refunding Payment");
                                    const p = this.props.waitForSystemUpdate(appt);
                                    return this.props.me.refundAppointment(appt.id).then(result => {
                                        /*
                                      ////console.log("refund: ", result);
                                      const appt = msg.data.appointment;
                                      appt.before = clone(appt);
                                      appt.status = "refunded";
                                      appt.paymentIntentId = null;
                                      appt.paymentStatus = null;
                                      msg.data.isActive = false;
                                      msg.modified = true;                                                
                                      this.forceUpdate();
                                        */
                                        if (result.error) {
                                            console.error(result.error);
                                            return;
                                        }
                                        return p;
                                    });
                                }
                                const refundButton =
                                      <div className='uiChatExtraButton'>
                                      <UIOKCancel okIcon={Refund} ok={refund} label="Refund Payment"/>
                                      </div>
                                    button =                                  
                                    startSessionButton(refundButton);
                            } else {
                                button = startSessionButton();
                            }
                        } else {
                            if (isOrganizer()) {
                                button = rescheduleButton();
                            } else {
                                button = <ChatPaymentComponent
                                waitForSystemUpdate={()=>this.props.waitForSystemUpdate(appt)}
                                setPaymentError={err=> {appt.paymentError=err; this.forceUpdate()}}
                                appointment={appt} me={this.props.me} msg={msg}/>
                            }
                        }
                    }
                    break
                }
            }
            ////console.log("button => ", button);
        }
        return <div className='uiChatAppointmentContainer'>
            <div className='uiChatAppointmentMessage'>{msg.text}</div>
            <UIAppointment openChat={this.props.openChat} onEnded={()=>this.props.onAppointmentEnded(msg)} onClick={()=>this.props.openAppointment(appt)} paymentError={appt.paymentError} isChat={true} organizer={organizer} client={appt.client} status={appt.status} appointment={appt} id={appt.id} editable={false} start={new Date(appt.start)} title={appt.title || "Video Conference"} end={new Date(appt.end)} with={remoteContact} paymentAmount={appt.paymentAmount} invoiceAmount={appt.invoiceAmount} paymentStatus={paymentStatus} hideWith={this.props.hideWith}/>
            {button}
        </div>
    }
}

class ChatPaymentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        const appt = this.props.appointment;
        if (appt.paymentStatus == 'succeeded') {
            this.state.savedPaymentMethod = appt.finalPaymentMethod;
        }
    }

    setPaymentMethod = (x) => {
        this.state.paymentMethod = x;
        this.forceUpdate();
    }

    componentDidMount() {
        const appt = this.props.appointment;
        if (appt.paymentStatus != "succeeded") {
            this.props.me.getPaymentMethod(appt.id).then(paymentMethod => {
                //debugger;
                this.state.savedPaymentMethod = paymentMethod;
                this.forceUpdate();
            });
        }
    }

    createPaymentMethod = () => {
        const elements = this.elements;
        const stripe = this.stripe;
        const cardElement = this.elements.getElement(CardElement);
        ////console.log("got card element: ", cardElement);
        return this.stripe.createToken(cardElement, {currency: "usd"}).then(result => {
            //debugger;
            if (result.error) {
                this.props.setPaymentError(result.error.message);
            } else {
                ////console.log("saving payment method: ", result);
                return this.props.me.savePaymentMethod(result.token.id).then(() => {
                    //debugger;
                    ////console.log("saved payment method: " , result);
                    this.state.savedPaymentMethod = result.token;
                    this.forceUpdate();
                    return result.token;
                });
                return result.token;
            }
        }).catch(err => {
            //debugger;
            this.props.setPaymentError(err.error.message);
        });
    }

    ensurePaymentMethod = () => {
        if (this.state.savedPaymentMethod) {
            return Promise.resolve(this.state.savedPaymentMethod);
        }
        return this.createPaymentMethod();
    }
                                        
    makePayment = () => {
        const msg = this.props.msg;
        const appt = this.props.appointment;
        const getPaymentIntent = () => {
            return this.props.me.getPaymentIntent(appt.id);
        }
        return getPaymentIntent().then(data => {
            ////console.log("got payment intent data: ", data);
            const clientSecret = data.id;
            let p = Promise.resolve();
            if (data.status == "requires_confirmation") {
                const stripe = window.Stripe(window.stripe_key, {stripeAccount: data.account});
                p = stripe.confirmCardPayment(clientSecret).then(result => {
                    ////console.log(result);
                });
            }
            const p1 = this.props.waitForSystemUpdate();
            return p.then(() => {
                /*
                msg.data.isActive = false;
                const appt = msg.data.appointment;
                appt.before = clone(appt);
                const paymentStatus = appt.paymentStatus;
                if (paymentStatus == 'refunded') {
                    appt.status = "accepted";
                }
                appt.paymentStatus = "succeeded";
                this.forceUpdate();
                msg.modified = true;
                */
                return p1;
            }).catch(err => {
                //debugger;
                this.props.setPaymentError(err.error.message);
            });
        });
    }
    
    accept = () => {
        return this.ensurePaymentMethod().then(result => {
            //debugger;
            if (result) {
                return this.makePayment();
            }
        });
    }

    setPaymentMethod = method => {
        let savedPaymentMethod = this.state.savedPaymentMethod;
        if (!method) {
            savedPaymentMethod = null;
        }
        this.setState({
            savedPaymentMethod: savedPaymentMethod
        });
    }
    
    render() {
        const paymentStatus = this.props.appointment.paymentStatus;
        const cancelIcon = Cross;
        const cancel = this.props.decline;
        const okIcon = Forward;
        const ok = this.accept;
        const buttonLabel = paymentStatus == "refunded" ? "Make a new payment" : "Make Payment";
        const payment = <div className='uiChatMakePayment'>
              <UICreditCardInput paymentMethod={this.state.savedPaymentMethod} setPaymentMethod={this.setPaymentMethod} paymentStatus={paymentStatus}/>
              
              {paymentStatus != "succeeded" && <UIOKCancel ok={ok} okIcon={okIcon} cancel={cancel} cancelIcon={cancelIcon} label={buttonLabel}/>}
        </div>;
        return <Elements stripe={window.stripePromise}>
            <ElementsConsumer>
            {({elements, stripe}) => {
                // stripe wtfs
                this.elements = elements;
                this.stripe = stripe;
                return payment;
            }}
        </ElementsConsumer>
            </Elements>
    }
}


class UIMessageEditor extends Component {
    constructor(props) {
        super(props);
        this.contentEditable = React.createRef();
        this.state = {
            html: "",
            editorWidth: 20,
        }
        this.resizeObserver = new ResizeObserver(entries => {
            this.setState({
                editorHeight: this.contentEditable.current.offsetHeight,
                editorWidth: this.contentEditable.current.offsetWidth
            });
        });
    }

    getNode = () => {
        return this.contentEditable.current;
    }

    onDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onDrop(e);
    }

    init = ()=> {
        const el = this.contentEditable.current;
        el.className = this.props.editorClass;
        const dropTarget = el.parentNode;
        dropTarget.ondrop = this.onDrop;
        el.ondrop = this.onDrop;
        if (this.props.onPaste) {
            el.onpaste = this.props.onPaste;
        }
        if (this.props.initialValue) {
            el.innerHTML = this.props.initialValue;
            this.selectAll();
        }
        el.addEventListener("input", () => {
                this.forceUpdate();
                if (this.props.onUpdate) {
                    this.props.onUpdate();
                }
            })
        if (this.props.autoFocus) {
            el.focus();
        }
        this.resizeObserver.observe(el);
    }

    isFocused= ()=> this.contentEditable.current && document.activeElement == this.contentEditable.current;

    selectAll = () => {
        const range = document.createRange();
        range.selectNodeContents(this.contentEditable.current);
        this.lastSelectionRange = range;
        if (document.activeElement == this.contentEditable.current) {
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }

    onSelectionChange=(e) => {
        if (document.activeElement != this.contentEditable.current) {
            return;
        }
        this.lastSelectionRange = window.getSelection().getRangeAt(0);
        //////console.log("saved selection: ", this.lastSelectionRange);
    }

    componentDidMount() {
        this.init();
    }
        
    componentWillUnmount() {
        document.removeEventListener("selectionchange", this.onSelectionChange);
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }


    restoreSelection = () => {
        if (this.lastSelectionRange) {
            const sel = window.getSelection();
            sel.removeAllRanges();
            var range = this.lastSelectionRange;
            sel.addRange(range);
        }
    }

    onFocus=(e)=> {
        this.focused = true;
        if (this.props.onFocus) this.props.onFocus(e, this, ()=>{});
        this.restoreSelection();
        document.addEventListener("selectionchange", this.onSelectionChange);
    }

    onEditorHeightChanged = height => {
        this.setState({editorHeight: height});
    }

    onBlur=(e)=> {
        this.focused = false;
        if (this.props.onBlur) this.props.onBlur(e, this, ()=>{});
    }

    onKeyDown=(e)=> {
        if (this.props.onKeyDown) this.props.onKeyDown(e, this, ()=>{});
    }

    onKeyUp=(e)=> {
        if (this.props.onKeyUp) this.props.onKeyUp(e, this, ()=>{});
    }

    focus = () => {
        this.contentEditable.current.focus();
    }

    blur = () => {
        if (this.contentEditable.current) this.contentEditable.current.blur();
    }

    isEmpty = (debug) => {
        if (!this.contentEditable.current) { 
           return true;
        }
        const trim = this.contentEditable.current.innerHTML;
        if (debug) {
            ////debugger;
        }
        return !trim || trim == "<br>";
    }

    render = () => {
        return <div className='uiChatEditor'>
            <div className='uiChatEditorBg' style={{height: this.state.editorHeight}} />
            <div className='uiChatEditorContainer'><div contentEditable={true} ref={this.contentEditable } onFocus={this.onFocus} onBlur={this.onBlur} onKeyDown={this.onKeyDown} onKeyUp={this.onKeyUp}/>
        {this.props.placeholder && <div className={this.props.placeholderClass} style={this.isEmpty() ? {} : {display: "none"}}>{this.props.placeholder}</div>}</div>
        </div>
    }

    undo = () => {
        document.execCommand("undo");
    }
    
 
    insert=(html, selectPastedContent) => {
       if (document.activeElement != this.contentEditable.current) {
           this.contentEditable.current.focus();
           setTimeout(() => {
                   this.insert(html, selectPastedContent);
               });
       } else {
           setTimeout(() => {
                   this.doInsert(html, selectPastedContent);
               }, 30);
       }
   }

   clear = () => {
       this.contentEditable.current.innerHTML = "";
       this.forceUpdate();
       if (this.props.onUpdate) {
           this.props.onUpdate();
       }
   }


   getText=() => {
       let result = "";
       walkDOM(this.contentEditable.current, n => {
           //////console.log("n.nodeName: ", n.nodeName);
           if (n instanceof HTMLVideoElement) {
               const link = n.getAttribute("vid-link");
               result += "Shared a video";
           }
           else if (n instanceof HTMLImageElement) {
               const name = n.getAttribute("emoji-name");
               if (name) {
                   result += name;
               } else {
                   const gif = n.getAttribute("gif-link");
                   if (gif) {
                       result += gif;
                   } else {
                       result += n.src;
                   }
               }
           } else if (n instanceof HTMLSpanElement) {
               const name = n.getAttribute("emoji-name");
               if (name) {
                   result += name;
               }
           } else if (n.nodeName == "WBR") {
           } else if (n.nodeName == "BR") {
               result += "\n";
           } else if (n.nodeType == 3 && !(n.parentElement instanceof HTMLSpanElement)) {
               let textContent = n.textContent.replace(nbsp, " ");
               result += textContent;
           }
       });
       return result.replace(/\n•[ ]+/g, "\n•  ");
   }

   doInsert=(html, selectPastedContent) => {
       document.execCommand("insertHTML", false, html);
   }
}

export class InlineMedia extends Component {
    constructor(props) {
        super(props);
        const file = this.props.file;
        this.state = {
            loaded: false,
            videoPlaying: false,
            isVideo: file.contentType.startsWith("video/"),
        };
    }
    componentWillUnmount() {
        if (this.video) this.video.playing = false;
        console.log("inline media unmounted: ", this.props.file);
    }

    handleLoaded = (e) => {
        this.setState({loaded: true}, this.props.onLoaded);
    }

    playPause = () => {
        this.setState({
                videoPlaying: !this.state.videoPlaying
            }, () => {
                if (this.state.videoPlaying) this.video.play(); else this.video.pause();
            });
        return Promise.resolve();
    }

    handleVideoFail = (e) => {
        this.setState({
                videoFail: true
            });
    }

    setImageRef = ref => {
        if (ref && ref != this.image) {
            this.image = ref;
            this.image.addEventListener("load", () => {
                this.image.style['max-width'] = "calc(min(100%, "+ this.image.naturalWidth + "px))";
            });
        }
    }

    setVideoSrc = (ref) => {
        if (ref && ref !== this.video) {
            this.video = ref;
            this.video.pause();
            this.video.loop = true;
            this.video.onloaded=(e) => {};
        }
    }
    render = () => {
        const file = this.props.file;
        ////console.log("file: ", file);
        let media;
        if (file.contentType.startsWith("image/") && file.src) {
            media = <img ref={this.setImageRef} onClick={this.props.maximize} height={this.props.image_height} width={this.props.image_width} onLoad={this.handleLoaded} className='uiChatInlineImage' src={file.thumbnailURL ? file.thumbnailURL : file.src}/>;
        } else if (this.state.isVideo) {
            if (this.state.videoFail && this.props.alternateUrl) {
                media = <img onLoad={this.handleLoaded}  className='uiChatInlineImage' src={this.props.alternateUrl}/>;
            } else {
                media = <video controls playsInline onError={this.handleVideoFail} onLoadedData={this.handleLoaded} ref={this.setVideoSrc} className='uiChatInlineVideo'><source type={file.contentType}  src={file.src}/></video>;
            }
        }
        if (media) {
            const filename = file.name;
            const downloading = file.state == 'downloading';
            const download = () => {
                if (file.state == 'downloading') return;
                file.state = "downloading";
                this.markDirty(msg);
                this.forceUpdate();
                const msg = this.props.msg;
                return this.props.downloadFile(msg).then(() => {
                    delete file.state;
                    this.markDirty(msg);
                    this.forceUpdate();
                });
            }
            return <div key={file.src} className='uiChatInlineMedia'>
            {media}
            {this.state.isVideo && !this.state.videoFail &&
             (false ? <div className='uiChatInlineMediaPlayPause'>
             <UIOKCancel okIcon={this.state.videoPlaying ? Pause: Forward} label={filename} ok={this.playPause}/>
              </div>
              :
                        <div className='uiChatFileUpload uiChatFileDownload' onClick={download}>
                        <div className='uiChatFileUploadSpinnerAndText'>
                        {downloading ? <div className='uiChatFileUploadSpinner'><ReactSVG src={SpinnerShape}/></div> :
                         <div className='uiChatFileUploadIcon'><ReactSVG src={Save}/></div>}
                        <div className='uiChatFileUploadText'>
                        <span>Download&nbsp;</span><span className='uiChatFileUploadTextFilename'>{file.name}</span>
                        </div>
                        </div>
                        <div className='uiChatFileUploadIcon'><ReactSVG src={ImageIcon}/></div>
              </div>)                    

              
             }
            </div>
        }
        return <a href={file.src}>{file.name}</a>;
    }
}


let chatId = 0;

export class UIChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editingImages: {},
            uploads:[],// [{progress: 40}]
        };
        this.popups = {};
        this.scrollBottom = -1;
        this.lastContentHeight = -1;
        this.lastHeight = 0;
        this.resizeObserver = new ResizeObserver(entries => {
            entries.map(entry => {
                let needsFixup;
                //////console.log("entry: ", entry);
                if (entry.target == this.scrollWindow && this.lastHeight != this.scrollWindow.offsetHeight) {
                    ////console.log("scroll window resized: ", this.lastHeight, " => ", this.scrollWindow.offsetHeight);
                    //this.scrollBottom = this.scrollWindow.scrollHeight - this.scrollWindow.scrollTop;
                    needsFixup = true;
                    const delta = this.scrollWindow.offsetHeight - this.lastHeight;
                    if (delta < 0) {
                        this.scrollBottom += delta;
                    }
                    this.lastHeight = this.scrollWindow.offsetHeight;
                    //this.checkLayout();
                }
                if (entry.target == this.scrollContent && this.lastContentHeight != this.scrollContent.offsetHeight) {
                    //console.log("scroll content resized: ", this.lastContentHeight, " => ", this.scrollContent.offsetHeight);
                    if (this.lastContentHeight >= 0) {
                        const delta = this.scrollContent.offsetHeight - this.lastContentHeight;
                        //console.log("scrollTop: ", this.scrollWindow.scrollTop, " scrollHeight: ", this.scrollWindow.scrollHeight);
                        //console.log("scrollbottom: ", this.scrollBottom, " => ", this.scrollBottom + delta);
                        //console.log("delta: ", delta, " scrollwindow.offsetHeight: ", this.scrollWindow.offsetHeight);
                        if (delta < 0) {
                            this.scrollBottom += delta;
                        } else {
                            //this.scrollBottom = this.scrollWindow.offsetHeight;
                        }
                    } else {
                        this.scrollBottom = this.scrollWindow.offsetHeight;
                    }
                    this.lastContentHeight = this.scrollContent.offsetHeight;
                    needsFixup = true;
                    
                }
                this.fixupScrollTop();
            });
        });
        ++chatId;
        this.chatId = chatId;
    }



    setEditor = ref => {
        if (ref) {
            this.editor = ref;
        }
    }


    clearEdit = () => {
        this.editor.clear();
        this.video = null;
    }
    
    undoEdit = () => {
        this.editor.undo();
        this.video = null;
        const text = this.editor.getText();
        if (!text) {
            this.setState({
                editingMessage: null
            });
        }
    }

    send = () => {
        if (this.state.editingMessage) {
            return this.saveMessage();
        }
        if (this.video) {
            this.editor.clear();
            const video = this.video;
            this.video = null;
            const ext = video.type.startsWith("video/mp4") ? ".mp4" : ".webm";
            const file = new File([video], "Recorded Message"+ext, {type: video.type});
            return this.uploadFile(file);
        }
        let text = this.editor.getText();
        if (text) {
            const links = find(text);
            let files;
            links.map(link => {
                this.props.me.linkPreviewer.getLinkPreview(link.href);
                text = text.split(link.href).join("<"+link.href+">");
            });
            this.props.sendMessage(text);
            this.scrollToBottom();
            this.editor.clear();
        }
    }

    onKeyDown = e => {
        if (isDesktop()) {
            const RETURN = "Enter";
            //console.log("key: ", e.key);
            if (e.key === RETURN && !e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                this.send();
            }
            const ESC = "Escape";
            if (e.key === ESC) {
                this.cancelEdit();
            }
        }
    }

   uploadFile = file => {
       const name = file.name.toLowerCase();
       if (name.endsWith(".mov")) {
           file = new File(file, name.replace(".mov", ".mp4"), {type: "video/mp4"});
       }
       const upload = {
           file: file,
           progress: 0
       };
       const uploads = [upload].concat(this.state.uploads);
       const progress = percent => {
           upload.progress = percent;
           this.forceUpdate();
       }
       this.setState({
           uploads: uploads
       })
       this.props.uploadFile(file, progress).then(() => {
           this.setState({
               uploads: this.state.uploads.filter(x => x.file != file)
           });
       });
   }

    handleDataTransfer = (event, transfer)=> {
        debugger;
        if (transfer.files.length > 0) {
            event.preventDefault();
            for (var i = 0; i < transfer.files.length; i++) {
                let file = transfer.files.item(i);
                this.uploadFile(file);
            }
            return true;
        }
        return false;
    }
    
    onPaste = e => {
        if (e.clipboardData.files && e.clipboardData.files.length > 0) {
            if (this.handleDataTransfer(e, e.clipboardData)) {
                return;
            }
        }
    }
    
    onUpdate = e => {
    }

    onDrop = e => {
        const transfer = e.dataTransfer;
        this.handleDataTransfer(e, transfer);
    }

    onBlur = e => {
    }

    onEditorUpdate = e => {
        this.forceUpdate();
        this.props.typing();
    }

    isGroup = () => this.props.remoteContact.isGroup;
    isGroupOrganizer = () => this.props.remoteContact.group.organizer == this.props.me.self.uid;

    renderLinkPreview=(preview)=> {
        let foundVideo = false;
        let alternateUrl;
        let contentType = preview.contentType;
        let showPreview = true;
        let mediaUrl = preview.url;
        let url = preview.url;
        if (!url) {
            console.error("invalid link preview: ", preview)
            return null;
        }
        if (url.endsWith(".gif")) {
            return null;
        }
        switch (preview.mediaType) {
        default:
            showPreview = false;
            break;
        case "index":
        case "website":
        case "article":
            {
                if (!preview.title || !preview.description) {
                    //////console.log("not showing preview: ", preview);
                    showPreview = false
                    break;
                }
            }
        case "video.other":
            try {
                if (preview.videos && preview.videos.length > 0) {
                    mediaUrl = preview.videos[0].secureUrl;
                    contentType = preview.videos[0].type;
                    foundVideo = mediaUrl != null;
                }
            } catch (error) {
                ////console.log(preview);
                console.error(error);
            }
            if (preview.images && preview.images.length > 0) {
                if (foundVideo) {
                    alternateUrl = preview.images[0];
                } else {
                    mediaUrl = preview.images[0];
                }
                contentType = mime.lookup(mediaUrl);
                if (!contentType) {
                    break;
                }
            }
        case "video":
            break;
        case "image":
            {
                if (preview.contentType == 'image/gif') {
                    return <img src={preview.url}/>;
                }
            }
        }
        let showInlineMedia = preview.mediaType == "video" || preview.mediaType == "image";
        const showDescription = !showInlineMedia && preview.mediaType != "video.other";
        if (preview.mediaType == "video.other") {
            showInlineMedia = true;
        }
        const inlineMedia = () => {
            const slash = mediaUrl.lastIndexOf("/");
            if (!contentType) {
            }
            let filename = mediaUrl.substring(slash + 1);
            const file = {
                name: preview.title ? preview.title : filename,
                src: mediaUrl,
                contentType: contentType
            }
            //////console.log("creating inline media: ", mediaUrl);
            return <InlineMedia height={preview.image_height} width={preview.image_width} key={mediaUrl} maximize={e=>this.openFile(file)} file={file} alternateUrl={alternateUrl}  isExternalLink={true}/>;
        }
        if (!preview.title) {
            //preview.title = node.url;
        }
        if (this.isBlacklistedForPreview(preview.siteName, true)) {
            showPreview = false;
        }
        if (preview.title == "YouTube" && preview.siteName == "youtube.com" || preview.siteName == "GIPHY") {
            showPreview = false;
        }
        if (showPreview) {
            let previewImage;
            if (preview.images && preview.images.length > 0) {
                previewImage = preview.images[0];
            }
            const div = (
                    <div className='website-preview'>
                    <div className='website-preview-site'>
                    {preview.favicons && preview.favicons.length > 0 && <img alt={""} src={preview.favicons[0]}/>}
                    <p>{preview.siteName}</p>
                       </div>
                    <div className='website-preview-title'>                                                       
                    <a href={url} onClick={e=>this.openFileLink(e, url)}>{preview.title}</a>
                    </div>
                    {showInlineMedia && inlineMedia()}
                {!showInlineMedia && preview.images && preview.images.length > 0 && <div className='website-preview-image'><img alt={""} src={preview.images[0]} onLoad={e => {
                    //console.log("img.onLoad: ", e);
                    preview.image_width  = e.target.naturalWidth;
                    preview.image_height = e.target.naturalHeight;
                    this.forceUpdate();
                }} height={preview.image_height} width={preview.image_width}/></div>}
                {showDescription && <div style={preview.image_width ? {maxWidth: preview.image_width}: null} className='website-preview-description'> 
                 <p >{preview.description}</p> 
                 </div>}
                </div>);
            return div;
        } else if (showInlineMedia) {
            return inlineMedia();
        }
        return null;
    }

    openFile = file => {
        FileSaver.saveAs(file.src, file.name, {type: file.type});
    }
    
    openFileLink = (event, url) => {
        if (event) event.preventDefault();
        window.open(url, "_blank");
    }

    noPreview = ["appspot.com", "google.com", "atlassian.net", "github.com", "microsoft.com", ".md", "gmail.com", "idmsa.apple.com"];

    isBlacklistedForPreview=(link, noValidate)=>{ 
        let url;
        try {
            url = new URL(link);
        } catch (exc) {
            return !noValidate;
        }
        return this.noPreview.find(domain => url.hostname.endsWith(domain));
    }

    slackToHtml = (editing, ts, message, noPreview) => {
        const emojiImageClass = 'slack_emoji';
        const emojiUnicodeClass = 'slack_emoji';
        let i = 0;
        const nextKey = () => {
            const result = "slackToHtml-"+i;
            i++;
            return result;
        }
        const n = parse(unescape(message));
        const nodeStack = [];
        const nodeToReact = (node, index)=> {
            //////console.log("parse: ", node);
            const content = () => {
                const doit = () => {
                    if (!node.children) {
                        if (node.text) {
                            return nodeToReact(parse(node.text));
                        }
                    }
                    return node.children.map(nodeToReact);
                }
                nodeStack.push(node);
                try {
                    return doit();
                } finally {
                    nodeStack.shift();
                }
            }
            switch (node.type) {
            case NodeType.Root:
                return <div key='message'>{content()}</div>;
                
            case NodeType.Text:
                {
                    const escaped = unescape(node.text);
                    const start = String.fromCodePoint(57344);
                    const end = String.fromCodePoint(57345);
                    const nbsp = "\u00A0";
                    const renderText = (escaped) => {
                        let j = escaped.indexOf(start);
                        let hi = false;
                        if (j >= 0) {
                            let i = -1;
                            if (j === 0) {
                                i = j;
                                j = escaped.indexOf(end);
                                hi = true;
                            } 
                            let result = [];
                            while (true) {
                                const className = hi ? "slack_highlight_text": "slack_text";
                                result.push(<span className={className}>{escaped.substring(i+1, j)}</span>);
                                hi = !hi;
                                i = j;
                                j = escaped.indexOf(hi ? end : start, i+1);
                                if (j < 0) {
                                    result.push(<span className='slack_text'>{escaped.substring(i+1)}</span>);
                                    break;
                                }
                            }
                            return <React.Fragment>{result}</React.Fragment>;
                        }
                        //return <span key={nextKey()} className='slack_text'>{escaped}</span>;
                        return escaped;
                    }
                    const lines = escaped.split("\n");
                    const renderLine = (j, line, br) => {
                        if (line.startsWith("•")) {
                            const bullet = [<br/>, "•", 
                                            nbsp, <wbr/>, 
                                            //nbsp, <wbr/>, 
                                            //nbsp, <wbr/>, 
                                            nbsp, <wbr/>,
                                            renderText(line.substring(1))];
                            return  <span key={'line'+j} className='slack_bullet'>{bullet}</span>;
                        }
                        if (br) {
                            return [renderText(line), <br/>];
                        }
                        return renderText(line);
                    };
                    if (lines.length > 1) {
                        const output = [];
                        for (var j = 0; j < lines.length; j++) {
                            const line = lines[j];
                            output.push(renderLine(j, line, j+1 < lines.length));
                        }
                        return <div key={'lines-'+index}>{output}</div>;
                    }
                    return renderLine(0, lines[0]);
                }
            case NodeType.Bold:
                return <strong className='slack_bold'>{content()}</strong>;
                
            case NodeType.Italic: 
                return <i className='slack_italics'>{content()}</i>;
                
            case NodeType.Strike: 
                return <del className='slack_strikethrough'>{content()}</del>;
                
            case NodeType.Quote: 
                return <blockquote className='slack_blockquote'>{content()}</blockquote>;
                
            case NodeType.ChannelLink: 
                
                break;
                
            case NodeType.UserLink: 
                {
                    const userName = this.resolveUserLink(node);
                    //////console.log("userLink: ", node);
                    if (this.props.isConference) {
                        return <span classname='slack_user_nolink'>{userName}</span>;
                    }
                    return <span key={nextKey()} onClick={e=>this.openUserLink(node.userID)} className='slack_user'>{userName}</span>;;
                }
            case NodeType.URL: 
                {
                    let results = [];
                    node.url = node.url.replace(/&amp;/g, "&");
                    if (node.url.endsWith('.gif')) {
                        return <img src={node.url}/>;
                    }
                    if (editing) {
                        let preview;
                        if (message.linkPreviews) {
                            preview = message.linkPreviews.find(x => x.url == node.url);
                        }
                        if (!preview) {
                            preview = this.props.me.linkPreviewer.getExistingLinkPreview(node.url, result => {
                                this.forceUpdate();
                            });
                        }
                        if (preview && preview.url) {
                            const result = this.renderLinkPreview(preview);
                            if (result) {
                                results.push(result);
                            }
                        } else if (this.state.editingImages[node.url]) {
                            const file = {
                                contentType: "image/gif",
                                name: 'some gif',
                                src: this.state.editingImages[node.url]
                            }
                            results.push(<img src={node.url}/>);
                        }
                    } else {
                        if (message.linkPreviews && !noPreview && !nodeStack.find(n => n.type == NodeType.PreText || n.type == NodeType.Code || n.type == NodeType.Quote)) {
                            const preview = message.linkPreviews.find(node.url);
                            if (preview && preview.url) {
                                const result = this.renderLinkPreview(preview);
                                if (result) {
                                    results.push(result);
                                }
                            } else {
                                //console.log("preview not found: ", node.url);
                            }
                        } else {
                            const preview = this.props.me.linkPreviewer.getExistingLinkPreview(node.url, result => {
                                this.forceUpdate();
                            });
                            if (preview) {
                                if (preview.url) {
                                    const result = this.renderLinkPreview(preview);
                                    if (result) {
                                        results.push(result);
                                    }
                                } 
                            } else {
                                //console.log("no preview found 1: ", node.url);
                                return;
                            }
                        }
                    }
                    if (!results.length) { // just show raw link
                        results.push(<a className='uiChatA' target={'_blank'} onClick={e=>this.openFileLink(e, node.url)} href={node.url}>{node.label ? node.label.map(nodeToReact) : node.url}</a>);
                    }
                    return results;
                }
            case NodeType.Command: 
                break;
                
            case NodeType.Emoji: 
                {
                    const emoji = nameToEmoji(node.name);
                    if (emoji) {
                        //const url = "https://a.slack-edge.com/production-standard-emoji-assets/10.2/apple-medium/"+emoji.unified.toLowerCase()+"@2x.png";
                        //let img = <img class='emoji' src={url}/>;
                        return emojiToSprite(emoji);
                    }
                    console.warn("can't find emoji: ", node);
                    return nodeToReact({
                        text: ":"+node.name+":",
                        type: NodeType.Text
                    });
                    
                }
            case NodeType.PreText: 
                return <pre className='slack_pre'>{content()}</pre>;
                
            case NodeType.Code: 
                return <div className='slack_code'>{content()}</div>;
            }
            //////console.log("not parsed: ", node);
            return "";
        }
        return nodeToReact(n);
    }

    renderChatMessageBody= msg => {
        if (msg.system && msg.data) {
            if (msg.data.newContact) {
                const newContact = msg.data.newContact;
                const contact = newContact.contact;
                const schedule = () => {
                    return this.props.scheduleAppointmentWith(contact).then(() => {
                        this.props.finishNewContact(newContact);
                    });
                }
                const cancel = () => {
                    return this.props.finishNewContact(newContact);
                }
                const chat = () => {
                    return this.props.openChat(contact).then(() => {
                        this.props.finishNewContact(newContact);
                    });
                }
                const openContact = () => {
                    return this.props.finishNewContact(newContact).then(() => {
                        return this.props.me.markContactOpened(contact);
                    });
                }
                const text = formatNewContactText(this.props.me, newContact);
                return <div className='uiChatNewContact'>
                    <div className='uiChatNewContactAcceptText'>
                    {text}
                </div>
                    {newContact.role == 'client' ?
                     <UIOKCancel cancelIcon={Cross} cancel={openContact} okIcon={Cal} ok={schedule} label="Schedule Appointment"/>
                     :
                     <UIOKCancel cancelIcon={Cross} cancel={openContact} okIcon={Forward} ok={chat} label="Chat Now"/>}
                    </div>
            }
            if (msg.data.subscription) {
                let deadline;
                //debugger;
                const sub = msg.data.subscription;
                const isOrganizer = () => sub.uid == this.props.localContact.uid;
                let text;
                let ok;
                let okIcon;
                let okLabel;
                let cancel;
                let cancelIcon;
                const remoteContact = this.props.me.getContact(isOrganizer() ? sub.client : sub.uid);
                if (sub.state == 'offer') {
                    if (sub.before && sub.before.state == 'offer') {
                        if (isOrganizer()) {
                            text = "You updated a subscription";
                        } else {
                            text = remoteContact.displayName+" updated an subscription";
                        }
                    } else {
                        if (isOrganizer()) {
                            text = "You offered "+remoteContact.displayName+" a subscription";
                            if (this.props.openChat) {
                                cancelIcon = Trash;
                                cancel = () => this.props.me.cancelSubscription(remoteContact);
                                okLabel = "Edit";
                                okIcon = Edit;
                                ok = ()=> this.props.openSubscription(remoteContact);
                            }
                        } else {
                            text = remoteContact.displayName+" offered you a subscription";
                            if (this.props.openChat) {
                                cancelIcon = Cross;
                                cancel = () => this.props.me.declineSubscription(remoteContact);
                                okIcon = Check;
                                ok = () => this.props.me.acceptSubscription(remoteContact);
                                okLabel = "Accept";
                            }
                        }
                    }
                } else if (sub.state == 'decline' || sub.state == '') {
                    if (isOrganizer()) {
                        text = remoteContact.displayName+" declined a subscription";
                    } else {
                        text = "You declined a subscription"; 
                    }
                } else if (sub.state == 'active') {
                    if (this.props.openChat) {
                        if (isOrganizer()) {
                            text = remoteContact.displayName+" is awaiting your response";
                        } else {
                            text = "You are awaiting a response from "+remoteContact.displayName;
                        }
                        okLabel = "Reply Now";
                        ok = ()=>this.props.openChat(remoteContact);
                        okIcon = Forward;
                    } else {
                        if (isOrganizer()) {
                            text = remoteContact.displayName+" started a subscription";
                        } else {
                            text = "You started a subscription";
                        }
                    }
                } else if (sub.state == 'provider-cancel') {
                    if (isOrganizer()) {
                        text = "You canceled a subscription";
                    } else {
                        text = remoteContact.displayName + " canceled a subscription";
                    }
                } else if (sub.state == 'client-cancel') {
                    if (isOrganizer()) {
                        text = remoteContact.displayName+" canceled a subscription";
                    } else {
                        text =" You canceled a subscription";
                    }
                }
                const organizer = isOrganizer() ? this.props.localContact: remoteContact;
                const with_ = remoteContact;
                const latestQuestion = sub.latestQuestion || 0;
                const latestResponse = sub.latestResponse || 0;
                let start = 0;
                let end = 0;
                if (sub.state == 'active' && latestQuestion > latestResponse) {
                    start = latestQuestion;
                    end = this.props.me.getNextResponseTime(sub);
                    deadline = end;
                }
                return <div className='uiChatSubscription'>
                    <div className='uiChatSubscriptionText'>
                    {text}
                </div>
                    <UISubscription
                editable={isOrganizer()}
                invoiceAmount={sub.invoiceAmount}
                isChat={true}
                state={sub.state}
                hideWith={!this.props.openChat}
                id={sub.uid + "-" + sub.client}
                organizer={organizer}
                onClick={()=>this.openSubscription(sub)}
                responseTime={sub.responseTime}
                start={start}
                end={end}
                description={sub.description}
                invoiceDescription={sub.invoiceDescription}
                openChat={this.props.openChat}
                with={with_}
                    />
                    {deadline && <div className='uiChatStartSessionWhen'>
                     Your reply is expected <span className='uiChatStartSessionWhenFromNow'>{moment(new Date(deadline)).fromNow()}</span></div>}
                    
                    <UIOKCancel cancel={cancel} cancelIcon={cancelIcon} okIcon={okIcon} ok={ok} label={okLabel}/>
                 </div>
            }
            if (msg.data.appointment) {
                return <ChatAppointment
                msg={msg}
                markDirty={this.markDirty}
                showSystemProgressIndicator={this.props.showSystemProgressIndicator}
                me={this.props.me}
                localContact={this.props.localContact}
                remoteContact={this.props.remoteContact}
                openChat={this.props.openChat}
                rescheduleAppointment={this.props.rescheduleAppointment}
                waitForSystemUpdate={this.props.waitForSystemUpdate}
                popups={this.popups}
                toggleCallActive={this.props.toggleCallActive}
                onAppointmentEnded={this.props.onAppointmentEnded}
                openAppointment={this.openAppointment}
                hideWith={this.props.hideWith}
                    />;
            } else if (msg.data.connect) {
                return msg.data.connect();
            } 
        }
        let text = msg.text;
        let editing = false;
        if (this.state.editingMessage && this.state.editingMessage.ts == msg.ts) {
            text = this.editor.getText();
            editing = true;
        }
        if (false && msg.linkPreviews) msg.linkPreviews.map(p => {
            const url = p.url;
            if (!url.endsWith(".gif")) {
                text = text.split("<"+url+">").join("").split(url).join("");
            }
        });
        if (msg.files && msg.files.length > 0) {
            const file = msg.files[0];
            ////console.log("file: ", file);
            let text = msg.text;
            let name = file.name;
            if (!name) {
                if (file.path) {
                    const comps = file.path.split('-')
                    name = comps.slice(2, comps.length-1).join("-");
                    file.name = name;
                }
            }
            if (name) {
                text += ": "+name;
            }
            let icon = file.contentType.startsWith("image/") || file.contentType.startsWith("video/") ? ImageIcon : FileIcon;
            if (file.contentType.startsWith("image/svg")) {
                icon = FileIcon;
            }
            let cantPlayVideo = false;
            if (isSafari() && file.contentType.startsWith("video/webm")) {
                cantPlayVideo = true;
            }
            const uploading = file.state == 'uploading';
            if (uploading) {
                let uploading;
                let className = "uiChatFileUploadTypeImage";
                if (file.contentType.startsWith("image/")) {
                    uploading = "Uploading an image";
                } else if (file.contentType.startsWith("video/")) {
                    uploading = "Uploading a movie";
                } else {
                    className = "uiChatFileUploadTypeFile";
                    uploading = "Uploading a file";
                }
                const filename = name;
               
                return <div className={'uiChatFileUpload ' + className}>
                    <div className='uiChatFileUploadSpinnerAndText'>
                    <div className='uiChatFileUploadSpinner'><ReactSVG src={SpinnerShape}/></div>
                    <div className='uiChatFileUploadText'>
                    <span className='uiChatFileUploadTextUploading'>{uploading}&nbsp;</span>
                    <span className='uiChatFileUploadTextFilename'>{filename}&nbsp;</span>
                    <span className='uiChatFileUploadTextPercent'>{file.progress}%</span>
                    </div>
                    </div>
                    <div className='uiChatFileUploadIcon'><ReactSVG src={icon}/></div>
                    </div>
            } else {
                if (icon == FileIcon || cantPlayVideo) {
                    const downloading = file.state == 'downloading';
                    const download = () => {
                        if (file.state == 'downloading') return;
                        file.state = "downloading";
                        this.markDirty(msg);
                        this.forceUpdate();
                        return this.props.downloadFile(msg).then(() => {
                            delete file.state;
                            this.markDirty(msg);
                            this.forceUpdate();
                        });
                    }
                    return <div className='uiChatMessageBody'>
                        <div className='uiChatFileUpload uiChatFileDownload' onClick={download}>
                        <div className='uiChatFileUploadSpinnerAndText'>
                        {downloading ? <div className='uiChatFileUploadSpinner'><ReactSVG src={SpinnerShape}/></div> :
                         <div className='uiChatFileUploadIcon'><ReactSVG src={Save}/></div>}
                        <div className='uiChatFileUploadText'>
                        <span>Download&nbsp;</span><span className='uiChatFileUploadTextFilename'>{file.name}</span>
                        </div>
                        </div>
                        <div className='uiChatFileUploadIcon'><ReactSVG src={icon}/></div>
                        </div>                    
                        </div>
                } else if (!file.src) {
                    const resolveFile  = () => {
                        if (file.resolving) return;
                        file.resolving = true;
                        file.resolve().then(() => {
                            this.markDirty(msg);
                            if (file.src) {
                                setTimeout(()=>this.forceUpdate(() => {
                                    if (this.props.mostRecent == msg.ts) {
                                        //console.log("file resolution complete: scroll to bottom");
                                        setTimeout(this.scrollToBottom, 20);
                                    }
                                }));
                            }                            
                        });
                        this.markDirty(msg);
                        this.forceUpdate();
                    }
                    if (file.resolve) {
                        if (file.contentType.startsWith("video/")) {
                            return <div className='uiChatMessageBody'>
                                <div className='uiChatFileUpload uiChatFileDownload' onClick={resolveFile}>
                                <div className='uiChatFileUploadSpinnerAndText'>
                                {file.resolving ? <div className='uiChatFileUploadSpinner'><ReactSVG src={SpinnerShape}/></div> :
                                 <div className='uiChatFileUploadIcon'><ReactSVG src={Forward}/></div>}
                                <div className='uiChatFileUploadText'>
                                <span>Watch&nbsp;</span><span className='uiChatFileUploadTextFilename'>{file.name}</span>
                                </div>
                                </div>
                                <div className='uiChatFileUploadIcon'><ReactSVG src={icon}/></div>
                                </div>                    
                                </div>
                        } else {
                            resolveFile();
                            return <div className='uiChatMessageBody'>
                                <div className='uiChatFileUpload uiChatFileDownload'>
                                <div className='uiChatFileUploadSpinnerAndText'>
                                <div className='uiChatFileUploadSpinner'><ReactSVG src={SpinnerShape}/></div> 
                                <div className='uiChatFileUploadText'>
                                <span>Loading&nbsp;</span><span className='uiChatFileUploadTextFilename'>{file.name}</span>
                                </div>
                                </div>
                                <div className='uiChatFileUploadIcon'><ReactSVG src={icon}/></div>
                                </div>                    
                                </div>
                        }
                    }
                }
            }
        }
        let markdown = '';
        if (text) {
            const links = find(text);
            links.map(link => {
                const markdownLink = "<"+link.href+">";
                if (text != markdownLink) {
                    text = text.split(markdownLink).join(link.href).split(link.href).join(markdownLink);
                }
            });
            markdown = this.slackToHtml(editing, msg.ts, text);
        }
        const files = msg.files ? msg.files.filter(file => file.src) : null;
        return <div className={'uiChatMessageBody' + (editing ?' uiChatMessageBodyEdited' : '')}>
            {files && files.length > 0 ? files.map(file => <InlineMedia maximize={()=>this.openFile(file)} downloadFile={this.props.downloadFile} msg={msg} file={file}/>) :
             <div className='uiChatMessageText'>{markdown}</div>}
        {false && msg.linkPreviews && msg.linkPreviews.map(preview => this.renderLinkPreview(preview))}

         </div>;
    }


    renderChatMessageFrom = (contact) => {
        return <div className={'uiChatFromName'}>{contact.displayName} <span className='uiChatFromCreds'>{contact.creds}</span></div>;
    }

    tappedReaction = (msg, reaction) => {
        const emoji = nameToEmoji(reaction.name);
        this.props.reactToChatMessage(msg, emoji);
    }

    renderReaction = (msg, reaction) => {
        const emoji = nameToEmoji(reaction.name);
        if (emoji) {
            const img = emojiToSprite(emoji);
            let tip = "";
            let sep = "";
            var i = 0;
            reaction.users.map(u => {
                const c = this.props.localContact.uid == u ? this.props.localContact : this.props.remoteContact;
                tip += sep;
                tip += c.displayName;
                sep = i+2 == reaction.users.length ? " and " : ", ";
                i++;
            });
            const onClick = () => this.tappedReaction(msg, reaction);
            tip += " reacted with " +emoji.name;
            return <Tooltip key={emoji.name} enterDelay={750} title={tip}>
                <div onClick={onClick} className={'uiChatReaction'}>{img}<div className='uiChatReactionCount'>{reaction.users.length}</div></div>
                </Tooltip>;
        }
    }

    addReaction = (msg, emoji) => {
        this.props.reactToChatMessage(msg, emoji);
        this.dismissEmojiPicker();
    }

    reactToMessage = msg => {
        this.setState({
            addReaction: emoji => this.addReaction(msg, emoji)
        });
        this.props.setPopupShowing(true);
    }

    dismissEmojiPicker = () => {
        this.setState({
            addReaction: null,
        });
        this.props.setPopupShowing(false);
    }

    deleteMessage = msg => {
        this.props.deleteChatMessage(msg);
    }

    cancelEdit = () => {
        this.editor.clear();
        this.setState({
            editingMessage: null,
            editingImages: {}
        });
    }

    editMessage = msg => {
        this.ignoreClickAway = true;
        this.markDirty(msg);
        this.setState({
            editingMessage: msg
        }, () => {
            let text = msg.text;
            const n = parse(unescape(text));
            const nodeStack = [];
            const render = (node, index)=> {
                const content = () => {
                    const doit = () => {
                        if (!node.children) {
                            if (node.text) {
                                return render(parse(node.text));
                            }
                        }
                        return node.children.map(render);
                    }
                    nodeStack.push(node);
                    try {
                        return doit();
                    } finally {
                        nodeStack.shift();
                    }
                }
                switch (node.type) {
                case NodeType.Root:
                    return content();
                case NodeType.Text:
                    {
                        return node.text;
                    }
                case NodeType.Bold:
                    {
                        return "*"+content()+"*";
                    }
                case NodeType.Italic: 
                    {
                        return "_"+content()+"_";
                    }
                case NodeType.Strike: 
                    {
                        return "~"+content()+"~";
                    }
                case NodeType.Quote: 
                    return ">"+content();
                case NodeType.ChannelLink: 
                case NodeType.UserLink:
                    return content();
                case NodeType.URL:
                    {
                        node.url = node.url.replace(/&amp;/g, "&");
                        //debugger;
                        if (node.url.endsWith('.gif')) {
                            return "<img src='"+node.url+"'/>";
                        }
                        return node.url;
                    }
                case NodeType.Command: 
                    return content();
                case NodeType.Emoji: 
                    {
                        const emoji = nameToEmoji(node.name);
                        //debugger;
                        if (emoji) {
                            return emojiToHtmlImg(emoji);
                        }
                        return content();
                    }
                case NodeType.PreText: 
                    return <pre className='slack_pre'>{content()}</pre>;
                    
                case NodeType.Code: 
                    return "`"+content()+"`";
                }
                //////console.log("not parsed: ", node);
                return "";
            }
            const html = render(n).join("");
            this.editor.clear();
            this.editor.insert(html);
        })
    }

    saveMessage = () => {
        const msg = this.state.editingMessage;
        const text = this.editor.getText();
        msg.text = text;
        this.props.saveMessage(msg);
        this.editor.clear();
        this.setState({
            editingMessage: null,
            editingImages: {}
        });
    }

    hideMenu = () => {
        if (this.state.showMenu) {
            this.setState({
                showMenu: false
            });
        }
    }
    
    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        });
    }

    manageGroup = () => {
        this.setState({
            showMenu: false,
            showManageGroup: true,
        });
    }

    dismissManageGroup = () => {
        this.setState({
            showManageGroup: false,
        });
    }

    createGroup = () => {
        this.setState({
            showMenu: false,
            showManageGroup: true,
        });
    }

    recordMessage = () => {
        this.setState({
            showMenu: false,
        });
        return this.props.recordMessage();
    }

    removeContact = () => {
        return this.props.removeContact();
    }

    cache = {};

    markAllDirty = () => {
        this.cache = {};
    }

    markDirty = msg => {
        delete this.cache[msg.ts];
    }

    renderChatMessage = (msg, prev, next)  => {
        const ts = msg.ts;
        let cached = this.cache[ts];
        if (cached) return cached;
        cached = this.renderChatMessageImpl(msg, prev, next);
        let canCache = (!msg.data || msg.data.type != 'connect') && msg != this.state.editingMessage;
        if (msg.files) {
            msg.files.map(file => {
                if (file.contentType.startsWith("image/") && !file.src || file.resolve || file.state) {
                    canCache = false;
                }
            });
        }
        if (canCache) {
            this.cache[ts] = cached;
        }
        return cached;
    }
    
    renderChatMessageImpl = (msg, prev, next)  => {
        const formatMessageTime = ()=> {
            const time = Number(msg.ts);
            let sameDay = "h:mm a";
            let sameElse =  "MM/DD/YYYY \\a\\t ";
            const sameMinute = (t1, t2) => {
                const d1 = new Date(t1);
                const d2 = new Date(t2);
                return (d1.getYear() === d1.getYear() && d1.getMonth() == d2.getMonth() && d1.getDate() === d2.getDate() && d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes());
            };
            if ((prev && sameMinute(time, prev)) || (next && sameMinute(time, next))) {
                sameDay = "h:mm:ss a";
            }
            const timestamp =  moment(time).calendar(null, {
                sameDay: sameDay,
                //lastDay: "[Yesterday] "+sameDay,
                //lastWeek: "[Last] dddd "+sameDay,
                sameElse: sameElse+ sameDay,
            })
            return timestamp;
        }
        const tete = {
            uid: "tete",
            displayName: "TeTe",
            profileImage: TeTeLogo
        }
        const from = msg.system ? tete : this.props.me.getContact(msg.from);
        const fromMe = msg.from == this.props.me.self.uid;
        const ringColor = from.profileImage ? '#FFFFFF' : '#dcdcdc';

        const renderHeader = (contact, noTime) => {
            return <div key='header' className={'uiChatMessageHeader' + (noTime ? ' uiChatMessageHeaderNoTime' : '')}>
                {!msg.system && (!prev || msg.from != prev.from) ? this.renderChatMessageFrom(from) : <div key='empty'/>}
            {!noTime ? renderTime() : null}
            </div>
        }

        const renderLeft = () => {
            return <div key='left' className='uiChatMessageLeft'>
                {(!prev || prev.from != msg.from) && !this.props.openChat &&
                <div className='uiChatProfileIconContainer'>
                 <UIProfileIcon contact={from}/>
                 </div>
                }
                </div>
        }

        const renderTime = () => {
            return <div className='uiChatMessageTime'>{formatMessageTime()}</div>
        }
        const noTime = !msg.system && next && (prev && msg.ts - prev.ts < 15 * 1000 * 60);
        const isSameFrom = prev && prev.from == msg.from;
        return <div key={msg.ts} className={'uiChatMessage'  + (!msg.system && isSameFrom ? " uiChatMessageSameFrom": "")}>
            {!this.props.openChat && renderLeft()}
            <div key='center' className='uiChatMessageCenter'>
            {renderHeader(from, noTime)}
              <div key='content' className='uiChatMessageContent'>
                 {this.renderChatMessageBody(msg)}
              </div>
            {!msg.system && <div key='bottom' className={'uiChatMessageBottomRow' + (noTime && (!msg.reactions || msg.reactions.length == 0) ? " uiChatMessageBottomRowNoReactions" : "")}>
              <div key='reactions' className='uiChatMessageReactions'>{msg.reactions ? msg.reactions.map(x => this.renderReaction(msg, x)): null}</div>
              <div key='buttons' className='uiChatMessageButtons'>
                <UIButton tooltip={"React"}  key='react' className='uiChatEmojiButton' icon={ChatReact} action={()=>this.reactToMessage(msg)}/>
             {fromMe && <UIButton tooltip={"Edit"} key='edit' className='uiChatEmojiButton' disabled={!fromMe} icon={ChatEdit} action={()=>this.editMessage(msg)}/>}
             {fromMe && <UIButton tooltip={"Delete"} key='del' className='uiChatEmojiButton' disabled={!fromMe} icon={ChatDelete} action={()=>this.deleteMessage(msg)}/>}
              </div>
             </div>}
             </div>
            <div key='right' className='uiChatMessageRight'/>
        </div>;
    }

    componentWillUnmount() {
        clearInterval(this.scrollHeightPoller);
        clearTimeout(this.typingTimeout);
        if (this.typingSub) this.typingSub.unsubscribe();
        if (this.provSub) this.provSub.unsubscribe();
        if (this.clientSub) this.clientSub.unsubscribe();
        window.removeEventListener("resize", this.onResized);
    }

    componentDidMount() {
        if (false) this.scrollHeightPoller = setInterval(() => {
            if (this.scrollWindow.scrollHeight != this.lastHeight) {
                this.lastHeight = this.scrollWindow.scrollHeight;
                this.fixupScrollTop();
            }
        }, 200);
        this.fixupScrollTop();
        if (this.props.observeTyping) {
            this.typingSub = this.props.observeTyping().subscribe(ts => {
                ////console.log("observe typing: ", ts);
                clearTimeout(this.typingTimeout);
                this.setState({
                    typing: true
                });
                this.typingTimeout = setTimeout(() => {
                    this.setState({
                        typing: false
                    });
                }, 5000);
            });
        }
        if (this.props.remoteContact) {
            //debugger;
            this.provSub = this.props.me.observeSubscription(this.props.remoteContact).subscribe(change => {
                //console.log("provSub: ", change);
                if (change.type == 'removed' || change.subscription.state == 'client-cancel' || change.subscription.state == 'provider-cancel') {
                    this.setState({
                        providerSubscription: null,
                        openSubscription: null,
                    });
                } else {
                    this.setState({
                        providerSubscription: change.subscription,
                        openSubscription: null
                    });
                }
            }, err => {
                //debugger;
            });
            this.clientSub = this.props.me.observeMySubscription(this.props.remoteContact).subscribe(change => {
                //console.log("clientSub: ", change);
                if (change.type == 'removed' || change.subscription.state == '' || change.subscription.state == 'decline' || change.subscription.state == 'client-cancel' || change.subscription.state == 'provider-cancel') {
                    this.setState({
                        clientSubscription: null,
                        openSubscription: null,
                    });
                } else {
                    this.setState({
                        clientSubscription: change.subscription,
                        openSubscription: null,
                    });
                }
            }, err => {
                //debugger;
            });
        }
        this.windowListener = window.addEventListener("resize", this.onResized);
        if (this.props.onChatCreated) this.props.onChatCreated(this);
    }

    onResized = () => {
        this.forceUpdate();
    }

    takeFocus = () => {
        if (!hasSoftKeyboard()) this.editor.focus()
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps.updates != this.props.updates) {
            this.fixupScrollTop();
        }
        if (this.props.active && !prevProps.active) {
            this.takeFocus();
        }
        const setEditorFocus = () => {
            if (!this.state.addGIF && !this.state.addReaction && !this.state.addAppointment) {
                this.takeFocus();
            }
        }
        if (!this.state.addAppointment) {
            this.cal = null;
            if (prevState.addAppointment) {
                setEditorFocus();
            }
        }
        if ((!this.state.addGIF && prevState.addGIF) || (!this.state.addReaction && prevState.addReaction)) {
            setEditorFocus();
        }
        if (this.cal && prevState.openEvent && !this.state.openEvent) {
            this.cal.takeFocus();
        }
        if (this.props.messages.length > 0) {
            this.checkScrollBack();
        }
        if (true) {
            if (prevProps.visible != this.props.visible) {
                console.log("chat update ", this.props.remoteContact.displayName, " => ", this.props.visible);
                if (!prevProps.visible) {
                    this.popScrollBottom();
                } else {
                    this.pushScrollBottom();
                }
            }
        }
        const current = {}
        for (var msg of this.props.messages) {
            current[msg.ts] = msg;
        }
        for (var i in this.cache) {
            if (!current[i]) {
                delete this.cache[i];
            }
        }
    }

    popScrollBottom() {
        this.scrollBottom = this.savedScrollBottom;
        this.fixupScrollTop();
        setTimeout(() => {
            console.log("popScrollBottom: ", this.scrollBottom, " => ", this.savedScrollBottom);
            this.scrollBottom = this.savedScrollBottom;
            this.fixupScrollTop();
        }, 50);
    }

    pushScrollBottom() {
        this.savedScrollBottom = this.scrollBottom;
        console.log("pushScrollBottom: ", this.savedScrollBottom);
    }

    fixupScrollTopLater = () => {
        clearTimeout(this.fixupTimeout);
        setTimeout(this.fixupScrollTop);
    }

    setScrollWindow = ref => {
        if (ref && ref != this.scrollWindow) {
            this.scrollWindow = ref;
            this.scrollBottom = ref.scrollHeight - ref.scrollTop;
            this.lastHeight = ref.offsetHeight;
            this.resizeObserver.observe(this.scrollWindow);
            this.onScrollHeight = ref.scrollHeight;
            ref.onscroll = e => {
                console.log(this.props.remoteContact.displayName, ": onscroll top: ", ref.scrollTop, " visible: ", this.props.visible);
                const before = this.scrollBottom;
                const heightDelta = ref.scrollHeight - this.lastScrollHeight;
                //console.log("onscroll heightDelta: ", heightDelta);
                this.scrollBottom = ref.scrollHeight - ref.scrollTop;
                this.lastScrollHeight = ref.scrollHeight;
                if (before != this.scrollBottom) {
                    //console.log("onscroll bottom => ", this.scrollBottom);
                }
                //////console.log("onscroll height: ", this.scrollWindow.scrollHeight);
                this.checkScrollBack();
            }
        }
    }

    checkScrollBack = () => {
        if (this.props.scrollBack) {
            const ref = this.scrollWindow;
            if (ref.scrollTop == 0 || ref.scrollHeight <= ref.offsetHeight) {
                if (this.props.messages.length > 0) {
                    const earliest = this.props.messages[0].ts;
                    this.props.scrollBack(earliest);
                }
            }
        }
    }

    setScrollContent = ref => {
        if (ref && ref != this.scrollContent) {
            this.scrollContent = ref;
            this.lastContentHeight = ref.offsetHeight;
            //////console.log("initial scroll content height: ", this.lastContentHeight);
            this.resizeObserver.observe(this.scrollContent);
        }
    }

    scrollToBottom=()=> {
        console.log("scroll to bottom: ", this.props.remoteContact.displayName);
        this.scrollBottom = this.scrollWindow.offsetHeight;
        this.fixupScrollTop();
    }

    fixupScrollTop = (div) => {
        const prev = this.scrollWindow.scrollTop;
        const next = this.scrollWindow.scrollHeight - this.scrollBottom;
        this.lastScrollHeight = this.scrollWindow.scrollHeight;
        if (next != prev) {
            //console.log("fixupScrollTop");
            //console.log("scrollTop ", prev, " => ", next);
            this.scrollWindow.scrollTop = next;
            //console.log("scrollBottom: ", this.scrollBottom);
            //////console.log("scrollTop: ", this.scrollWindow.scrollTop);
            //////console.log("scrollHeight: ", this.scrollWindow.scrollHeight);
            //////console.log("scrollContentHeight: ", this.scrollContent.offsetHeight);
        }
    }

    onFileInput = e => {
        this.handleDataTransfer(e, e.target);
        e.target.value = "";
    }

    dismissGIFPicker = () => {
        this.setState({
            addGIF: null,
        });
        this.props.setPopupShowing(false);
    }

    renderGIFPicker() {
        const theme = {
            select: 'uiChatGIFSelect',
            selectInput: 'uiChatGIFSelectInput',
            attribution: 'uiChatGIFAttribution'
        }
        return <div className='uiChatEmojiPicker GIFPicker'>
            <ClickAwayListener onClickAway={this.dismissGIFPicker}>
            <div className='uiEmojiPickerContainer'>
            <div className='uiChatGiphyContainer'>
            <GiphySelect autoFocus={true} theme={theme} onEntrySelect={this.doInsertGIF} requestKey={'NliWm3ch154djr59X5ONE3UazkbDgepu'}/>
            <img className='uiChatGiphyAttributionImage' src={GiphyAttribution}/>
            </div>
            <div className='uiEmojiPickerArrow'>
            <div className='uiEmojiPickerArrowShape'/>
            </div>
            </div>
            </ClickAwayListener>
            </div>
    }

    onSelectEmoji = emoji => {
        //console.log("emoji: ", emoji);
        this.state.addReaction(normalizeEmoji(emoji));
        this.dismissEmojiPicker();
    }

    renderEmojiPicker() {        
        return <div className='uiChatEmojiPicker'>
            <ClickAwayListener onClickAway={this.dismissEmojiPicker}>
            <div className='uiEmojiPickerContainer'>
            <Picker autoFocus={!hasSoftKeyboard()} native={isApple()} set={emojiSet} title="Choose an emoji" emoji="+1" onSelect={this.onSelectEmoji} emojisToShowFilter={this.emojisToShowFilter}/>
            <div className='uiEmojiPickerArrow'>
            <div className='uiEmojiPickerArrowShape'/>
            </div>
            </div>
            </ClickAwayListener>
            </div>
    }

    doInsertEmoji = emoji => {
        const html = emojiToHtmlImg(emoji);
        this.editor.insert(html);
        this.dismissEmojiPicker();
    }

    insertEmoji = () => {
        this.props.setPopupShowing(true);
        this.setState({
            addReaction: this.doInsertEmoji
        });
    }

    setCal=x=> {
        if (x && x != this.cal) {
            this.cal = x;
        }
    }

    onCalendarClick = date => {
        if (date.getTime() === this.state.calendarDate) {
            this.createAppointment(date);
        } else {
            this.setState({
                calendarDate: date.getTime()
            })
        }
    }

    onChangeSubscription = (field, value) => {
        if (field == 'startDate') {
            value = value.getTime();
        }
        this.state.openSubscription[field] = value;
        console.log(field, " => ", value);
        this.forceUpdate();
    }

    openSubscription = sub => {
        this.subscribeToChat();
    }

    acceptSubscription = () => {
        return this.props.me.acceptSubscription(this.props.remoteContact).then(result => {
            if (!result.error) {
                this.dismissChatSubscription();
            }
            return result;
        });
    }

    declineSubscription = () => {
        return this.props.me.declineSubscription(this.props.remoteContact).then(result => {
            if (!result.error) {
                this.dismissChatSubscription();
            }
            return result;
        });
    }

    updateSubscription = () => {
        const form = this.state.openSubscription;
        const fields = ["description", "invoiceAmount", "invoiceDescription",
                        "startDate", "responseTime"];
        const updates = {};
        debugger;
        fields.map(field => {
            let value = form[field];
            if (!this.state.providerSubscription || this.state.providerSubscription[field] != value) {
                updates[field] = value;
            }
        });
        console.log("form: ", form);
        console.log("updates: ", updates);
        let p;
        if (this.state.providerSubscription) {
            if (this.state.openSubscription.state != 'offer') {
                updates.state = 'offer'
            }
            p = this.props.me.updateSubscription(this.props.remoteContact, updates);
        } else {
            p = this.props.me.offerSubscription(this.props.remoteContact, updates);
        }
        return p.then(result => {
            if (!result.error) {
                this.dismissChatSubscription();
            }
            return result;
        });
    }

    cancelSubscription = () => {
        let p;
        if (this.state.clientSubscription) {
            p = this.props.me.cancelClientSubscription(this.props.remoteContact);
        } else {
            p = this.props.me.cancelSubscription(this.props.remoteContact);
        }
        return p.then(this.dismissChatSubscription);
    }



    renderSubscribeToChat() {
        return <div className='uiScheduleAppointmentPopup'>
            <ClickAwayListener onClickAway={this.dismissChatSubscription}>
            <UISubscribeToChat
        title={"Professional Chat"}
        editable={!this.state.clientSubscription &&
                  (!this.state.providerSubscription || this.state.providerSubscription.state != "active")}
        isNew={!this.state.providerSubscription && !this.state.clientSubscription}
        small={!this.state.clientSubscription}
        me={this.props.me}
        state={this.state.openSubscription.state}
        with={this.props.remoteContact}
        withReadOnly={true}
        description={this.state.openSubscription.description}
        responseTime={this.state.openSubscription.responseTime}
        startDate={new Date(this.state.openSubscription.startDate)}
        on={new Date(this.state.openSubscription.startDate)}
        invoiceDescription={this.state.openSubscription.invoiceDescription}
        invoiceAmount={this.state.openSubscription.invoiceAmount}
        client={this.state.clientSubscription}
        onChange={this.onChangeSubscription}
        back={this.dismissChatSubscription}
        accept={this.acceptSubscription}
        decline={this.declineSubscription}
        update={this.updateSubscription}
        cancel={this.cancelSubscription}
            />
            </ClickAwayListener>
             </div>
    }
    
    onCalendarPlus = e => this.createAppointment();
    renderCalendarPicker() {
        const isIPad = () => {
            return document.documentElement.clientWidth < 1024;
        }
        let className = 'uiChatEmojiPicker uiChatCalendarPicker';
        if (isIPad()) {
            className +=  " uiChatCalendarPickerIPad";
        }
        return <div className={className}>
            <ClickAwayListener onClickAway={this.dismissCalendarPicker}>
            <div className='uiEmojiPickerContainer'>
            <div className='uiChatCalendarContainer'>
            <UICalendar contactFilter={this.props.remoteContact} picker={true} visible={true} onSet={this.setCal} me={this.props.me} onClick={this.onCalendarClick}/>
            <div className='uiCalendarEventControls'>
            <div className='uiCalendarEventPlus' onClick={this.onCalendarPlus}>
            <div className='uiCalendarPlusIcon' ><ReactSVG src={Plus}/></div>
            <div className='uiCalendarPlusText'>Schedule an appointment with {this.props.contact.displayName}</div>
            </div>
            </div>
            </div>
            <div className='uiEmojiPickerArrow'>
            <div className='uiEmojiPickerArrowShape'/>
            </div>
            {this.state.openEvent &&
             <div className='uiScheduleAppointmentPopup'>
             <UIScheduleAppointment 
              appointmentId={this.state.openEvent.id}
               back={() => this.setState({
                   openEvent: null,
               })}
              isNew={this.state.openEvent.isNew}
              withReadOnly={true}
              date={this.state.openEvent.date}
              start={this.state.openEvent.start}
              end={this.state.openEvent.end}
              headerTitle={"Schedule Appointment"}
              title={this.state.openEvent.title}
              with={this.state.openEvent.with}
              on={this.state.openEvent.date}
              schedule={this.scheduleAppointment}
              error={this.state.openEvent.error}
              onChange={this.onChangeEvent}
              editable={this.state.openEvent.editable}
              client={this.state.openEvent.client}
              invoiceAmount={this.state.openEvent.invoiceAmount || 0}
              invoiceDescription={this.state.openEvent.invoiceDescription}
             paymentIntentId={this.state.openEvent.paymentIntentId}
             status={this.state.openEvent.status}
             paymentStatus={this.state.openEvent.paymentStatus}
             paymentMethod={this.state.openEvent.paymentMethod}
             paymentIntentId={this.state.openEvent.paymentIntentId}
              event={this.state.openEvent}
              me={this.props.me}
             />
             </div>}
            </div>
            </ClickAwayListener>
            </div>
    }

    dismissCalendarPicker = () => {
        if (this.state.openEvent) return;
        //console.log("dismiss calendar picker");
        this.setState({
            addAppointment: null,
            openEvent: null,
            calendarDate: null,
        });
    }

    rescheduleAppointment = () => {
        const appt = this.state.openEvent.appt;
        const id = appt.id;
        const data = this.state.openEvent;
        const date = data.date;
        const start = getTime(date, data.start);
        const end = getTime(date, data.end);
        const prevStart = appt.start;
        const prevEnd = appt.end;
        const prevInvoiceAmount = appt.invoiceAmount || 0;
        const prevInvoiceDescription = appt.invoiceDescription || "";
        const prevTitle = appt.title || "";
        const updates = {
            id: id,
            start: start,
            end: end,
            invoiceDescription: this.state.openEvent.invoiceDescription || "",
            invoiceAmount: this.state.openEvent.invoiceAmount || 0,
            title: this.state.openEvent.title || "",
        }
        if (updates.start == prevStart &&
            updates.end == prevEnd &&
            updates.invoiceDescription == prevInvoiceDescription &&
            updates.invoiceAmount == prevInvoiceAmount &&
            updates.title == prevTitle) {
            ////console.log("no change");
            this.closeEvent();
            return Promise.resolve();
        }
        const p = this.props.waitForSystemUpdate(appt);
        return this.props.me.updateAppointment(updates).then(response => {
            const appt = this.state.openEvent.appt;
            if (!response.data.error) {
                this.closeEvent();
            }
            if (response.data.error) {
                return response;
            }
            return p;
        });
    }

    closeEvent = () => {
        this.dismissCalendarPicker();
    }

    
    scheduleAppointment = () => {
        const data = this.state.openEvent;
        let p;
        if (data.appt) {
            p = this.rescheduleAppointment();
        } else  {
            const contact = data.with;
            const date = data.date;
            const start = data.start;
            const end = data.end;
            const updates = {
                start: getTime(date, start),
            end: getTime(date, end),
                title: data.title,
                invoiceDescription: data.invoiceDescription,
                invoiceAmount: data.invoiceAmount,
                title: data.title,
            }               
            window.showProgressIndicator("Scheduling");
            p = this.props.me.createAppointment(contact, updates);
        }
        return p.then(() => {
            this.setState({
                openEvent: null,
                addAppointment: null,
                calendarDate: null,
            });
        });
    }

    insertAppointment = () => {
        this.setState({
            addAppointment: true
        });
    }

    subscribeToChat = () => {
        let openSubscription;
        if (this.state.clientSubscription) {
            openSubscription = clone(this.state.clientSubscription);
        } else if (this.state.providerSubscription) {
            openSubscription = clone(this.state.providerSubscription);
        } else {
            openSubscription = {
                responseTime: 1,
                startDate: Date.now(),
                description: "Professional Chat",
            }
        }
        this.setState({
            addChatSubscription: true,
            openSubscription: openSubscription
        });
    }

    dismissChatSubscription = () => {
        this.setState({
            addChatSubscription: false,
            openSubscription: null
        });
    }

    createAppointment = (date) => {
        const with_ = this.props.remoteContact;
        this.props.setPopupShowing(true);
        const now = new Date(Date.now());
        if (!date) {
            date = now;
        } else {
            //debugger;
            date.setHours(now.getHours());
        }
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        const start = date;
        const end = new Date(date);
        end.setHours(date.getHours() % 24 + 1)
        this.setState({
            openEvent: {
               id: null,
               date: start,
               start: start,
               end: end,
               with: with_,
               isNew: true,
               title: "Video Conference",
               editable: true,
               client: false,
               reschedule: false,
               invoiceDescription: "",
               invoiceAmount: 0,
               paymentIntentId: "",
               status: "",
               paymentStatus: "",
               finalPaymentMethod: null,
               paymentIntentId: ""
           }
       });
    }

    openAppointment = appt  => {
        if (appt.client == this.props.me.self.uid || this.props.openChat) {
            return this.props.rescheduleAppointment(appt);
        }
        let start = new Date(appt.start);
        let end = new Date(appt.end);
        this.setState({
            addAppointment: true,
            openEvent: {
                isNew: false,
                appt: appt,
                id: appt.id,
                date: start,
                start: start,
                end: end,
                with: appt.contact,
                title: appt.title || "Video Conference",
                editable: appt.editable,
                client: appt.client.uid == this.props.me.self.uid,
                reschedule: true,
                invoiceDescription: appt.invoiceDescription || "",
                invoiceAmount: appt.invoiceAmount || 0,
                paymentIntentId: appt.paymentIntentId,
                status: appt.status,
                paymentStatus: appt.paymentStatus,
                finalPaymentMethod: appt.finalPaymentMethod,
                paymentIntentId: appt.paymentIntentId,
            }
        });
        return Promise.resolve();
    }

    insertGIF = () => {
        this.props.setPopupShowing(true);
        this.setState({
            addGIF: this.doInsertGIF
        }, () => {
            const pollFocus = () => {
                setTimeout(() => {
                    const els = document.getElementsByClassName("uiChatGIFSelectInput");
                    if (els && els.length > 0) {
                        return els[0].focus();
                    } else {
                        if (this.state.addGIF) {
                            pollFocus();
                        }
                    }
                }, 50);
            }
            pollFocus();
        });
    }

    doInsertGIF = gif => {
        const url = gif.images.downsized.url;
        const link = gif.embed_url;
        const html = "<img class='gif' src='"+url+"' gif-link='"+url+"'/>";
        this.state.editingImages[url] = url;
        this.editor.insert(html);
        this.dismissGIFPicker();
    }

    videos = {}

    insertVideo = blob => {
        const url = URL.createObjectURL(blob);
        this.videoURL = url;
        this.video = blob;
        const html = "<video controls class='vid' vid-link='"+url+"'/><source type='"+blob.type+"' src='"+url+"' /></video>";
        this.editor.insert(html);
    }

    renderUploads = () => {
        return <UploadProgress uploads={this.state.uploads}/>
    }

    onChangeEvent = (field, value) => {
        if (value == this.state.openEvent[field]) return;
        ////console.log(field, " => ", value);
        this.state.openEvent[field] = value;
    }

    setFilesRef = ref => {
        if (ref) this.filesRef = ref;
    }

    setMediaFilesRef = ref => {
        if (ref) this.mediaFilesRef = ref;
    }

    chatInputClickAway = () => {
        if (this.state.editingMessage && !this.ignoreClickAway) {
            this.cancelEdit();
        }
        this.ignoreClickAway = false;
    }

    render() {
        const sendLabel = "Send Message";
        console.log("UIChat callActive: ", this.props.callActive);
        const isDev= this.props.me.isDev;
        return <div className={'uiChat'} >
            <div className='uiChatMarginBottom'>
            <div key='chatMessages' className={'uiChatMessages'} ref={this.setScrollWindow}>
            <div key='chatMessagesContainer' className={'uiChatMessagesContainer'} ref={this.setScrollContent}>
            {
                this.props.messages.map((msg, i) => {
                    const prev = i > 0 ? this.props.messages[i-1] : null;
                    const next = i + 1 < this.props.messages.length ? this.props.messages[i+1] : null;
                    return this.renderChatMessage(msg, prev, next);
                })
            }
            </div>
            </div>            
            {this.props.contact && !this.props.callActive && <div className='uiChatCallControls'>
            <CallButton callActive={false} contact={this.props.contact} action={this.props.toggleCallActive}/>
            </div>}
            {this.props.sendMessage && <ClickAwayListener onClickAway={this.chatInputClickAway}><div key='chatInputContainer'className='uiChatInputContainer'>
            {this.state.addReaction && this.renderEmojiPicker()}
            {this.state.addGIF && this.renderGIFPicker()}
            {this.state.addAppointment && this.renderCalendarPicker()}
            {this.state.openSubscription && this.renderSubscribeToChat()}
            <div className='uiChatInputMessageEditorRow'>
            <UIMessageEditor
               autoFocus={isDesktop()}
               editorClass={'uiChatInput'}
               placeholder={"Send a message to " +this.props.remoteContact.displayName + " "+this.props.remoteContact.creds}
               placeholderClass={"uiChatInputPlaceholder"}
               ref={x=> this.setEditor(x)}
               onKeyDown={this.onKeyDown}
               onPaste={this.onPaste}
               onDrop={this.onDrop}
               onUpdate={this.onEditorUpdate}
               onBlur={this.onBlur}
               onFocus={this.onFocus}
               onHeightChanged={this.onEditorHeightChanged}
             />
             <div className='uiChatInputFieldButtons' style={!this.editor || this.editor.isEmpty() ? {display: 'none'} : null}>
             <UIButton tooltip={"Clear"} key='clear' className='uiChatUndoButton' disabled={false} icon={Cross} action={this.clearEdit}/>
             <UIButton tooltip={"Undo"} key='undo' className='uiChatUndoButton' disabled={false} icon={Undo} action={this.undoEdit}/>
             </div>             
            </div>
            <div key='chatButtonContainer' className='uiChatButtonContainer'>
            <div key='chatButtonContainerLeft' className='uiChatButtonContainerLeft'>
            <form key='form1'>
             <input ref={this.setFilesRef} className={'uiFileUpload'} id={'uiFileUpload-'+this.chatId} name={'uiFileUpload-'+this.chatId} type='file' onChange={this.onFileInput}/>
            <label htmlFor={'uiFileUpload-'+this.chatId}>
             <UIButton tooltip={"Files "}className='uiChatButton' icon={FileIcon} action={()=>{}}/>
            </label>
             </form> 
            <form key='form2'>
             <input ref={this.setMediaFilesRef} className={'uiFileUpload'} id={'uiImageUpload-'+this.chatId} name={'uiImageUpload-'+this.chatId} type='file' accept={'image/*,video/mp4'} onChange={this.onFileInput}/>
            <label htmlFor={'uiImageUpload-'+this.chatId}>
            <UIButton tooltip={"Media Files"} className='uiChatButton' icon={ImageIcon}/>
            </label>
            </form>
            <UIButton tooltip={"Emojis"} key='emoji' className='uiChatButton' icon={Emoji} action={this.insertEmoji}/>
            <UIButton tooltip={"GIFs"} key='gif' className={'uiChatButton uiChatButtonGiphy'} icon={Giphy} action={this.insertGIF}/>
            <UIButton tooltip={"Appointments"} key='cal' className={'uiChatButton'} icon={Cal} action={this.insertAppointment}/>
            {this.props.localContact.uid != this.props.remoteContact.uid && <UIButton tooltip={"Subscription"} key='subscribe' className={'uiChatButton'} icon={ChatSpace} action={this.subscribeToChat}/>}
            </div>
            <div key='typing' className='uiChatTyping' style={this.state.typing ? null: {visibility: 'hidden'}}>
            {this.props.remoteContact.displayName} is typing
            </div>
             <div className='uiChatSendButtonContainer'> 
            {!this.props.callActive && <ClickAwayListener onClickAway={this.hideMenu}>
             <div className='uiChatMenu'>
             <div className={'uiChatMenuButton' + (this.state.showMenu ? " uiChatMenuButtonActive" : "")} onClick={this.toggleMenu}><ReactSVG src={Menu}/></div>
             {this.state.showMenu && 
              <div className='uiChatMenuMenu'>
              {this.props.me.isDev && (this.isGroup() ?
               <div key='manageGroup' className='uiChatRecordMessage' onClick={this.manageGroup}>{this.isGroupOrganizer() ? "Manage Group" : "Members"} </div> :
                                       <div key='manage' className='uiChatRecordMessage' onClick={this.createGroup}>Create Group</div>)}
               
              {window.MediaRecorder && <div key='recordMessage' className='uiChatRecordMessage' onClick={this.recordMessage}>Record Message</div>}
              {this.props.remoteContact.uid != this.props.me.self.uid && <div key='removeContact' className='uiChatRemoveContact'><UIRemoveContact contact={this.props.remoteContact} removeContact={this.removeContact}/></div>}
             </div>}
              </div>
             </ClickAwayListener>}
             <UIButton key='send' className='uiChatSendButton' icon={Send} label={sendLabel} action={this.send}/>
             
            </div>
            </div>
            </div>
             </ClickAwayListener>
            }
        </div>
            {this.state.showManageGroup &&
            <ClickAwayListener onClickAway={this.dismissManageGroup}>
            <div className='uiChatManageGroupContainer'>
             <UICreateGroup hide={this.dismissManageGroup} id={this.props.remoteContact.isGroup ? this.props.remoteContact.uid : null} me={this.props.me} contact={this.props.remoteContact} onChange={this.onModifyGroup}/>
            </div>
            </ClickAwayListener>}
            </div>
            
    }
}
