import React, { Component } from 'react';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {ReactSVG} from 'react-svg';
import {UIToggleButton} from "../Button";
import MicOn from "../../assets/icons/MicOn.svg";
import NoCamera from "../../assets/icons/NoCamera62x34.svg";
import Check from "../../assets/icons/CheckMark.svg";
import VideoOn from "../../assets/icons/VideoOn.svg";
import {isFirefoxDesktop} from "../../Platform";
//import Check from "../../assets/icons/Check.svg";
import './index.css'

let currentHome;

export class UICantOpenMedia extends Component {
    render() {
        return <div className='uiCantOpenMediaContainer'>
            <div className='uiCantOpenMedia'>
            <div className='uiCantOpenMediaIcon'><ReactSVG src={NoCamera}/></div>
            <div className='uiCantOpenMediaText'>{"Oof, can't access your camera"}</div>
            </div>
            </div>
    }
}


export class UISelectionList extends Component {
    constructor(props) {
        super(props);
    }
    
    selectOption = value => {
        let newValue = value;
        if (this.props.multiSelect) {
            const existing = this.props.value.find(x => x == value);
            if (existing) {
                newValue = this.props.value.filter(x => x != value);
            } else {
                newValue = this.props.value.concat([newValue]);
            }
        }
        this.props.select(newValue);
    }

    render() {
        return <div className='uiSelectionList' style={this.props.visible ? null: {display: "none"}}>
            {this.props.options.map((opt, i) => {
                let selected;
                if (this.props.multiSelect) {
                    selected = this.props.value.find(x => x == opt.value);
                } else {
                    selected = opt.value == this.props.value;
                }
                //console.log("opt.value: ", opt.value, " = ", this.props.value, " => ", selected);
                const className = "uiSelectionListOption" + (selected ? " uiSelectionListOptionSelected" : "");
                return <div key={i} className={className} onClick={()=>this.selectOption(opt.value)}>
                    <div className={'uiSelectionListOptionName'}>{opt.name}</div>
                    {selected && <div key='check' className={'uiSelectionListOptionCheck'}><ReactSVG src={Check}/></div>}
                    </div>
            })}
            </div>
    }
}

export class UIDeviceSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: "",
            selecting: false,
            options: {
                audioinput: [],
                videoinput: [],
            },
            selected: {
                audioinput: 0,
                videoinput: 0
            }
        }
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        currentHome = this;
        const videoInput = localStorage.getItem("videoinput");
        const audioInput = localStorage.getItem("audioinput");
        navigator.mediaDevices.enumerateDevices().then(devices => {
            //console.log("devices: ", devices);
            devices.map((device, i) => {
                if (this.state.options[device.kind]) {
                    this.state.options[device.kind].push(device);
                }
            });
            this.state.selected['videoinput'] = videoInput || this.state.options.videoinput[0].deviceId;
            this.state.selected['audioinput'] = audioInput || this.state.options.audioinput[0].deviceId;
            this.openStream();
            this.forceUpdate();
        }).catch(err => {
            debugger;
        });
        if (this.videoRef.current) {
            this.videoRef.current.onloadeddata = () => {
                this.setState({
                    videoWidth: this.videoRef.current.videoWidth,
                    videoHeight: this.videoRef.current.videoHeight,
                });
            }
        }
    }

    componentWillUnmount() {
        currentHome = null;
        if (this.videoRef.current) {
            this.videoRef.current.onloadeddata = null;
        }
        this.closeStream(this.stream);
    }

    selectVideo = () => {
        const selected = this.state.selection == "videoinput";
        this.setState({
            selection: selected ? "" : 'videoinput',
            selecting: !selected || !this.state.selecting
        })
    }

    selectAudio = () => {
        const selected = this.state.selection == "audioinput";
        this.setState({
            selection: selected ? "" : "audioinput",
            selecting: !selected || !this.state.selecting
        })
    }

    select = value => {
        this.state.selected[this.state.selection] = value;
        this.openStream();
        localStorage.setItem(this.state.selection, value);
        this.state.selecting = false;
        this.state.selection = "";
        this.forceUpdate();
        
    }

    hideMenu = () => {
        this.setState({
            selecting: false,
            selection: "",
        });
    }

    closeStream = (stream) => {
        if (stream) {
            stream.getTracks().map(track => track.stop());
        }
    }

    openUserMedia = (constraints) => {
        return new Promise((resolve, reject) => {
            const open = () => {
                if (!currentHome) {
                    console.log("device setup unmounted");
                    return;
                }
                navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                    resolve(stream);
                }).catch(err => {
                    this.setState({
                        cantOpenMedia: true,
                    });
                    console.warn(err);
                    setTimeout(open, 1000);
                });
            }
            open();
        });
    }

    openStream = () => {
        const video = this.state.selected.videoinput ? {deviceId: {exact: this.state.selected.videoinput}} : {}
        const audio = this.state.selected.audioinput ? {deviceId: {exact: this.state.selected.audioinput}} : {};
        video.width = {min: 640, ideal: 1920};
        video.height = {min: 480, ideal: 1080};
        video.frameRate = {min: 15, ideal: 24};
        const constraints = {
            video: video,
            audio: audio,
        }
        const videoRef = this.videoRef;
        //console.log("open stream: ", constraints);
        return this.openUserMedia(constraints).then(stream => {
            this.setState({
                cantOpenMedia: false,
                videoWidth: 0,
                videoHeight: 0
            });
            //console.log("got stream");
            if (this.stream) this.closeStream(this.stream);
            this.stream = stream;
            if (videoRef.current) videoRef.current.srcObject = stream;
        }).catch (err => {
            console.log("failed to get stream: ", err);
            this.setState({
                cantOpenMedia: true
            });
        });
    }
    
    render() {
        let selectionOptions;
        let selectedOption;
        const makeLabel = (opt, i) => {
            let label = opt.label;
            if (!label) {
                if (opt.kind == 'audioinput') {
                    label = "Audio Input "+(i+1);
                } else {
                    label = "Video Input "+(i+1);
                }
            }
            return label;
        }
        if (this.state.selection) {
            selectionOptions = this.state.options[this.state.selection].map((opt, i) => {
                return {
                    value: opt.deviceId,
                    name: makeLabel(opt, i)
                }
            });
            selectedOption = this.state.selected[this.state.selection];
        }
        let selectionListClassName = 'uiDeviceSetupSelection';
        if (this.state.selection == 'audioinput') {
            selectionListClassName += " uiDeviceSetupSelectionOneMic";
        }
        return <div className='uiDeviceSetup'>
            <div className='uiDeviceSetupVideo'>
            {this.state.cantOpenMedia ?
             <div className='uiDeviceSetupCantOpenMedia'>
             <UICantOpenMedia/>
             </div>
             : <video playsInline autoPlay ref={this.videoRef} muted/>}
             <ClickAwayListener onClickAway={this.hideMenu}><div className='uiDeviceSetupControls' style={this.state.videoWidth ? null: {display: 'none'}}>
            <UIToggleButton label={"Microphone"} icon={MicOn} selected={this.state.selection == 'audioinput'} onChange={this.selectAudio}/>
            <UIToggleButton label={"Camera"} icon={VideoOn} selected={this.state.selection == 'videoinput'} onChange={this.selectVideo}/>
            {selectionOptions && <div className={selectionListClassName}><UISelectionList select={this.select} options={selectionOptions} selected={selectedOption} visible={this.state.selecting} value={selectedOption}/></div>}
            </div></ClickAwayListener>
            </div>
            </div>
    }
}
