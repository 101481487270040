import React, { Component } from 'react';
import {UIRemoteVideo} from "../RemoteVideo";
import {UIChat} from "../Chat";
import {isMobile} from "../../Platform";
import ResizeObserver from 'resize-observer-polyfill';
import './index.css'

const lerp = (a, b, t) => a * (1.0-t) + b * t;

export class UIOpenContactView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            callHeight: 0.0,
            callActive: false,
            dir: "left",
            dim: "width",
        }
        this.selfRef = React.createRef();
        this.chatRef = React.createRef();
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", this.onWindowResize);
        this.onWindowResize();
        this.unmounted = false;
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
    }

    onWindowResize = () => {
        if (!isMobile()) {
            let dir = "left";
            let dim = "width";
            const v = document.documentElement;
            const w = v.offsetWidth - 265;
            const h = v.offsetHeight;
            const leftW = w * 0.6;
            const topH = h * 0.6;
            if (topH > leftW) {
                dir = "top";
                dim = "height";
            }
            if (dir != this.state.dir) {
                this.setState({
                    dir: dir,
                    dim: dim
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log("callActive: ", this.props.callActive);
        if (this.props.callActive != this.state.callActive) {
            const target = this.props.callActive ? 1.0 : 0.0;
            let t = 0.0;
            this.state.callActive = this.props.callActive;
            const rate = 1.0/24;
            const dur = 1.0;
            const inc = rate;
            const interval = rate * 1000;
            clearInterval(this.interv);
            this.interv = setInterval(() => {
                this.state.callHeight = lerp(this.state.callHeight, target, t);
                t += inc;
                //console.log("t: ", t, "height: ", this.state.callHeight);
                if (t >= 1.0) {
                    clearInterval(this.interv);
                    t = 1.0;
                    this.state.callHeight = target;
                    this.props.onAnimationComplete(target);
                }
                this.forceUpdate();
            }, interval);
        }
    };


    checkLayout() {
        const v = this.videoRef.current;
        const c = this.chatRef.current;
        let bottom = true;
        if (this.props.remoteStreams) {
            const self = v.parentElement;
            const w = self.offsetWidth;
            const h = self.offsetHeight;
            const vw = v.offsetWidth;
            const vh = v.offsetHeight;
            const vid = this.props.remoteStreams[0].getVideoTracks()[0];
            const set = vid.getSettings();
            const vidH = set.height;
            const vidW = set.width;
            if (vidH > vidW) {
                bottom = false;
            }
        }
        if (bottom != this.state.bottom) {
            this.setState({
                bottom: bottom
            });
        }
    }

    updates = 0;

    shouldComponentUpdate(nextProps) {
        if (!this.selfRef.current) return true;
        if (!nextProps.visible) {
            if (this.updates > 0) {
                console.log("not updating: ", this.props.contact.displayName);
                return false;
            }
            this.updates++;
        } else {
            this.updates = 0;
        }
        return true;
    }
    

    render() {
        let remoteContact = this.props.contact;
        if (remoteContact.remoteContact) {
            remoteContact = remoteContact.remoteContact;
        }
        let alpha = this.state.callHeight * 100;
        let invAlpha = 100 - alpha;
        const top = -(invAlpha * 0.6);
        const videoStyle = {};
        const chatStyle = {};
        let dir = this.state.dir;
        let dim = this.state.dim;
        let factor = 0.7;
        if (dir == "left" && this.selfRef.current) {
            const w = this.selfRef.current.offsetWidth;
            if (w) {
                const max = 1.0-385/w;
                factor = max;
            }
        }
        videoStyle[dir] = top;
        videoStyle[dim] = (factor*100)+"%";
        chatStyle[dir] = (alpha*factor) + "%";
        chatStyle[dim] = 100-(alpha*factor) + "%";
        if (dir == "left") {
            videoStyle.height = "calc(100% - 90px)";
            if (alpha >= 100) {
                chatStyle[dim] = 385;
                chatStyle[dir] = "calc(100% - 385px)";
                videoStyle[dim] = "calc(100% - 385px)";
            }
        }
        //console.log("videoStyle: ", videoStyle);
        //console.log("chatStyle: ", chatStyle);
        const toggleCallActive = this.props.toggleCallActive;
        return <div
        ref={this.selfRef}
        style={this.props.visible ? null: {display: "none"}}
        className={(this.props.className || 'uiOpenContactView') + (isMobile() ? " uiOpenContactViewMobile" : " uiOpenContactViewDesktop")}>
            <div className='uiOpenContactVideo' ref={this.videoRef} style={videoStyle}>
            <UIRemoteVideo remoteStreams={this.props.remoteStreams}
        uploadFile={this.props.shareFile}
        observeCanvasData={this.props.observeCanvasData}
        sendCanvasData={this.props.sendCanvasData}
        setLocalVideoResolution={this.props.setLocalVideoResolution}
        remoteScreenShare={this.props.remoteScreenShare}
        remoteComposite={this.props.remoteComposite}
        joinCall={this.props.joinCall}
        close={this.props.closeContact}
        height={this.state.callHeight}
        callActive={this.props.callActive}
        onStreamVisible={this.props.onStreamVisible}
        makeCall={this.props.makeCall}
        toggleCallActive={toggleCallActive}
        hangup={()=>this.props.endCall()}  contact={remoteContact}
        fullScreen={this.props.fullScreen} toggleFullScreen={this.props.toggleFullScreen}
        screenShare={this.props.screenShare} toggleScreenShare={this.props.toggleScreenShare}
        recording={this.props.recording} toggleRecording={this.props.toggleRecording}
        audioMuted={this.props.audioMuted} toggleAudioMuted={this.props.toggleAudioMuted}
        videoMuted={this.props.videoMuted} toggleVideoMuted={this.props.toggleVideoMuted}
        yourVideoHidden={this.props.yourVideoHidden} toggleYourVideoHidden={this.props.toggleYourVideoHidden}
        yourMediaStream={this.props.localVideoStream}
            />
            </div>
            <div className='uiOpenContactChat' ref={this.chatRef} style={chatStyle}>
            <UIChat
        
        visible={this.props.visible}
        mostRecent={this.props.mostRecent}
        scrollBack={this.props.scrollBack}
        recordMessage={this.props.recordMessage}
        onChatCreated={this.props.onChatCreated}
        removeContact={this.props.removeContact}
        onAppointmentEnded={this.props.onAppointmentEnded}
        downloadFile={this.props.downloadFile}
        waitForSystemUpdate={this.props.waitForSystemUpdate}
        hideWith={true}
        scheduleAppointmentWith={this.props.scheduleAppointmentWith}
        rescheduleAppointment={this.props.rescheduleAppointment}                    
        showSystemProgressIndicator={this.props.showSystemProgressIndicator}
        me={this.props.me}
        typing={this.props.typing}
        observeTyping={this.props.observeTyping}
        goHome={this.props.closeContact}
        contact={remoteContact}
        callActive={this.props.callActive}
        toggleCallActive={toggleCallActive}
        close={this.props.closeContact}
        height={this.state.callHeight}
        reactToChatMessage={this.props.reactToChatMessage}
        deleteChatMessage={this.props.deleteChatMessage}
        setPopupShowing={this.props.setPopupShowing}
        uploadFile={this.props.uploadFile}
        sendMessage={this.props.sendMessage}
        saveMessage={this.props.saveMessage}
        saveWhiteboard={this.props.saveWhiteboard}
        observeWhiteboard={this.props.observeWhiteboard}
        observeCanvasData={this.props.observeCanvasData}
        sendCanvasData={this.props.sendCanvasData}
        messages={this.props.messages}
        localContact={this.props.localContact}
        remoteContact={remoteContact}/>
            </div>
            </div>

    }
}
