import {Contact} from "./Contact";
import {DM} from "./DM";
import {LinkPreviewer} from "./LinkPreviewer";
import {docData, doc, collectionChanges} from "rxfire/firestore";
import {merge, of, concat, from, Subject} from "rxjs";
import {filter, map, flatMap, take} from 'rxjs/operators';
import moment from 'moment';
import filenamify from 'filenamify'
import FileSaver from 'file-saver';
import phone from 'phone';
require("moment-business-days");

const TeTePrivacyNotice = "/static/TeTePrivacyNotice.html";
const TeTeTOS = "/static/TeTeTermsOfService.html";
const TeTeBAA = "/static/TeTeBAA.html";

//import { EThree } from '@virgilsecurity/e3kit-browser';
const EThree = window.E3kit ? window.E3kit.EThree : null;

let firstTime = true;

const getEThree = () => {
    if (!EThree) {
        if (!firstTime) {
            return window.location.reload();
        }
        firstTime = false;
        throw {
            code: "network-error",
            message: "A network error occurred"
        };
    }
    return EThree;
}

const webAssemblySupported = (() => {
    try {
        if (typeof WebAssembly === "object"
            && typeof WebAssembly.instantiate === "function") {
            const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
            if (module instanceof WebAssembly.Module)
                return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
        }
    } catch (e) {
    }
    return false;
})();

let encryptionEnabled = EThree && webAssemblySupported;
let fileEncryptionEnabled = EThree && webAssemblySupported;

const toBase64 = function (u8) {
    return btoa(String.fromCharCode.apply(null, u8));
}

const fromBase64 = function (str) {
    return atob(str).split('').map(function (c) { return c.charCodeAt(0); });
}

const decode = toBase64;




export class Me {

    constructor(firebase, teteFunctionEndpoint, config) {
        this.upcoming = {};
        this.products = [];
        this.selfSubject = new Subject();
        this.accountSubject = new Subject();
        this.productsSubject = new Subject();
        this.firebase = firebase;
        this.stripeAuthSubject = new Subject();
        const auth = firebase.auth();
        auth.onAuthStateChanged(this.onAuthStateChanged);
        this.linkPreviewer = new LinkPreviewer(firebase);
        this.contacts = {};
        this.contactsSubject = new Subject();
        this.eThreeSubject = new Subject();
        this.online = {};
        this.onlineSubject = new Subject();
        this.teteFunctionEndpoint = teteFunctionEndpoint;
        this.isDev = config == 'dev';
        this.onAuthStateChanged(auth.currentUser);
    }

    getContactsAndObserveGroups = () => {
        return this.getContacts().then(this.observeGroups);
    }

    groupsRef = () => this.firebase.firestore().collection("Groups").where("members", "array-contains", this.self.uid);
    groupRef = (id) => this.firebase.firestore().collection("Groups").doc(id);

    observeGroup = (id) => {
        return docData(this.groupRef(id)).pipe(map(data => {
            const info = {
                uid: id,
                displayName: data.name,
                group: {
                    organizer: data.organizer,
                    members: data.members,
                    displayName: data.name,
                    uid: id,
                    isGroup: true,
                },
                isGroup: true,
            }
            return new Contact(info);
        }));
    }

    observeGroups = () => {
        return collectionChanges(this.groupsRef()).pipe(flatMap(changes => {
            return from(changes.map(change => {
                const getContact = uid => {
                    const result = this.getContact(uid);
                    if (!result) {
                        const userData = {
                            displayName: "n/a",
                            uid: uid,
                        }
                        return new Contact(userData);
                    }
                    return result;
                }
                const data = change.doc.data();
                const json = {
                    isGroup: true,
                    displayName: data.name,
                    uid: change.doc.id,
                    organizer: data.organizer,
                    members: data.members,
                }
                const result = {
                    type: change.type,
                    groupId: change.doc.id,
                    organizer: getContact(data.organizer),
                    members: data.members.map(uid => getContact(uid)),
                    uid: change.doc.id,
                }
                result.contact = {
                    isGroup: true,
                    uid: result.groupId,
                    displayName: data.name,
                    licenses: "",
                    degrees: "",
                    creds: "",
                    email: "",
                    phoneNumber: "",
                    profileImage: result.organizer.profileImage,
                    group: json,
                    toJSON: () => result.contact
                }
                console.log("GROUP: ", result);
                //debugger;
                return result;
            }));
        }));
    }


    createGroup = (name, contacts) => {
        const group = {
            name: name,
            members: contacts.map(c => c.uid).concat(this.self.uid),
            organizer: this.self.uid,
        }
        return this.firebase.firestore().collection("Groups").add(group);
    }

    updateGroup = (id, name, contacts) => {
        const group = {
            name: name,
            members: contacts.map(c => c.uid).concat(this.self.uid),
            organizer: this.self.uid,
        }
        return this.firebase.firestore().collection("Groups").doc(id).set(group, {merge: true});
    }

    getNextResponseTime = sub => moment(new Date(sub.latestQuestion)).businessAdd(sub.responseTime).toDate().getTime();

    setEncryptionEnabled = value =>  encryptionEnabled = fileEncryptionEnabled = value;

    observeAccountImpl = () => docData(this.accountRef());

    observeAccount = () => this.accountData ? concat(from([this.accountData]), this.accountSubject) : this.accountSubject;

    markOnline = () => {
        const updates = {
            lastOnline: Date.now()
        }
        return this.firebase.firestore().collection("Online").doc(this.self.uid).set(updates, {merge: true});
    }

    observeContactOnline = contact => {
        const uid = contact.uid;
        const now = this.online[uid];
        let ob = this.onlineSubject.pipe(filter(x => x.uid == uid));
        if (now){
            return concat(of(now), ob);
        }
        return ob;
    }

    observeOnline = () => {
        return collectionChanges(this.firebase.firestore().collection("Online"));
    }

    observeStripeAccount = () => {
        return this.observeAccount().pipe(filter(x => x && x.account), map(x => x.account));
    }

    observeSelf = () => {
        const existing = this.self ? [this.self] : [];
        return concat(existing, this.selfSubject);
    }

    getAccount = () => {
        return this.observeAccount().pipe(take(1)).toPromise();
    }

    getPaymentMethod = () => {
        return this.getAccount().then(accountData => accountData.paymentMethod);
    }

    savePaymentMethod = paymentMethod => {
        return this.getToken().then(token => {
            const savePaymentMethod = this.firebase.functions().httpsCallable("savePaymentMethod?idToken="+token+"&paymentMethodId="+paymentMethod);
            return savePaymentMethod().then(response => {
                //console.log("savePaymentMethod: ", response);
                return Promise.resolve(this.contactLink = response.data);
            });
        });
    }

    getPaymentIntent = (appointmentId) => {
        return this.getToken().then(token => {
            const getPaymentIntent = this.firebase.functions().httpsCallable("getPaymentIntent?idToken="+token+"&appointmentId="+appointmentId);
            return getPaymentIntent().then(response => {
                //console.log("getPaymentIntent: ", response);
                return response.data;
            });
        });
    }

    refundAppointment = (appointmentId) => {
        return this.getToken().then(token => {
            const refundPayment = this.firebase.functions().httpsCallable("refundAppointment?idToken="+token+"&appointmentId="+appointmentId);
            return refundPayment().then(response => {
                //console.log(response);
                return response.data;
            });
        });
    }

    getContactLink = () => {
        const p = this.getToken().then(token => {
            const getContactLink = this.firebase.functions().httpsCallable("getContactLink?idToken="+token);
            return getContactLink().then(response => {
                console.log("getContactLink: ", response);
                return Promise.resolve(this.contactLink = response.data);
            });
        });
        const link = this.contactLink;
        if (link) return Promise.resolve(link);
        return p;
    }

    markContactOpened = contact => {
        if (contact.isGroup) {
            return Promise.resolve();
        }
        ////////debugger;
        const user = this.contacts[contact.uid];
        if (!user) {
            console.log("bad contact: ", contact);
            //debugger;
        }
        if (!user.open) {
            user.open = true;
            return this.updateContact(contact, {
                open: true
            });
        }
        return Promise.resolve();
    }

    updateContact = (contact, updates) => {
        return this.getToken().then(token => {
            const updateContact =
                  this.firebase.functions().httpsCallable("updateContact?idToken="+token+"&contact="+contact.uid);
            return updateContact(updates).then(response => {
                ////////debugger;
                //console.log(response);
                return response.data;
            });
        });
    }

    applyContactLink = (type, link) => {
        const p = this.getToken().then(token => {
            let url = "applyContactLink?idToken="+token+"&link="+link+"&type="+type;
            const applyContactLink = this.firebase.functions().httpsCallable(url);
            return applyContactLink().then(response => {
                //console.log("applyContactLink: ", response);
                return Promise.resolve(response.data);
            });
        });
        return p;
    }

    removeContact = contact => {
        const contactUid = contact.uid;
        const p = this.getToken().then(token => {
            const removeContact = this.firebase.functions().httpsCallable("removeContact?idToken="+token+"&contact="+contactUid);
            return removeContact().then(response => {
                //console.log("removeContact: ", response);
                return Promise.resolve(response.data);
            });
        });
        return p;
    }

    hasContacts = () => {
        ////debugger;
        for (var i in this.contacts) {
            if (this.contacts[i].uid != this.self.uid) {
                return Promise.resolve(true);
            }
        }
        return this.myContactsRef().limit(2).get().then(snap => {
            ////debugger;
            return snap.docs.length > 1;
        });
    }

    updateSubscription = (contact, updates) => {
        const providerUpdates = {
            client: contact.uid,
        }
        for (var field in updates) {
            providerUpdates[field] = updates[field];
        }
        return this.doSubscriptionUpdates(providerUpdates);
    }

    doSubscriptionUpdates = updates => {
        console.log("updates: ", updates);
        return this.getToken().then(token => {
            let url = "updateSubscription?idToken="+token;
            for (var i in updates) {
                url += "&"+i+"="+encodeURIComponent(updates[i]);
            }
            ////debugger;
            const updateSubscription = this.firebase.functions().httpsCallable(url);
            return updateSubscription().then(response => {
                console.log(response);
                return response;
            });
        });
    }

    doClientSubscriptionUpdates = updates => {
        return this.getToken().then(token => {
            let url = "updateClientSubscription?";
            let sep = "";
            for (var i in updates) {
                url += sep+i+"="+encodeURIComponent(updates[i]);
                sep = "&";
            }
            console.log("updateClientSubscription: ", url);
            ////debugger;
            url += "&idToken="+token;
            const updateSubscription = this.firebase.functions().httpsCallable(url);
            return updateSubscription().then(response => {
                console.log(response);
                return response;
            });
        });
    }


    acceptSubscription = contact => {
        const updates = {
            uid: contact.uid,
            state: "accept"
        };
        return this.doClientSubscriptionUpdates(updates);
    }

    declineSubscription = contact => {
        const updates = {
            uid: contact.uid,
            state: "decline"
        };
        return this.doClientSubscriptionUpdates(updates);
    }

    cancelSubscription = contact => {
        //debugger;
        const updates = {
            client: contact.uid,
            state: "cancel",
        };
        return this.doSubscriptionUpdates(updates);
    }

    cancelClientSubscription = contact => {
        //debugger;
        const updates = {
            uid: contact.uid,
            state: "cancel"
        };
        return this.doClientSubscriptionUpdates(updates);
    }

    offerSubscription = (contact, subscription) => {
        const updates = {
            client: contact.uid,
            state: "offer",
            startDate: subscription.startDate,
            description: subscription.description || "",
            invoiceAmount: subscription.invoiceAmount,
            invoiceDescription: subscription.invoiceDescription || "",
            responseTime: subscription.responseTime,
        };
        return this.doSubscriptionUpdates(updates);
    }

    observeSubscriptions = () => {
        return collectionChanges(this.providerSubscriptionsRef()).pipe(flatMap(changes => changes.map(change => {
            const sub = change.doc.data();
            sub.latestQuestion = sub.latestQuestion || 0;
            sub.latestResponse = sub.latestResponse || 0;
            const result = {
                type: change.type,
                subscription: sub,
            }
            sub.contact = this.getContact(sub.client);
            return result;
        })));
    }

    observeMySubscriptions = () => {
        return collectionChanges(this.mySubscriptionsRef()).pipe(flatMap(changes => changes.map(change => {
            const sub = change.doc.data();
            sub.latestQuestion = sub.latestQuestion || 0;
            sub.latestResponse = sub.latestResponse || 0;
            const result = {
                type: change.type,
                subscription: sub,
            }
            sub.contact = this.getContact(sub.uid);
            return result;
        })));
    }

    observeSubscription = contact => {
        return collectionChanges(this.subscriptionRef(contact)).pipe(flatMap(changes => changes.map(change => {
            const result = {
                type: change.type,
                subscription: change.doc.data(),
            }
            result.subscription.contact = this.getContact(result.subscription.client);
            return result;
        })));
    }

    observeMySubscription = contact => {
        return collectionChanges(this.mySubscriptionRef(contact)).pipe(flatMap(changes => changes.map(change => {
            const result = {
                type: change.type,
                subscription: change.doc.data(),
            }
            result.subscription.contact = this.getContact(result.subscription.uid);
            return result;
        })));
    }
    
    hasAppointments = () => {
        return this.appointmentsRef().limit(1).get().then(snap => snap.docs.length > 0);
    }

    observeAppointment = (appointment) => {
        const id = appointment.id;
        const editable = appointment.editable;
        const ref = this.firebase.firestore().collection("Appointments").doc(id);
        return docData(ref).pipe(map(data => {
            if (data) {
                data.id = id
                data.contact = this.getContact(data.uid == this.self.uid ? data.client : data.uid);
                data.organizer = this.getContact(appointment.uid);
                data.editable = editable;
            }
            return data;
        }));
    }

    getAppointments = contact => {
        return this.appointmentsRef().where("client", "==", contact.uid).get().then(snap => {
            const results = snap.docs.map(doc => {
                const appt = doc.data();
                appt.contact = contact;
                return appt;
            });
            results.sort((a, b) => b.start - a.start);
            return results;
        });
    }

    observeUpcomingAppointments = contact => {
        const now = Date.now();
        return this.observeAppointments(now).pipe(filter(change => change.appointment.contact.uid == contact.uid))
    }

    observeAppointments = (after) => {
        const byMe = collectionChanges(this.appointmentsRef(after));
        const converted = byMe.pipe(flatMap(changes => {
            return from(changes.map(change => {
                const data = change.doc.data();
                const appt = {
                    client: this.getContact(data.client),
                    id: change.doc.id,
                    organizer: this.self,
                    contact: this.getContact(data.client),
                    start: data.start,
                    end: data.end,
                    editable: true,
                    invoiceDescription: data.invoiceDescription,
                    invoiceAmount: data.invoiceAmount,
                    status: data.status,
                    finalPaymentMethod: data.finalPaymentMethod,
                    paymentStatus: data.paymentStatus,
                    paymentIntentId: data.paymentIntentId,
                    title: data.title,
                }
                return {type: change.type, appointment: appt};
            }).filter(change => change.appointment.contact))
        }
        ));
        const toMe = collectionChanges(this.myAppointmentsRef(after)).pipe(flatMap(changes => changes.map(change => {
            const data = change.doc.data();
            const contact = this.getContact(data.uid);
            const appt = {
                id: change.doc.id,
                organizer: contact,
                contact: contact,
                client: this.self,
                start: data.start,
                end: data.end,
                editable: false,
                invoiceDescription: data.invoiceDescription,
                invoiceAmount: data.invoiceAmount,
                paymentIntentId: data.paymentIntentId,
                status: data.status,
                finalPaymentMethod: data.finalPaymentMethod,
                paymentStatus: data.paymentStatus,
                paymentIntentId: data.paymentIntentId,
                title: data.title
            }
            return {type: change.type, appointment: appt};
        })));
        return merge(converted, toMe);
    }

    doAppointmentUpdates = updates => {
        return this.getToken().then(token => {
            let url = "updateAppointment?idToken="+token;
            const autofill = this.getAppointmentAutofill();
            for (var i in updates) {
                url += "&"+i+"="+encodeURIComponent(updates[i]);
                autofill[i] = updates[i];
            }
            this.setAppointmentAutofill(autofill);
            ////debugger;
            const updateAppointment = this.firebase.functions().httpsCallable(url);
            return updateAppointment().then(response => {
                //console.log(response);
                return response;
            });
            
        });
    }

    getAppointmentAutofill = () => {
        const string = localStorage.getItem("appointmentAutofill."+this.self.uid);
        if (string) return JSON.parse(string);
        return {};
    }

    setAppointmentAutofill = (autofill) => {
        return localStorage.setItem("appointmentAutofill."+this.self.uid, JSON.stringify(autofill));
    }
    
    acceptAppointment = (appointmentId) => {
        return this.doAppointmentUpdates({
            appointmentId: appointmentId,
            status: "accepted",
        });
    }

    declineAppointment = (appointmentId) => {
        return this.doAppointmentUpdates({
            appointmentId: appointmentId,
            status: "declined",
        });
    }

    deleteAppointment = (appointmentId) => {
        return this.getToken().then(token => {
            let url = "deleteAppointment?idToken="+token;
            url += "&appointmentId="+appointmentId;
            const deleteAppointment = this.firebase.functions().httpsCallable(url);
            return deleteAppointment().then(response => {
                //console.log(response);
                return response;
            });
        });
    }

    updateAppointment = (appointment) => {
        const client = appointment.client;
        const appointmentId = appointment.id;
        const title = appointment.title || "";
        const start = appointment.start;
        const end = appointment.end;
        const invoiceDescription = appointment.invoiceDescription || "";
        const invoiceAmount = appointment.invoiceAmount || "";
        return this.getToken().then(token => {
            let url = "updateAppointment?idToken="+token;
            if (client) {
                url += "&client="+client;
            }
            if (appointmentId) {
                url += "&appointmentId="+appointmentId;
            }
            url += "&start="+start;
            url += "&end="+end;
            let autofill = this.getAppointmentAutofill();
            if (invoiceAmount) {
                url += "&invoiceAmount="+invoiceAmount;
                autofill.invoiceAmount = invoiceAmount;
            }
            if (invoiceDescription) {
                url += "&invoiceDescription="+encodeURIComponent(invoiceDescription);
                autofill.invoiceDescription = invoiceDescription;
            }
            if (title) {
                url += "&title="+encodeURIComponent(title);
                autofill.title = title;
            }
            const updateAppointment = this.firebase.functions().httpsCallable(url);
            return updateAppointment().then(response => {
                //console.log(response);
                this.setAppointmentAutofill(autofill);
                return response;
            });
        });
    }
    
    createAppointment = (contact, appointment) => {
        appointment.client = contact.uid;
        return this.updateAppointment(appointment);
    }

    acceptBAA = () => {
        return this.getToken().then(token => {
            const func = this.firebase.functions().httpsCallable("acceptBAA?idToken="+token);
            return func().then(result => {
                console.log("accept baa: ", result);
                if (result.data.error) {
                    return Promise.reject("oof, sorry");
                }
                return result;
            });
        });
    }

    downloadBAA = () => {
        const url = "/static/BAA.pdf";
        return fetch(url).then(response => response.blob()).then(blob => {
            if (blob.type != "application/pdf") {
                return blob.text().then(result => {
                    console.log(result);
                    return Promise.reject("oof, sorry");
                });
            }
            const when = this.accountData.acceptedBAA;
            const date = moment(new Date(when)).format("Do MMM YYYY");
            FileSaver.saveAs(new File([blob], filenamify(this.self.displayName) + " TeTe BAA Effective "+date+".pdf", {type: "application/pdf"}));
        });
    }
       
    updateAccount = updates0 => {
        const updates = JSON.parse(JSON.stringify(updates0));
        return this.getToken().then(token => {
            const updateAccount = this.firebase.functions().httpsCallable("updateAccount?idToken="+token);
            let p = Promise.resolve();
            if (updates.password) {
                p = this.updatePassword(updates.password).then(() => null).catch(err => err);
            }
            return p.then(err => {
                if (err) {
                    return {data: {error: err}};
                }
                delete updates['password'];
                //debugger;
                for (var i in updates) {
                    return updateAccount(updates).then(result => {
                        console.log("updateAccount: ", result);
                        if (result.data.error) {
                            return result;
                        }
                        let changed = false;
                        for (var field in this.self) {
                            if (updates[field]) {
                                if (this.self[field] != updates[field]) {
                                    this.self[field] = updates[field];
                                    changed = true;
                                }
                            }
                        }                        
                        if (changed) {
                            this.selfSubject.next(this.self);
                        }
                        return result;
                    }).catch(err => {
                        console.error(err);
                        return {data: {error: {code: "internal-error"}}};
                    });
                }
                return Promise.resolve({data: {}});
            });
        });
    }

    uploadProfileImage = (file, progress) => {
        const ref = this.firebase.storage().ref("ProfileImages").child(this.self.uid);
        return new Promise((resolve, reject) => {
            const uploadTask = ref.put(file);
            if (progress) {
                progress(0);
            }
            uploadTask.on("state_changed", snap => {
                //console.log("state_changed", snap);
                const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
                if (progress) {
                    progress(percent);
                }
            }, reject, () => {
                return resolve(ref.getDownloadURL());
            });
        });
    }
    
    sendMessage = message => {
        return this.getToken().then(token => {
            const updateMessage = this.firebase.functions().httpsCallable("updateMessage?idToken="+token);
            return updateMessage(message).then(result => {
                //console.log("update message: ", result);
            });
        });
    }

    sendGroupMessage = message => {
        return this.getToken().then(token => {
            const updateMessage = this.firebase.functions().httpsCallable("updateGroupMessage?idToken="+token);
            return updateMessage(message).then(result => {
                console.log("update group message: ", result);
            }).catch(err => {
                console.log("update group message: ", err);
            })
        });
    }

    addReaction = (to, ts, emoji) => {
        return this.getToken().then(token => {
            let url = "addReaction?idToken="+token;
            url += "&uid="+to;
            url += "&emoji="+encodeURIComponent(emoji);
            url += "&ts="+ts;
            const addReaction = this.firebase.functions().httpsCallable(url);
            return addReaction().then(result => result.data).then(result => {
                //console.log("add reaction: ", result);
            });
        });
    }


    /* Product related */

    addProduct = prod => {
        return this.getToken().then(token => {
            const createProduct= this.firebase.functions().httpsCallable("createProduct?idToken="+token);
            return createProduct(prod).then(result => {
                //console.log("created product: ", prod, " => ", result);
                //////debugger;
            });
        });
    }

    acceptOffer = (productId, paymentMethodId) => {
        return this.getToken().then(token => {
            const acceptOffer = this.firebase.functions().httpsCallable("acceptOffer?idToken="+token+"&productId="+productId+"&paymentMethodId="+paymentMethodId);
            return acceptOffer().then(result => {
                //console.log("accept offer complete: ", result);
                return result;
            });
        });
    }

        
    deleteProduct = productId => {
        return this.getToken().then(token => {
            const deleteProduct= this.firebase.functions().httpsCallable("deleteProduct?idToken="+token+"&productId="+productId);
            return deleteProduct().then(result => {
                //console.log("deleted product: ", productId, " => ", result);
            });
        });
    }

    applyProductLink = link => {
        return this.getToken().then(token => {
            const applyProductLink = this.firebase.functions().httpsCallable("applyProductLink?idToken="+token+"&link="+link);
            return applyProductLink().then(result => {
                //console.log("applied product link: ", result);
            });
        });
    }

    observePurchased = fromContact => {
        return collectionChanges(this.purchasedRef(this.self.uid, fromContact.uid)).pipe(flatMap(changes => {
            return from(changes.map(change => {
                const data = change.doc.data();
                const product = data.product;
                product.state = data.state;
                return {type: change.type, data: product};
            }));
        }));;
    }

    observeMyProducts = () => {
        return collectionChanges(this.myProductsRef()).pipe(flatMap(changes => {
            return from(changes.map(change => {
                const data = change.doc.data();
                const product = data.product;
                product.productLink = data.productLink;
                return {type: change.type, data: product};
            }));
        }));
    }

    observeEThree = () => {
        if (this.eThree) return from([this.eThree]);
        return this.eThreeSubject.pipe(take(1));
    }

    /* End Product related */

    getPublicKey = () => {
        if (this.publicKey) return Promise.resolve(this.publicKey);
        return this.observeEThree().toPromise().then(eThree => eThree.findUsers(this.self.uid).then(publicKey => {
            return this.publicKey = publicKey;
        }));
    }

    getToken = () => this.user.getIdToken(false);

    generateContacts = n => {
        return this.getToken().then(token => {
            const func = this.firebase.functions().httpsCallable("generateContacts?idToken="+token+"&count="+n);
            return func().then(result => {
                console.log(result.data.contacts);
                return result.data.contacts;
            });
        });
    }

    completeGoogleSignUp = (form, getDetails, getPassword, done, fail) => {
        return getDetails(form).then(result => {
            const converted = phone("+"+result.countryCode+result.phoneNumber);
            const phoneNumber = converted[0];
            const email = result.email;
            return getEThree().derivePasswords(result.password).then(derived => {
                var {loginPassword, backupPassword } = derived;
                loginPassword = decode(loginPassword);
                const credential = this.firebase.auth.EmailAuthProvider.credential(email, loginPassword);
                const finish = () => {
                    this.backupPassword = backupPassword;
                    return this.initE3(backupPassword, true, getPassword, false, done, fail).then(() => {
                        return this.updateAccount({
                            displayName: result.name,
                            email: result.email,
                            phoneNumber: phoneNumber,
                            photoURL: result.photoURL,
                        });
                    });
                }
                debugger;
                return this.firebase.auth().currentUser.linkWithCredential(credential).then(finish).catch(err => {
                    console.error(err);
                    //debugger;
                    return finish();
                });
            });
        });
    }

    completePhoneSignUp = (phoneNumber, email, password, displayName) => {
        return getEThree().derivePasswords(password).then(derived => {
            var {loginPassword, backupPassword } = derived;
            loginPassword = decode(loginPassword);
            const credential = this.firebase.auth.EmailAuthProvider.credential(email, loginPassword);
            const finish = () => {
                this.backupPassword = backupPassword;
                return this.initE3(backupPassword, true).then(() => {
                    return this.updateAccount({
                        displayName: displayName,
                        email: email,
                        phoneNumber: phoneNumber,
                    });
                });
            }
            return this.firebase.auth().currentUser.linkWithCredential(credential).then(finish).catch(err => {
                console.error(err);
                //debugger;
                return finish();
            });
        });
    }
    
    
    signUpWithGoogle = (getPassword) => {
        const provider = new this.firebase.auth.GoogleAuthProvider();
        return this.firebase.auth().signInWithPopup(provider).then(result => {
            this.signUpDisplayName = result.user.displayName;
            if (result.user.providerData.length > 1) { // already signed up
                return this.initE3(null, false, getPassword).then(() => result);
            }
            return result;
        });
    }

    signInWithGoogle = (onNeedsSignUp, getPassword) => {
        const provider = new this.firebase.auth.GoogleAuthProvider();
        return this.firebase.auth().signInWithPopup(provider).then(result => {
            const user = result.user;
            //debugger;
            if (user.providerData.length != 3) {
                return onNeedsSignUp(user);
            }
            return this.initE3(null, false, getPassword);
        });
    }
    
    sendSignUpEmailVerification = email => {
        const func = this.firebase.functions().httpsCallable("sendEmailVerification?email="+encodeURIComponent(email));
        return func().then(result => {
            return result.data;
        });
    }

    verifySignUpEmail = (email, code) => {
        const func = this.firebase.functions().httpsCallable("verifyEmail?email="+encodeURIComponent(email)+"&code="+code);
        return func().then(result => {
            return result.data;
        });
    }
    
    signUp = (email, password, displayName, phoneNumber) => {
        this.signupDisplayName = displayName;
        this.signUpPhoneNumber= phoneNumber;
        let p;
        this.self = new Contact({
            email: email,
            displayName: displayName,
            phoneNumber: phoneNumber,
        });
        this.accountData = {
            email: email,
            displayName: displayName,
            phoneNumber, phoneNumber,
        };
        if (encryptionEnabled) {
            p = getEThree().derivePasswords(password).then(result => {
                var { loginPassword, backupPassword } = result;
                loginPassword = decode(loginPassword);
                this.backupPassword = backupPassword;
                return this.firebase.auth().createUserWithEmailAndPassword(email, loginPassword).then(user => {
                    return this.initE3(backupPassword, true).then(() => {
                        window.analytics.logEvent("signUp");
                        return user;
                    });
                })
            });
        } else {
            p = this.firebase.auth().createUserWithEmailAndPassword(email, password);
        }
        return p.then(user => {
            return this.updateAccount({
                displayName: displayName,
                email: email,
                phoneNumber: phoneNumber
            });
        });
    }

    isTherapist = () => {
        return window.isBusiness == 't';
    }

    isBusiness = () => {
        return window.isBusiness;
    }

    phoneNumberExists = (phoneNumber, accountExists) => {
        let url = "phoneNumberExists?phoneNumber="+encodeURIComponent(phoneNumber);
        const func = this.firebase.functions().httpsCallable(url);
        return func().then(result => {
            return result.data.exists;
        });
    }

    emailExists = email => {
        const func = this.firebase.functions().httpsCallable("emailExists?email="+encodeURIComponent(email));
        return func().then(result => {
            return result.data.exists;
        }).catch(err => {
            console.error(err);
            return false;
        });
    }

    signIn = (email, password) => {
        return getEThree().derivePasswords(password).then(result => {
            var { loginPassword, backupPassword } = result;
            //debugger;
            loginPassword = decode(loginPassword);
            if (encryptionEnabled) {
                this.backupPassword = backupPassword;
                //console.log("signing in to firebase");
                return this.firebase.auth().signInWithEmailAndPassword(email, loginPassword).then(result => {
                    //console.log("signed in to firebase");
                    this.initE3(backupPassword, false).then(() => {
                        window.analytics.logEvent("login", {method: 'email'});
                        return result;
                    });
                }).catch(err => {
                    //console.log(err);
                    return Promise.reject(err);
                });
            }
            return this.firebase.auth().signInWithEmailAndPassword(email, password);
        });
    }

    signInWithPhoneNumber = (phoneNumber, recaptcha, getCode, getPassword, forgotPassword, done) => {
        return this.firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha).then(result => {
            //////debugger;
            const doit = err => {
                getCode(err).then(code => {
                    debugger;
                    try {
                        result.confirm(code).then(result => {
                            debugger;
                            window.analytics.logEvent("login", {method: 'phone'});
                            if (encryptionEnabled) {
                                return this.initE3(null, false, getPassword, forgotPassword, (err) => {
                                    if (!err) done();
                                });
                            }
                        }).catch(err => {
                            debugger;
                            return doit(err);
                        });
                    } catch (err) {
                        debugger;
                        return doit(err);
                    }
                });
            }
            doit();
        })
    }
  

    updatePassword = (newPassword) => {
        if (encryptionEnabled) {
            return getEThree().derivePasswords(newPassword).then(result => {
                var { loginPassword, backupPassword } = result;
                loginPassword = decode(loginPassword);
                return this.firebase.auth().currentUser.updatePassword(loginPassword).then(() => {                
                    return this.eThree.changePassword(this.backupPassword, backupPassword).then(() => {
                        this.backupPassword = backupPassword;
                        window.analytics.logEvent("changePassword");
                        return loginPassword;
                    });
                })
            })
        } else {
            return this.firebase.auth().currentUser.updatePassword(newPassword).then(() => newPassword);
        }
    }

    resetPassword = email => {
        let p = Promise.resolve();
        if (encryptionEnabled) {
            const eThree = this.eThree;
            const ops = [];
            ops.push(eThree.cleanup());
            ops.push(eThree.resetPrivateKeyBackup());
            return Promise.all(ops).then(eThree.rotatePrivateKey());
        }
        //return this.firebase.auth().sendPasswordResetEmail(email);
    }

    signOut = () => {
        this.signUpDisplayName = null;
        this.online = {};
        return this.firebase.auth().signOut().then(() => {
            window.analytics.logEvent("signOut");
            console.log("signed out");
        });
    }

    initE3 = (backupPassword, isSignUp, getPassword, forgotPassword, done, fail) => {
        const tokenCallback = () => {
            const getJwt = this.firebase.functions().httpsCallable("getJwt");
            //console.log("token callback started");
            return getJwt().then(result => {
                //console.log("token callback complete");
                return result.data.token;
            });
        }
        return EThree.initialize(tokenCallback).then(eThree => {
            this.eThree = eThree;
            //////debugger;
            if (isSignUp) {
                return eThree.register().then(() => eThree.backupPrivateKey(backupPassword));
            } else {
                return eThree.hasLocalPrivateKey().then(hasLocalPrivateKey => {
                    //debugger;
                    let test = false;
                    let count = 0;
                    if (test || !hasLocalPrivateKey || forgotPassword) {
                        const doGetPassword = (wasInvalid) => {
                            ++count;
                            let getPasswords;
                            if (backupPassword) {
                                getPasswords = () => Promise.resolve({backupPassword: backupPassword});
                            } else {
                                getPasswords = () => getPassword(wasInvalid).then(password => {
                                    console.log("got password: ", password);
                                    return EThree.derivePasswords(password);
                                });
                            }
                            return getPasswords().then(result => {
                                if (hasLocalPrivateKey && !forgotPassword) {
                                    if (count < 2) {
                                        return doGetPassword(true);
                                    }
                                    return;
                                }
                                var { loginPassword, backupPassword } = result;
                                if (!forgotPassword) {
                                    return eThree.restorePrivateKey(backupPassword).then(()=> eThree).catch (err => {
                                        console.error(err);
                                        if (done) done({error: "Password not valid for this device"});
                                        return doGetPassword(true);
                                    });
                                } else {
                                    console.log("got passwords: ", result);
                                    return eThree.resetPrivateKeyBackup().then(() => {
                                        return eThree.backupPrivateKey(backupPassword).then(() => {
                                            loginPassword = decode(loginPassword);
                                            return this.firebase.auth().currentUser.updatePassword(loginPassword).then(() => {                
                                                if (done) done();
                                                return eThree;
                                            }).catch(err => {
                                                if (done) done({
                                                    error: err.message
                                                })
                                            });
                                        });
                                    }).catch(err => {
                                        console.error(err);
                                        return doGetPassword(true);
                                    });
                                }
                            }).then(result => {
                                if (done) done();
                                return result;
                            });
                        }
                        return doGetPassword();
                    }
                    if (done) done();
                    return eThree;
                })
            }
        }).then(() => {
            console.log("firing e3 subject");
            this.eThreeSubject.next(this.eThree);
            return this.eThree;
        })
    }


    canResetPassword = () => {
    }

    onAuthStateChanged = user => {
        console.log("auth state changed: ", user);
        if (!user) {
            if (this.contactsSub) {
                this.contactsSub.unsubscribe();
                this.contactsSub = null;
            } 
            if (this.stripeAuthUnsubscribe) {
                this.stripeAuthUnsubscribe();
                this.stripeAuthUnsubscribe = null;
            }
            if (this.onlineSub) {
                this.onlineSub.unsubscribe();
            }
            this.stripeAuth = null;
            this.stripeAuthSubject.next(null);
            this.accountData = null;
            this.accountSubject.next(null);
            this.user = null;
            this.self = null;
            console.log("nullified self");
            this.eThree = null;
            this.backupPassword = null;
            this.selfSubject.next(null);
            this.contacts = {};
            clearInterval(this.checkOnline);
            clearInterval(this.onlineTimer);
            return;
        }
        this.user = user;
        //console.log("firebase user: ", user);
        this.self = new Contact(user);
        this.contacts[this.self.uid] = this.self;
        //////debugger;
        if (this.signupDisplayName) {
            this.self.displayName = this.signupDisplayName;
        }
        this.accountData = {
            uid: this.self.uid,
            email: this.self.email,
            displayName: this.self.displayName,
            phoneNumber: this.self.phoneNumber
        }
        this.stripeAuthUnsubscribe = this.stripeAuthRef().onSnapshot(snap => {
            snap.docChanges().forEach(change => {
                const doc = change.doc;
                const data = change.type == "removed" ? null: doc.data();
                //console.log("stripeAuth: ", data);
                if (data && !data.access_token) {
                    return;
                }
                this.stripeAuth = data;
                this.stripeAuthSubject.next(this.stripeAuth);
            });
        }, error => {
            ////debugger;
        });
        this.accountSub = this.observeAccountImpl().subscribe(accountData => {
            this.accountData = accountData;
            console.log("got account: ", this.accountData);
            if (accountData.email) { 
                this.self.displayName = accountData.displayName;
                this.self.email = accountData.email;
                this.self.phoneNumber = accountData.phoneNumber;
            } else {
                accountData.email = this.self.email;
                accountData.phoneNumber = this.self.phoneNumber;
                accountData.displayName = this.self.displayName;
            }
            this.self.licenses = accountData.licenses || "";
            this.self.degrees = accountData.degrees || "";
            this.self.updateCreds();
            this.selfSubject.next(this.self);
            this.accountSubject.next(accountData);
        });
        this.contactsSub = this.observeContactsImpl().subscribe(change => {
            const user = change.contact;
            //console.log("contactsSub: ", user);
            user.contact = new Contact(user.contact);
            const contact = user.contact;
            if (change.type == "removed") {
                delete this.contacts[contact.uid];
            }
            this.contacts[contact.uid] = user;
            this.contactsSubject.next({type: change.type, contact: user});
        });
        
        this.selfSubject.next(this.self);
        this.getContactLink();
        //this.magazines = new Magazines(this.linkPreviewer);
        //this.magazines.start();
        this.onlineTimer = setInterval(this.markOnline, 1000 * 60 * 5);
        this.markOnline();
        this.online[this.self.uid] = {uid: this.self.uid, online: true};
        this.checkOnline = setInterval(() => {
            const now = Date.now();
            const sixMinutesAgo = now - 1000 * 60 * 6;
            for (var uid in this.online) {
                if (uid == this.self.uid) continue;
                const data = this.online[uid];
                const ts = data.lastOnline || 0;
                const online = sixMinutesAgo <= ts;
                if (online != data.online) {
                    data.online = online;
                    ////////debugger;
                    this.onlineSubject.next(data);
                }
            }
        }, 5000);
        this.onlineSub = this.observeOnline().subscribe(changes => {
            changes.map(change => {
                const data = change.doc.data();
                const uid = change.doc.id;
                const ts = data.lastOnline;
                const online = Date.now() - 1000 * 60 * 5 <= ts;
                if (ts) {
                    //console.log("last online: ", uid, ": ", moment(new Date(ts)).fromNow());
                }
                ////debugger;
                if (!this.online[uid]) {
                    this.online[uid] = {online: online, lastOnline: ts, uid: uid};
                } else {
                    this.online[uid].lastOnline = ts;
                    if (this.online[uid].online == online) {
                        return;
                    }
                    this.online[uid].online = online;
                }
                //console.log("is online ", uid, " => ", this.online[uid]);
                this.onlineSubject.next(this.online[uid]);
            });
        });
    }

    getContact = uid => {
        const c = this.contacts[uid];
        return c ? c.contact : null;
    }

    showPrivacyPolicy = () => {
        return window.open(TeTePrivacyNotice, "_blank");
    }

    showTOS = () => {
        return window.open(TeTeTOS, "_blank");
    }

    showBAA = () => {
        return window.open(TeTeBAA, "_blank");
    }

    showSupport = () => {
        return window.open("mailto:tete@tete.video?subject=TeTe Support Request");
    }

    showReceipt = appointmentId => {
        return this.getToken().then(token => {
            const getReceiptURL =
                  this.firebase.functions().httpsCallable("getAppointmentReceiptURL?idToken="+token+"&appointmentId="+appointmentId);
            return getReceiptURL().then(response => {
                if (response.data.receiptURL ) {
                    const popup = window.open(response.data.receiptURL);
                    return popup;
                }
                console.error(response.error);
                return null;
            })
        });
    }

    stripeConnect = () => {
        return this.firebase.firestore().collection("StripeAuth").where("uid", "==", this.self.uid).get().then(snap => {
            const data = snap.data;
            const connected = snap.docs.length > 0 && snap.docs[0].data().stripe_user_id;
            return this.getAccount().then(autofill => {
                return this.getToken().then(token => {                    
                    let url = this.teteFunctionEndpoint+"/stripeConnect?idToken="+token;
                        const fields = [
                            "email",
                            "country",
                            "phoneNumber",
                            "firstName",
                            "lastName",
                            "streetAddress",
                            "city",
                            "state",
                            "zip",
                            "dobMonth",
                            "dobDay",
                            "dobYear"
                        ];
                    fields.forEach(field => {
                        if (autofill[field]) {
                            url += "&"+field+"="+encodeURIComponent(autofill[field]);
                        }
                    });
                    if (autofill["bday"]) {
                        const date = new Date(autofill["bday"]);
                        const d = date.getDate()+1;
                        const m = date.getMonth()+1;
                        const y = date.getFullYear();
                        url += "&dobDay="+d+"&dobMonth="+m+"&dobYear="+y;
                    }
                    window.analytics.logEvent("stripeConnect");
                    const popup = window.open(url);
                    if (!connected) {
                        this.getStripeAuth().toPromise().then(auth => {
                            //console.log("got response: ", auth);
                            popup.close();
                        });
                    }
                    return Promise.resolve(popup);
                });
            });
        });
    }

    stripeAuthRef = () =>this.firebase.firestore().collection("StripeAuth").where("uid", "==", this.self.uid);

    hasStripeAuth = () => {
        if (this.stripeAuth) return Promise.resolve(true);
        return this.stripeAuthRef().get().then(snap => {            
            if (snap.docs.length > 0) {
                const data = snap.docs[0].data();
                if (data.stripe_user_id) {
                    this.stripeAuth = data;
                    return true;
                }
            }
            return false;
        });
    }
    
    getStripeAuth = () => {
        return this.observeStripeAuth().pipe(filter(x => x.stripe_user_id), take(1));
    }

    observeStripeAuth = () => {
        if (this.stripeAuth) {
            return concat(from([this.stripeAuth]), this.stripeAuthSubject);
        }
        return this.stripeAuthSubject;
    }

    you = uid => {
        if (encryptionEnabled) {
            const ops = [this.getPublicKey(), this.eThree.findUsers(uid)];
            return Promise.all(ops).then(publicKeys => {
                console.log("got public keys: ", publicKeys);
                return new You(this, uid, publicKeys[1]);
            }).catch(err => {
                console.error(err);
                return new Id();
            });
        }
        return Promise.resolve(new Id());
    }

    systemMessagesRef = () => this.firebase.firestore().collection("SystemMessages").where("to", "==", this.self.uid);

    markSystemMessagesRead = () => {
        //debugger;
        console.log("markSystemMessagesRead");
        return this.updateAccount({systemUnread: 0, lastSystemReadTime: Date.now()});
    }

    observeSystemMessages = (limit) => {
        const convertTs = ts => {
            const result = ts.seconds * 1000 + Math.round(ts.nanoseconds/1000000);
            //console.log("convertTs: ", new Date(result));
            return result;
        }
        let q = this.systemMessagesRef().orderBy("ts", "desc");
        if (limit) q = q.limit(limit);
        return collectionChanges(q).pipe(flatMap(changes => {
            return changes.map(change => {
                const data = change.doc.data();
                const msg = data;
                console.log("got system message: ", msg);
                msg.system = true;
                if (msg.data.newContact) {
                    const newContact = msg.data.newContact;
                    ////////debugger;
                    if (!newContact.contact) return;
                    if (!newContact.contact.displayName) {
                        const c = this.getContact(newContact.contact.uid);
                        if (!c) {
                            ////////debugger;
                        } else {
                            newContact.contact = c;
                        }
                    }
                    newContact.contact = new Contact(newContact.contact);
                }
                msg.ts = convertTs(msg.ts);
                return {type: change.type, message: msg};
            });
        }));
    }

    observeMyServices = ()=> {
        return this.observePurchasedChannels({by: this.self.uid});
    }

    observeMyClients = () => {
        return this.observePurchasedChannels({from: this.self.uid});
    }
    
    observePurchasedChannels = (purchased)=> {
        const by = purchased.by
        const from = purchased.from;
        return collectionChanges(this.purchasedRef(by, from).where("state", "==", "purchased")).pipe(flatMap(changes => {
            return changes.map(change => {
                const data = change.doc.data();
                data.getChannelId = () => data.by + "-" + data.from + "-" + data.product.productId;
                return {type: change.type, channel: data};
            });
        }));
    }

    getCurrentContacts = () => {
        return Object.values(this.contacts).filter(c => c.state != "removed").map(c => c.contact);       
    }

    getContacts = () => {
        return this.myContactsRef().get().then(snap => {
            snap.docs.map(doc => {
                const user = doc.data();
                const contact = user.contact;
                if (contact.uid == this.self.uid && contact.displayName == contact.email &&
                    this.signupDisplayName) {
                    contact.displayName = this.signUpDisplayName;
                }
                user.contact = new Contact(contact);
                this.contacts[contact.uid] = user;
            });
            return this.contacts;
        });
    }

    observeContact = uid => {
        return this.observeContacts().pipe(map(x => x.contact.contact), filter(c => c.uid == uid));
    }

    observeContacts = () => {
        const existing = Object.values(this.contacts).map(c => {
            return {type: 'added', contact: c}
        });
        console.log("existing contacts: ", this.contacts);
        return concat(from(existing), this.contactsSubject);
    }


    observeContactsImpl = () => {
        return collectionChanges(this.myContactsRef()).pipe(flatMap(changes => {
            return changes.map(change => {
                console.log("observeContacts change: ", change);
                const data = change.doc.data();
                console.log("observeContacts data: ", data);
                const contact = data.contact = new Contact(data.contact);
                if (contact.uid == this.self.uid &&
                    contact.displayName == contact.email) {
                    contact.displayName = this.signupDisplayName;
                }
                return {type: change.type, contact: data};
            });
        }));
    }

    resolveContact = uid => {
        if (uid == this.self.uid) return Promise.resolve(this.self);
        const contact = this.getContact(uid);
        if (contact) {
            return Promise.resolve(contact);
        }
        return this.myContactsRef().where("contactId", "==", uid).get().then(snap => {
            if (snap.docs.length > 0) {
                const doc = snap.docs[0];
                const user = doc.data();
                user.contact = new Contact(user.contact);
                const contact = user.contact;
                this.contacts[contact.uid] = user;
                return Promise.resolve(contact);
            }
            return null;
        });
    }
    

    resolveAppointmentContact = (appointmentId) => {
        return this.firebase.firestore().collection("Appointments").doc(appointmentId).get().then(doc => {
            if (doc.exists) {
                const appt = doc.data();
                return this.getContact(appt.uid == this.self.uid ? appt.client : appt.uid);
            }
            return Promise.resolve()
        });
    }

    providerSubscriptionsRef = () => {
        return this.firebase.firestore().collection("Subscriptions").where('uid', '==', this.self.uid);
    }

    mySubscriptionsRef = () => {
        return this.firebase.firestore().collection("Subscriptions").where('client', '==', this.self.uid);
    }

    subscriptionRef = contact => {
        return this.firebase.firestore().collection("Subscriptions").where('uid', '==', this.self.uid).where('client', '==', contact.uid); 
    }

    mySubscriptionRef = contact => {
        return this.firebase.firestore().collection("Subscriptions").where('client', '==', this.self.uid).where('uid', '==', contact.uid);
    }

    appointmentsRef = (after) => {
        const q = this.firebase.firestore().collection("Appointments").where('uid', '==', this.self.uid);
        return after ? q.where("end", ">", after) : q;
    }

    myAppointmentsRef = (after) => {
        const q = this.firebase.firestore().collection("Appointments").where('client', '==', this.self.uid);
        return after ? q.where("end", ">", after) : q;
    }

    myContactsRef = () => this.firebase.firestore().collection("Contacts").where('uid', '==', this.self.uid);

    channelsRef = channelId => this.firebase.firestore().collection("Channels").doc(channelId);
    
    accountRef = () => this.firebase.firestore().collection("Users").doc(this.user.uid);

    purchasedRef = (by, from) => {
        let q = this.firebase.firestore().collection("Purchased");
        if (by) {
            q = q.where("by", "==", by);
        }
        if (from) {
            q = q.where("from", "==", from);
        }
        return q;
    }
        

    myProductsRef = () => this.firebase.firestore().collection("MyProducts").where("uid", "==", this.user.uid);

    unreadsRef = () => this.accountRef().collection("unreads");

    markRead = channelId => {
        return this.unreadsRef().doc(channelId).set({channel: channelId, unread: 0});
    }

    getUnreads = () => {
        const ops = [this.unreadsRef().get()];
        return Promise.all(ops).then(results => {
            const [snap] = results;
            return snap.docs.map(doc => doc.data())
        });
    }

    observeUnreads = () => {
        return collectionChanges(this.unreadsRef()).pipe(flatMap(changes => {
            return changes.map(change => {
                const data = change.doc.data();
                const comps = data.channel.split("-");
                const other = comps[0] == this.self.uid ? comps[1] : comps[0];
                const contact = this.contacts[other];
                if (!contact || contact.state == 'removed') return null;
                if (change.type == 'removed') {
                    return {channel: data.channel, contact: contact.contact, unread: 0};
                } 
                return {channel: data.channel, contact: contact.contact, unread: data.unread}
            })
        }), filter(x => !!x));
    }
    
    openChat = source => {
        return this.observeEThree().toPromise().then(() => {
            let remoteContact = source;
            let channelId;
            //debugger;
            if (source.isGroup) {
                channelId = source.uid;
                //debugger;
                return openGroup(this.eEThree, channelId, source.organizer).then(group => {
                    return new DM(this, channelId, source, group);
                });
            }  else {
                const uids = [this.self.uid, remoteContact.uid].sort();
                channelId = uids.join("-");
            }
            return this.you(remoteContact.uid).then(you => {
                return new DM(this, channelId, remoteContact, you);
            });
        });
    }

    getChannelFromContact = contact => {
        if (!this.self || !contact.uid) {
            return null;
        }
        const uids = [this.self.uid, contact.uid];
        uids.sort();
        return uids.join("-");
    }



}

class Id {
    encryptFile = file => Promise.resolve(file);
    decryptFile = file => Promise.resolve(file);
    encrypt = text => Promise.resolve(text);
    decrypt = text => Promise.resolve(text);
    encryptMessage = msg => Promise.resolve(msg);
    decryptMessage = msg => Promise.resolve(msg);
}

class You {

    constructor(user, uid, publicKey) {
        this.localUser = user;
        this.eThree = user.eThree;
        this.uid = uid;
        this.publicKey = publicKey;
    }

    downloadFile = msg => {
        const publicKey = this.getPublicKey(msg.from);
        const file = msg.files[0];
        return this.doDownloadFile(file, publicKey);
    }
    
    doDownloadFile = (file, publicKey) => {
        const url = file.downloadURL;
        return new Promise((resolve, reject) => {
            return this.localUser.getToken().then(token => {
                const options = {
                    headers: {
                        'Authorization': token
                    }
                }
                return fetch(url, options).then(response => response.blob()).then(blob => {
                    this.decryptFile(blob, publicKey).then(result => {
                        FileSaver.saveAs(new File([result], file.name, {type: file.type}));
                        return resolve();
                    });
                });
            });
        });
    }

    resolveFileSrc = (file, publicKey) => {
        if (file.contentType.startsWith("image/svg") ||
            (!file.contentType.startsWith("image/") && !file.contentType.startsWith("video/"))) {
            return Promise.resolve();
        }
        const url = file.downloadURL;
        if (encryptionEnabled && fileEncryptionEnabled) {
            return new Promise((resolve, reject) => {
                return this.localUser.getToken().then(token => {
                    const options = {
                        headers: {
                            'Authorization': token
                        }
                    }
                    return fetch(url, options).then(response => response.blob()).then(blob => {
                        if (blob.type.startsWith("text") || blob.type.endsWith("json")) {
                            return blob.text().then(text => {
                                //////debugger;
                            });
                        }
                        console.log("download complete");
                        this.decryptFile(blob, publicKey).then(result => {
                            file.resolve = null;
                            file.src = URL.createObjectURL(result);
                            console.log("decryption complete: ", file);
                            if (true) return resolve(file.src);
                            const reader = new FileReader();
                            reader.addEventListener("load", function () {
                                resolve(file.src = reader.result);
                            }, false);
                            reader.readAsDataURL(result);
                        }).catch(err => {
                            //console.log("failed to decrypt file: ", err);
                            return null;
                        });
                    }).catch(err => {
                        console.error(err);
                        //////debugger;
                        return null;
                    });
                });
            });
        }
        return Promise.resolve(file.src = url);
    }

    encryptFile = file => {
        if (!fileEncryptionEnabled) {
            return Promise.resolve(file);
        }
        const publicKey = this.publicKey;
        return this.eThree.encryptFile(file, publicKey).then(blob => {
            const uid = this.localUser.self.uid;
            const timestamp = Date.now();
            const name = uid + "-"+ file.name+ "-"+timestamp;
            const result = new File([blob], name, {type: "application/octet-stream"});
            return Promise.resolve(result);
        });
    }
    
    decryptFile = (file, publicKey) => {
        return this.eThree.decryptFile(file, publicKey);
    }

    encrypt = (text, publicKey) => this.eThree.encrypt(text, publicKey);

    decrypt = (text, publicKey) => this.eThree.decrypt(text, publicKey)

    getPublicKey = from => from == this.uid ? this.publicKey : this.localUser.publicKey;

    encryptMessage = msg => {
        const publicKey = this.publicKey;
        return this.encrypt(msg.text, publicKey).then(encryptedText => {
            const dup = JSON.parse(JSON.stringify(msg));
            if (dup.files) {
                dup.files.map(file => {
                    delete file.src;
                    delete file.state;
                });
            }
            if (!this.isDev) {
                delete dup.text;
            }
            dup.encryptedText = encryptedText;
            return dup;
        });
    }

    decryptMessage = msg => {
        const publicKey = this.getPublicKey(msg.from);
        const ops = [];
        ops.push(this.decrypt(msg.encryptedText, publicKey).catch (err => {
            console.log("public key: ", publicKey);
            console.log("while decrypting: ", msg);
            console.error(err);
            return msg.text;
        }));
        if (true) {
            if (msg.files) msg.files.map(file => {
                if (file.contentType && (file.contentType.startsWith("image/") ||  file.contentType.startsWith("video/"))) {
                    file.resolve = () =>this.resolveFileSrc(file, publicKey);
                }
            });
        } else {
            if (msg.files) ops.push(Promise.all(msg.files.map(file => {
                if (file.contentType && (file.contentType.startsWith("image/") ||  file.contentType.startsWith("video/"))) {
                    return this.resolveFileSrc(file, publicKey);
                }
                return Promise.resolve();
            })));
        }
        return Promise.all(ops).then(results => {
            const [text, urls] = results;
            delete msg.encryptedText;
            msg.text = text;
            return msg;
        });
    }

    
}

const createGroup = (eThree, contacts, groupId) => {
    return eThree.findUsers(contacts.map(c => c.uid)).then(participants => {
        return eThree.createGroup(groupId, participants);
    });
}

const openGroup = (eThree, groupId, ownerContact) => {
    if (true) {
        return Promise.resolve(new Group(this, groupId, ownerContact));
    }
    return eThree.findUsers(ownerContact.uid).then(card => {
        return eThree.loadGroup(groupId, card);
    });
}

const joinGroup = (eThree, groupId, uid) => {
}

class Group {
    constructor(me, groupId, ownerContact) {
    }
    encryptFile = file => Promise.resolve(file);
    decryptFile = file => Promise.resolve(file);
    encrypt = text => Promise.resolve(text);
    decrypt = text => Promise.resolve(text);
    encryptMessage = msg => Promise.resolve(msg);
    decryptMessage = msg => Promise.resolve(msg);
    getPublicKey = () => null;
}
