import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import SpinnerShape from "../../assets/icons/SpinnerShape.svg";
import Back from "../../assets/icons/Back.svg";
import Err from "../../assets/icons/Error.svg";
import './index.css'

export class UIOKCancel extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    ok = () => {
        const block = !this.props.background;
        if (block) window.blockInput()
        this.setState({
            okBusy: true
        });
        const done = () => {
            this.setState({
                okBusy: false
            });
            if (block) window.unblockInput();
        }
        const p = this.props.ok();
        if (!p || !p.then) {
            console.log("didn't return a promise: ", this.props.ok);
        }
        p.then(done).catch(err => {
            console.error(err);
            this.setState({
                error: true
            });
            done();
        });
    }

    cancel = () => {
        window.blockInput()
        this.setState({
            cancelBusy: true
        });
        const done = () => {
            window.unblockInput();
            this.setState({
                cancelBusy: false
            });
        }
        this.props.cancel().then(done).catch(err => {
            console.error(err);
            this.setState({
                error: true
            });
            done();
        });
    }

    resetError = () => {
        this.setState({error: false});
    }

    componentWillUnmount() {
        if (this.state.busy) {
            window.unblockInput();
        }
    }

    
    render() {
        if (this.state.error || this.props.error) {
                return <div className='uiOKCancel'>
                <div className="uiOKCancelOof" onClick={this.props.onErrorClick || this.resetError}>
                <div className="uiOKCancelOofIcon">
                <ReactSVG src={Err}/>
                </div>
                <div className="uiOKCancelOofLabel">
                {"Oof, Sorry that didn't work"}
             </div>
             </div>
            </div>
        }
        const busy = this.state.okBusy || this.props.lookBusy;
        const cancelBusy = this.state.cancelBusy;
        
        return <div className='uiOKCancel'>
            {this.props.back && <div key='back' className='uiOKCancelBack' onClick={this.props.back}>
             <ReactSVG src={Back}/>
             </div>}
            {this.props.cancel &&<div key='cancel' className={'uiOKCancelCancel'} onClick={this.cancel}>
             <div className={'uiOKCancelCancelIcon'  + (cancelBusy ? " uiOKCancelBusy" : "")}>
             <ReactSVG src={cancelBusy ? SpinnerShape : this.props.cancelIcon}/>
             </div>
             </div>}
            {this.props.ok && <div key='ok' className='uiOKCancelOK' onClick={this.ok}>
            <div className={'uiOKCancelOKIcon' + (busy ? " uiOKCancelBusy" : "")}>
            <ReactSVG src={busy ? SpinnerShape  : this.props.okIcon}/>
             </div>
            <div className='uiOKCancelOKLabel'>
            {this.props.label}
            </div>
             </div>}
            </div>
    }
}
