import React, {useState, useEffect, Component } from 'react';
import {ReactSVG} from 'react-svg';
import {UIProfileIcon} from "../ProfileIcon";
import Bubble from "../../assets/icons/ChatSpace.svg";
import moment from "moment";
import './index.css'
import { DragPreviewImage, useDrag } from 'react-dnd'

const hashCode = function(s) {
  var h = 0, l = s.length, i = 0;
  if ( l > 0 )
    while (i < l)
      h = (h << 5) - h + s.charCodeAt(i++) | 0;
  return hash_32b_to_16b(h);
};

function hash_32b_to_16b(val32b) {
    var rightBits = val32b & 0xffff; // Left-most 16 bits
    var leftBits = val32b & 0xffff0000; // Right-most 16 bits

    leftBits = leftBits >>> 16; // Shift the left-most 16 bits to a 16-bit value

    return rightBits ^ leftBits; // XOR the left-most and right-most bits
}

export const formatStartEndTime = (start, end) => moment(start).format('h:mm A') + " - "+ moment(end).format('h:mm A')
export const formatDate = start => moment(start).format('MMMM Do YYYY');

export const renderPaymentStatus = (paymentStatus) => {
    let paymentStatusLabel = "Payment Requested";
    switch (paymentStatus) {
    case "requires_payment_method":
    case "requires_confirmation":
    case "requires_action":
        paymentStatusLabel = "Payment Requested";
        break;
    case "processing":
        paymentStatusLabel = "Payment Processing";
        break;
    case "canceled":
        paymentStatusLabel = "Payment Canceled";
        break;
    case "succeeded":
        paymentStatusLabel = "Payment Complete";
        break;
    case "refunded":
        paymentStatusLabel = "Payment Refunded";
        break;
    }
    return paymentStatusLabel;
}

class AppointmentTimeComp extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const now = Date.now();
        const end = this.props.end.getTime();
        if (end > now) {
            const until = end - now;
            if (until < 2147483647) {
                this.timeout = setTimeout(this.props.onEnded, until);
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const props = this.props;
        return <div className='uiAppointmentInfoStartEndTime'>
            {formatStartEndTime(props.start, props.end)}
        </div>
    }
}

export const UIAppointment = props => { 
    const [collectedProps, drag] = useDrag({
        item: { id: props.id || "", type: "appointment" || "", appointment: props.appointment},
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                console.log("You dropped ", item, " into ", dropResult);
            }
        }
    });

    const now = Date.now();
    let className='uiAppointment';
    let isPast = false;
    if (props.end.getTime() < now) {
        className += " uiAppointmentPast";
        isPast = true;
    } else {
    }
    if (!props.editable) {
        className += " uiAppointmentClient";
    }
    if (props.invoiceAmount || props.paymentAmount) {
        className += " uiAppointmentWithPayment";
    }
    if (props.isChat) {
        className += " uiAppointmentChat";
    }
    let indicator ="";
    if (props.status == "accepted" && (props.invoiceAmount == 0 || props.paymentStatus == 'succeeded')) {
        indicator = " uiAppointmentIndicatorAccepted";
    } else if (props.status == "declined") {
        indicator = " uiAppointmentIndicatorDeclined";
    } else if (props.status != 'canceled') {
        indicator = " uiAppointmentIndicatorInWaiting";
    }
    
    let error;
    if (props.paymentError) {
        error = props.paymentError
            .replace("Your card number", "Card number")
            .replace("Your postal code", "Zip code")
            .replace("Your card's security code", "CVC")
            .replace("Your card's expiration", "Expiration")
            .replace("is ", "")
            .replace(/[.]/, "");
            
    } else {
        if (props.status == 'canceled') {
            error = "Canceled"
        } else if (props.status == "refunded") {
            error = "Refunded";
        }
    }
    const id = "A-"+hashCode(props.id);
    const invoiceAmount = props.paymentAmount && (props.status == 'refunded' || props.paymentStatus == 'succeeded') ? props.paymentAmount : props.invoiceAmount;
    return <div draggable={props.editable} key={props.id} className={className} onClick={props.onClick} ref={!props.editable ? null: drag}>
          <div className='uiAppointmentInfoId'>
          {id}
          </div>
        <div className={'uiAppointmentIndicator' + indicator} />
        <div className='uiAppointmentInfo'>
          <div className='uiAppointmentInfoOrganizer'>
        {props.organizer.displayName} <span className='uiAppointmentOrganizerCreds'>{props.organizer.creds}</span>
          </div>
          <div className='uiAppointmentInfoTitle'>
            {props.title}
    </div>
          <AppointmentTimeComp start={props.start} end={props.end} onEnded={props.onEnded}/>
          <div className={'uiAppointmentInfoDate' + (isPast ? " uiAppointmentInfoDatePast" : "")}>
          {formatDate(props.start)}
          </div>
          {invoiceAmount ? <div className='uiAppointmentPaymentInfo'>
           {error ?  <div className='uiAppointmentStatusCanceled'>
            {error}
            </div>
            :
            <div className='uiAppointmentPaymentInfoStatus'>
            {renderPaymentStatus(props.paymentStatus)}
            </div>}
           <div className={'uiAppointmentPaymentInfoAmount' + (props.paymentStatus == "refunded" ? ' uiAppointmentPaymentInfoAmountNegative': "")}>
                ${invoiceAmount.toFixed(2)}
                </div>
           </div> : null
          }
        </div>
        {!props.hideWith && <div className='uiAppointmentWith' onClick={(e)=>{
            e.preventDefault();
            e.stopPropagation();
            props.openChat(props.with)
        }}>
          <div className='uiAppointmentWithIcon'>
            <UIProfileIcon radius={20} contact={props.with}/>
          </div>
          <div className='uiAppointmentWithName'>
         {props.with.displayName} <span className='uiAppointmentWithCreds'>{props.with.creds}</span>
         </div>
         <div className='uiAppointmentWithChat'><ReactSVG src={Bubble}/></div>
         </div>}
     </div>;
}



