import React, { Component } from 'react';
import {UIOpenContactView} from "../OpenContactView";
import {MediaDevice} from "../../Signaling";
import './index.css'
import {isDesktop} from "../../Platform";
import CallLeft from "../../assets/audio/Igor/callLeft.wav";
import CallJoin from "../../assets/audio/Igor/callJoin.wav";
import RingShort from "../../assets/audio/Igor/ring_short.wav";
import Ring1 from "../../assets/audio/Igor/ring_1.wav";
import Ring2 from "../../assets/audio/Igor/ring_2.wav";
import NewMessage from "../../assets/audio/Igor/notification.wav";
import NotShort from "../../assets/audio/Igor/notification_short.wav";
/*
import {createFFmpeg} from "@ffmpeg/ffmpeg";

const isChrome = window.MediaRecorder && window.MediaRecorder.isTypeSupported && window.MediaRecorder.isTypeSupported("video/webm;codecs=h264");
let ffmpeg;

const doTranscode = async (blob) => {
    if (!ffmpeg) {
        ffmpeg = createFFmpeg({log: true});
    }
    console.log('Loading ffmpeg-core.js');
    await ffmpeg.load();
    console.log('Start transcoding');
    await ffmpeg.write('recording.webm', blob);
    console.log("wrote recording.webm");
    //await ffmpeg.transcode('recording.webm', 'recording.mp4', "-threads 4");
    await ffmpeg.run("-i recording.webm -c:v mpeg4 -b:v 6400k -threads 4 -strict experimental recording.mp4");
    console.log('Complete transcoding');
    const data = ffmpeg.read('recording.mp4')
    ffmpeg.remove('recording.webm');
    ffmpeg.remove('recording.mp4');
    return new Blob([data.buffer], { type: 'video/mp4' });
};
*/

const {Howl, Howler} = require('howler');

const callJoin = new Howl({
    src: [CallJoin],
    loop: false,
});

const callLeft = new Howl({
    src: [CallLeft],
    loop: false,
});

const newMessage = new Howl({
    src: [NewMessage],
    loop: false,
});

const notShort = new Howl({
    src: [NotShort],
    loop: false,
});

const ring1 = new Howl({
    src: [Ring1],
    loop: true,
});

const ring2 = new Howl({
    src: [Ring2],
    loop: true,
});

const ringShort = new Howl({
    src: [RingShort],
    loop: true,
});


export class UIActiveContactView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoMuted: false,
            audioMuted: false,
            remoteVideo: [],
            callStats: {
                duration: 0,
                bytesReceived: 0,
                bytesSent: 0
            },
            calls: [],
            messages: [],
            incomingCalls: [],
            upNext: [],
            signInError: {},
            signUpError: {},
            resetPasswordError: {},
            phoneSignInError: {},
            yourVideoHidden: true,
            to: this.props.to,
        }
        this.mediaDevice = new MediaDevice(this.props.to.isGroup);
        this.chatMessages = {};
        this.streams = {};
        this.inProgress = {};
        this.waiting = {};
        this.messagePageSize = 5;
        this.messageCount = this.messagePageSize;
        this.cursor = 0;
        this.screenShare = {};
    }

    setLocalVideoResolution = value => {
        return this.mediaDevice.setWebCamResolution(value).then(() => {
            this.state.calls.map(call => call.sendDeviceSettings());
        });
    }

    showSystemProgressIndicator = (ts, apptId, msg) => {
        this.inProgress = {
            ts: ts,
            apptId: apptId,
        }
        window.showProgressIndicator(msg);
    }


    openSubscription = () => {
        if (this.chat) {
            this.chat.subscribeToChat();
        } else {
            this.openSubscriptionRequested = true;
        }
    }

    componentWillUnmount = () => {
        this.props.onDeleted(this.state.to);
        if (this.contactSub) {
            this.contactSub.unsubscribe();
        }
    }

    componentDidMount() {
        this.chatStart = Date.now();
        this.props.me.openChat(this.state.to).then(chat => {
            this.setState({
                chat: chat
            });
            chat.markRead();
            chat.onMessage(this.onChatMessage);
            if (this.props.incomingCall) {
                this.handleIncomingCall(this.props.incomingCall);
            } else if (this.props.outgoingCall) {
                this.makeCall();
            }
        }).catch(err => {
            console.error("couldn't open chat: ", err);
            //debugger;
        });
        this.props.onCreated(this.state.to, this);
        setTimeout(() => {
            if (!this.messagesShown) {
                this.messagesShown = true;
                this.props.onMessagesShown()
            }
        }, 2500);
        if (this.props.to.isGroup) {
            this.contactSub = this.props.me.observeGroup(this.props.to.uid).subscribe(c => {
                if (this.chat) this.chat.markAllDirty();
                this.setState({
                    to: c
                });
            });
        } else {
            this.contactSub = this.props.me.observeContact(this.props.to.uid).subscribe(c => {
                console.log("CONTACT CHANGED: ", c);
                if (this.chat) this.chat.markAllDirty();
                this.setState({
                    to: c
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected) {
            if (!prevProps.selected) {
                if (this.state.chat) this.state.chat.markRead();
                if (this.chat) {
                    this.chat.takeFocus();
                }
            }
        }
        if (this.props.incomingCall != prevProps.incomingCall) {
            this.handleIncomingCall(this.props.incomingCall);
        }
        if (this.props.incomingCallRequest != prevProps.incomingCallRequest) {
            this.handleIncomingCallRequest(this.props.incomingCallRequest);
        }
        if (this.props.outgoingCall && !prevProps.outgoingCall) {
            this.makeCall();
        }
        if (this.props.callHolding != this.callHolding) {
            this.callHolding = this.props.callHolding;
            this.updateLocalMediaStream();
        }
    }

    onChatMessage = (op, msg) => {
        console.log("onChatMessage ", op, ": ", msg);
        if (op == 'removed') {
            delete this.chatMessages[msg.ts];
        } else {
            this.chatMessages[msg.ts] = msg;
            if (!this.chatAdded) {
                this.chatAdded = msg.from != this.props.me.self.uid && msg.ts > this.chatStart;
            }
            if (this.chat && (!this.mostRecent || msg.ts > this.mostRecent)) {
                this.mostRecent = msg.ts;
                this.chat.scrollToBottom();
            }
        }
        this.setState({
            mostRecent: this.mostRecent || 0,
            chatUpdates: this.state.chatUpdates + 1
        });
        if (msg.data && msg.data.appointment) {
            const appt = msg.data.appointment;
            const waiting = this.waiting[appt.id];
            if (waiting) {
                delete this.waiting[appt.id];
                waiting.map(resolve => resolve());
                return this.updateMessagesNow();
            }
        }
        if (this.chat) this.chat.markDirty(msg);
        if (msg.files && msg.files[0].progress) {
            return this.updateMessagesNow();
        }
        this.updateMessagesLater();
    }

    reactToMessage = (msg, emoji) => {
        const uid = this.state.chat.localContact.uid;
        const existing = msg.reactions && msg.reactions.find(x => x.name == emoji.short_name);
        if (!msg.reactions) msg.reactions = [];
        if (existing) {
            const found = existing.users.find(userId => userId == uid);
            if (found) {
                existing.users = existing.users.filter(userId => userId != uid)
                if (existing.users.length == 0) {
                    msg.reactions = msg.reactions.filter(x => x.name != emoji.short_name);
                }
            } else {
                existing.users.push(uid);
            }
        } else {
            msg.reactions.push({
                users: [uid],
                name: emoji.short_name
            });
        }
        const to = this.state.chat.remoteContact.uid;
        this.onChatMessage("modified", msg);
        this.updateMessagesNow();
        return this.props.me.addReaction(to, msg.ts, emoji.short_name);
    }


    saveWhiteboard = data => {
        this.props.me.saveWhiteboard(this.state.chat.channelId, data);
    }


    observeWhiteboard = () => {
        return this.props.me.observeWhiteboard(this.state.chat.channelId);
    }

    observeCanvasData = () => {
        return this.state.chat.observeCanvasData(this.state.chat.channelId);
    }

    sendCanvasData = (data) => {
        return this.state.chat.sendCanvasData(this.state.chat.channelId, data);
    }


    saveMessage = msg => {
        this.onChatMessage("added", msg);
        return this.state.chat.sendMessage(msg);
    }

    sendMessage = text => this.state.chat.send(text);

    deleteMessage = message => {
        this.state.chat.deleteMessage(message);
    }

    waitForUpdate = appt => {
        let waiting = this.waiting[appt.id];
        if (!waiting) {
            waiting = [];
            this.waiting[appt.id] = waiting;
        }
        return new Promise((resolve, reject) => {
            waiting.push(resolve);
        });
    }

    updateMessagesNow = () => {
        if (this.props.selected) {
            if (this.state.chat) {
                this.state.chat.markRead();
            }
        }
        let msgs = [];
        let messages = {};
        let active = {};
        let appts = {};
        msgs = Object.values(this.chatMessages);
        msgs.sort((x, y) => {
            return x.ts - y.ts;
        });
        msgs.map(msg => {
            const ts = msg.ts;
            if (msg.data && msg.data.appointment) {
                const appt = msg.data.appointment;
                if (appt.paymentIntentId &&
                    (!appt.paymentStatus ||
                     appt.paymentStatus == "requires_confirmation" ||
                     appt.paymentStatus == "requires_payment_method")) {
                    return;
                }
                if (!appt.contact) {
                    appt.organizer = this.props.me.getContact(appt.uid);
                    const other = appt.uid == this.props.me.self.uid ? appt.client : appt.uid;
                    appt.contact = this.props.remoteContact ? this.props.remoteContact : this.props.me.getContact(other);
                    
                }
                //console.log("appointment message: ", msg);
                const prev = appts[appt.id]
                //console.log("appointment message prev: ", prev);
                if (prev) {
                    const prevAppt = prev.data.appointment;
                    if (appt.paymentSeq) {
                        if (appt.paymentSeq != prevAppt.paymentSeq) {
                            if (!prevAppt.paymentSeq || prevAppt.paymentSeq < appt.paymentSeq) {
                                delete messages[prev.ts];
                            } else {
                                if (prev.paymentSeq && appt.paymentSeq < prev.paymentSeq) {
                                    return;
                                }
                            }
                        } 
                    }
                }
                appts[appt.id] = msg;
            }
            if (msg.data && msg.data.subscription) {
                const sub = msg.data.subscription;
                if (sub.before && sub.before.state == sub.state) {
                    //return;
                }
            }
            if (msg.text || msg.system) {
                messages[msg.ts] = msg;
            }
        });
        let ms = Object.values(messages);
        ms.sort((a, b) => {
            return a.ts - b.ts;
        });
        const seen = {};
        const status = {};
        let result = ms;
        for (var i = result.length-1; i >= 0; --i) {
            const m = result[i];
            if (m.data && m.data.appointment) {
                if (!seen[m.data.appointment.id]) {
                    const appt = m.data.appointment;
                    if (this.chat && !m.data.isActive) {
                        this.chat.markDirty(m);
                    }
                    m.data.isActive = true;
                    active[appt.id] = appt;
                    seen[m.data.appointment.id] = true;
                } else {
                    if (this.chat && m.data.isActive) {
                        this.chat.markDirty(m);
                    }
                    m.data.isActive = false;
                }
            }
        }
        console.log("msgs=", result);
        result = result.slice(Math.max(result.length-this.messageCount, 0), result.length);
        this.setState({
            messages: result
        }, () => {
            if (this.chatAdded) {
                this.chatAdded = false;
                newMessage.play();
            }
            if (!this.messagesShown) {
                this.messagesShown = true;
                this.props.onMessagesShown();
            }
        });
    }
    
    updateMessagesLater = () => {
        clearTimeout(this.messagesUpdate);
        this.messagesUpdate = setTimeout(() => {
            this.updateMessagesNow();
        }, 200);
    }

    updateLocalMediaStream0 = () => {
        if (this.state.localVideoStream) {
            this.state.localVideoStream.getVideoTracks().map(track => track.enabled = !this.state.videoMuted);
            this.state.localVideoStream.getAudioTracks().map(track => track.enabled = !this.state.audioMuted);
        }
    }

    updateLocalMediaStream = () => {
        this.updateLocalMediaStream0();
        this.state.calls.map(call => {
            call.setAudioMuted(this.props.callHolding || this.state.audioMuted);
            call.setVideoMuted(this.props.callHolding || this.state.videoMuted);
        });
    }

    toggleVideoMuted = () => {
        const muted = !this.state.videoMuted;
        this.setState({
            videoMuted: muted
        }, this.updateLocalMediaStream);
    }

    toggleAudioMuted = (forceMute) => {
        const muted = forceMute ? true : !this.state.audioMuted;
        //console.log("toggle audio muted: ", muted);
        this.setState({
            audioMuted: muted
        }, this.updateLocalMediaStream);
    }

    toggleYourVideoHidden = () => {
        this.setState({
            yourVideoHidden: !this.state.yourVideoHidden
        });
    }

    toggleFullScreen = () => {
        this.setState({
            fullScreen: !this.state.fullScreen
        });
    }

    toggleScreenShare = () => {
        if (this.props.to.isGroup) {
            for (var call of this.state.calls) {
                if (call.isSharingScreen()) {
                    return;
                }
            }
        }
        const mediaDevice = this.mediaDevice;
        const screenShare = !this.state.screenShare;
        let p = screenShare ? mediaDevice.getScreenVideo() : mediaDevice.getWebcamVideo();
        return p.then(stream => {
            const track = stream.getVideoTracks()[0];
            if (screenShare) {
                track.addEventListener('ended', () => {
                    if (this.state.screenShare) {
                        this.toggleScreenShare();
                    }
                });
            }
            if (this.state.localVideoStream) {
                const s = this.state.localVideoStream;
                s.getVideoTracks().map(t => {
                    t.stop();
                    s.removeTrack(t);
                });
                s.addTrack(track);
            }
            stream.getTracks().forEach(t => stream.removeTrack(t));
            Promise.all(this.state.calls.map(call => call.replaceSenderTrack(track))).then(() => {
                this.state.calls.map(call => call.setScreenShare(screenShare));                
            });
            this.setState({
                screenShare: screenShare
            });

        });
    }


    startGroupCall = (id) => {
        const caller = this.props.me.self;
        const mediaDevice = this.mediaDevice;
        let callees;
        const group = this.state.to.group;
        if (group.organizer == this.props.me.self.uid) {
            callees = group.members.filter(uid => uid != group.organizer).map(uid => this.props.me.getContact(uid));
        } else {
            callees = [this.props.me.getContact(group.organizer)];
        }
        ring1.play();
        callees = callees.filter(c => {
            const uid = c.uid;
            if (uid == this.props.me.self.uid) {
                return false;
            }
            this.state.calls.map(call => {
                if (uid == call.getRemoteContact().uid) {
                    return false;
                }
            });
            return true;
        });
        const ops = callees.map(callee => {
            if (!this.state.callActive) return;
            return this.props.signaling.call(id, mediaDevice, caller, callee, group).then(call => {
                if (!this.state.callActive) {
                    call.hangup();
                    return;
                }
                this.handleCall(call);
                this.state.calls.map(c => {
                    c.add(call);
                    call.add(c);
                });
                this.state.calls.push(call);
                console.log("calls: ", this.state.calls);
                this.state.outgoingCall = call;
                this.forceUpdate(this.updateLocalMediaStream);
                this.props.onCallStarted(call);
                call.observeHangup().subscribe(() => {
                    this.endCall(call);
                });
                call.onStatsUpdate(this.onStatsUpdate);
                call.observeHangup().subscribe(()=>this.handleStreamClosed(call));
                call.observeRemoteStreams().subscribe(stream => {
                    this.handleIncomingStreams(call, stream);               
                });
            });
        });
        return Promise.all(ops);
    }

    startCall = (id) => {
        const caller = this.props.me.self;
        let callee = this.state.to;
        if (callee.isGroup) {
            return this.startGroupCall(id);
        }
        const mediaDevice = this.mediaDevice;
        ring1.play();
        return this.props.signaling.call(id, mediaDevice, caller, callee).then(call => {
            if (!this.state.callActive) {
                call.hangup();
                return;
            }
            console.log("outgoing call connected: ", call);            
            if (call.getRemoteContact().uid == this.props.me.self.uid) {
                //console.log("self-call!!");                        
                // self-call
                this.toggleAudioMuted(true);
            }
            if (!call) {
                // call failed
                this.setState({
                    callActive: false
                });
                return;
            }
            if (false && !this.state.callActive || !this.state.callActive.id == id) {
                // user already hung up;
                call.hangup();
                return;
            }
            this.state.callActive.hangup = () => this.endCall(call);
            this.handleCall(call);
            this.state.calls.push(call);
            this.setState({
                outgoingCall: call,
            }, this.updateLocalMediaStream());
            this.props.onCallStarted(call);
            call.observeHangup().subscribe(() => {
                this.endCall(call);
            });
            call.onStatsUpdate(this.onStatsUpdate);
            call.observeHangup().subscribe(()=>this.handleStreamClosed(call));
            call.observeRemoteStreams().subscribe(stream => {
                console.log("got outgoing stream: ", call);
                if (!this.isGroupOrganizer() && this.incomingCall) {
                    const cmp = this.props.to.isGroup ? (this.isGroupOrganizer() ?  0 : 1) :
                          this.props.me.self.uid.localeCompare(this.state.to.uid);
                    if (cmp != 0) {
                        const shouldAnswer = cmp > 0;
                        let toHangup;
                        if (shouldAnswer) {
                            console.log("hanging up outgoing call");
                            toHangup = call;
                        } else {
                            console.log("hanging up incoming call");
                            toHangup = this.incomingCall;
                        }
                        this.killDup(toHangup);
                        return;
                    }
                }
                if (this.props.incomingCallRequest) {
                    this.props.incomingCallRequest.answer();
                }
                this.outgoingCall = call;
                this.handleIncomingStreams(call, stream);               
            });
            if (this.props.incomingCallRequest) {
                this.props.incomingCallRequest.answer();
            }
            return Promise.resolve();
        });
    }

    callId = 0;

    makeCall = ()=> {
        const id = ++this.callId;
        const to = this.props.contact;
        if (this.state.callActive && !this.state.isCaller) {
            if (!this.state.call) {
                return this.state.callActive.answer();
            }
            return Promise.resolve();
        }
        if (this.props.incomingCallRequest) {
            this.props.answerIncomingCall(false);
            return Promise.resolve();
        }
        this.incomingCall = null;
        this.outgoingCall = null;
        const callActive = {
            id: id,
            remoteContact: to,
            answer: ()=> this.startCall(id),
            hangup: ()=> this.endCall()
        }
        this.beginCall(callActive, true);
     }

    handleCall = call => {
        call.onMuted((type, muted) => {
            if (type == "audio") {
                this.setState({
                    remoteAudioMuted: muted
                });
            } else if (type == "video") {
                this.setState({
                    remoteVideoMuted: muted
                });
            }
        });
        call.observeHangup().subscribe(() => {
            delete this.screenShare[call.id];
            this.updateRemoteScreenShare();
        });
        call.observeScreenShare().subscribe(screenShare => {
            if (screenShare) {
                if (this.screenShare[call.id]) {
                    return;
                }
                this.screenShare[call.id] = call;
            } else {
                if (!this.screenShare[call.id]) {
                    return;
                }
                delete this.screenShare[call.id];
            }
            this.updateRemoteScreenShare();
        });
        call.observeGroupScreenShare().subscribe(contact => {
            console.log("group screen share: ", contact);
            if (!this.state.groupScreenShare || this.state.groupScreenShare.uid != contact.uid) {
                this.setState({
                    groupScreenShare: contact,
                });
            }
        });
        call.observeComposite().subscribe(composite => {
            //console.log("composite: ", composite);
            this.setState({
                remoteComposite: composite
            });
        });
    }

    updateRemoteScreenShare = () => {
        console.log("updateRemoteScreenShare: ", this.state.remoteScreenShare, ": ", this.screenShare);
        for (var id in this.screenShare) {
            if (!this.state.remoteScreenShare) {
                if (this.isGroupOrganizer()) {
                    const call = this.screenShare[id];
                    this.state.calls.forEach(c => {
                        if (c.id != id) {
                            c.setGroupScreenShare(call);
                        }
                    });
                }
                this.state.remoteScreenShare = true;
                this.forceUpdate();
            }
            return;
        }
        if (this.state.remoteScreenShare) {
            if (this.isGroupOrganizer()) {
                this.state.calls.forEach(c => {
                    c.setGroupScreenShare(null);
                });
            }
            this.state.remoteScreenShare = false;
            this.forceUpdate();
        }
    }

    removeCall = c => {
        const newCalls = this.state.calls.filter(call => c != call);
        this.updateStreamsState(newCalls);
        this.setState({
            calls: newCalls,
        });
    }

    handleStreamClosed = call => {
        console.log("STREAM CLOSED: ", call);
        ring1.stop();
        this.endCall(call);
    }

    updateStreamsState = () => {
        const video = [];
        var added = {};
        for (var callId in this.streams) {
            const call = this.state.calls.find(c => c.id == callId);
            if (!call) {
                delete this.streams[callId];
                continue;
            }
            for (var j in this.streams[callId]) {
                const stream = this.streams[callId][j];
                stream.getVideoTracks().forEach(track => {
                    if (!added[stream.id]) {
                        added[stream.id] = true;
                    }
                    video.push({
                        call: call,
                        stream,
                    });
                });
            }
        }
        console.log("streams state: ", this.streams);
        console.log("video: ", video);
        this.setState({
            remoteVideo: video
        });
    }
    
    handleIncomingStreams = (call, stream) => {
        ring1.stop();
        //console.log("got stream :", call.id, ": ", stream.id, ": tracks: ", stream.getVideoTracks().length);
        if (!this.streams[call.id]) {
            this.streams[call.id] = {};
        }
        if (!this.streams[call.id][stream.id]) {
            this.streams[call.id][stream.id] = stream;
            stream.onaddtrack = this.updateStreamsState;
            stream.onremovetrack = this.updateStreamsState;
        }
        this.updateStreamsState();        
    }

    loadLocalStream = () => {
        return this.mediaDevice.getLocalStream().then(stream => {
            if (this.state.localVideoStream != stream) {
                if (this.state.localVideoStream) {
                    this.state.localVideoStream.getTracks().map(track => track.stop());
                }
                console.log("make call: got webcam stream: ", stream);
                // @TODO handle webcam loss
                stream.getVideoTracks()[0].addEventListener('ended', () => {
                    console.log("webcam closed");
                    //debugger;
                    //this.mediaDevice.reset();
                    //this.localLocalStream();
                });
                return new Promise((resolve, reject) => {
                    this.setState({
                        localVideoStream: stream
                    }, resolve)
                });
            }
            return Promise.resolve(stream);
        });
    }       

    beginCall = (callActive, isCaller) => {
        return this.loadLocalStream().then(stream => {
            console.log("loaded local stream: ", stream);
            this.setState({
                callActive: callActive,
                isCaller: isCaller
            });
        })
    }

    killDup = (call) => {
        console.log("killDup: ", call);
        this.state.calls = this.state.calls.filter(x => x != call);
        call.ignoreEndCall = true;
        call.hangup();
        this.props.onCallEnded(call);
        this.handleCallEnded(call);
        this.forceUpdate();
    }

    handleCallEnded = call => {
        if (call == this.state.incomingCall) {
            this.state.incomingCall = null;
        }
        if (call == this.state.outgoingCall) {
            this.state.outgoingCall = null;
        }
        if (call == this.incomingCall) {
            this.incomingCall = null;
        }
        if (call == this.outgoingCall) {
            this.outgoingCall = null;
        }
    }

    endCall = (call) => {
        this.endCallImpl(call);
        console.log("after endCall: ", this.state.calls);
    }
    
    endCallImpl = (call) => {
        if (call) this.props.onCallEnded(call);
        this.handleCallEnded(call);
        if (call) {
            if (this.streams[call.id]) {
                delete this.streams[call.id];
                this.updateStreamsState();
            }
        } else {
            //debugger;
            if (!this.state.calls.length) {
                this.reset();
                return;
            }
            this.state.calls.filter(x => x).map(call => {
                call.hangup();
            });
            return;
        }
        //debugger;
        if (call && call.ignoreEndCall) return;
        if (call) call.ignoreEndCall = true;
        const active = this.state.callActive;
        if (active) {
            const isCaller = active.isCaller;
            if (isCaller) {
                ring1.stop();
            } 
        } else {
            ring1.stop();
        }
        this.removeCall(call);
    }

    removeCall = call => {
        let calls = this.state.calls = this.state.calls.filter(x => x != call);
        //debugger;
        this.updateStreamsState();
        if (!calls.length) {
            this.reset();
        } else {
            this.setState({
                calls: calls
            });
        }
    }

    reset = () => {
        console.log("RESET");
        //debugger;
        const active = this.state.callActive;
        this.state.callActive = null;
        if (active) {
            callLeft.play();
            active.hangup();
        }
        if (this.screenshareSub) {
            this.screenshareSub.unsubscribe();
            this.screenshareSub = null;
        }
        console.log("ended call");
        this.streams = {};
        this.setState({
            remoteVideoWidth: 0,
            messagingActive: false,
            screenShare: false,
            callActive: false,
            remoteVideo: [],
            isCaller: false,
            calls: [],
            callStats: {
                duration: 0,
                bytesReceived: 0,
                bytesSent: 0
            },
            incomingCall: null,
            outgoingCall: null,
            audioMuted: false,
            videoMuted: false,
        });
        this.incomingCall = this.outgoingCall = null;
    }

    onAnimationComplete = value => {
        if (value == 0) {
            //debugger;
            const localVideoStream = this.state.localVideoStream;
            if (localVideoStream) {
                localVideoStream.getTracks().map(track => track.stop());
                this.setState({
                    localVideoStream: null,
                });
            }
            this.mediaDevice.reset();
        }
    }

    closeContact = (shouldEndCall) => {
        return new Promise((resolve, reject) => {
            this.chatMessages = {};
            this.updateMessagesLater();
            if (this.state.to) {
                if (this.state.chat) this.state.chat.markRead();
                this.setState({openContact: null, chat: null, remoteVideo: []},
                              () => {
                                  if (shouldEndCall) this.endCall();
                                  if (isDesktop() && this.contactsSearchField) {
                                      this.contactsSearchField.focus();
                                  }
                                  resolve();
                              });
            } else {
                resolve();
            }
        });
    }

    callOrHangup = () => {
        if (this.state.callActive) {
            this.endCall();
        } else {
            this.makeCall(this.state.to);
        }
    }

    sendMessage = text => this.state.chat.send(text);

    deleteMessage = message => {
        this.state.chat.deleteMessage(message);
    }

    answerIncomingCall = (call, decline) => {

    }

    handleIncomingCallRequest = (c) => {
        console.log("incoming call request: ", c);
        if (!c) {
            return;
        }
        if (this.state.calls.length > 0) {
            c.answer();
        }
    }

    isGroupOrganizer = () => this.state.to.isGroup && this.state.to.group.organizer == this.props.me.self.uid;
    
    handleIncomingCall = (c) => {
        if (!c) {
            return;
        }
        console.log("got incoming call: ", c);
        const {call, resolve} = c;
        const me = this.props.me;
        const contact = this.state.to;
        const mediaDevice = this.mediaDevice;
        const decline = () => {
            return resolve();
        }
        const accept = () => {
            return resolve({
                mediaDevice: mediaDevice,
                answer: call => {
                    console.log("incoming call connected: ", call);
                    if (call) {
                        const remote = call.getRemoteContact();
                        if (me.self.uid == remote.uid) {
                            //console.log("self-call!");
                            this.toggleAudioMuted(true);
                            call.observeHangup().subscribe(()=> this.endCall(call));
                            return;
                        }
                        if (this.isGroupOrganizer()) {
                            this.state.calls.map(c => {
                                c.add(call);
                                call.add(c);
                            });
                        } else if (this.props.to.isGroup) {
                            if (this.state.calls.length > 0) {
                                // duplicate call
                                return call.hangup();
                            }
                        }
                        this.setState({
                            calls: this.state.calls.concat([call])
                        }, this.updateLocalMediaStream);
                        this.handleCall(call);
                        this.setState({
                            incomingCall: call,
                        }, this.updateLocalMediaStream);
                        call.onStatsUpdate(this.onStatsUpdate);
                        call.observeHangup().subscribe(() => {
                            console.log("got incoming call hangup");
                            this.handleStreamClosed(call);
                        });
                        call.observeRemoteStreams().subscribe(stream => {
                            console.log("got incoming stream: ", call);
                            if (this.outgoingCall && !this.isGroupOrganizer()) {
                                const cmp = this.props.to.isGroup ? (this.isGroupOrganizer() ?  0 : 1) :
                                      this.props.me.self.uid.localeCompare(this.state.to.uid);
                                if (cmp != 0) {
                                    const shouldAnswer = cmp > 0;
                                    let toHangup;
                                    if (shouldAnswer) {
                                        console.log("hanging up incoming call");
                                        toHangup = call;
                                    } else {
                                        console.log("hanging up outgoing call");
                                        toHangup = this.outgoingCall;
                                    }                                
                                    this.killDup(toHangup);
                                    return;
                                }
                            }
                            this.incomingCall = call;
                            this.handleIncomingStreams(call, stream)
                        });
                        this.props.onCallStarted(call);
                        return Promise.resolve();
                    }
                    return Promise.resolve();
                },
                from: contact,
                received: Date.now()
            });
        };

        if (this.state.callActive) { // already on a call
            console.log("accepting incoming call");
            return accept();
        }
        
        const callActive = {
            answer: accept,
            hangup: decline
        }
        call.observeHangup().subscribe(() => {
            console.log("Incoming call hung up");
            if (this.state.calls.length == 0) {
                if (this.state.callActive == callActive) {
                    this.setState({
                        callActive: null,
                    });                
                }
            }
        });
        return this.beginCall(callActive, false);
        
    }

    recordMessage = () => {
        return this.loadLocalStream().then(stream => {
            this.setState({
                callActive: {
                    recording: true,
                    hangup: this.cancelRecording,
                    answer: this.startRecording,
                }
            });
        });
    }

    startRecording = () => {
        const chunks = [];
        const stream = this.state.localVideoStream;        
        let mimeType;
        const types = ["video/mp4;codecs=h264", "video/webm;codecs=vp9", "video/webm;codecs=vp8"];
        if (MediaRecorder.isTypeSupported) {
            types.map(t => {
                if (!mimeType && MediaRecorder.isTypeSupported(t)) {
                    mimeType = t;
                }
            });
        } else { // safari
            mimeType = "video/mp4";
        }
        console.log("recorder mime type: ", mimeType);
        if (!mimeType) {
            //debugger;
        }
        this.mediaRecorder = new MediaRecorder(stream, {mimeType: mimeType});
        this.mediaRecorder.start();
        this.mediaRecorder.onstop = () => {
            const blob = new Blob(chunks, {type: mimeType});
            let p = Promise.resolve(blob);
            //if (isChrome) {
            //p = doTranscode(blob);
            //}
            return p.then(blob => {
                console.log("blob type: ", blob.type);
                this.chat.insertVideo(blob);
                this.mediaRecorder = null;
                this.setState({
                    callActive: null,
                });
                const resolve = this.mediaRecorderResolve;
                this.mediaRecorderResolve = null;
                resolve();
            }).catch(err => {
                console.error(err);
                return blob;
            });
        }
        this.mediaRecorder.ondataavailable = (e) => {
            chunks.push(e.data);
        }
    }

    cancelRecording = () => {
        if (this.mediaRecorder) {
            this.mediaRecorder.onstop = () => {};
            this.mediaRecorder.ondataavailable = () => {};
            this.mediaRecorder.stop();
            this.mediaRecorder = null;
        }
        this.setState({
            callActive: null,
        });
        return Promise.resolve();
    }

    setRecordingPaused = (paused) => {
        if (paused) {
            this.mediaRecorder.pause();
        } else {
            this.mediaRecorder.resume();
        }
    }

    stopRecording = () => {
        return new Promise((resolve, reject) => {
            this.mediaRecorderResolve = resolve;
            this.mediaRecorder.stop();
        });
    }

    callJoin = () => {
        if (!this.state.callActive) {
            return Promise.resolve();
        }
        if (this.state.callActive.recording) {
            if (this.mediaRecorder) {
                return this.stopRecording();
            } else {
                this.state.callActive.answer();
            }
            return Promise.resolve();
        }
        this.state.callActive.answer()
        return Promise.resolve();
    }

    onRemoteVideoVisible = (width) => {
        const prev = this.state.remoteVideoWidth || 0;
        this.setState({
            remoteVideoWidth: width
        });
        console.log("WIDTH: ", width);
        if (width > 0) {
            if (!prev) {
                callJoin.play();
            } else {
                const res = w => w >= 3840 ? 3840 : w >= 1920 ? 1920 : w >= 1280 ? 1280 : w >= 640 ? 640 : 480;
                //console.log("res prev: ", prev, " => ", res(prev));
                //console.log("res: ", width, " => ", res(width));
                if (res(prev) != res(width)) { 
                    notShort.play();
                }
            }
        }
    }

    toggleCallActive = () => {
        //debugger;
        return this.state.callActive ? this.endCall() : this.makeCall();
    }

    onAppointmentEnded = msg => {
        this.forceUpdate();
    }

    onChatCreated = chat => {
        this.chat = chat;
        if (this.openSubscriptionRequested) {
            chat.subscribeToChat();
        }
    }

    scrollBack = ts => {
        if (this.cursor != ts) {
            this.cursor = ts;
            this.messageCount += this.messagePageSize;
            this.state.chat.getHistory(ts, this.messagePageSize);
            this.updateMessagesNow();
        }
    }


    render() {
        const isNonOrganizerGroup = this.state.to.isGroup;
        console.log("isNonOrganizerGroup: ", isNonOrganizerGroup);
        if (!this.state.chat) return null;
        return <UIOpenContactView
        observeCanvasData={this.observeCanvasData}
        sendCanvasData={this.sendCanvasData}
        observeWhiteboard={this.observeWhiteboard}
        saveWhiteboard={this.saveWhiteboard}
        mostRecent={this.state.mostRecent}
        scrollBack={this.scrollBack}
        recordMessage={this.recordMessage}
        onChatCreated={this.onChatCreated}
        onAnimationComplete={this.onAnimationComplete} visible={this.props.selected}
        removeContact={this.props.removeContact}
        onAppointmentEnded={this.onAppointmentEnded}
        setLocalVideoResolution={this.setLocalVideoResolution}
        waitForSystemUpdate={this.waitForUpdate}
        onStreamVisible={this.onRemoteVideoVisible}
        scheduleAppointmentWith={this.props.scheduleAppointmentWith}
        rescheduleAppointment={this.props.rescheduleAppointment}                    
        showSystemProgressIndicator={this.showSystemProgressIndicator}
        key={this.state.to.uid}
        localVideoStream={this.state.localVideoStream}
        remoteStreams={this.state.remoteVideo}
        joinCall={() => this.callJoin()}
        recording={false}
        remoteScreenShare={this.state.remoteScreenShare || this.state.groupScreenShare}
        remoteComposite={this.state.remoteComposite || isNonOrganizerGroup}
        audioMuted={this.state.audioMuted} toggleAudioMuted={this.toggleAudioMuted}
        videoMuted={this.state.videoMuted} toggleVideoMuted={this.toggleVideoMuted}
        screenShare={this.state.screenShare} toggleScreenShare={this.toggleScreenShare}
        fullScreen={this.state.fullScreen} toggleFullScreen={this.toggleFullScreen}
        yourVideoHidden={this.state.yourVideoHidden} toggleYourVideoHidden={this.toggleYourVideoHidden}
        toggleCallActive={this.toggleCallActive}
        callActive={this.state.callActive}
        call={this.state.calls.length > 0 ? this.state.calls[0] : null}
        endCall={()=> this.endCall(null)}
        makeCall={this.makeCall}
        closeContact={this.closeContact}
        reactToChatMessage={this.reactToMessage}
        deleteChatMessage={this.deleteMessage}
        setPopupShowing={x => this.setState({popupShowing: x})}
        uploadFile={file => this.state.chat.uploadFile(file)}
        shareFile={file=>this.chat.uploadFile(file)}
        localContact={this.props.me.self}
        contact={this.state.to}
        me={this.props.me}
        messages={this.state.messages}
        downloadFile={this.state.chat.downloadFile}
        saveMessage={this.saveMessage}
        sendMessage={text => {
            const chat = this.state.chat;
            const msg = chat.createMessage(text);
            this.onChatMessage("added", msg);
            chat.sendMessage(msg).then(() => {
                this.chat.scrollToBottom();
            });
        }}
        typing={()=>this.state.chat.typing()}
        observeTyping={()=>this.state.chat.observeTyping()}
        decline={call=>this.answerIncomingCall(call, true)}
        answer={call=>this.answerIncomingCall(call, false)}
        isSameChannel={this.isSameChannel}
        incomingCalls={this.state.incomingCall}
        selectedContact={this.state.to}
        />
    }
}


