import React, {useState, Component } from 'react';
import {ReactSVG} from 'react-svg';
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { DragPreviewImage, useDrag } from 'react-dnd'
import TeTeLogo from "../../assets/Assets/TeteLogo00.svg";
import Search from "../../assets/icons/Search.svg";
import Menu from "../../assets/icons/Menu.svg";
import Settings from "../../assets/icons/Settings.svg";
import Upgrade from "../../assets/icons/Upgrade.svg";
import SpinnerShape from "../../assets/icons/SpinnerShape.svg";
import Plus from "../../assets/icons/AddContact.svg";
import Cross from "../../assets/icons/Cross.svg";
import Trash from "../../assets/icons/Trash.svg";
import {UIOKCancel} from "../OKCancel";
import {UIMeContact, UIMeCard} from "../Me";
import Edit from "../../assets/icons/ChatEdit.svg";
import Hangup from "../../assets/icons/EndCall.svg";
import Call from "../../assets/icons/Call.svg";
import Hold from "../../assets/icons/Hold.svg";
import Forward from "../../assets/icons/Forward.svg";
import Send from "../../assets/icons/Send.svg";
import Home from "../../assets/icons/Home.svg";
import Arrow from "../../assets/icons/PointerRight.svg";
import Cal from "../../assets/icons/Calender.svg";
import {Contact} from "../../Contact";
import Clipboard from "../../Clipboard";
import {isDesktop} from "../../Platform";
import {generateThumbnail, UIProfileIcon} from "../ProfileIcon";
import {UIIcon} from "../Icon";
import {UIButton, UIToggleButton} from "../Button";
import {makeDroppable} from "../Calendar";
import CallSound from "../../assets/audio/ConfernceAlert.wav";
import {isMobile, isTabletOrMobile} from "../../Platform";
import './index.css'
import moment from 'moment';

const {Howl, Howler} = require('howler');

class IncomingCallPrompt extends Component {
    constructor(props) {
        super(props);
        this.buttons = [
            {
                icon: Call,
                label: "Answer Call",
                className: "uiIncomingCallPromptButtonAnswer",
                action: this.props.call
            },
            {
                icon: Cross,
                label: "Decline Call",
                className: "uiIncomingCallPromptButtonDecline",
                action: this.props.decline
            }]
    }
    render() {
        const contact = this.props.contact;
        return <div className='uiIncomingCallPrompt'>
            <div className='uiIncomingCallPromptBody'>
            <div className='uiIncomingCallPromptHeader'>
            <div className="uiSidebarHeaderProfile">
            <UIProfileIcon radius={65/2-2} contact={contact}/>
            <div className='uiSidebarHeaderProfileName'>{contact.displayName}</div>
            <div className='uiSidebarHeaderProfileCreds'>{contact.creds}</div>
            </div>
            <div className='uiIncomingCallPromptTitle'>Incoming Call</div>
            </div>
            <div className='uiIncomingCallPromptButtons'>
            {this.buttons.map(button => {
                return <div onClick={button.action} className={'uiIncomingCallPromptButton ' + button.className}>
                    <div className='uiIncomingCallPromptButtonIcon'><ReactSVG src={button.icon}/></div>
                    <div className='uiIncomingCallPromptButtonLabel'>{button.label}</div>
                    </div>
            })}
            </div>
            </div>
            <div className='uiIncomingCallPromptArrow'>
            <ReactSVG src={Arrow}/>
             </div>
            </div>
    }
}
class SidebarNavButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let className = 'uiSidebarNavButton';
        if (this.props.className) {
            className += " "+this.props.className;
        }
        const selected = this.props.selected == this.props.name;
        if (selected) {
            className += ' uiSidebarNavButtonSelected';
        }
        let unreadCount = this.props.unreadCount || 0;
        return <div className={className} onClick={()=>this.props.onChange(this.props.name)}>
            <div className='uiSidebarNavButtonIcon'><ReactSVG src={this.props.icon}/></div>
            <div className='uiSidebarNavButtonLabel'>{this.props.label}</div>
            <div className='uiSidebarNavButtonArrow'><ReactSVG src={Arrow}/></div>
            {unreadCount > 0 && !selected && <div className='uiSideListContactUnread'>{this.props.unreadCount> 99 ? "99+" : this.props.unreadCount}</div>}
            </div>
    }
}



const SideListElement = props => {

    const [collectedProps, drag] = useDrag({
        item: { id: props.dragId || "", type: props.dragType || "", appointment: props.appointment},
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                //console.log("You dropped ", item, " into ", dropResult);
            }
        }
    });

    const [hover, setHover] = useState(false);

    const renderLeft = ()=> {
        if (props.busy) {
            return <div key='spinner' className={'uiNiceButtonIcon  uiNiceButtonIconBusy'}><ReactSVG src={ SpinnerShape }/></div>
        }
        return <UIProfileIcon key={'profileIcon-'+props.contact.uid} contact={props.contact} className='uiSideListIcon'/>;
    }
    
    const renderMiddle = (truncate) =>  {
        return <div key='middle' className={'uiSideListItemMiddle' + (!truncate ? "" : " uiSideListItemMiddleTruncated")} >
            <div key='title' className='uiSideListItemTitle'>{props.title}</div>
            <div key='tubtitle' className='uiSideListItemSubtitle'>{props.subtitle}</div>
            </div>
    }

    const renderRight = () => {
        return <div key='right' className={'uiSideListItemRight'} >
            <div key='title' className='uiSideListItemSubtitle'>{props.msg1}</div>
            <div key='subtitle' className='uiSideListItemSubtitle'>{props.msg2}</div>
            </div>;
    }
    const unread = props.unreadCount ? <div key='unread' className='uiSideListContactUnread'>{props.unreadCount}</div> : null;

    const renderControls = () => {
        const result = [];
        if (props.selected) {
            result.push(<div key='arrow' className='uiSideListItemSelectedArrow'><ReactSVG src={Arrow}/></div>)
        } else {
            if (unread) {
                result.push(unread);
            }
            if (hover && props.buttons && props.buttons.length > 0) {
                props.buttons.map(b => result.push(b));
            }
        }
        return <div className='uiSideListElementControls'>
            {result}
            </div>
    }

    const callAction = () => {
        if (props.action) props.action();
    }

    const render = () => {
        let containerClassName = 'uiSideListElementContainer';
        let className = props.className ? " "+props.className : '';
        if (props.selected) {
            containerClassName += " uiSideListElementContainerSelected";
            className += " uiSideListElementSelected";
        }
        if (props.containerClassName) {
            containerClassName += " "+ props.containerClassName;
        }
        const tooltip = props.tooltip || "";
        return <div ref={props.Ref}
        className={containerClassName} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
            <div className={'uiSideListElement'+className} onClick={callAction} ref={!isTabletOrMobile() && props.onAddToCalendar ? drag : null}>
            <div className='uiSideListLeft'>
            {renderLeft()}
            </div>
            <div className='uiSideListMiddle'>
            {renderMiddle(props.msg1 || props.msg2)}
            </div>
            <div className='uiSideListRight'>
            {renderRight()}
            {props.statusClass && <div key='status' className={"uiSideListElementStatus "+props.statusClass}/>}
            </div>
            </div>
            {renderControls()}
        </div>;
    }
    return render();
}

class SideListEvent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const event = this.props.event;
        const now = Date.now();
        const until = moment.duration(event.when - now).format("mm:ss");
        return <SideListElement
        selected={this.props.selected}
        contact={event.contact}
        title={event.contact.displayName}
        subtitle={event.subscription.displayName}
        msg1={until}
        msg2={"$"+event.subscription.price.toFixed(2)}
        />
    }
}

const callSound = new Howl({
    src: [CallSound],
    loop: false,
});

class SideListImpromptuCall extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.scrollIntoView();
        this.timer = setInterval(() => {
            const now = Date.now();
            const call = this.props.call;
            const dur = now - call.getStartTime();
            if (dur > 60000) {
                const mins = Math.round(dur / 60000);
                if (mins != this.mins) {
                    this.mins = mins;
                    this.forceUpdate();
                } else {
                    return;
                }
            }
            this.forceUpdate()
        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }
    setRef = x => {
        if (x && x != this.ref) {
            this.ref = x;
        }
    }
    render() {
        const call = this.props.call;
        let contact = call.getRemoteContact();
        if (contact.group) {
            contact = contact.group;
        }
        const inProgress = call.isInProgress();
        const now = Date.now();
        const dur = now - call.getStartTime();
        const fmt = dur < 60000 ? "s": "m";
        const since = moment.duration(dur).format(fmt)+fmt;
       // const message = <Tooltip title="Message"><div className='uiSideListMessage' onClick={()=>this.props.message(call)}><ReactSVG src={Send}/></div></Tooltip>;
        //const hangup = <Tooltip title="Hangup"><div className='uiSideListMessage uiSideListMessageHangup' onClick={()=>this.props.hangup(call)}><ReactSVG src={Hangup}/></div></Tooltip>;
        //const hold = <Tooltip title={this.props.held ? "Resume" : "Hold"}><div className='uiSideListMessage uiSideListMessageHold' onClick={()=>this.props.hold(call)}><ReactSVG src={this.props.held ? Forward : Hold}/></div></Tooltip>;
        //const buttons = this.props.selected ? [] : [hold];
        let inc = this.props.incoming.length > 0 ? this.props.incoming[0] : null;
        return <div className='uiSideListIncomingCallItem'>
            <SideListElement Ref={this.setRef} containerClassName={this.props.selected? 'WaitingRoomSelected' : 'WaitingRoom'} action={()=>this.props.answer(call)}
        selected={this.props.selected}
        contact={contact}
        title={contact.displayName}
        subtitle={this.props.held ? "Call Holding": inProgress ? "Call in Progress" : "Calling"}
        msg1={call.isRelay() ? "Relayed" : "" }
        msg2={since}
            />
            {inc && <IncomingCallPrompt selected={this.props.selected} contact={inc.from} call={()=>this.props.answer(inc)} decline={()=>this.props.decline(inc)}/>}
        </div>
            
    }
}

export class SideListImpromptuCallWaiting extends Component {
    constructor(props) {
        super(props);
    }
    setRef = x => {
        if (x && x != this.ref) {
            this.ref = x;
        }
    }
    componentDidMount() {
        this.timer = setInterval(() => this.forceUpdate(), 1000);
        this.props.scrollIntoView(this.ref);
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }
    render() {
        const call = this.props.call;
        const contact = call.from.group ? call.from.group : call.from;
        const now = Date.now();
        const dur = now - call.received;
        const fmt = dur < 60000 ? "s": "m";
        const since = moment.duration(dur).format(fmt)+fmt;
        const message = <Tooltip title="Message"><div className='uiSideListMessage' onClick={()=>this.props.message(call)}><ReactSVG src={Send}/></div></Tooltip>;
        const decline = <Tooltip title="Decline"><div className='uiSideListMessage uiSideListMessageHangup' onClick={()=>this.props.decline(call)}><ReactSVG src={Hangup}/></div></Tooltip>;
        //const answer = <Tooltip title="Answer"><div className='uiSideListMessage uiSideListMessageCall' onClick={()=>this.props.answer(call)}><ReactSVG src={Hangup}/></div></Tooltip>
        return <div className='uiSideListIncomingCallItem'>
            <SideListElement Ref={this.setRef} containerClassName={this.props.selected ? 'WaitingRoomSelected' : 'WaitingRoom'} action={this.props.action}
        selected={this.props.selected}
        tooltip={"Answer Call"}
        contact={contact}
        title={contact.displayName}
        subtitle={"Call Waiting"}
        msg1={''}
        msg2={dur > 0 ? since : ''}
            />
            {!call.answered && (this.props.index == 0 || this.props.selected) && <IncomingCallPrompt selected={this.props.selected} contact={call.from} call={()=>this.props.answer(call)} decline={()=>this.props.decline(call)}/>}
            </div>
            
    }
}


export class SideListProductChannel extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const channel = this.props.channel;
        const contact = channel.remoteContact;
        const det = channel.product.details;
        return <SideListElement
        selected={this.props.selected}
        dragId={channel.product.productId}
        dragType={"appointment"}
        appointment={channel}
        draggable={true}
        onAddToCalendar={this.props.onAddToCalendar ? date => this.props.onAddToCalendar(this.props.channel, date) : null}
        action={this.props.action} className={"uiSideListProductChannel"}
        contact={contact}
        title={channel.product.name}
        subtitle={(this.props.isSeller ? "for " : "by ")+contact.displayName}
        msg1={det.service.name}
        msg2={"$"+channel.product.details.unitPrice.toFixed(2)}
        />
    }
}


class SideListContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.future = [];
    }

    componentDidMount() {
        this.checkSub();
    }

    componentDidUpdate() {
        this.checkSub();
    }
    
    checkSub = () => {
        if (this.sub) return;
        if (!this.props.me) return;
        if (!this.props.contact) return;
    }

    componentWillUnmount() {
        if (this.sub) this.sub.unsubscribe();
    }

    
    render() {
        ////console.log("add to calendar: ", this.props.onAddToCalendar);
        const contact = this.props.contact;
        //const remove = <RemoveContact removeContact={()=>this.props.remove(contact)} contact={contact}/>;
        //const call = <Tooltip title={"Call "+contact.displayName}><div className='uiSideListMessage uiSideListMessageCall' onClick={()=>this.props.call(contact)}><ReactSVG src={Hangup}/></div></Tooltip>;
        let title = "";
        let price;
        let until;
        let statusClass;
        let appt = this.props.nextAppointment;
        if (appt) {
            title = appt.title;
            price = appt.invoiceAmount;
            switch (appt.status) {
            case "accepted":
                statusClass = "uiSideListElementStatusAccepted";
                if (price == 0 || appt.paymentStatus == "succeeded") {
                    break;
                }
            default:
                statusClass = "uiSideListElementStatusScheduled";
                break;
            case "declined":
                statusClass = "uiSideListElementStatusDeclined";
                break;
            }
            const date = new Date(appt.start);
            const now = new Date(Date.now());
            const isSameMonth = (now, date) => now.getYear() == date.getYear() && now.getMonth() == date.getMonth();
            const isSameDay = (now, date) => isSameMonth(now, date) && now.getDate() == date.getDate();
            if (isSameDay(now, date)) {
                const hrs = (date.getHours() + date.getMinutes() / 60) - (now.getHours() + now.getMinutes() / 60);
                if (hrs >= 1) {
                    until = Math.round(hrs) + "h";
                } else {
                    const mins = hrs * 60;
                    if (mins <= 0) {
                        until = "Now";
                    } else {
                        until = Math.round(mins) + "m";
                    }
                }
            } else {
                const tom = new Date(now.getTime() + 24 * 60 * 60 * 1000);
                if (isSameDay(tom, date)) { 
                    until = "1d";
                } else {
                    until = moment(date).format("MMM Do");
                }
            } 
        } else {
            const sub = this.props.nextSub;
            if (sub) {
                const responseDur = sub.responseTime * 24 * 60 * 60 * 1000;
                const when = this.props.me.getNextResponseTime(sub);
                title = "Reply by "+moment(new Date(when)).format("h:mm A");
                until = moment(when).format("MMM Do");
                const factor = (when - Date.now()) / responseDur;
                if (factor < 0.25) {
                    statusClass = "uiSideListElementStatusDeclined";
                } else if (factor < 0.5) {
                    statusClass = "uiSideListElementStatusScheduled";
                } else {
                    statusClass = "uiSideListElementStatusAccepted";
                }
            }
        }
        let msg1 = '';
        let msg2 = '';
        
        msg1 = until;
        if (price) {
            msg2 = '$'+price;
        }
        if (!title) {
            if (contact.creds) {
                title = <span className='uiSideListElementCreds'>{contact.creds}</span>;
            }
        }
        return <SideListElement action={()=>this.props.message(contact)}
        unreadCount={this.props.unreadCount}
        statusClass={statusClass}
        dragId={contact.uid}
        dragType={"appointment"}
        appointment={contact}
        onAddToCalendar={date => this.props.onAddToCalendar(contact, date)}
        selected={this.props.selected}
        busy={this.props.busy}
        tooltip={this.props.selected ? null: "Open Chat"}
        contact={contact}
        title={contact.displayName}
        subtitle={title}
        msg1={msg1}
        msg2={msg2}
        />
    }
}

class SideListHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    onSearchChange = e => {
        this.props.onSearch(e.target.value)
    }

    onPlusButtonClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.togglePlusLink();
    }

    onSearchButtonClick = () => {
        if (this.state.searching) {
            if (!this.props.searchTerm) {
                this.setState({
                    searching: false,
                });
                return;
            }
            this.props.onSearch("");
            this.input.focus();
            return;
        }
        this.setState({
            searching: true
        }, () => {
            this.input.focus();
        });
    }
    
    setInputRef = x => {
        if (x && x != this.input) {
            this.input = x;
            if (this.state.searching) {
                x.focus();
            }
        }
    }

    stopSearch = () => {
        this.setState({
            searching: false
        });
    }
    
    render() {
        return <div className='uiSideListHeader'>
            <div key='title' style={this.state.searching ? {display: 'none'} : null} className='uiSideListHeaderTitle'>{this.props.title}</div>
            <ClickAwayListener onClickAway={this.stopSearch}><div className='uiSideListHeaderSearchFieldContainer'>
            <div key='searchInput' style={!this.state.searching ? {display: 'none'} : null} className='uiSideListHeaderSearchField'>
            <input value={this.props.searchTerm} placeholder={"Search Contacts"} ref={this.setInputRef} autoFocus={true} onChange={this.onSearchChange}/>
            </div>
            <Tooltip title={this.state.searching ? "Clear": 'Search Contacts'}><div className='uiSideListHeaderSearch' onClick={this.onSearchButtonClick}><UIIcon className='uiSideListHeaderButton' icon={this.state.searching ? Cross : Search}/></div></Tooltip>
            </div>
            </ClickAwayListener>
            <div key='buttons' className='uiSideListHeaderButtons'>
            <Tooltip title='Add Contact'><div className='uiSideListHeaderPlus' onClick={this.onPlusButtonClick} ><UIIcon className='uiSideListHeaderButton' icon={Plus}/></div></Tooltip>
            </div>
            </div>
    }
}

class UpNext extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className='uiSideList uiUpNext'>
               <SideListHeader title={"Up Next"}/>
               <div className='uiSideListList'>
                 {this.props.events.map(event => <SideListEvent event={event}/>)}
              </div>
            </div>;
        
    }
}

class WaitingRoom extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className='uiSideList uiWaitingRoom'>
               <SideListHeader title={"Waiting Room"}/>
               <div className='uiSideListList'>
              </div>
            </div>;
        
    }
}

class ContactModel {

    nextAppt = () => {
        debugger;
        const future = this.future;
        if (future && future.length > 0) {
            future.sort((x, y) => x.start - y.start);
            this.appt = future.shift();
        } else {
            this.appt = null;
        }
        console.log("next appt: ", this.appt);
        this.onChange(this);      
    }

    constructor(me, contact, onChange) {
        this.me = me;
        this.contact = contact;
        this.future = [];
        this.onChange = x => {
            let start;
            if (this.appt) {
                start = this.appt.start;
            }
            if (this.sub) {
                const sub = this.sub;
                if (sub.latestQuestion > sub.latestResponse) {
                    start = me.getNextResponseTime(sub);
                }
            }
            this.start = start;
            onChange(x);
        }
        
        this.subSub = me.observeSubscription(this.contact).subscribe(change => {
            const sub = change.subscription;
            if (change.type != 'removed') {
                const q = sub.latestQuestion || 0;
                const r = sub.latestResponse || 0;
                if (sub.state == 'active' && q > r) {
                    this.sub = sub;
                } else {
                    this.sub = null;
                }
            } else {
                this.sub = null;
            }
            this.onChange(this);
        });
        this.subSub1 = me.observeMySubscription(this.contact).subscribe(change => {
            const sub = change.subscription;
            if (change.type != 'removed') {
                const q = sub.latestQuestion || 0;
                const r = sub.latestResponse || 0;
                if (sub.state == 'active' && q > r) {
                    this.sub = sub;
                } else {
                    this.sub = null;
                }
            } else {
                this.sub = null;
            }
            this.onChange(this);
        });
        this.apptSub = me.observeUpcomingAppointments(this.contact).subscribe(change => {
            const appt = change.appointment;
            let active = true;
            if (change.type == 'removed') {
                active = false;
            } 
            if (appt.status == 'canceled') {
                active = false;
            }
            if (appt.status == 'declined' && appt.organizer.uid != this.me.self.uid) {
                active = false;
            }
            if (active) {
                const me = this.me;
                if (appt.contact.uid != this.contact.uid) {
                    //console.log("Got appointment: ", appt);
                    return;
                }
                //console.log("Got my next appointment: ", appt);
                const prev = this.appt;
                const now = Date.now();
                if (appt.end < now) {
                    return
                }
                if (this.appt && this.appt.id == appt.id) {
                    this.appt = null;
                    this.future.push(appt);
                    clearTimeout(this.timeout);
                    this.nextAppt();
                    return;
                } else {
                    if (prev) {
                        if (prev.start < appt.start) {
                            this.future.push(appt);
                            return;
                        }
                    }
                }
                this.appt = appt;
                const until = appt.end - now;
                if (until < 2147483647) {
                    this.timeout = setTimeout(this.nextAppt, until);
                }
                this.onChange(this);
            } else {
                const future = this.future;
                if (this.appt &&
                    this.appt.id == appt.id) {
                    this.appt = null;
                    clearTimeout(this.timeout);
                    this.nextAppt();
                }
            }
        });
    }

    release = () => {
        clearTimeout(this.timeout);
        this.apptSub.unsubscribe();
        this.subSub.unsubscribe();
        this.subSub1.unsubscribe();
    }
}

class ContactsList extends Component {

    
    constructor(props) {
        super(props);
        this.contacts = {};
        this.state = {
            contacts: []
        };
        this.addToCalendar = props.addClientToCalendar;
        this.scroller = React.createRef();
    }

    componentDidMount() {
        this.scroller.current.onwheel = (e) => {
            //console.log("mouse wheel: ", e.deltaY);
            requestAnimationFrame(() => this.scroller.current.parentElement.scrollBy(0, e.deltaY));
        }
        this.sub = this.props.me.observeContacts().subscribe(change => {
            //console.log("observe contact: ", change);
            const contactRec = change.contact;
            const contact = contactRec.contact;
            if (contact == null) return;
            const existing = this.contacts[contact.uid];
            if (change.type == "removed" || contactRec.state == "removed") {
                //console.log("removing contact: ", contact);
                if (existing) {
                    existing.release();
                    delete this.contacts[contact.uid];
                }
            } else {
                if (existing) {
                    existing.contact = contact;
                } else {
                    this.contacts[contact.uid] = new ContactModel(this.props.me, contact, this.onAppointmentChanged);
                }
            }
            this.updateContactsLater();
        });
        //this.getContactLink();
    }

    componentWillUnmount() {
        Object.values(this.contacts).map(c => c.release());
        if (this.sub) this.sub.unsubscribe();
        if (this.sub1) this.sub1.unsubscribe();
        this.contacts = {};
        this.state = {};
        console.log("sidebar unmounted");
    }

    onAppointmentChanged = contactModel => {
        this.forceUpdate();
    }

    checkObserveGroups = () => {
        if (this.sub1Init) return;
        this.sub1Init = true;
        this.props.me.getContacts().then(() => {
            this.sub1 = this.props.me.observeGroups().subscribe(change => {
                console.log("observe group: ", change);
                const contact = change.contact;
                if (contact == null) return;
                const existing = this.contacts[contact.uid];
                if (change.type == "removed") {
                    //console.log("removing contact: ", contact);
                    if (existing) {
                        existing.release();
                        delete this.contacts[contact.uid];
                    }
                } else {
                    if (existing) {
                        existing.contact = contact;
                    } else {
                        this.contacts[contact.uid] = new ContactModel(this.props.me, contact, this.onAppointmentChanged);
                    }
                }
                this.updateContactsLater();
            });
        });
    }

    updateContactsLater = () => {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            this.checkObserveGroups();
            const contacts = [];
            for (var i in this.contacts) {
                contacts.push(this.contacts[i]);
            }
            this.setState({
                contacts: contacts
            });
        }, 200);
    }

    getContactLink = () => {
        if (this.state.link) {
            return Promise.resolve(this.state.link);
        }
        return new Promise((resolve, reject) => {
            this.props.contacts.getContactLink().then(link => {
                this.setState({link: link}, () => resolve(link));
            });
        });
    }

    scrollIntoView = el => {
        this.scroller.current.parentElement.scrollTop = 0;
    }

    render() {
        let contacts = this.state.contacts.filter(contactModel => {
            const x = contactModel.contact;
            for (var call of this.props.calls) {
                if (this.props.isSameChannel(call.getRemoteContact(),  x)) return false;
            }
            for (var call of this.props.incomingCalls) {
                if (this.props.isSameChannel(call.from, x)) return false;
            }
            if (!x.displayName) {
                return false;
            }
            return true;
        });
        if (this.props.searchTerm) {
            const searchTerms = this.props.searchTerm.toLowerCase().split(/\s+/);
            const matches = {};
            const filter = contactModel => {
                const x = contactModel.contact;
                if (!x || !x.displayName) return false;
                let matched = 0;
                const name = x.displayName.toLowerCase();
                const terms = name.split(/\s+/);
                searchTerms.map(searchTerm => {
                    terms.map(term => { if (term.startsWith(searchTerm)) { matched++ } });
                });
                matches[x.uid] = matched;
                return matched > 0;
            }
            const sort = (c1, c2) => {
                const x = c1.contact;
                const y = c2.contact;
                const w1 = matches[x.uid];
                const w2 = matches[y.uid];
                const cmp1 = w2-w1;
                if (cmp1 !== 0) {
                    return cmp1;
                }
                return x.displayName.localeCompare(y.displayName);
            }
            contacts = contacts.filter(filter).sort(sort);
        } else {
            contacts.sort((c1, c2) => {
                const a = c1.contact;
                const b = c2.contact;
                const u1 = c1.sub ? 0 : this.props.getUnreadCount(a);
                const u2 = c2.sub ? 0 : this.props.getUnreadCount(b);
                const cmp = u2 - u1;
                if (cmp) return cmp;
                if (c1.start) {
                    if (!c2.start) {
                        return -1;
                    }
                    return c1.start - c2.start;
                } else if (c2.start) {
                    return 1;
                }
                return a.displayName.localeCompare(b.displayName)
            });
        }
        
        const calls = this.props.calls.filter((call, j) => {
            for (var i = 0; i < this.props.calls.length; i++) {
                const c = this.props.calls[i];
                if (i > j && this.props.isSameChannel(call.getRemoteContact(), c.getRemoteContact())) {
                    return false;
                }
            }
            return true;
        });
        const incomingCalls = this.props.incomingCalls.filter((incoming, j) => {
            for (var call of calls) {
                if (this.props.isSameChannel(call.getRemoteContact(), incoming.from)) {
                    return false;
                }
            }
            return true;
        });
        let className = 'uiSideListList uiContactsList';
        if (isTabletOrMobile()) {
            className += ' uiSideListTablet';
        }
        if (this.props.incomingCalls.length > 0) {
            className =+ ' uiSideListCallWaiting';
        }
        return <div className='uiSideList' >
            <div className={className}>
            <div className='uiSideListListContent' ref={this.scroller}>
            {calls.map(call => {
                return <SideListImpromptuCall scrollIntoView={this.scrollIntoView}
                selected={this.props.isSameChannel(this.props.selectedContact, call.getRemoteContact())}
                held={this.props.isHolding(call)}
                answer={this.props.answer}
                decline={this.props.decline}
                incoming={this.props.incomingCalls.filter(i => this.props.isSameChannel(call.getRemoteContact(), i.from))}
                hold={this.props.hold} call={call}/>;
            })}
        {incomingCalls.map((call, i) => {
            console.log("incoming call: ", call);
            const channel = call.from;
            const selected = this.props.isSameChannel(this.props.selectedContact, channel);
            if (i == 0 && !selected) {
                const otherSelected = this.props.incomingCalls.find(c => this.props.isSameChannel(this.props.selectedContact, channel));
                if (otherSelected) {
                    i = -1;
                }
            }
            return <SideListImpromptuCallWaiting index={i} scrollIntoView={this.scrollIntoView}
            selected={selected}
            action={()=>this.props.openChat(channel)}
            answer={()=>this.props.answer(call)} decline={()=>this.props.decline(call)} message={this.props.message} call={call}/>;
            })}
        {contacts.map(contactModel => {
            const contact = contactModel.contact;
            const selected = this.props.isSameChannel(this.props.selectedContact, contact)
            ////console.log("contact: ", contact);
            ////console.log("selected: ", this.props.selectedContact, " => ", selected);
            if (contact.product) {
                const uid = contact.from == this.props.me.self.uid ? contact.by : contact.from;
                const remoteContact = this.state.contacts.find(c => c.uid == uid);
                if (!remoteContact) return null;
                const isSeller = this.props.me.self.uid == contact.from;
                contact.remoteContact = remoteContact;
                const addToCalendar = isSeller ? this.addToCalendar : null;
                
                return <SideListProductChannel selected={selected}
                isSeller={isSeller}
                channel={contact}
                onAddToCalendar={addToCalendar}
                action={()=>this.props.openContact(contact)}/>;
            } else {
                const appt = contactModel.appt && contactModel.start == contactModel.appt.start ? contactModel.appt: null;
                return <SideListContact
                nextAppointment={appt}
                nextSub={appt ? null: contactModel.sub}
                me={this.props.me}
                onAddToCalendar={this.addToCalendar}
                unreadCount={this.props.getUnreadCount(contact)}
                call={this.props.call}
                message={this.props.message}
                selected={selected}
                busy={selected && !this.props.messagesShown[contact.uid]}
                action={()=>this.props.openContact(contact)}
                remove={this.props.removeContact} contact={contact}/>;
            }
        })}
        </div>
            </div>
            </div>;
        
    }
}


export class UISidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        let start = new Date(Date.now())
        this.tick = setInterval(() => {
            const now = new Date(Date.now());
            if (now.getMinutes() != start.getMinutes()) {
                start = now;
                this.forceUpdate();
            }
        }, 500);
    }

    checkSystemUnread() {
        if (false && this.props.nav == 'home') {
            if (this.props.systemUnreadCount > 0) {
                this.props.markSystemMessagesRead();
            }
        }
    }

    componentDidMount() {
        this.checkSystemUnread();
        this.props.me.getContactLink().then(link => {
            this.setState({
                contactLink: link,
            });
        });
        
    }
    
    componentDidUpdate(prevProps) {
        this.checkSystemUnread();
        if (this.props.profilePictureToUpload) {
            if (this.props.profilePictureToUpload != prevProps.profilePictureToUpload) {
                debugger;
                this.setState({
                    profilePictureToUpload: this.props.profilePictureToUploadURL
                });
            }
        } else {
            if (this.state.profilePictureToUpload) {
                this.setState({
                    profilePictureToUpload: null,
                });
            }
        }
    }
    

    searchContacts = searchTerm => {
        if (searchTerm.endsWith("!")) {
            const n = parseInt(searchTerm);
            if (!isNaN(n)) {
                this.props.me.generateContacts(n);
            }
        }
        if (this.state.searchTerm != searchTerm) {
            this.setState({
                searchTerm: searchTerm
            });
        }
    }

    togglePlusLink = () => {
        const value = this.state.plusLink;
        setTimeout(() => {
            this.setState({
                plusLink: !value
            });
        });
    }

    dismissPlusLink = () => {
        console.log("dismiss plus link: ", this.state.plusLink);
        if (this.state.plusLink) {
            this.setState({
                plusLink: false
            });
        }
    }

    render() {
        const me = this.props.me;
        if (!me.self) return null;
        let unreadCount = 0;
        if (this.props.nav != 'home') {
            unreadCount = this.props.systemUnreadCount;
        }
        console.log("sidebar unread: ", unreadCount);
        const now = moment(new Date(Date.now()));
        const weekday = now.format("dddd");
        const timestamp = now.format("h:mm A");
        const date = now.format("Do MMM YYYY");
        let contact = me.self;
        if (this.state.profilePictureToUpload) {
            contact = new Contact(me.self);
            contact.profileImage = this.state.profilePictureToUpload;
        }
        //console.log("props.nav="+this.props.nav);
        return <div className={'uiSidebar ' + (isMobile() ? "uiSidebarMobile" : "uiSideBarDestkop")} style={!this.props.visible ? {display: "none"} : null}>
            <div className='uiSidebarHeader'>
            <Tooltip title={/*"Go Home"*/ ""}><div className='uiChatHome' style={this.props.canGoHome ? null: {display: 'none'}} onClick={this.props.goHome}>
            <div className='uiChatHomeHome'><ReactSVG src={Home}/></div>
            <div className='uiChatHomeArrow'><ReactSVG src={Arrow}/></div>
            </div></Tooltip>
            <div className="uiSidebarHeaderProfile" onClick={isMobile() ? this.props.toggleMe : null}>
            <UIProfileIcon radius={65/2-2} contact={contact}/>
            <div className='uiSidebarHeaderProfileName'>{me.self.displayName}</div>
            <div className='uiSidebarHeaderProfileCreds'>{me.self.creds}</div>
            </div>
            <div className='uiSidebarHeaderClock'>
            <div className='uiSidebarProfileClockWeekday'>{weekday}</div>
            <div className='uiSidebarProfileClockTime'>{timestamp}</div>
            <div className='uiSidebarProfileClockDate'>{date}</div>
            </div>
            <div className='uiSidebarNav' style={this.props.canGoHome ? {display: 'none'} : null}>
            <SidebarNavButton unreadCount={unreadCount} selected={this.props.nav} name='home' icon={Home} onChange={this.props.navigate}/>
            <SidebarNavButton selected={this.props.nav} className='uiSidebarNavCal' name='cal'  icon={Cal} onChange={this.props.navigate}/>
            </div>
            </div>
            <div className='uiSidebarBody' id='idSidebarBody'>
            <SideListHeader me={this.props.me} togglePlusLink={this.togglePlusLink} searchTerm={this.state.searchTerm} onSearch={this.searchContacts}
        title={"CONTACTS" + (this.state.searchTerm ? " / "+this.state.searchTerm : "")}/>
            <div className='uiSidebarBodyScroller'>
            <ContactsList searchTerm={this.state.searchTerm}
        messagesShown={this.props.messagesShown}
        removeContact={this.props.removeContact}
        isSameChannel={this.props.isSameChannel} answer={this.props.answer} hangup={this.props.hangup} decline={this.props.decline} selectedContact={this.props.selectedContact} incomingCalls={this.props.incomingCalls} calls={this.props.calls} isHolding={this.props.isHolding} hold={this.props.hold}       
        getUnreadCount={this.props.getUnreadCount} isSameChannel={this.props.isSameChannel} call={this.props.call} message={this.props.message} selectedContact={this.props.selectedContact} me={this.props.me} contacts={this.props.contacts} addClientToCalendar={this.props.onAddClientToCalendar} openContact={this.props.openContact} openChat={this.props.openChat}/>
            </div>
            </div>
            <div className='uiSidebarFooter'>
            <div className='uiSidebarFooterRow'>
            <SidebarNavButton icon={Settings} label={"Settings"}
              name='settings' selected={this.props.nav} onChange={this.props.navigate}/>
            </div>
            <div className='uiSidebarFooterRow uiSidebarFooterRowLinks'>
            <div className='uiSidebarPrivacyPolicy' onClick={this.props.me.showPrivacyPolicy}>Privacy Policy</div>|
            <div className='uiSidebarPrivacyPolicy' onClick={this.props.me.showTOS}>Terms of Service</div>|
            <div className='uiSidebarPrivacyPolicy' onClick={this.props.me.showSupport}>Support</div>
            </div>
            </div>
            {this.props.nav == 'settings' &&
            <form key='profilePictureUpload'>
            <input className={'uiFileUpload'} id={'uiProfilePictureUpload'} name='uiProfilePictureUpload' type='file' accept={'image/png,image/jpeg'} onChange={this.props.onProfilePictureInput}/>
            <label htmlFor={'uiProfilePictureUpload'}>
            <div className='uiSidebarEditProfilePictureButton'>
            <div className='uiSidebarEditProfilePictureButtonIcon'><ReactSVG src={Edit}/></div>
             </div>
            </label>
             </form>}
            <div className='uiSidebarTeTe' onClick={this.props.openWebsite}><ReactSVG src={TeTeLogo}/></div>

            <ClickAwayListener onClickAway={this.dismissPlusLink}>

            <div style={this.state.plusLink ? null: {display: 'none'}} className='uiSidebarContactLink'>
            <UIMeCard title='Add Contact'>
            <UIMeContact me={this.props.me} link={this.state.contactLink}/>
            </UIMeCard>
            <div className='uiSidebarContactLinkArrow'><ReactSVG src={Arrow}/></div>
            </div>
            </ClickAwayListener>
            </div>
    }
}
