import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import {UIInputField} from "../Home";
import {UIOKCancel} from "../OKCancel";
import {UISelectionList} from "../DeviceSetup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Lock from "../../assets/icons/Padlock.svg";
import Email from "../../assets/icons/Email.svg";
import User from "../../assets/icons/ProfileSml.svg";
import Phone from "../../assets/icons/EndCall.svg";
import Cross from "../../assets/icons/Cross.svg";
import CheckBox from "../../assets/icons/Checkbox.svg";
import CheckBoxOn from "../../assets/icons/CheckboxOn.svg";
import Save from "../../assets/icons/Save.svg";
import SignOut from "../../assets/icons/SignOut.svg";
import Bell from "../../assets/icons/Bell.svg";
import validator from 'validator';
import './index.css'
import phone from 'phone';
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const owasp = require("owasp-password-strength-test");

const degrees = [
{value: "DDiv", name: "Doctor of Divinity"},
{value: "DMin", name: "Doctor of Ministry"},
{value: "DO", name: "Doctor of Osteopathy"},
{value: "DrPH", name: "Doctor of Public Health"},
{value: "DSW", name: "Doctor of Social Work"},
{value: "EdD", name: "Doctor of Education"},
{value: "EdM", name: "Master of Education"},
{value: "MA", name: "Master of Arts"},
{value: "MAHS", name: "Master of Arts in Human Services"},
{value: "MAOP", name: "Master of Arts in Organizational Psychology"},
{value: "MC", name: "Master of Counseling"},
{value: "MD", name: "Doctor of Medicine"},
{value: "MDiv", name: "Masters in Divinity"},
{value: "MEd", name: "Master of Education"},
{value: "MHDL", name: "Master of Human Development & Learning"},
{value: "MPH", name: "Master of Public Health"},
{value: "MPS", name: "Master of Professional Studies"},
{value: "MS", name: "Master of Science"},
{value: "MSc", name: "Master of Science"},
{value: "MSEd", name: "Master of Science in Education"},
{value: "MSN", name: "Master of Science in Nursing"},
{value: "MSS", name: "Master of Social Science"},
{value: "MSSW", name: "Master of Science in Social Work"},
{value: "MSW", name: "Master of Social Work"},
{value: "PhD", name: "Doctor of Philosophy"},
{value: "PsyaD", name: "Doctor of Psychoanalysis"},
{value: "PsyD", name: "Doctor of Psychology"},
{value: "ScD", name: "Doctor of Science"},
];

const licenses = [

{value: "ABECSW", name: "American Board of Examiners in Clinical Social Work"},
{value: "ABFamP", name: "American Board of Family Psychology"},
{value: "ABPN", name: "American Board of Professional Neuropsychology"},
{value: "ABPP", name: "American Board of Professional Psychology"},
{value: "ACSW", name: "Academy of Clinical Social Workers"},
{value: "ADTR", name: "Academy of Dance Therapists Registered"},
{value: "APRN", name: "Advanced Practice Registered Nurse"},
{value: "ATR", name: "Registered Art Therapist"},
{value: "ATR-BC", name: "Registered Art Therapist - Board Certified"},
{value: "BCBT", name: "Board Certification in Bereavement Trauma™"},
{value: "BCD", name: "Board Certified Diplomate in Clinical Social Work"},
{value: "BCDT", name: "Board Certification in Disability Trauma™"},
{value: "BCDV", name: "Board Certification in Domestic Violence™"},
{value: "BCETS", name: "Board Certified Expert in Traumatic Stress™"},
{value: "BCFT", name: "Board Certification in Forensic Traumatology™"},
{value: "BCIT", name: "Board Certification in Illness Trauma™"},
{value: "BCMVT", name: "Board Certification in Motor Vehicle Trauma™"},
{value: "BCPM", name: "Board Certification in Pain Management™"},
{value: "BCRT", name: "Board Certification in Rape Trauma™"},
{value: "BCSA", name: "Board Certification in Sexual Abuse™"},
{value: "BCSCR", name: "Board Certification in School Crisis Response™"},
{value: "BCSM", name: "Board Certification in Stress Management™"},
{value: "BCUCR", name: "Board Certification in University Crisis Response™"},
{value: "CAC", name: "Certified Addictions Counselor -or- Certified Alcoholism Counselor"},
{value: "CADAC", name: "Certified Alcohol and Drug Abuse Counselor"},
{value: "CADC", name: "Certified Alcohol and Drug Counselor"},
{value: "CAGS", name: "Certificate of Advanced Graduate Study"},
{value: "CAMF", name: "Certified Anger Management Facilitator"},
{value: "CAP", name: "Certified Addictions Professional"},
{value: "CART", name: "Certified Anger Resolution Therapist"},
{value: "CAS", name: "Certified Addiction Specialist"},
{value: "CASAC", name: "Certified Alcohol and Substance Abuse Counselor"},
{value: "CBT", name: "Certified Bioenergetic Therapist"},
{value: "CCADC", name: "Certified Clinical Alcohol and Drug Counselor"},
{value: "CCDP", name: "Certified Chemical Dependency Professional"},
{value: "CCH", name: "Certified Clinical Hypnotherapist"},
{value: "CCHT", name: "Certified Clinical Hypnotherapist"},
{value: "CCPT", name: "Certified Clinical Pastoral Therapist"},
{value: "CCMH", name: "Clinical Counselor in Mental Health"},
{value: "CCMHC", name: "Certified Clinical Mental Health Counselor"},
{value: "CCSW", name: "Certified Clinical Social Worker"},
{value: "CEAP", name: "Certified Employee Assistance Professional"},
{value: "CEDS", name: "Certified Eating Disorders Specialist"},
{value: "CFLE", name: "Certified Family Life Educator"},
{value: "CGP", name: "Certified Group Psychotherapist"},
{value: "CHT", name: "Certified Hypnotherapist"},
{value: "CICSW", name: "Certified Independent Clinical Social Worker"},
{value: "CISW", name: "Certified Independent Social Worker"},
{value: "CMFT", name: "Certified Marriage and Family Therapist"},
{value: "CP", name: "Certified Psychologist -or- Clinical Psychologist"},
{value: "CpastC", name: "Certified Pastoral Counselor"},
{value: "CPC", name: "Certified Professional Counselor"},
{value: "CPLC", name: "Certified Professional Life Coach"},
{value: "CRADC", name: "Certified Reciprocal Alcohol and Drug Counselor"},
{value: "CRC", name: "Certified Rehabilitation Counselor"},
{value: "CSAC", name: "Certified Substance Abuse Counselor"},
{value: "CSW", name: "Certified Social Worker -or- Clinical Social Worker"},
{value: "CSW-C", name: "Certified Social Worker - Clinical"},
{value: "DAPA", name: "Diplomate, American Psychotherapy Association"},
{value: "DCSW", name: "Diplomate in Clinical Social Work"},
{value: "DTR", name: "Dance Therapist Registered"},
{value: "LAC", name: "Licensed Associate Counselor"},
{value: "LADAC", name: "Licensed Alcohol and Drug Abuse Counselor"},
{value: "LADC", name: "Licensed Alcohol and Drug Counselor"},
{value: "LAMFT", name: "Licensed Associate Marriage and Family Therapist"},
{value: "LAPC", name: "Licensed Associate Professional Counselor"},
{value: "LASAC", name: "Licensed Associate Substance Abuse Counselor"},
{value: "LCADC", name: "Licensed Clinical Alcohol and Drug Counselor"},
{value: "LCAS", name: "Licensed Clinical Addictions Specialist"},
{value: "LCAT", name: "Licensed Creative Arts Therapist"},
{value: "LCDC", name: "Licensed Chemical Dependency Counselor"},
{value: "LCDP", name: "Licensed Chemical Dependency Professional"},
{value: "LCMFT", name: "Licensed Clinical Marriage and Family Therapist"},
{value: "LCMHC", name: "Licensed Clinical Mental Health Counselor"},
{value: "LCP", name: "Licensed Clinical Psychologist -or- Licensed Counseling Professional"},
{value: "LCPC", name: "Licensed Clinical Professional Counselor"},
{value: "LCSW", name: "Licensed Clinical Social Worker"},
{value: "LCSW-C", name: "Licensed Certified Social Worker-Clinical"},
{value: "LGSW", name: "Licensed Graduate Social Worker"},
{value: "LICSW", name: "Licensed Independent Clinical Social Worker"},
{value: "LIMFT", name: "Licensed Independent Marriage and Family Therapist"},
{value: "LISW", name: "Licensed Independent Social Worker"},
{value: "LISW-CP", name: "Licensed Independent Social Worker - Clinical Practice"},
{value: "LLP", name: "Limited Licensed Psychologist"},
{value: "LMFT", name: "Licensed Marriage and Family Therapist"},
{value: "LMHC", name: "Licensed Mental Health Counselor"},
{value: "LMHP", name: "Licensed Mental Health Practitioner"},
{value: "LMSW", name: "Licensed Master Social Worker"},
{value: "LMSW-ACP", name: "Licensed Master Social Worker - Advanced Clinical Practitioner"},
{value: "LP", name: "Licensed Psychoanalyst -or- Licensed Psychologist"},
{value: "LPA", name: "Licensed Psychological Associate"},
{value: "LpastC", name: "Licensed Pastoral Counselor"},
{value: "LPC", name: "Licensed Professional Counselor"},
{value: "LPCC", name: "Licensed Professional Clinical Counselor"},
{value: "LPCMH", name: "Licensed Professional Counselor of Mental Health"},
{value: "LPE", name: "Licensed Psychological Examiner"},
{value: "LPP", name: "Licensed Pastoral Psychotherapist"},
{value: "LSATP", name: "Licensed Substance Abuse Treatment Practitioner"},
{value: "LSCSW", name: "Licensed Specialist Clinical Social Worker"},
{value: "LSP", name: "Licensed School Psychologist"},
{value: "LSW", name: "Licensed Social Worker"},
{value: "MAC", name: "Master Addiction Counselor - also offered by NAADAC"},
{value: "MFCC", name: "Marriage, Family and Child Counselor"},
{value: "MFT", name: "Marriage and Family Therapist"},
{value: "MT-BC", name: "Music Therapist - Board Certified"},
{value: "NBCCH", name: "National Board Certified Clinical Hypnotherapist"},
{value: "NBCDCH", name: "National Board Certified Diplomate in Clinical Hypnotherapy"},
{value: "NCC", name: "National Certified Counselor"},
{value: "NCPsyA", name: "Nationally Certified Psychoanalyst"},
{value: "NCSC", name: "National Certified School Counselor"},
{value: "NCSP", name: "Nationally Certified School Psychologist"},
{value: "PA", name: "Psychological Associate"},
{value: "PLMHP", name: "Provisionally Licensed Mental Health Practitioner"},
{value: "PLPC", name: "Provisional Licensed Professional Counselor"},
{value: "PMHNP", name: "Psychiatric Mental Health Nurse Practitioner"},
{value: "RAS", name: "Registered Addiction Specialist"},
{value: "RDT", name: "Registered Drama Therapist"},
{value: "REAT", name: "Registered Expressive Arts Therapist"},
{value: "RN", name: "Registered Nurse"},
{value: "RPC", name: "Registered Professional Counselor"},
{value: "RPT", name: "Registered Play Therapist"},
{value: "RPT-S", name: "Registered Play Therapist-Supervisor"},
{value: "SAP", name: "Substance Abuse Professional"},
{value: "SW", name: "Social Worker"},
    {value: "TLLP", name: "Temporary Limited License Psychologist"}];

const licenses2 = licenses.map(x => {
    return {
        name: x.name + "  (" + x.value + ")",
        value: x.value,
    };
});

const degrees2 = degrees.map(x => {
    return {
        name: x.name + " ("+x.value+")",
        value: x.value,
    }
});


const parsePhoneNumber = number => {
    try {
        const parsed = phoneUtil.parseAndKeepRawInput(number, 'US')
        return {countryCode: parsed.getCountryCode(), phoneNumber: parsed.getNationalNumber()};
    } catch (ignored) {
        return {countryCode: 1, phoneNumber: number};
    }
}

class UISettingsTabview extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const selectedTab = this.props.tabs.find(tab => tab.value == this.props.selection);
        return <div className='uiSettingsTabview'>
            <div className='uiSettingsTabviewTabs'>
            {this.props.tabs.map((tab, i) => {
                return <UISettingsTab select={this.props.select} label={tab.name} value={tab.value} icon={tab.icon} selected={tab == selectedTab}/>;
            })}
        </div>
            <div className='uiSettingsTabviewBody'>
            {this.props.tabs.map((tab, i) => {
                    return <div className='uiSettingsTabviewBodyTab' style={tab == selectedTab ? null: {display: 'none'}}>
                    {tab.render()}
                </div>
            })}
            </div>
       </div>
    }
}

class UISettingsTab extends Component {
    constructor(props) {
        super(props);
    }

    select = () => {
        this.props.select(this.props.value);
    }
    
    render() {
        return <div className='uiSettingsTab'>
            <div onClick={this.select} className={'uiSettingsTabTab' + (this.props.selected ? " uiSettingsTabSelected" : "")}>
            <div className='uiSettingsTabIcon'><ReactSVG src={this.props.icon}/></div>
            <div className='uiSettingsTabLabel'>{this.props.label}</div>
            </div>
            </div>
    }
}

class NotificationSetting extends Component {
    constructor(props) {
        super(props);
    }
    toggle= () => {
        this.props.toggle(this.props.value);
    }
    render() {
        let className = 'uiNotificationSetting';
        if (this.props.checked) {
            className += ' uiNotificationSettingChecked';
        }
        return <div key={this.props.value} className={className} onClick={this.toggle}>
            <div key='label'className='uiNotificationSettingLabel'>{this.props.label}</div>
            <div key='icon' className='uiNotificationSettingIcon'><ReactSVG src={this.props.checked ? CheckBoxOn : CheckBox}/></div>
            </div>
    }
}

class UISettingsNotifications extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contacts: true,
            messages: true,
            appointments: true,
            payments: true,
            account: true,
            promotions: true
        }
        this.settings = [
            {
                value: 'contacts',
                name: "Contacts"
            },
            {
                value: 'messages',
                name: "Messages"
            },
            {
                value: 'appointments',
                name: "Appointments"
            },
            {
                value: 'payments',
                name: "Payments"
            },
            {
                value: 'account',
                name: "Account Changes"
            },
            {
                value: 'promotions',
                name: "TeTe Announcements and News"
            }]
            
    }

    toggle = value => {
        this.state[value] = !this.state[value];
        this.forceUpdate();
    }

    initState = () => {
        const me = this.props.me;
        const acct = me.accountData;
        const unsubscribe = acct.unsubscribe;
        this.settings.map(s => {
            const value = unsubscribe ? !unsubscribe[s.value] : true;
            this.state[s.value] = value;
        });
    }

    componentDidMount() {
        this.initState();
        this.sub = this.props.me.observeAccount().subscribe(acct => {
            this.initState();
            this.forceUpdate();
        })
    }

    componentWillUnmount() {
        if (this.sub) this.sub.unsubscribe();
    }

    save = () => {
        const unsubscribe = {
        }
        this.settings.map(x => {
            const value = x.value;
            unsubscribe[value] = !this.state[value];
        });
        return this.props.me.updateAccount({
            unsubscribe: unsubscribe
        });
    }

    cancel = () => {
        const me = this.props.me;
        const acct = me.accountData;
        const unsubscribe = acct.unsubscribe;
        this.settings.map(s => {
            const value = unsubscribe ? !unsubscribe[s.value] : true;
            this.settings[s.value] = value;
        });
        return Promise.resolve();
    }
    
    render() {
        const me = this.props.me;
        const acct = me.accountData;
        if (!acct) return null;
        const unsubscribe = acct.unsubscribe;
        let modified;
        this.settings.map(s => {
            const value = unsubscribe ? !unsubscribe[s.value] : true;
            if (value != this.state[s.value]) {
                modified = true;
            }
        });
        return <div key='notifications' className='uiSettingsNotifications'>
            <div className='uiSettingsNotificationsTitle'>Email me notifications about:</div>
            {this.settings.map(s => {
                return <NotificationSetting label={s.name} value={s.value} checked={this.state[s.value]} toggle={this.toggle}/>
            })}
            <div style={modified ? null: {display: 'none'}} className='uiSettingsSave'>
            <UIOKCancel ok={this.save} cancel={this.cancel} okIcon={Save} cancelIcon={Cross} label="Save"/>
            </div>
            </div>
    }
}


class UISettingsProfile extends Component {
    constructor(props) {
        super(props);
        const {countryCode, phoneNumber} = parsePhoneNumber(this.props.me.self.phoneNumber);
        this.state = {
            form: {
                email: this.props.me.self.email,
                displayName: this.props.me.self.displayName,
                phoneNumber: phoneNumber,
                countryCode: countryCode
            },
            degrees: [],
            licenses: [],
        }
        this.savedPassword = '';
    }

    componentDidMount() {
        this.sub = this.props.me.observeAccount().subscribe(account => {
            if (account) {
                console.log("account updated: ", account);
                this.account = account;
                const {countryCode, phoneNumber} = parsePhoneNumber(account.phoneNumber);
                const degrees = account.degrees ? account.degrees.split(", ") : [];
                const licenses = account.licenses ? account.licenses.split(", ") : [];
                this.state.degrees = degrees;
                this.state.licenses = licenses
                if (this.state.form.phoneNumber != phoneNumber || this.state.form.countryCode != countryCode) {
                    this.state.form.phoneNumber = phoneNumber;
                    this.state.form.countryCode = countryCode;
                }
                this.forceUpdate();
            }
        });
    }

    componentWillUnmount() {
        if (this.sub) this.sub.unsubscribe();
    }
    
    onChange = (field, value) => {
        this.state.form[field] = value;
        this.state.formError = {};
        this.forceUpdate();
    }

    formError = info => {
        this.state.formError = info;
        this.forceUpdate();
        return Promise.resolve();
    }

    save = () => {
        let p = Promise.resolve();
        if (this.props.profilePictureToUpload) {
            p = this.props.me.uploadProfileImage(this.props.profilePictureToUpload);
        }
        return p.then(profileImageDownloadURL => {
            debugger;
            const updates = {
            }
            let update = profileImageDownloadURL;
            if (profileImageDownloadURL) {
                updates.profileImage = profileImageDownloadURL
            }
            const me = this.account;
            const form = this.state.form;
            if (form.displayName != me.displayName) {
                if (!form.displayName.trim()) {
                    return this.formError({field: 'name', err: "Display name is required"});
                }
                updates.displayName = form.displayName;
                update = true;
            }
            if (form.email != me.email) {
                if (!form.email.trim()) {
                    return this.formError({field: 'email', err: "Email address is required"});
                }
                if (!validator.isEmail(form.email)) {
                    return this.formError({field: 'email', err: "Email address is invalid"});
                }
                updates.email = form.email;
                update = true;
            }
            const {countryCode, phoneNumber} = parsePhoneNumber(this.account.phoneNumber);
            if (form.phoneNumber != phoneNumber || form.countryCode != countryCode) {
                debugger;
                if (!form.phoneNumber.trim()) {
                    return this.formError({field: 'phoneNumber', err: "Phone number is required"});
                }
                const newPhoneNumber = "+"+form.countryCode + form.phoneNumber;
                if (!phone(newPhoneNumber).length) {
                    return this.formError({field: 'phoneNumber', err: "Phone number is invalid"});
                }
                updates.phoneNumber = newPhoneNumber;
                update = true;
            }
            if (form.password) {
                if (window.teteConfig != 'dev') {
                    const result = owasp.test(form.password);
                    if (result.errors.length) {
                        return this.formError({field: 'password', err: result.errors[0]});
                    }
                }
                updates.password = form.password;
                update = true;
            }
            const degrees = this.state.degrees.join(", ");
            const licenses = this.state.licenses.join(", ");
            if (degrees != this.account.degrees) {
                updates.degrees = degrees;
                update = true;
            }
            if (licenses != this.account.licenses) {
                updates.licenses = licenses;
                update = true;
            }
            this.setState({formError: {}});
            if (update) {
                return this.props.me.updateAccount(updates).then(result => {
                    console.log(result);
                    if (result && result.data.error) {
                        const err = result.data.error;
                        const formError = {};
                        if (err.code == "auth/email-already-exists") {
                            formError.field = "email";
                            formError.err = "Email address is in use by another account";
                        }
                        if (err.code == "auth/phone-number-already-exists") {
                            formError.field = "phoneNumber";
                            formError.err = "Phone number is in use by another account";
                        }
                        this.setState({formError: formError});
                        return;
                    }
                    if (updates.password) {
                        this.savedPassword = updates.password;
                        this.forceUpdate();
                    }
                    this.props.onSave();
                });
            }
        });

    }

    cancel = () => {
        this.props.onCancel();
        return new Promise((resolve, reject) => {
            const {countryCode, phoneNumber} = parsePhoneNumber(this.account.phoneNumber);
            const account = this.account;
            const degrees = account.degrees ? account.degrees.split(", ") : [];
            const licenses = account.licenses ? account.licenses.split(", ") : [];
            this.setState({
                formError: {},
                form: {
                    email: account.email,
                    displayName: account.displayName,
                    phoneNumber: phoneNumber,
                    countryCode: countryCode,
                    password: this.savedPassword,
                },
                degrees: degrees,
                licenses: licenses
            }, () => {
                console.log("form: ", this.state.form);
                resolve();
            });
        });
    }

    signOut = () => {
        return new Promise((resolve, reject) => {
            setTimeout(()=> this.props.me.signOut().then(resolve), 750);
        });
    }

    onChangeDegrees = value => {
        this.setState({
            degrees: value,
        });
    }

    onClickDegrees = () => {
        this.setState({
            selectingDegrees: !this.state.selectingDegrees
        });
    }

    dismissDegreesSelection = () => {
        this.setState({
            selectingDegrees: false
        });
    }

    onChangeLicenses = value => {
        this.setState({
            licenses: value,
        });
    }

    onClickLicenses = () => {
        this.setState({
            selectingLicenses: !this.state.selectingLicenses
        });
    }

    dismissLicensesSelection = () => {
        this.setState({
            selectingLicenses: false
        });
    }
    

    fields = ["displayName", "email", "phoneNumber", "password"];

    render() {
        let modified = !!this.props.profilePictureToUpload;
        if (modified) {
            console.log("changed: profilePictureToUpload");
        }
        const form = this.state.form;
        const acc = this.account;
        if (acc && !modified) {
            for (var i = 0; i < this.fields.length; i++) {
                const field = this.fields[i];
                const value = acc[field];
                if (field == 'phoneNumber') {
                    const {countryCode, phoneNumber} = parsePhoneNumber(this.account.phoneNumber);
                    if (form.countryCode != countryCode || form.phoneNumber != phoneNumber) {
                        modified = true;
                        console.log("changed: ", field, ": ", form[field], " <> ", acc[field]);
                    }
                } else if (field == 'password') {
                    if (form.password != this.savedPassword) {
                        modified = true;
                        console.log("changed: ", field, ": ", form[field], " <> ", this.savedPassword);
                    }
                } else {
                    if (form[field] != acc[field]) {
                        modified = true;
                        console.log("changed: ", field, ": ", form[field], " <> ", acc[field]);
                    }
                }                
            }
            const degrees = this.state.degrees.join(", ");
            if (degrees != (acc.degrees || "")) {
                console.log("changed:  degrees: ", degrees, "<>", acc.degrees);
                modified = true;
            }
            const licenses = this.state.licenses.join(", ");
            if (licenses != (acc.licenses || "")) {
                console.log("changed:  licenses: ", degrees, "<>", acc.licenses);
                modified = true;
            }
        }
        console.log("modified: ", modified);
        return <div key='profile' className='uiSettingsProfile'>
            <div className='uiSettingsProfileFields'>
            <form>
            <UIInputField error={this.state.formError} icon={User} label={'Display Name'} name='displayName' value={form.displayName} type='text' autoComplete='name' onChange={this.onChange}/>
            <div className='uiSettingsDegrees'>
            <div className='uiSettingsDegreesLabel'>Degrees</div>
            <ClickAwayListener onClickAway={this.dismissDegreesSelection}>
            <div className='uiSettingsDegreesBody'>
            <div className='uiSettingsDegreesButton' onClick={this.onClickDegrees}>{
                this.state.degrees.join(", ")
            }</div>
            <UISelectionList multiSelect={true} select={this.onChangeDegrees} options={degrees2} selected={this.state.degrees} visible={this.state.selectingDegrees} value={this.state.degrees}/>
            </div>
            </ClickAwayListener>
            </div>
            <div className='uiSettingsLicenses'>
            <div className='uiSettingsLicensesLabel'>Licenses/Certifications</div>
            <ClickAwayListener onClickAway={this.dismissLicensesSelection}>
            <div className='uiSettingsLicensesBody'>
            <div className='uiSettingsLicensesButton' onClick={this.onClickLicenses}>{
                this.state.licenses.join(", ")
            }</div>
            <UISelectionList multiSelect={true} select={this.onChangeLicenses} options={licenses2} selected={this.state.licenses} visible={this.state.selectingLicenses} value={this.state.licenses}/>
            </div>
            </ClickAwayListener>
            </div>
            <UIInputField readOnly={!this.props.me.user || this.props.me.user.providerData.find(x => x.providerId == 'google.com')} error={this.state.formError} icon={Email} label={'Email Address'} name='email' value={form.email} type='email' autoComplete='username' onChange={this.onChange}/>
            <UIInputField countryCodeName='countryCode' countryCode={form.countryCode} error={this.state.formError} icon={Phone} label={'Phone Number'} name='phoneNumber' value={form.phoneNumber} type='tel' autoComplete='tel' onChange={this.onChange}/>
            <UIInputField error={this.state.formError} icon={Lock} label={'Password'} name='password' value={form.password} type='password' autoComplete='new-password' onChange={this.onChange}/>
            <div className='uiSettingsFormError'>
            {this.state.formError ? this.state.formError.err : ''}
            </div>
            <div style={modified ? null: {display: 'none'}} className='uiSettingsSave'>
            <UIOKCancel ok={this.save} cancel={this.cancel} okIcon={Save} cancelIcon={Cross} label="Save"/>
            </div>
            <div className='uiSettingsSignout'>
            <UIOKCancel okIcon={SignOut} ok={this.signOut} label={"Sign Out"}/>
            </div>
            </form>
            </div>
            </div>
    }
}

export class UISettings extends Component {
    constructor(props) {
        super(props);

        this.tabs = [
            {
                icon: User,
                name: "Profile",
                value: 'profile',
                render: () => <UISettingsProfile onSave={this.props.onSave} onCancel={this.props.onCancel} me={this.props.me} profilePictureToUpload={this.props.profilePictureToUpload}/>
                
            },
            {
                icon: Bell,
                name: "Notifications",
                value: 'notification',
                render: () => <UISettingsNotifications me={this.props.me}/>
            }
            ]
    }

    select = value => {
        this.props.navigate(value);
    }
    
    render() {
        if (!this.props.visible) return null;
        return <div className='uiSettings' style={this.props.visible ? null: {display: "none"}}>
            <div className='uiSettingsTitle'>Settings</div>
            <div className='uiSettingsBody'>
            <UISettingsTabview tabs={this.tabs} selection={this.props.nav} select={this.select}/>
            </div>
            </div>
    }
}
