import React, {useState, findDOMNode, Component } from 'react';
import { ReactSVG } from 'react-svg'
import { useDrop } from 'react-dnd'
import {isMobile} from "../../Platform";
import {hasSoftKeyboard, isDesktop} from "../../Platform";
import {formatDate, formatStartEndTime, renderPaymentStatus, UIAppointment} from "../Appointment";
import {UIScheduleAppointment} from "../ScheduleAppointment";
import {UISubscription} from "../Subscription";
import Search from "../../assets/icons/Search.svg";
import Cross from "../../assets/icons/Cross.svg";
import moment from 'moment';
import mobiscroll from "@mobiscroll/react";
import TeTe from "../../assets/Assets/TeteLogo00.svg";
import Plus from "../../assets/icons/Plus.svg";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import "./index.css";

export const getTime = (date, time) => {
    const dateTime = new Date(date);
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
    return dateTime.getTime();
}

export const UICalendar = props => {
    const accept = "appointment";
    ////console.log("props: ", props);
    let mobi
    const setRef = x => {
        mobi = x;
    }
    const [dropped, setValue] = useState(""); // integer state
    const [collectedProps, drop] = useDrop({
        accept: accept,
        drop: (result, mon) => {
            setValue(result.appointment);
        },
        collect: mon => mon,

    })
    return <div ref={drop} className='uiCalendarDropTarget'>
        <UICalendarMobi openContact={props.openContact} contactFilter={props.contactFilter} onClick={props.onClick} onSet={props.onSet} me={props.me} dragSource={dropped} ref={setRef} visible={props.visible}/>
        </div>;
}


class UICalendarSelectionButton extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const selected = this.props.selection == this.props.value;
        return <div onClick={()=>this.props.select(this.props.value)} className={'uiCalendarSelectionButton' + (selected ? " uiCalendarSelectionButtonSelected" : "")}>
            <div className='uiCalendarSelectionButtonText'>{this.props.name}</div>
            </div>
    }
}

class UICalendarSelectionMenu extends Component {
    constructor(props) {
        super(props);
        const nav = "month";
        this.state = {
            selection: nav 
        }
    }

    componentDidMount() {
        this.props.onSelect(this.state.selection);
    }

    select = value => {
        this.setState({
            selection: value
        }, () => {
            //localStorage.setItem("cal-nav", value);
        });
        this.props.onSelect(value);
    }
    
    render() {
        return <div className='uiCalendarSelectionMenu'>
            <UICalendarSelectionButton value='day' name="Day" selection={this.state.selection} select={this.select}/>
            <UICalendarSelectionButton value='week' name="Week" selection={this.state.selection} select={this.select}/>
            <UICalendarSelectionButton value='month' name="Month" selection={this.state.selection} select={this.select}/>
            </div>
    }
}



class UICalendarMobi extends Component {
    constructor(props) {
        super(props);
        this.cal = React.createRef();
        this.events = {};
        this.appts = {};
        this.subs = {};
        this.state = {
            searchTerm: this.props.contactFilter ? this.props.contactFilter.displayName : "",
            view: 'month',
            myEvents: [] ,
            calView: {
                calendar: { type: 'month' },
                eventList: { type: 'month', scrollable: true }
            },
            currentDate: new Date(Date.now())
        }
        //console.log("contactFilter: ", this.props.contactFilter);
        this.createWaiters = [];
        this.waiting = {};
    }

    openSubscription = sub => {
        let contact = sub.contact;
        const me = this.props.me;
        if (!contact) {
            contact = sub.uid == me.self.uid ? me.self : me.getContact(sub.client);
        }
        return this.props.openContact(contact);
    }

    createEvent = appt => {
        const status = appt.status || "requested";
        const id = appt.id;
        const dur = appt.end - appt.start;
        const contact = appt.contact;
        const title = contact.displayName;
        const event = {
            id: id,
            start: new Date(appt.start),
            end: new Date(appt.end),
            text: title,
            desc: title,
            status: status,
            open: () => this.openEvent(event),
            ts: Date.now(),
        }
        this.events[id] = event;
        this.appts[id] = appt;
    }

    componentDidMount() {
        this.apptSub = this.props.me.observeAppointments().subscribe(change => {
            const appt = change.appointment;
            console.log("appt ", change.type, ": ", appt);
            const id = appt.id;
            if (!appt.organizer) {
                return;
            }
            if (change.type == "removed" || (appt.organizer.uid != this.props.me.self.uid && appt.status == 'declined')) {
                delete this.appts[id];
                delete this.events[id];
            } else {
                this.createEvent(appt);
                //console.log("event: ", this.events[id]);
            }
            const waiters = this.waiting[id];
            if (waiters) {
                delete this.waiting[id];
                waiters.map(resolve => resolve());
            } else if (this.createWaiters.length > 0) {
                for (var i = 0; i < this.createWaiters.length; i++) {
                    const w = this.createWaiters[i];
                    if (w.start == appt.start && w.end == appt.end &&
                        w.contact.uid == appt.contact.uid) {
                        this.createWaiters.splice(i, 1);
                        w.resolve();
                        break;
                    }
                }
            }
            this.updateEventsLater();
        });
        this.subSub = this.props.me.observeSubscriptions().subscribe(change => {
            const sub = change.subscription;
            console.log("sub: ", sub);
            const id = sub.uid + "-" + sub.client;
            if (change.type == 'removed' || sub.state == 'client-cancel' || sub.state == 'provider-cancel') {
                delete this.events[id];
                delete this.subs[id];
            } else {
                if (!sub.latestQuestion || sub.latestResponse >= sub.latestQuestion) {
                    delete this.events[id];
                    delete this.subs[id];
                } else {
                    const title = sub.contact.displayName + " is waiting for your response"
                    this.subs[id] = sub;
                    const event = {
                        id: id,
                        start: new Date(sub.latestQuestion),
                        end: new Date(this.props.me.getNextResponseTime(sub)),
                        text: title,
                        desc: title,
                        open: () => this.openEvent(event),
                        ts: sub.latestQuestion,
                    }
                    this.events[id] = event;
                    console.log("created event for sub: ", event);
                }
            }
            this.updateEventsLater();
        });
        this.props.onSet(this);
        if (this.props.visible && this.searchInput) {
            this.takeFocus();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.visible && this.searchInput) {
            if (!prevProps.visible) {
                this.takeFocus();
            } else if (!this.state.openEvent && prevState.openEvent) {
                this.takeFocus();
            }
        }
    }

    takeFocus = () => {
        if (hasSoftKeyboard()) return;
        if (this.searchInput && !this.props.contactFilter) this.searchInput.focus();
    }

    componentWillUnmount() {
        if (this.apptSub) this.apptSub.unsubscribe();
        if (this.subSub) this.subSub.unsubscribe();
    }

    updateEventsNow = () => {
        let events = Object.values(this.events);
        //console.log("searchTerm: ", this.state.searchTerm, " applied to: ", events);
        if (this.state.searchTerm) {
            const matches = {};
            const searchTerms = this.state.searchTerm.toLowerCase().split(/\s+/);
            events = events.filter(event => {
                const appt = this.appts[event.id];
                if (appt) {
                    let terms = appt.contact.displayName.toLowerCase().split(/\s+/);
                    let timeTerms = formatStartEndTime(new Date(appt.start), new Date(appt.end)).split("-").map(x => x.trim()).join("").split(/\s+/);
                    let dateTerms = formatDate(new Date(appt.start)).toLowerCase().split(/\s+/);
                    let paymentTerms = renderPaymentStatus(appt.paymentStatus).toLowerCase().split(/\s+/);
                    let titleTerms = appt.title ? appt.title.split(/\s+/) : [];
                    let amountTerms = appt.invoiceAmount ? ["$"+appt.invoiceAmount, ""+appt.invoiceAmount] : [];
                    const allTerms = [terms, timeTerms, dateTerms, paymentTerms, titleTerms, amountTerms];
                    let matched = 0;
                    allTerms.map(terms => {
                        terms.map(term => {
                            if (term) {
                                term = term.toLowerCase();
                                searchTerms.map(searchTerm => {
                                    if (searchTerm) {
                                        if (term.startsWith(searchTerm) || searchTerm.startsWith(term)) {
                                            matched++;
                                        }
                                    }
                                });
                            }
                        })
                    });
                    //console.log("matched: ", matched, " in ", appt);
                    if (matched == 0) return false;
                    matches[event.id] = matched;
                    return true;
                }
                return false;
            });
            events.sort((x, y) => {
                const w1 = matches[x.id];
                const w2 = matches[y.id];
                const cmp1 = w2-w1;
                if (cmp1 !== 0) {
                    return cmp1;
                }
                return x.start.getTime() - y.start.getTime();
            });
        }
        //console.log(events);
        this.state.myEvents = events;
        this.forceUpdate();
    }    
    
    updateEventsLater = () => {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            this.updateEventsNow();
        }, 200);
    }

    onEventSelect = event => {
    }

    setSearchInput = x => {
        if (x && this.searchInput != x) {
            this.searchInput = x;
        }
    }

    onSearchFocus = e => {
        if (!this.state.searchFocus) {
            this.setState({searchFocus: true})
        }
    }

    onSearchBlur = e => {
        if (this.state.searchFocus) {
            this.setState({searchFocus: false})
        }
    }

    onSearchTermChanged = e => {
        const term = e.target.value;
        this.setState({
            searchTerm: term,
        }, this.updateEventsLater);
    }

    changeView = (event) => {
        var view;
        switch (event.target.value) {
            case 'month':
                view = {
                    calendar: { type: 'month' },
                    eventList: { type: 'month', scrollable: true }
                };
                break;
            case 'week':
                view = {
                    calendar: { type: 'week' },
                    eventList: { type: 'week', scrollable: true }
                };
                break;
            case 'day':
                view = {
                    eventList: { type: 'day', scrollable: true }
                };
                break;
        }
    
        this.setState({
            view: event.target.value,
            calView: view
        });
    }

    onDragOver = e => {
        //console.log("onDragOver: ", e.target);
    }

    onDrop = e => {
        let targetDate;
        let targetNode = e.target;
        while (targetNode) {
            //console.log("onDrop: ", targetNode);
            if (targetNode.attributes['data-full']) {
                const date = targetNode.attributes['data-full'].value;
                targetDate = moment(date, "YYYY-MM-DD");
                break;
            }
            targetNode = targetNode.parentElement;
        }
        setTimeout(() => {
            //console.log("targetDate: ", targetDate);
            //console.log("dragSource: ", this.props.dragSource);
            const cal = this.cal.current;
            cal.instance.navigate(targetDate.toDate());
            this.setState({
                currentDate: targetDate.toDate()
            });
            if (this.props.dragSource.contact) {
                this.openEvent(this.events[this.props.dragSource.id], targetDate);
            } else {
                this.plusEvent(this.props.dragSource, targetDate.toDate());
            }
        }, 100);
    }

    onPageChange = (event, inst) => {
        let field;
        if (this.state.view == 'week') {
            field = 'weekStart';
        } else if (this.state.view == 'month') {
            field = 'month';
        } else {
            field = 'currentDate';
        }
        if (event.firstDay && (!this.state[field] || this.state[field].getTime() != event.firstDay.getTime())) {
            //console.log("onPageChange: ", this.state.view, ": ", field, ": ", this.state[field], " => ", event.firstDay);
            this.state[field] = event.firstDay;
            this.forceUpdate();
        }
    }

    onDayChange = (event, inst) => {
        debugger;
        if (this.state.currentDate && this.state.currentDate.getTime() == event.date.getTime()) {
            if (this.props.onClick) {
                this.props.onClick(event.date);
            } else {
                if (!this.state.openEvent) {
                    this.plusEvent();
                }
            }
            return true;
        }        
        if (this.state.currentDate.getTime() !== event.date.getTime()) {
            this.state.currentDate = event.date;
            if (this.state.openEvent) {
                ['date', 'start', 'end'].map(field => {
                    const date = new Date(this.state.openEvent[field]);
                    date.setMonth(event.date.getMonth());
                    date.setDate(event.date.getDate());
                    this.state.openEvent[field] = date;
                });
            }            
            this.forceUpdate();
        }
        if (this.props.onClick && event.target.className.indexOf("mbsc-cal-cell") >= 0) {
            this.props.onClick(event.date);
        }
        return true;
    }

    getCurrentDate = () => this.state.currentDate;

    onTap = (e, inst) => {
        debugger;
        if (this.props.onClick) {
            this.props.onClick(e.date);                    
        } else {
            if (this.state.currentDate == e.date) {
                this.plusEvent();
            } 
        }
    }

    onSearchIconClick = e => {
        this.setState({
            searchTerm: ""
        }, ()=> this.searchInput.focus());
    }

    render() {

        let filter;

        function startOfWeek(date)  {
            return date.getDate() - date.getDay();
        }
        const sameDate = (x, y) => x.getYear() == y.getYear() && x.getMonth() == y.getMonth() &&
                    x.getDate() == y.getDate();
        
        if (this.state.searchTerm || !this.state.currentDate) {
            let now;
            switch (this.state.view) {
            case 'month':
                now = this.state.monthStart || new Date(Date.now());
                break;
            case 'week':
                now = this.state.weekStart || new Date(Date.now());
                break;
            default:
                now = this.state.currentDate || new Date(Date.now());
            }
            
            let weekStart = startOfWeek(now);
            
            switch (this.state.view) {
            case 'month':
                filter = event => event.start.getYear() == now.getYear() && event.start.getMonth() == now.getMonth();
                break;
            case 'week':
                filter = event => event.start.getYear() == now.getYear() && event.start.getMonth() == now.getMonth() &&
                    startOfWeek(event.start) == weekStart;
                break;
            case 'day':
                filter = event => event.start.getYear() == now.getYear() && event.start.getMonth() == now.getMonth() &&
                    event.start.getDate() == now.getDate();
                break;
            }
        } else {
            let date = this.state.currentDate;
            const endOfDay = date => {
                const result = new Date(date.getTime());
                result.setHours(23);
                result.setMinutes(59);
                return result.getTime();
            }
            filter = event => event.start <= endOfDay(date) && endOfDay(event.end) >= date.getTime();
        }
        let events;
        if (this.state.searchTerm) {
            if (this.state.view != 'month') {            
                events = this.state.myEvents.filter(filter);
            } else {
                events = this.state.myEvents;
            }
            if (this.props.contactFilter && this.props.contactFilter.displayName == this.state.searchTerm) {
                events = events.filter(e => {
                    const appt = this.appts[e.id];
                    const result = appt.contact.uid == this.props.contactFilter.uid;
                    return result;
                });
            }
        } else {
            events = this.state.myEvents.filter(event => {
                if (event.client && event.status == "declined") {
                    return false;
                }
                const result = filter(event);
                return result;
            });
            events.sort((a, b) => a.start - b.start);
        }
        return <div className={'uiCalendar' + (isMobile() ? " uiCalendarMobile" : " uiCalenderDesktop")} onDragOver={this.onDragOver} onDrop={this.onDrop} style={this.props.visible ? {} : {display: "none"}}>
            <div style={this.props.onClick ? {display: 'none'} : null} className='uiCalendarTitle'>Appointments</div>
            <div className='uiCalendarSearch'>
            <input placeholder={'Search'} value={this.state.searchTerm}   onFocus={this.onSearchFocus} onBlur={this.onSearchBlur}
             onChange={this.onSearchTermChanged} ref={this.setSearchInput} className='uiCalendarSearchField'/>
            <div className='uiCalendarSearchIcon' onClick={this.onSearchIconClick}>
            <ReactSVG src={this.state.searchTerm ? Cross: Search}/>
            </div>
            </div>
            <div className='uiCalendarScroll'>
            <div className='uiCalendarSelectionContainer'><UICalendarSelectionMenu onSelect={value => this.changeView({target: {value: value}})}/></div>
            <div className='uiCalendarCalendar'>
            <div className="md-switching-view-cont">
            <div className="md-switching-view-cal-cont">
            <mobiscroll.Eventcalendar
                        ref={this.cal}
                        display="inline"
                        theme={"windows"}
                        themeVariant={"light"}
                        view={this.state.calView}
                        data={this.state.myEvents}
                        onDayChange={this.onDayChange}
        onPageChange={this.onPageChange}
        onTap={this.onTap}
                    />
                </div>
            </div>
            </div>
            <div className='uiCalendarEvents'>
            <div style={this.props.onClick ? {display: 'none'} : null} className='uiCalendarEventControls'>
            <div className='uiCalendarEventPlus' onClick={this.plusClick}>
            <div className='uiCalendarPlusIcon' ><ReactSVG src={Plus}/></div>
            <div className='uiCalendarPlusText'>Schedule an appointment</div>
            </div>
            <div key='eventsDate' className='uiCalendarEventsDate'>
            {this.state.searchTerm ? "Search Results: "+events.length : this.state.currentDate && moment(this.state.currentDate).format("MMM Do YYYY")}
            </div>
            </div>
            <div className='uiCalendarEventsScroller'>
            <div className='uiCalendarEventsList'>
            {events.map(event => {
                const appt = this.appts[event.id];
                if (!appt) {
                    const sub = this.subs[event.id];
                    if (sub && sub.state == 'active') {
                        return <div key={event.id} className='uiCalendarAppointmentEvent'>
                            <UISubscription
                        editable={false}
                        openChat={this.props.openContact}
                        invoiceAmount={sub.invoiceAmount}
                        isChat={false}
                        state={sub.state}
                        hideWith={false}
                        id={event.id}
                        start={event.start}
                        end={event.end}
                        organizer={this.props.me.self}
                        onClick={()=>this.openSubscription(sub)}
                        responseTime={sub.responseTime}
                        description={sub.description}
                        invoiceDescription={sub.invoiceDescription}
                        with={sub.contact}/>
                        </div>
                    }
                    return null;
                }
                return <div key={appt.id} className='uiCalendarAppointmentEvent'>
                    <UIAppointment openChat={this.props.openContact} organizer={appt.organizer} client={appt.client} status={appt.status} appointment={this.appts[event.id]} id={event.id} editable={appt.editable} start={new Date(appt.start)} title={appt.title || "Video Conference"} end={new Date(appt.end)} with={appt.contact} invoiceAmount={appt.invoiceAmount} paymentAmount={appt.paymentAmount} paymentStatus={appt.paymentStatus} onClick={event.open}/>
                    </div>
            })}
            </div>
            </div>
            </div>
            </div>
            {this.state.openEvent &&
             <div key='appointmentPopup' className='uiScheduleAppointmentPopup'>
             <UIScheduleAppointment
             appointmentId={this.state.openEvent.id}
             back={this.closeEvent}
             isNew={this.state.openEvent.isNew}
             date={this.state.openEvent.date}
             start={this.state.openEvent.start}
             end={this.state.openEvent.end}
             headerTitle={"Schedule Appointment"}
             title={this.state.openEvent.title}
             with={this.state.openEvent.with}
             on={this.state.openEvent.date}
             trash={this.trashEvent}
             schedule={this.okEvent}
             downloadInvoice={()=>this.downloadInvoice(this.state.openEvent.id)}
             accept={()=>this.acceptEvent(this.state.openEvent.id)}
             decline={()=>this.declineEvent(this.state.openEvent.id)}
             error={this.state.openEvent.error}
             onChange={this.onChangeEvent}
             editable={this.state.openEvent.editable}
             client={this.state.openEvent.client}
             invoiceAmount={this.state.openEvent.invoiceAmount || 0}
             invoiceDescription={this.state.openEvent.invoiceDescription}
             paymentIntentId={this.state.openEvent.paymentIntentId}
             status={this.state.openEvent.status}
             paymentStatus={this.state.openEvent.paymentStatus}
             paymentMethod={this.state.openEvent.paymentMethod}
             paymentIntentId={this.state.openEvent.paymentIntentId}
             event={this.state.openEvent}
             me={this.props.me}
             />
             </div>}
            </div>
    }

    onChangeEvent = (field, value) => {
        if (value == this.state.openEvent[field]) return;
        this.state.openEvent[field] = value;
    }


    trashEvent = () => {
        let p;
        //debugger;
        if (this.state.openEvent.client) {
            p = this.declineEvent(this.state.openEvent.id);
        } else {
            p = this.removeEvent(this.state.openEvent.id);
        }
        return p.then(result => {
            this.setState({
                openEvent: null
            });
        }).catch(err => {
            console.error(err);
            this.setState({
                openEvent: null
            });
            return Promise.reject(err);
        });
    }

    waitForUpdate = (id) => {
        let waiting = this.waiting[id];
        if (!waiting) {
            waiting = [];
            this.waiting[id] = waiting;
        }
        return new Promise((resolve, reject) => {
            waiting.push(resolve);
        });
    }

    waitForCreate = (contact, appt) => {
        return new Promise((resolve, reject) => {
            this.createWaiters.push({
                start: appt.start,
                end: appt.end,
                contact: contact,
                resolve: resolve,
            })
        });
    }

    okEvent = () => {
        let p;
        if (this.state.openEvent.isNew) {
            const data = this.state.openEvent;
            const contact = data.with;
            const start = data.start;
            const end = data.end;
            const date = data.date;
            const updates = {
                start: getTime(date, start),
                end: getTime(date, end),
                title: data.title,
                invoiceDescription: data.invoiceDescription,
                invoiceAmount: data.invoiceAmount,
                title: data.title,
            }
            const p1 = this.waitForCreate(contact, updates);
            p = this.props.me.createAppointment(contact, updates).then(p1);
        } else  if (!this.state.openEvent.editable) {
            p = this.acceptEvent(this.state.openEvent.id);
        } else {
            p = this.rescheduleEvent(this.state.openEvent.id, this.state.openEvent.start, this.state.openEvent.end);
        }
        window.showProgressIndicator("Scheduling");
        return p.then(result => {
            //console.log("okEvent: ", result);
            window.hideProgressIndicator();
            if (result && result.data && result.data.error) {
                this.state.openEvent.error = result.data.error;
                return this.forceUpdate();
            }
            this.setState({
                openEvent: null
            });
        }).catch (err => {
            console.log(err);
            return Promise.reject(err);
        });
    }

    closeEvent = event => {
        this.setState({
            openEvent: null
        });
    }

    plusClick = e => this.plusEvent();

    plusEvent = (with_, date) => {
       if (!date) date = new Date(this.state.currentDate);
       const now = new Date(Date.now());
       date.setHours(now.getHours());
       date.setMinutes(0);
       date.setSeconds(0);
       date.setMilliseconds(0);
       const start = date;
       const end = new Date(date);
       end.setHours(date.getHours() % 24 + 1);
       let p = Promise.resolve();
       const event = {
           id: null,
           date: start,
           start: start,
           end: end,
           with: with_,
           isNew: true,
           title: "Video Conference",
           editable: true,
           client: false,
           reschedule: false,
           invoiceDescription: "",
           invoiceAmount: 0,
           paymentIntentId: "",
           status: "",
           paymentStatus: "",
           finalPaymentMethod: null,
           paymentIntentId: ""
       }        
       //debugger;
       this.setState({
           openEvent: event,
       });
    }


    openAppointment = appt => {
        if (!this.appts[appt.id]) {
            this.createEvent(appt);
        }
        this.openEvent(this.events[appt.id]);
        const cal = this.cal.current;
        const when = new Date(appt.start)
        cal.instance.navigate(when);
        this.setState({
            currentDate: when
        }, this.updateEventsLater);
    }

    scheduleNewAppointment = contact => {
        this.plusEvent(contact);
    }

    openEvent = (event, newDate)  => {
        const appt = this.appts[event.id]
        let start = event.start;
        let end = event.end;
        //debugger;
        if (newDate) {
            start = new Date(newDate);
            start.setHours(event.start.getHours());
            start.setMinutes(event.start.getMinutes());
            end = new Date(newDate);
            end.setHours(event.end.getHours());
            end.setMinutes(event.end.getMinutes());
        }
        console.log(appt);
        this.setState({
            openEvent: {
                id: event.id,
                date: start,
                start: start,
                end: end,
                with: appt.contact,
                title: appt.title || "Video Conference",
                editable: appt.editable && appt.status != 'canceled',
                client: appt.organizer.uid != this.props.me.self.uid,
                reschedule: true,
                invoiceDescription: appt.invoiceDescription || "",
                invoiceAmount: appt.invoiceAmount || 0,
                paymentIntentId: appt.paymentIntentId,
                status: appt.status,
                paymentStatus: appt.paymentStatus,
                finalPaymentMethod: appt.finalPaymentMethod,
                paymentIntentId: appt.paymentIntentId,
            }
        });
    }

    downloadInvoice = id => {
        return this.props.me.showReceipt(id);
    }

    acceptEvent = id => {
        window.showProgressIndicator("Accepting Appointment");
        const p = this.waitForUpdate(id);
        return this.props.me.acceptAppointment(id).then(window.hideProgressIndicator).then(() => {
            return p.then(this.closeEvent);
        });
    }

    declineEvent = id => {
        window.showProgressIndicator("Declining Appointment");
        const p = this.waitForUpdate(id);
        return this.props.me.declineAppointment(id).then(window.hideProgressIndicator).then(() => {
            return p.then(this.closeEvent);
        });                                                                                            
    }

    rescheduleEvent = (id) => {
        const data = this.state.openEvent;
        const date = data.date;
        const start = getTime(date, data.start);
        const end = getTime(date, data.end);
        const appt = this.appts[id];
        const prevStart = appt.start;
        const prevEnd = appt.end;
        const prevInvoiceAmount = appt.invoiceAmount || 0;
        const prevInvoiceDescription = appt.invoiceDescription || "";
        const prevTitle = appt.title || "";
        const updates = {
            id: id,
            start: start,
            end: end,
            invoiceDescription: this.state.openEvent.invoiceDescription || "",
            invoiceAmount: this.state.openEvent.invoiceAmount || 0,
            title: this.state.openEvent.title || "",
        }
        if (updates.start == prevStart &&
            updates.end == prevEnd &&
            updates.invoiceDescription == prevInvoiceDescription &&
            updates.invoiceAmount == prevInvoiceAmount &&
            updates.title == prevTitle) {
            //console.log("no change");
            this.closeEvent();
            return Promise.resolve();
        }
        const event = this.events[id];
        const p = this.waitForUpdate(id);
        return this.props.me.updateAppointment(updates).then(response => {
            if (!response.data.error) {
                this.closeEvent();
            }
            if (response.data.error) {
                return response;
            }
            return p.then(() => response);
        });
    }

    removeEvent = id => {
        window.showProgressIndicator("Removing Appointment");
        const p = this.waitForUpdate(id);
        return this.props.me.deleteAppointment(id).then(() => p);
    }
}




