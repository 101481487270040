import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import {UIInputField} from "../Home";
import {UIProfileIcon} from "../ProfileIcon";
import {UIOKCancel} from "../OKCancel";
import Plus from "../../assets/icons/AddContact.svg";
import Cross from "../../assets/icons/Cross.svg";
import Trash from "../../assets/icons/Trash.svg";
import Edit from "../../assets/icons/ChatEdit.svg";
import ProfileSml from "../../assets/icons/ProfileSml.svg";
import Forward from "../../assets/icons/Forward.svg";
import './index.css'

export class UICreateGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formError: {},
            form: {
                toAdd: null
            },
            members:[]
        }
        const contact = this.props.contact;
        const g = contact.isGroup ? contact.group: null;
        if (g) {
            console.log("group=", g);
            let members = this.isOrganizer() ? g.members.filter(uid => uid != g.organizer) : g.members;
            this.state.members = members.map(uid => this.props.me.getContact(uid));
            this.state.form.groupName = g.displayName;
        } else {
            this.state.members.push(contact);
        }
    }

    onChange = (field, value) => {
        this.state.form[field] = value;
        if (field == this.state.formError.field) {
            this.state.formError.field = '';
            this.state.formError.err = '';
        }
        this.forceUpdate();
    }

    removeMember = c => {
        this.setState({
            members: this.state.members.filter(x => x.uid != c.uid)
        });
    }

    addMember = () => {
        const c = this.state.form.toAdd;
        if (c) {
            this.state.members.push(c);
            this.state.form.toAdd = null;
            this.forceUpdate()
            this.memberSelectionField.clearDisplayName();
        }
        return Promise.resolve();
    }

    createGroup = () => {
        if (!this.state.form.groupName) {
            this.setState({
                formError: {
                    field: 'groupName',
                    err: "Group name is required"
                }
            });
            return Promise.resolve();
        }
        if (this.state.members.length < 2) {
            this.setState({
                formError: {
                    field: 'toAdd',
                    err: "Please choose at least two members"
                }
            });
            return Promise.resolve();
        }
        if (this.props.id) {
            return this.props.me.updateGroup(this.props.id, this.state.form.groupName, this.state.members).then(this.props.hide);
        }
        return this.props.me.createGroup(this.state.form.groupName, this.state.members).then(this.clear).then(this.props.hide);
    }

    cancel = () => this.clear();

    clear = () => {
        this.state.form.groupName = '';
        this.state.form.toAdd = null;
        this.state.formError = {};
        this.state.members = [];
        this.forceUpdate();
        return Promise.resolve();
    }

    onCreateMemberSelection = field => {
        this.memberSelectionField = field;
    }

    searchContacts = searchValue => {
        const searchTerms = searchValue.toLowerCase().split(/\s+/);
        const matches = {};
        const filter = x => {
            if (this.state.members.find(y => y.uid == x.uid)) {
                return false;
            }
            if (x.uid == this.props.me.self.uid) return false;
            if (!searchValue) return true;
            let matched = 0;
            const name = x.displayName.toLowerCase();
            const terms = name.split(/\s+/);
            searchTerms.map(searchTerm => {
                terms.map(term => { if (term.startsWith(searchTerm)) { matched++ } });
            });
            matches[x.uid] = matched;
            return matched > 0;
        }
        const sort = (x, y) => {
            const w1 = matches[x.uid] || 0;
            const w2 = matches[y.uid] || 0;
            const cmp1 = w2-w1;
            if (cmp1 !== 0) {
                return cmp1;
            }
            return x.displayName ? y.displayName ? x.displayName.localeCompare(y.displayName) : 1 : -1;
        }
        let result = this.props.me.getCurrentContacts().filter(filter);
        result.sort(sort);
        return result;
    }

    isOrganizer = () => {
        const group = this.props.contact.group;
        return !group || group.organizer == this.props.me.self.uid;
    }

    render() {
        const group = this.props.contact.group;
        let cancel;
        let label = group ? "Update Group" : "Create Group";
        let className = 'uiCreateGroup';
        if (group && !this.isOrganizer()) {
            className += " uiCreateGroupClient";
        }
        return <div className={className}>
            <div className='uiCreateGroupBody'>
            <div className='uiCreateGroupBodyFields'>
            <div className='uiCreateGroupBodyField'>
            <UIInputField label={'Group Name'} search={this.searchContacts} value={this.state.form.groupName} error={this.state.formError} icon={Edit} name='groupName' onChange={this.onChange} type='text'/>
            </div>
            <div className='uiCreateGroupBodyField uiCreateGroupBodyFieldChooseMembers'>
            <UIInputField onCreate={this.onCreateMemberSelection} label={'Choose Members'} search={this.searchContacts} value={this.state.form.toAdd} error={this.state.formError} icon={ProfileSml} name='toAdd' onChange={this.onChange} type='contact'/>
            </div>
            <div className='uiCreateGroupBodyField'>
            <UIOKCancel label="Add Member" okIcon={ProfileSml} ok={this.addMember}/>
            </div>
            </div>
            <div className='uiCreateGroupMembersBody'>
            <div className='uiCreateGroupMembersLabel'>Members</div>
                <div className='uiCreateGroupMembers'>
            {this.state.members.map(c => {
                const canDelete = group || this.props.contact.uid != c.uid;
                    return <div className='uiCreateGroupMember'>
                       <div className='uiCreateGroupMemberIcon'><UIProfileIcon contact={c}/></div>
                       <div className='uiCreateGroupMemberName'>{c.displayName}</div>
                       {canDelete &&<div className='uiCreateGroupMemberDelete' onClick={()=>this.removeMember(c)}><ReactSVG src={Cross}/></div>}
                       </div>;
                    })}
             </div>
            </div>
            <div className='uiScheduleAppointmentBodyFieldsError'>
            {this.state.formError.err}
            </div>
           </div>
           <div className='uiCreateGroupFooter'>
            <UIOKCancel label={label} ok={this.createGroup} cancel={cancel} okIcon={Forward} cancelIcon={Trash}/>
           </div>
       </div>
    }
}
