export class Contact {
    constructor(userRecord) {
        this.uid = userRecord.uid;
        this.displayName = userRecord.displayName || userRecord.email;
        this.email = userRecord.email;
        this.phoneNumber = userRecord.phoneNumber;
        this.profileImage = userRecord.photoURL ? userRecord.photoURL : userRecord.profileImage;
        this.licenses = userRecord.licenses || "";
        this.degrees = userRecord.degrees || "";
        this.updateCreds();
        this.open = userRecord.open;
        if (userRecord.group) {
            this.group = userRecord.group;
        }
        if (userRecord.isGroup) {
            this.isGroup = true;
        }
    }

    updateCreds = () => {
        this.creds = this.degrees.split(", ").concat(this.licenses.split(", ")).filter(x => x).join(", ");
    }

    toJSON() {
        const result = {};
        ["uid", "email", "displayName", "phoneNumber", "profileImage", "degrees", "licenses", "open"].map(i => {
            result[i] = this[i] ? this[i] : "";
        });
        ["group", "isGroup"].map(i => {
            if (this[i]) result[i] = i;
        });
        return result;
    }
}
