import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import TitleBackground from '../../assets/WebAssets/TitleBackground.png';
import TitleBackgroundVideo from '../../assets/WebAssets/TeteBanner.mp4';
import TeTeChat from '../../assets/WebAssets/TeTeChat.svg';
//import Devices from '../../assets/WebAssets/mScreensStuart2.png';
import Devices from '../../assets/WebAssets/devices.png';
import Lock from "../../assets/WebAssets/wLock.svg";
import Calendar from "../../assets/WebAssets/wCal.svg";
import CreditCard from "../../assets/WebAssets/wCard.svg";
import Video from "../../assets/WebAssets/wCam.svg";
import Chat from "../../assets/WebAssets/wSmile.svg";
import User from "../../assets/WebAssets/wUser.svg";
import Onboarding from "../../assets/WebAssets/Onboard.png";
import StartSession from "../../assets/WebAssets/StartSession.png";
import Payment from "../../assets/WebAssets/Payment.png";
import Appointment from "../../assets/WebAssets/Appointment.png";
import {isMobile} from "../../Platform";
import DownArrow from "../../assets/icons/Down.svg";
import LeftArrow from "../../assets/icons/Back.svg";
import './index.css';

const TETE_URL = "tete.video";

class UIWebsiteGetStarted extends Component {
    render() {
        const SignUpText = this.props.signUpText;
            return <div className='uiWebsiteGetStarted'>
            <div className='uiWebsitePage2MainBlurb'>
            <div className='uiWebsitePage2MainBlurbColumn'>
            <div className='uiWebsitePage2MainBlurbTitle'>Therapist, Counselor, Tutor, Trainer, Coach, Teacher</div>
            <div className='uiWebsitePage2MainBlurbSubtitle'>{this.props.blurb || "Connect your clients today"}</div>
            <div className='uiWebsiteTopBarSignUpButton' onClick={this.props.signUp}>{SignUpText}</div>
            </div>
            </div>
            </div>
    }
}

class UIWebsiteFAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    toggleOpen = () => {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        let answerStyle;
        if (this.state.open) {
            answerStyle = null;
        } else {
            answerStyle = {
                display: 'none'
            }
        }
        let className= 'uiWebsiteFAQQuestion';
        if (this.state.open) className += ' uiWebsiteFAQQuestionOpen';
        return <div className='uiWebsiteFAQ'>
            <div className={className}  onClick={this.toggleOpen}>
            <div className='uiWebsiteFAQQuestionText'>{this.props.question}</div>
            <div className='uiWebsiteFAQQuestionButton'><ReactSVG src={this.state.open ? DownArrow : LeftArrow}/></div>
            </div>
            <div style={answerStyle} className='uiWebsiteFAQAnswer'>
            {this.props.answer}
            </div>
            </div>;
    }
}


class UIWebsiteBullet extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const style = {
            marginLeft: this.props.margin,
            marginRight: this.props.margin,
        }
        let iconClass = 'uiWebsiteBulletIcon';
        if (this.props.iconClass) {
            iconClass += " " + this.props.iconClass;
        }
        let sep = "";
        let text = [];
        this.props.text.map(t => {
            if (sep) text.push(sep)
            text.push(t);
            sep = <p/>;
        });
        if (false && this.props.isMobile) {
            return <div className='uiWebsiteBullet' style={style}>
                <div className='uiWebsiteBulletHeader'>
                <div className='uiWebsiteBulletIconContainer'>
                <div className={iconClass}><ReactSVG src={this.props.icon}/></div>
                </div>
                <div className='uiWebsiteBulletTitle'>{this.props.title}<p/></div>
                </div>
                <div className='uiWebsiteBulletText'>{text}</div>
                </div>
        }
        return <div className='uiWebsiteBullet' style={style}>
            <div className='uiWebsiteBulletIconContainer'>
            <div className={iconClass}><ReactSVG src={this.props.icon}/></div>
            </div>
            <div className='uiWebsiteBulletTitle'>{this.props.title}<p/></div>
            <div className='uiWebsiteBulletText'>{text}</div>
            </div>
    }
}

class HowItWorksItem extends Component {
    constructor(props) {
        super(props);
    }

    getOrientation = () => {
        return this.props.item.leftToRight ? "uiWebsiteHowItWorksItemLeftToRight" : "uiWebsiteHowItWorksItemRightToLeft";
    }

    renderImage() {
            return <div className='uiWebsiteHowItWorksItemImage'><img src={this.props.item.image}/></div>
    }

    renderBlurb() {
        let style;
        let titleStyle;
        let contentStyle;
        if (this.props.height) {
            const s = this.props.height / 512;;
            const x = s * 25 * (this.props.item.leftToRight ? -1 : 1);
            style = {
                height: s * 200,
            }
            titleStyle = {
                fontSize: Math.max(s, 0.6) * 24,
                transform: 'translate('+x+'px, 0)'
            }
            contentStyle = {
                fontSize: Math.max(s, 0.6) * 20,
                transform: 'translate('+x+'px, 0)'
            }
            console.log("contentStyle: ", contentStyle);
        }
        let blurbTitle = (this.props.isMobile && this.props.item.blurbTitleMobile) ?
            this.props.item.blurbTitleMobile : this.props.item.blurbTitle;

        return <div style={style} className={'uiWebsiteHowItWorksItemBlurb'}>
         <div  style={titleStyle} className='uiWebsiteHowItWorksItemBlurbTitle'>
            {blurbTitle}
        </div>
        <div style={contentStyle} className='uiWebsiteHowItWorksItemBlurbContent'>
            {this.props.isMobile && this.props.item.blurbContentMobile ? this.props.item.blurbContentMobile : this.props.item.blurbContent}
        </div>
       </div>
    }

    
    render() {
        let style;
        if (this.props.height) {
            style = {
                height: this.props.height
            }
        }
        return <div style={style} className={'uiWebsiteHowItWorksItem '+ this.getOrientation()}>
            {!this.props.item.leftToRight ? [this.renderImage(), this.renderBlurb()] : [this.renderBlurb(), this.renderImage()]}
            </div>
    }
}
   

export class UIWebsite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bullets: [
                {
                    title: "STRONGEST PRIVACY",
                    text: ["GDPR/HIPAA compliant privacy with the highest level of data security: end-to-end encryption"],
                    icon: Lock,
                },
                {
                    title: "HIGHEST-DEFINITION VIDEO",
                    text: ["Delivers the highest video quality available including 1080p and UHD at no extra cost"],
                    icon: Video,
                },
                {
                    title: "APPOINTMENTS",
                    text: ["Integrated appointment scheduling that automatically syncs with your personal or work calendar"],
                    icon: Calendar,
                },
                {
                    title: "PAYMENTS",
                    text: ["Use TeTe to collect payments on a per-client or per-session basis. Individualize services and prices per client and session"],
                    icon: CreditCard,
                },
                {
                    title: "PROFESSIONAL CHAT",
                    text: ["Advanced GDPR/HIPAA compliant chat and collaborative whiteboard with optional support for paid subscriptions"],
                    icon: Chat,
                },
                {
                    title: "EASY SECURE CLIENT SETUP",
                    text: ["Onboard your clients by simply sharing a secure private link. TeTe provides secure client authentication unlike some other solutions"],
                    icon: User,
                },
            ],
            faqs: [
                {
                    question: "How does TeTe's privacy differ from other apps?",
                    answer: <div>TeTe provides <em>true end-to-end encryption</em> unlike other apps which only encrypt data during network communication. As a result, such apps cannot safely protect your data between sessions and will have GDPR/HIPAA compliance disclaimers such as "No private data stored." TeTe's true end-to-end encryption guarantees you and your client can always read the messages and files you've shared and nobody else can - not even TeTe. As a result, TeTe enables you to keep an ongoing personal conversation with each of your clients, unlike with other apps.</div>
                },
                {
                    question: "How do I keep all my clients' appointments up-to-date in TeTe?",
                    answer: <div>You can easily schedule appointments with clients in just a couple of clicks. TeTe's scheduling system allows you to automatically sync with your personal calendar. You can reschedule, update payment information, or refund charges just as easily. Filling up your calendar is a good thing, but finding the information you need can become challenging. TeTe lets you search your calendar by any combination of keywords to quickly locate appointments. In addition, TeTe's to-do list provides step-by-step notifications of your next task for each individual client throughout the whole process.</div>
                },
                {
                    question: "How do I collect payments from my clients with TeTe?",
                    answer: "TeTe uses Stripe for payments with easy setup in just a few clicks. When you schedule an appointment with a client, you specify the invoice amount for that session. The client will be notified by TeTe and can complete payment at any time before or after your session. TeTe will keep you apprised of the payment status for each appointment. You can adjust the invoice amount or refund payments as necessary. And through your Stripe dashboard on TeTe, you will have a complete accounting of the payment history for all your clients."
                },
                {
                    question: "What is TeTe's pricing?",
                    answer: "For payments, TeTe charges 2.9% + $4.30 per credit card payment made to you by a client."
                },
                {
                    question: "What kind of video quality should I expect?",
                    answer: <div>Your video quality will depend on several factors<ul><li>The resolution of your webcam (or screen-share)</li><li>The speed of your network</li><li>Your computer's processing power</li></ul>TeTe will enable you to send and receive video at the highest available resolution given these factors including 1080p and UHD, unlike with other apps which either charge extra for such high definition video or simply do not offer it at all.</div>
                },
                {
                    question: "What's different about TeTe's chat system?",
                    answer: "Thanks to end-to-end encryption, TeTe is able to provide an advanced GDPR/HIPAA compliant messaging platform, with permanent editable conversations, file sharing, reactions, and recorded messages, which is fully integrated with TeTe's scheduling and payment systems. This provides a direct link to each of your clients for exchanging information as necessary both during and between sessions, and will also contain the complete history of appointments and payments for each client. TeTe also supports online teaching/counseling via professional chat (see below)."
                },
                {
                    question: "I'm an independent contractor. How do I bring my business online with TeTe?",
                    answer: <div>There are a few simple steps once you've signed up to TeTe. <ol><li>Optionally set up payments with Stripe. This involves a single click in TeTe and filling out some basic information about your business in Stripe.</li><li>Copy a secure private link from your TeTe dashboard and share it with your clients by emailing or texting it to them. When a client clicks the link they will be taken to TeTe's sign-up page. After signing up to TeTe the client will automatically be added to your contact list (and you to theirs) and an item will be added to your TeTe to-do list to set up their first appointment.</li></ol>Note: you can directly text the client to discuss arranging their sessions, or even have an impromptu video call. TeTe doesn't require any additional installation or setup for your client.</div> 
                },
                {
                    question: "How does professional chat work in TeTe?",
                    answer: "TeTe supports online teaching, mentoring, tutoring, and counseling via texting where the provider responds according to an agreed upon schedule. You can offer professional chat to a client with a single click. You specify the time interval within which you'll respond and price to be billed monthly. Once the client accepts and provides their payment method, whenever the client texts, shares a file, or leaves a recorded messsage, TeTe will place an event on your calendar and an item on your to-do list with the date and time by which your reply is expected. As soon as you respond to your client your calendar and to-do list will be automatically updated. Requires TeTe for payments."
                },
                {
                    question: "I'm using Microsoft Windows. Can I use Internet Explorer with TeTe?",
                    answer: <div>Oof, no, please use <a href='https://www.microsoft.com/en-us/edge'>Microsoft Edge</a>, <a href='https://www.google.com/chrome/'>Chrome</a>, or <a href='https://www.mozilla.org/en-US/exp/firefox/new/'>Firefox</a>.</div>
                },
                {
                    question: "I'm using Microsoft Windows. TeTe said \"Oof, can't access your camera\". What can I do?",
                    answer: "On Microsoft Windows only one app can access the camera at a time. Close any other apps that are using your webcam"
                },
                {
                    question: "I'm using Safari. My webcam video is black. What can I do?",
                    answer: "Safari will mute your camera if you have it open in multiple tabs even if they are both tete.video. Make sure you have only one tab open that's using your webcam (there will be a red camera icon in the address bar of the tab)."
                },
                {
                    question: "How is TeTe pronounced?",
                    answer: "Tee Tee"
                },
                {
                    question: "What does TeTe mean?",
                    answer: <div>TeTe is short for the French phrase <em>tête-à-tête</em>, meaning a private conversation between two individuals.</div>
                }],

            howItWorks: [
                {
                    leftToRight: false,
                    image: Onboarding,
                    background: "rgb(19, 128, 177)",
                    color: 'white',
                    blurbTitle: "Onboard Clients",
                    blurbContent: "Share a private link with each of your clients and they will be securely added to your TeTe contacts list. No additional download or client setup is required.",
                    blurbContentMobile: "Share a private link with each of your clients and they will be securely added to your TeTe contacts list."
                },
                {
                    leftToRight: true,
                    image: Appointment,
                    background: "rgb(249, 249, 249)",
                    color: "rgb(51, 51, 51)",
                    blurbTitle: "Use TeTe for Appointments (Optional)",
                    blurbTitleMobile: "Schedule Appointments",
                    blurbContent: "Cut down on the time you spend (re-)scheduling appointments. TeTe makes it quick, easy, and foolproof. Individualize services and and optionally require payment. Each appointment will be synced with your personal or work calendar. Easily reschedule, adjust payment amounts, or issue refunds as necessary." ,
                    blurbContentMobile: "Individualize services and payment amounts. Each appointment will be synced with your personal calendar."
                },
                {
                    leftToRight: false,
                    image: Payment,
                    background: "rgb(19, 128, 177)",
                    color: 'white',
                    blurbTitleMobile: "Collect Payments",
                    blurbTitle: "Use TeTe for Payments (Optional)",
                    blurbContent: "Don't spend your time chasing down payments. As required, TeTe will keep you informed of the payment status of each client, collect payment for each appointment, and notify you of changes as they occur. Requires the use of TeTe for appointments.",
                    blurbContentMobile: "TeTe will keep you informed of the payment status of each client and collect payment for each appointment." 
                },
                {
                    leftToRight: true,
                    image: StartSession,
                    background: "rgb(249, 249, 249)",
                    color: "rgb(51, 51, 51)",
                    blurbTitle: "Conduct Sessions",
                    blurbContentMobile: "Conduct your sessions with TeTe's secure, private, best-in-class video conferencing technology.",
                    blurbContent: "Conduct your sessions with TeTe's secure, private, best-in-class video conferencing technology. Note: you can use TeTe's GDPR/HIPAA compliant messaging, file-sharing, and video conferencing with your clients even if you choose not use TeTe for appointments or for payments."
                },
               ]
        }
    }

    signUp = () => {
        window.analytics.logEvent("websiteSignUp");
        this.props.signUp();
    }

    signIn = () => {
        window.analytics.logEvent("websiteSignUp");
        this.props.signIn();
    }

    onResized = () => {
        this.forceUpdate();
    }

    componentDidMount() {
        this.windowListener = window.addEventListener("resize", this.onResized);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResized);
    }
    
    render() {
        let className = 'uiWebsite';
        const vw = document.documentElement.clientWidth;
        const vh = document.documentElement.clientHeight;
        let page3Style;
        let page3BackgroundStyle;
        let page4Style;
        let howItWorksStyle;
        let howItWorksItemHeight;
        let howItWorksMainBlurbStyle;
        let bulletStyle;
        let mainImageStyle;
        let bulletMargin = 45;
        let isMobilePortrait = isMobile(true) && vw < vh;
        let mobileScaleStyle;
        console.log("vw: ", vw, ", vh: ", vh);
        if (isMobilePortrait) {
            className += " uiWebsiteMobile";
        }
        if (vw < 1128) {
            const h1 = 730 - 580;
            const factor = (vw/1128);
            const h = 580  * factor + h1;
            const diff = 730 - h;
            console.log("vw="+vw, " h="+h);
            page3BackgroundStyle = {
                height: h
            };
            className += " uiWebsiteNarrow";
            page3Style = {
                //height: 1020 * factor
            }
            mainImageStyle = {
                opacity: 0.5
            }
            const top = 486 + (isMobilePortrait ? 1089 : 630) + 1020;
            howItWorksStyle = {
                //top: top - diff,
            }
            howItWorksItemHeight = factor * 512;
            howItWorksMainBlurbStyle = {
                //top: 10,
                //transform: "scale("+factor+", "+factor+")"
            }

            page4Style= {
                //top: top - diff + factor*(4 * 512)
            }
            bulletMargin *= factor;
        }

        let SignUpText = "Get Started";
        if (isMobile(true)) {
            SignUpText = "To get started, please visit tete.video on your laptop, tablet, or desktop";
            className += " uiWebsiteMobileDevice";
        }
        console.log("className: ", className);
        
        return <div className={className}>
            <div className='uiWebsiteScroll' style={mobileScaleStyle}>
            <div className='uiWebsiteContent'>
            <div className='uiWebsitePage1'>
            <div className='uiWebsiteBackgroundImage'>
            {false && <img style={mainImageStyle} src={TitleBackground}/>}
            <video muted autoPlay loop playsInline src={TitleBackgroundVideo}/>
            <div className='uiTeTeDotChatLogo'>
            <ReactSVG src={TeTeChat}/>
            </div>
            <div className='uiWebsiteTopBar'>
            <div className='uiWebsiteTopBarSignInButton' onClick={this.signIn}>Sign in</div>
            <div className='uiWebsiteTopBarSignUpButton' onClick={this.signUp}>{SignUpText}</div>
            </div>
            <div className='uiWebsiteMainBlurb'>
            <div className='uiWebsiteMainBlurbTitle'>Video conferencing designed for your business.{!isMobile(true) && <div>High-definition. End-to-end encrypted.</div>}</div>
            <div className='uiWebsiteMainBlurbSubtitle'>Conduct your sessions in a secure, GDPR/HIPAA compliant environment.</div>
            {isMobile(true) && <div className='uiWebsiteMainBlurbSubtitle2'>High-Definition. End-to-End Encrypted</div>}
            <div className='uiWebsiteMainBlurbSignup'>
        <div className='uiWebsiteMainBlurbFree'>
            <div>No cost to sign up</div>
            <div>No credit card required</div>
            <div>All features enabled - nothing to upgrade</div>
            </div>
            <div className='uiWebsiteTopBarSignUpButton' onClick={this.signUp}>{SignUpText}</div>
            </div>
            </div>
            </div>
            </div>
            <div className='uiWebsitePage2'>
            <div className='uiWebsitePage2MainBlurb'>
            <div className='uiWebsitePage2MainBlurbColumn'>
            <div className='uiWebsitePage2MainBlurbTitle'>Therapist, Counselor, Tutor, Trainer, Coach, Teacher</div>
            <div className='uiWebsitePage2MainBlurbSubtitle'>{"Everything you need to stay connected with your clients"}</div>

            {!isMobilePortrait ?
             
            <div className='uiWebsitePage2BulletsVert'>
            <div className='uiWebsitePage2BulletsRow1'>
             {this.state.bullets.slice(0, 3).map(bullet => {
                 return <UIWebsiteBullet margin={bulletMargin} title={bullet.title} text={bullet.text} icon={bullet.icon} iconClass={bullet.iconClass}/>
            })}
             </div>
            <div className='uiWebsitePage2BulletsRow2'>
             {this.state.bullets.slice(3, 6).map(bullet => {
                 return <UIWebsiteBullet margin={bulletMargin} title={bullet.title} text={bullet.text} icon={bullet.icon} iconClass={bullet.iconClass}/>
            })}
             </div>
             </div>

             :
             
            <div className='uiWebsitePage2BulletsMobile'>
            {this.state.bullets.map(bullet => {
                return <UIWebsiteBullet title={bullet.title} text={bullet.text} icon={bullet.icon}
                iconClass={bullet.iconClass} isMobile={isMobilePortrait}/>
            })}
             </div>}
            </div>
            </div>
            </div>
            <div className='uiWebsitePage3' style={page3Style}>
            <div className='uiWebsitePage3MainBlurb'>
            <div className='uiWebsitePage3MainBlurbColumn'>
            <div className='uiWebsitePage3MainBlurbTitle'>Stress-free setup for everyone</div>
            <div className='uiWebsitePage3MainBlurbSubtitle'>Just go to {TETE_URL}. No downloads or installation</div>
            </div>
            </div>
            <div className='uiWebsitePage3Background' style={page3BackgroundStyle}>
             <div className='uiWebsiteDevices'>
             <img src={Devices}/>
             </div>
            </div>
            </div>
            <UIWebsiteGetStarted blurb={"Designed for You"} signUpText={SignUpText} signUp={this.signUp}/>
            <div className='uiWebsiteHowItWorks' style={howItWorksStyle}>
            <div className='uiWebsiteHowItWorksMainBlurb' style={howItWorksMainBlurbStyle}>
            <div className='uiWebsiteHowItWorksMainBlurbColumn'>
            <div className='uiWebsiteHowItWorksMainBlurbTitle'>
            Getting Started with TeTe
            </div>
            <div className='uiWebsiteHowItWorksMainBlurbSubtitle'>
            {isMobilePortrait ? "":  "How It Works"}
            </div>
            </div>
            </div>
            {this.state.howItWorks.map(item => {
                return <HowItWorksItem height={howItWorksItemHeight} item={item} isMobile={isMobilePortrait}/>
            })}
            </div>
            
            <div className='uiWebsitePage4' style={page4Style}>

            <UIWebsiteGetStarted signUpText={SignUpText} signUp={this.signUp}/>
            
            <div className='uiWebsitePage4Blurb'>
            <div className='uiWebsitePage4BlurbTitle'>FAQs</div>
            <div className='uiWebsitePage4BlurbSubtitle'>What you should know about TeTe</div>
            </div>
            <div className='uiWebsitePage4FAQs'>
            {this.state.faqs.map(faq => {
                    return <UIWebsiteFAQ question={faq.question} answer={faq.answer}/>
            })}
            </div>
            </div>
            <div className='uiWebsiteFooter'>
            <div className='uiWebsiteFooterLinks'><a target="_blank" href='/static/TeTePrivacyNotice.html'>Privacy Policy</a>&nbsp;|&nbsp;<a target="_blank" href='/static/TeTePrivacyNotice.html'>Terms of Service</a>&nbsp;|&nbsp;<a target="_blank" href='mailto:tete@tete.video'>Contact TeTe</a></div>
            <div className='uiWebsiteFooterCopyRight'>Copyright 2020 Badnano Corporation | California</div>
            </div>
            </div>
            </div>
            </div>

    }
}
