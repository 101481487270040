import {Subject} from 'rxjs';
import {Contact} from './Contact'
import {isDesktop, isIPad} from "./Platform";
import {doc, collectionChanges} from "rxfire/firestore";
import {concat, of, from} from 'rxjs';
import {take, filter, map, flatMap} from 'rxjs/operators';
import {VideoStreamMerger} from "./classes/video-stream-merger";
const uuid_v4 = require("uuid").v4;
const { createDM } = require("./DM");

const SIGNALING_DEVICE = uuid_v4();

 function cropImage(ctx, vid, opts) {

    const outputWidth = opts.width;
    const outputHeight = opts.height;

    const inputWidth = vid.videoWidth;
    const inputHeight = vid.videoHeight;

    const widthRatio = inputWidth / outputWidth;
    const heightRatio = inputHeight / outputHeight;
    let sx = 0;
    let sy = 0;
    let sw = inputWidth;
    let sh = inputHeight;
    if (widthRatio < heightRatio) {
        sh = outputHeight * widthRatio;
        sx = 0;
        sy = Math.min(0, inputHeight - sh);
    } else if (widthRatio > heightRatio) {
        sw = outputHeight * heightRatio;
        sy = 0;
        sx = Math.min(0, inputWidth - sw);
    }
    ctx.drawImage(vid, sx, sy, sw, sh, opts.x, opts.y, outputWidth, outputHeight);
}

class Device {
    constructor(stream) {
        this.stream = stream;
    }
    getVideoTrack() {
        return this.stream.getVideoTracks()[0];
    }
    hangup() {
    }
    setCompositeTrack(track) {
    }
    isSharingScreen() {
        return false;
    }
    getCall() {
        return null
    }
}

class LocalDevice extends Device {
    constructor(call, localStream) {
        super(localStream);
        this.call = call;
        this.isDesktop = isDesktop();
        const dim = localStream.getVideoTracks()[0].getSettings();
        this.width = dim.width;
        this.height = dim.height;
    }
    getUid = ()=> this.call.getLocalContact().uid;
    setCompositeTrack(track) {
        this.call.replaceSenderTrack(track);
    }
    isSharingScreen() {
        return !!this.call.localScreenShare;
    }
    getCall() {
        return this.call;
    }
        
}

class RemoteDevice extends Device {
    constructor(local, call, stream) {
        super(stream);
        this.local = local;
        call.observeHangup().subscribe(() => {
            local.onRemoteDeviceHangup(this);
        });
        this.remote = call;
        const device = call.getRemoteDevice();
        this.isDesktop = device.width > device.height;
        this.width = device.width;
        this.height = device.height;
    }
    getUid = ()=> this.remote.getRemoteContact().uid;
    setCompositeTrack(track) {
        this.remote.replaceSenderTrack(track);
    }
    hangup() {
        this.remote.hangup();
    }
    isSharingScreen() {
        return this.screenShare;
    }
    getCall() {
        return this.remote;
    }
}


const getGridStyle = (numCells)=> {
    let h = "100%";
    let w = "100%";
    let numCols;
    switch (numCells) {
      case 0: numCols = 0; break;
      case 1: numCols = 1; break;
      case 2: numCols = 2; break;
      case 3: numCols = 2; break;
      case 4: numCols = 2; break;
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
        numCols = 3; break;
      default:
        numCols = 4; break;
    }
    if (numCols === 0) {
      return {};
    }
    const numRows = Math.ceil(numCells/numCols);
    w = (100/numCols) + "%";
    h = ((1/numRows)*100)+"%";
    return {
       numRows: numRows,
       numCols: numCols,
        //"grid-auto-rows": h,
        "grid-auto-rows": "min-content",
        //"grid-template-columns": Array.from({length: numCols}).fill(w).join(" "),
        "grid-template-columns": Array.from({length: numCols}).fill("max-content").join(" "),
    };
}

const gridLayout = (expectsPortrait, dims) => {
    const gap = 0;
    const grid = document.createElement("div");
    grid.style.position = "fixed";
    grid.style.display = "grid";
    const style = getGridStyle(dims.length);
    let numCols, numRows;
    if (false && expectsPortrait) {
        let height;
        let i = 0;
        let maxHeight = 0;
        numRows = style.numCols;
        numCols = style.numRows;
        dims.forEach(dim => {
            const w = dim.width;
            const h = dim.height;
            //const h = dim.height < dim.width ? 320: 480;
            height += h;
            maxHeight = Math.max(height, maxHeight);
            i++;
            if (i == numRows) {
                height = 0;
            }
        });
        grid.style["max-height"] = maxHeight + 'px';
        grid.style["grid-auto-columns"] = style["grid-auto-rows"];
        grid.style["grid-template-rows"] = style["grid-template-columns"];
    } else {
        let width;
        let i = 0;
        let maxWidth = 0;
        numRows = style.numRows
        numCols = style.numCols;
        dims.forEach(dim => {
            const current = dim.lastFrame ? dim.lastFrame : dim.stream.getVideoTracks()[0].getSettings();
            const w = current.width;
            const h = current.height;
            //const w = dim.height < dim.width ? 480 : 320;
            width += w;
            maxWidth = Math.max(width, maxWidth);
            i++;
            if (i == numCols) {
                width = 0;
            }
        });
        grid.style["max-width"] = maxWidth + "px";
        grid.style["grid-auto-rows"] = style["grid-auto-rows"];
        grid.style["grid-template-columns"] = style["grid-template-columns"];
    }
    grid.style["grid-gap"] = gap + "px";
    grid.style.visibility = "hidden";
    grid.style.margin = "0";
    grid.style.padding = "0";
    const cells = [];
    dims.forEach(dim => {
        const div = document.createElement("div");
        const current = dim.lastFrame ? dim.lastFrame : dim.stream.getVideoTracks()[0].getSettings();
        const w = current.width;
        const h = current.height;
        //const w = dim.height < dim.width ? 480 : 320;
        //const h = dim.height < dim.width ? 320 : 480;
        div.style.height = h + "px";
        div.style.width = w + "px";
        div.style.margin = "0";
        div.style.padding = "0";
        grid.appendChild(div);
        cells.push(div);
    });
    document.documentElement.appendChild(grid);
    return {grid, cells, numCols, numRows}
}


let callId = 0;

export class MediaDevice {

    constructor(isGroup) {
        this.localStream = null;
        this.isGroup = !!isGroup;
        this.queue = [];
    }

    reset() {
        if (this.localStream) {
            this.localStream.getTracks().map(t => t.stop());
            this.localStream = null;
            this.p = null;
        }
    }

    getWebcamVideo = () => {
        return this.getUserMedia(this.isGroup);
    }

    resToDim = value => {
        let idealX;
        let idealY;
        switch (value) {
        default:
        case 'uhd':
            if (!isIPad()) {
                idealX = 3840;
                idealY = 2160;
                break;
            }
        case '1080p':
            if (!isIPad()) {
                idealX = 1920;
                idealY = 1080;
                break;
            }
        case 'hd':
            idealX = 1280;
            idealY = 720;
            break;
        case 'sd':
            idealX = 640;
            idealY = 480;
            break;
        case 'ld':
            idealX = 480;
            idealY = 320;
            break;
        case 'min':
            idealX = 320;
            idealY = 240;
            break;
        }
        if (isIPad()) {
            return {
                height: idealX,
                width: idealY,
            }
        }
        return {
            width: idealX,
            height: idealY,
        }
    }

    getConstraints = res => {
        const {width, height} = this.resToDim(res);
        const video = {};
        video.width = {min: 0, ideal: width};
        video.height = {min: 0, ideal: height};
        video.frameRate = {min: 15, ideal: 24};
        return video;
    }

    setWebCamResolution = value => {
        if (this.isGroup) {
            return;
        }
        const video = this.getConstraints(value);
        return Promise.all(this.localStream.getVideoTracks().map(track => {
            if (track.getCapabilities) {
                console.log('capabilities: ', track.getCapabilities());
            }
            console.log('track settings: ', track.getSettings());
            return track.applyConstraints(video).then(result => {
                //debugger;
                console.log("applied constraints: ", video, ": ", track.getSettings());
            }).catch(err => {
                console.error(err);
            });
        }));
    }

    getUserMedia = () => {
        const isGroupChat = this.isGroup;
        const videoInput = localStorage.getItem('videoinput');
        const audioInput = localStorage.getItem('audioinput');
        let videoRes = localStorage.getItem('videoRes');
        const video = videoInput ? {deviceId: {exact: videoInput}} : {};
        if (isGroupChat) {
            video.height = {};
            video.width = {};
            if (window.innerWidth < window.innerHeight) {
                video.height.exact = 480;
            } else {
                video.width.exact = 480;
            }
        } else {
            if (!isIPad() && !videoRes && window.numCores < 4) {
                videoRes = 'min';
            }
            const {width, height} = this.resToDim(videoRes);
            video.width = {
                min: 0,
                ideal: width,
            }
            video.height = {
                min: 0,
                ideal: height
            }
        } 
        video.frameRate = {min: 15, ideal: 24};
        const constraints = {
            video: video,
            audio: audioInput ? {deviceId: {exact: audioInput}} : true
        }
        try {
            console.log("constraints: ", constraints); 
            return navigator.mediaDevices.getUserMedia(constraints).then(result => {
                if (!result) {
                    //debugger;
                }
                console.log("got user media: ", result);
                result.getVideoTracks().map(t => {
                    console.log("video track: ", t.getSettings());
                });
                return result;
            }).catch (err => {
                //debugger;
                console.error(err);
                return Promise.resolve();
            });
        } catch (err) {
            //debugger;
            return Promise.resolve();
        }
    }

    getLocalStream = () => {
        if (this.localStream) {
            return Promise.resolve(this.localStream)
        }
        if (this.p) {
            return new Promise((resolve, reject) => {
                this.queue.push(resolve);
            });
        }
        console.log("calling webcam get user media");
        this.p = this.getUserMedia().then(stream => {
            console.log("get webcam user media then");
            this.localStream = stream;
            this.queue.map(resolve => resolve(stream));
            this.p = null;
            this.queue = [];
            return stream;
        })
        return this.p;
    }

    getScreenVideo = () => {
        const constraints = { video: true }
        return navigator.mediaDevices.getDisplayMedia(constraints);
    }
}

class Call {

    constructor(pc, isCaller) {
        this.startTime = Date.now();
        this.id = ++callId;
        this.pc = pc;
        this.isCaller = isCaller;
        this.pc.observeHangup().subscribe(() => {
            this.disconnected = true;
            const toDestroy = this.merger;
            this.merger = null;
            this.destroyMerger(toDestroy);
            if (this.master) {
                this.srcs.map(src => src.hangup());
            }
        });
        this.pc.connect().then(() => {
            if (isCaller) {
                console.log("=> call setup complete");
            } else {
                console.log("call setup complete <=");
            }
        });
    }
                               
    isInProgress = () => !!this.pc.remoteStream;

    isRelay = () => {
        return this.pc.isRelay;
    }

    getLocalContact = () => {
        return this.pc.call.localContact;
    }

    getRemoteDevice = () => this.pc.call.remoteDevice;

    getRemoteContact = () => {
        return this.pc.call.remoteContact;
    }

    sendDeviceSettings = () => {
        return this.pc.sendDeviceSettings();
    }

    getStartTime = () => {
        return this.startTime || 0;
    }

    observeRemoteStreams() {
        return this.pc.observeRemoteStreams();
    }
    
    observeHangup() {
        return this.pc.observeHangup();
    }

    observeDeviceSettings = () => this.pc.call.observeDeviceSettings();
    getDeviceSettings = () => this.pc.call.getDeviceSettings();


    isSharingScreen = () => {
        return this.pc.call.remoteScreenShare;
    }

    getRemoteStream = () => {
        return this.pc.getRemoteStream().then(stream => {
            if (this.isCaller) {
                console.log("=> got remote stream ", stream);
            } else {
                console.log("got remote stream <= ", stream);
            }
            return stream;
        });
    }

    merge = (call, src2) => {
        const device = new RemoteDevice(this, call, src2);
        if (!this.srcs) {
            this.srcs = [new LocalDevice(this, this.pc.localStream),
                         device];
        } else {
            this.srcs.push(device);
        }
        this.createMerger();
    }

    unmerge = device => {
        this.srcs = this.srcs.filter(src => src != device);
        this.createMerger();
    }

    getSendingStream = () => {
        return this.merger ? this.merger.result : this.pc.localStream;
    }

    onRemoteScreenShare = (device, value) => {
        //this.createMerger();
    }

    onRemoteDeviceHangup = device => {
        console.log("got hangup: ", device);
        this.unmerge(device);
    }

    destroyMerger = (merger) => {
        if (merger) {
            merger.destroy();
        }
    }

    compositedScreenShare = (prev, screenShare) => {
        const settings = screenShare.stream.getVideoTracks()[0].getSettings();
        this.merger = new VideoStreamMerger(settings);
        console.log("created screen share merger: ", settings);
        const srcs = this.srcs.filter(x => x.stream);
        let needsRemerge = false;
        srcs.forEach((src, i) => {
            const opts = {
                draw: (ctx, frame, done) => {
                    if (src == screenShare) {
                        if (!needsRemerge) {
                            const current = src.stream.getVideoTracks()[0].getSettings();
                            if (current.width != settings.width ||
                                current.height != settings.height) {
                                needsRemerge = true;
                                console.log("remerge: ", settings, " <> ", current);
                                setTimeout(this.createMerger);
                            } else {
                                ctx.drawImage(frame, 0, 0, settings.width, settings.height, 0, 0, settings.width, settings.height);
                            }
                        }
                    }
                    done();
                }
            }
            this.merger.addStream(src.stream, opts);
        });
        this.startMerger(prev);
    }
    
    createMerger = () => {
        const srcs = this.srcs.filter(x => x.stream);
        const toDestroy = this.merger;
        this.merger = null;
        if (this.disconnected) {
            if (toDestroy) {
                this.destroyMerger(toDestroy);
            }
            return;
        }
        let screenShare = this.srcs.find(src => src.getCall() == this.groupScreenShare);
        if (!screenShare) screenShare = this.srcs.find(src => src.isSharingScreen());
        if (screenShare) {
            return this.compositedScreenShare(toDestroy, screenShare);
        }
        console.log("srcs: ", srcs);
        const layout = gridLayout(this.pc.call.expectsPortrait(), srcs);
        console.log("layout: ", layout);
        const {grid, cells, numRows, numCols} = layout;
        console.log("grid: ", grid.offsetWidth, ", ", grid.offsetHeight);
        const gridOpts = {
            width: grid.offsetWidth,
            height: grid.offsetHeight
        };
        console.log("creating merger: ", gridOpts);
        console.log(VideoStreamMerger);
        this.merger = new VideoStreamMerger(gridOpts);
        const uvs = [];
        let needsRemerger = false;
        srcs.forEach((src, i) => {
            const opts = {
                x: cells[i].offsetLeft,
                y: cells[i].offsetTop,
                width: cells[i].offsetWidth,
                height: cells[i].offsetHeight,
                                
            };
            console.log("opts: ", i, ": ", opts);
            const setting = src.stream.getVideoTracks()[0].getSettings();
            opts.draw = (ctx, frame, done) => {
                if (!needsRemerger && frame.videoWidth > 0) {
                    const current = src.stream.getVideoTracks()[0].getSettings();
                    let orientationChange = false;
                    if (src.lastFrame) {
                        if (src.lastFrame.width != frame.videoWidth ||
                            src.lastFrame.height != frame.videoHeight) {
                            console.log("remerger last frame no match: ", frame.videoWidth, ", ", frame.videoHeight, " <> ", src.lastFrame);
                            orientationChange = true;
                            src.lastFrame = {
                                width: frame.videoWidth,
                                height: frame.videoHeight
                            }
                        }
                    } else {
                        if (frame.videoWidth != current.width || frame.videoHeight != current.height) {
                            orientationChange = true;
                            src.lastFrame = {
                                width: frame.videoWidth,
                                height: frame.videoHeight
                            }
                        }
                    }
                    const settingsChange = current.width != setting.width || current.height != setting.height;
                    if (orientationChange || settingsChange) {
                        console.log("remerger: ", setting, " <> ", current, " lastFrame: ", src.lastFrame);
                        if (settingsChange) {
                            src.lastFrame = null;
                        }
                        needsRemerger = true;
                        setTimeout(this.createMerger);
                    } else {
                        cropImage(ctx, frame, opts);
                    }
                }
                done();
            }
            const uid = src.getUid();
            uvs.push({
                uid: uid,
                x: opts.x / gridOpts.width,
                y: opts.y / gridOpts.height,
                w: opts.width / gridOpts.width,
                h: opts.height / gridOpts.height,
            });
            console.log("adding stream: ", opts);
            this.merger.addStream(src.stream, opts);
        });
        grid.remove();
        this.setComposite(uvs);
        this.startMerger(toDestroy);
    }

    startMerger = (toDestroy) => {
        this.merger.start();
        return Promise.all(this.merger.result.getTracks().map(track => this.replaceSenderTrack(track))).then(() => {
            this.destroyMerger(toDestroy);
        });
    }

    add = call => {
        const ops = [this.getRemoteStream(), call.getRemoteStream(), call.getDeviceSettings()];
        return Promise.all(ops).then(results => {
            const [myStream, stream, remoteDevice] = results;
            if (myStream && stream && remoteDevice) {
                console.log("merging call: ", call);
                this.merge(call, stream);
            }
        });
    }

    replaceSenderTrack = track => {
        return this.pc.replaceSenderTrack(track);
    }

    hangup = () => {
        return this.pc.hangup();
    }

    setAudioMuted = muted => {
        this.getSendingStream().getAudioTracks().map(t => t.enabled = !muted);
        this.pc.call.setAudioMuted(muted);
    }

    setVideoMuted = muted => {
        this.getSendingStream().getVideoTracks().map(t => t.enabled = !muted);
        this.pc.call.setVideoMuted(muted);
    }

    setGroupScreenShare = call => {
        if (this.groupScreenShare != call) {
            this.groupScreenShare = call;
            if (call != this && this.merger) {
                this.createMerger();
            }
            this.pc.call.setGroupScreenShare(call ? call.getRemoteContact() : null);
        }
    }

    setScreenShare = on => {
        if (this.localScreenShare != on) {
            this.localScreenShare = on;
            if (this.srcs && this.srcs.length > 0) {
                this.createMerger();
            }
            return this.pc.call.setScreenShare(on);
        }
        return Promise.resolve();
   }

    setComposite = uvs => {
        return this.pc.call.setComposite(uvs);
    }

    observeComposite = () => {
        return this.pc.call.observeComposite();
    }

    observeScreenShare = () => {
        return this.pc.call.observeScreenShare();
    }

    observeGroupScreenShare = () => {
        return this.pc.call.observeGroupScreenShare();
    }

    observeMuted = () => {
        return this.pc.call.observeMuted();
    }

    onMuted = k => {
        this.pc.call.onMuted(k);
    }

    onStatsUpdate = k => {
        this.pc.onStatsUpdate = k;
    }

    onHangup = k => {
        this.pc.onHangup = k;
    }
}

class PeerConnection {

    constructor(call, localStream, iceServers, localUserId) {
        this.call = call;
        this.localStream = localStream;
        this.localContact = call.localContact;
        this.remoteContact = call.remoteContact;
        this.localUserId = localUserId;
        this.incomingIceCandidates = [];
        const pc = new RTCPeerConnection({iceServers: iceServers, iceTransportPolicy: "all"});
        console.log("iceServers: ", iceServers);
        this.pc = pc;
        pc.onconnectionstatechange = this.onConnectionStateChange;
        pc.onicecandidate = this.onIceCandidate;
        pc.oniceconnectionstatechange = this.onIceConnectionStateChange;
        pc.onsignalingstatechange = this.onSignalingStateChange;
        pc.onicegatheringstatechange = this.onIceGatheringStateChange;
        pc.onidentityresult = this.onIdentityResult;
        pc.onnegotiationneeded = this.onNegotiationNeeded;
        pc.onremovestream = this.onRemoveStream;
        pc.ontrack = this.onTrack;
        this.iceSub = call.observeIceCandidates().subscribe(candidate => {
            pc.addIceCandidate(candidate).then(() => {
                console.log("**added ice candidate: ", candidate);
            }).catch(err => {
                console.error(err);
                this.incomingIceCandidates.push(candidate);
            })
        });
        call.onDisconnect(() => this.endCall(true));
        this.hangupSubject = new Subject();
        this.onStreamSubject = new Subject();
    }

    addTrack = track => {
        return this.pc.addTrack(track, this.localStream);
    }

    removeTrack = track => {
        this.pc.removeTrack(track);
    }

    observeHangup = () => {
        console.log("peer connection observe hangup");
        return this.hangupSubject;
    }

    onNegotiationNeeded = e => {
        //////debugger;
    }

    onIceCandidate = e => {
        if (e.candidate) this.call.addIceCandidate(e.candidate);
        //else ////debugger;
    }

    replaceSenderTrack = track => {
        const sender = this.pc.getSenders().find(s => s.track.kind == track.kind);
        if (sender) {
            return sender.replaceTrack(track).then(result => {
                console.log("replace track: ", track, " => ", result);
            }).catch(err => {
                console.error(err);
                ////debugger;
            });
        }
    }

    sendDeviceSettings = () => {
        const call = this.call;
        const localStream = this.localStream;
        const dims = localStream.getVideoTracks()[0].getSettings();
        ////debugger;
        const settings = {
            type: "device",
            isDesktop: isDesktop(),
            width: dims.width,
            height: dims.height
        };
        console.log("sending device settings: ", settings);
        call.sendData(settings);
    }

    connect = () => {
        const pc = this.pc;
        const call = this.call;
        console.log("localUserId: ", this.localUserId);
        console.log("from: ", call.from);
        const localStream = this.localStream;
        this.sendDeviceSettings();
        localStream.getTracks().forEach(track => pc.addTrack(track, localStream));
        if (call.isCaller) {
            console.log("=> pc.connect got local stream: ", localStream);
            console.log("=> added tracks");
            return pc.createOffer().then(offer => {
                console.log("=> created offer");
                return pc.setLocalDescription(offer).then(() => {
                    console.log("=> set local description");
                    return call.setOffer(offer).then(answer => {
                         console.log("=> got answer: ", answer);
                        return pc.setRemoteDescription(answer).then(() => {
                            this.incomingIceCandidates.map(c => {
                                pc.addIceCandidate(c).then(() => {
                                    console.log("**added ice candidate: ", c)
                                }).catch(err => {
                                    console.error(err);
                                })
                            });
                            console.log("=> set remote description");
                        });
                    });
                });
            });
        } else {
            return call.getOffer().then(offer => {
                console.log("created offer <=");
                return pc.setRemoteDescription(offer).then(() => {
                    console.log("set remote description <=");                    
                    this.incomingIceCandidates.map(c => {
                        pc.addIceCandidate(c).then(() => {
                            console.log("**added ice candidate: ", c)
                        }).catch(err => {
                            console.error(err);
                        })
                    });
                    return pc.createAnswer().then(answer => {
                        console.log("created answer: ", answer);
                        return pc.setLocalDescription(answer).then(() => {
                            console.log("set local description <=");
                            return call.setAnswer(answer).then(() => {
                                console.log("sent answer <=");
                            });
                        });
                    });
                });
            });
        }
    }

    onSignalingStateChange = e => {
        console.log("signalingState: ", this.pc.signalingState);
    }

    onIceGatheringStateChange =  e => {
        console.log("iceGatheringState: ", this.pc.iceGatheringState);
    }
    
    onIceConnectionStateChange =  e => {
        console.log("iceConnectionState: ", this.pc.iceConnectionState);
        if (this.pc.iceConnectionState === 'failed') {
            if (this.pc.restartIce) {
                this.pc.restartIce();
            } else {
                this.pc.createOffer({iceRestart: true})
                    .then(function(offer) {
                        return this.pc.setLocalDescription(offer);
                    })
            }
        }
    }
    
    onConnectionStateChange = e => {
        console.log("connectionState: ", this.pc.connectionState);
         switch(this.pc.connectionState) {
         case "new":
         case "checking":
             break;
         case "connected":
             // call started
             {
                 this.pc.getStats().then(stats => {
                     [...stats.values()].map(s => {
                         if (s.type == "candidate-pair" && s.nominated) {
                             const localCandidate = stats.get(s.localCandidateId);
                             console.log(localCandidate);
                             this.isRelay = localCandidate.candidateType == "relay";
                         }
                     });
                 });
                 this.callStart = Date.now();
                 this.statsPoller = setInterval(() => {
                     if (this.onStatsUpdate)  {
                         this.pc.getStats().then(stats => {
                             let sent = 0;
                             let received = 0;
                             for (const stat of stats.values()) {
                                 if (stat.type == "outbound-rtp") {
                                     sent += stat.bytesSent;
                                 } else if (stat.type == "inbound-rtp") {
                                     received += stat.bytesReceived;
                                 }
                             }
                             this.onStatsUpdate({
                                 isRelay: this.isRelay,
                                 bytesReceived: received,
                                 bytesSent: sent,
                                 duration: Date.now() - this.callStart
                             });
                         });
                     }
                 }, 5000);
             }
             break;
         case "disconnected":
         case "closed":
         case "failed":
             // call ended
             this.endCall();
             break;
         default:
             break;
         }
    }

    getStartTime() {
        return this.startTime || 0;
    }
    

    onTrack = e => {
        console.log("onTrack: ", this.remoteStream);
        this.remoteStream = e.streams[0];
        this.onStreamSubject.next(e.streams[0]);
    }

    observeRemoteStreams = () => {
        if (this.remoteStream) {
            return of(this.remoteStream);
        }
        return this.onStreamSubject;
    }

    endCall = (fromCall) => {
        if (this.callEnded) return;
        this.callEnded = true;
        if (this.iceSub) this.iceSub.unsubscribe();
        console.log("pc hangupSubject next");
        this.hangupSubject.next(this);
        this.hangupSubject.complete();
        console.log("pc hangupSubject complete done");
        this.onStreamSubject.complete();
        if (this.onHangup) {
            this.onHangup();
        }
        clearInterval(this.statsPoller);
        if (!fromCall) this.call.disconnect(true);
        this.pc.close();
        console.log("Closed Peer Connection");
    }

    hangup = () => this.endCall()

    getRemoteStream = () => {
        if (this.remoteStream) {
            return Promise.resolve(this.remoteStream);
        } else {
            return this.observeRemoteStreams().pipe(take(1)).toPromise();
        }
    } 
}


const NO_ANSWER_TIMEOUT = 30000;

class CallSignaling {

    constructor(signaling, isCaller, local, remote, data, docRef) {
        this.deviceSettingsSubject = new Subject();
        this.iceCandidateSubject = new Subject();
        this.deviceId = uuid_v4();
        this.signaling = signaling;        
        this.doc = docRef;
        this.hangupSubject = new Subject();
        this.groupScreenShareSubject = new Subject();
        this.from = data.from;
        this.to = data.to;
        this.localContact = new Contact(local);
        this.remoteContact = new Contact(remote);
        this.group = data.group;
        if (this.group) {
            this.groupContact = new Contact(this.group);
        }
        ////debugger;ew
        this.iceCandidates = [];
        this.isCaller = isCaller;
        console.log("Created call signaling: isCaller: ", isCaller);
        this.callUnsubscribe = this.addDocListener(change => {
            if (change.type == "removed") {
                this.disconnect(false);
            } 
        });
        this.startTime = data.startTime;
        this.compositeSubject = new Subject();
        this.dataSub = this.observeData().subscribe(msg => {
            console.log("received data: ", msg);
            if (msg.type == "device") {
                this.remoteDevice = msg;
                this.deviceSettingsSubject.next(msg);
            } else if (msg.type == "iceCandidate") {                
                this.iceCandidates.push(msg.iceCandidate);
                this.iceCandidateSubject.next(msg.iceCandidate);
            } else if (msg.type == "hangup") {
                this.hangupSent = true; // don't send
                console.log("got hangup");
                //debugger;
                this.disconnect(false);
            } else if (msg.type == "composite") {
                this.compositeSubject.next(msg.uvs);
            } else if (msg.type == "groupScreenShare") {
                this.groupScreenShareSubject.next(msg.contact);
            }
        });
    }

    getChannel = () => {
        if (this.group) {
            return this.groupContact;
        }
        return this.from;
    }

    observeDeviceSettings = () => {
        if (this.remoteDevice) {
            return concat(of(this.remoteDevice), this.deviceSettingsSubject);
        }
        return this.deviceSettingsSubject;
    }

    getDeviceSettings = () => this.observeDeviceSettings().pipe(take(1)).toPromise();
    
    sendHangup() {
        if (this.hangupSent) return;
        this.hangupSent = true;
        this.sendData({
            type: "hangup"
        });
    }

    observeHangup = () => {
        console.log("callsignaling observeHangup");
        if (this.disconnected) {
            return of(Promise.resolve());
        }
        return this.hangupSubject;
    }

    expectsPortrait = () => {
        if (true) return false;
        return this.remoteDevice && !this.remoteDevice.isDesktop;
    }

    observeIceCandidates = () => {
        if (this.iceCandidates.length == 0) return this.iceCandidateSubject;
        const past = from(this.iceCandidates);
        const future = this.iceCandidateSubject;
        return concat(past, future);
    }

    sendData = msg => {
        const me = this.localContact.uid;
        const you = this.remoteContact.uid;
        const data = {
            deviceId: this.deviceId,
            from: me,
            to: you,
            data: msg,
        }
        return this.doc.collection("data").add(data).then(() => {
            ////debugger;
            console.log("sent: ", data);
            return;
        });
    }

    observeData = () => {
        const me = this.localContact.uid;
        const collection = this.doc.collection('data').where("to",  "==", me);
        return collectionChanges(collection).pipe(flatMap(changes => from(changes.filter(change => change.type == 'added').map(change => {
            const msg = change.doc.data().data;
            if (msg.deviceId != this.deviceId) {
                console.log("received: ", msg);
                return msg;
            }
        }))));
    }

    addDocListener = k => this.doc.onSnapshot(snap => {
        const data = snap.data();
        if (data && (!this.isCaller && data.answeredBy && data.answeredBy != SIGNALING_DEVICE)) {
            console.log("somebody else answered");
            //debugger;
            this.disconnect(false);
        } else {
            if (!data) {
                this.disconnect(false);
            }  else {
                k(snap)
            }
        }
    });

    getResponse = () => {
        return new Promise((resolve, reject) => {
            let unsubscribe;
            let timeout;
            if (false) timeout = setTimeout(() => {
                unsubscribe();
                resolve("no-answer");
            }, NO_ANSWER_TIMEOUT);
            const k = change => {
                const call = change.data();
                if (!call) {
                    return reject("call-ended");
                }
                if (call.state == "answered" || call.state == "declined") {
                    unsubscribe();
                    clearTimeout(timeout);
                    resolve(call.state);
                }
            }
            unsubscribe = this.addDocListener(k);
        });
    }

    getProp = prop => {
        return new Promise((resolve, reject) => {
            let unsubscribe;
            const k = change => {
                const call = change.data();
                if (!call) {
                    // call was deleted
                    return reject("call-ended");
                }
                //////debugger;
                if (call[prop]) {
                    const value = call[prop];
                    unsubscribe()
                    console.log(prop, " <==");
                    resolve(value);
                }
            }
            unsubscribe = this.addDocListener(k);
        });
    }

    setProp = (prop, value) => {
        const updates = {};
        updates[prop] = value;
        return this.update(updates).then(result => {
            console.log("==> ", prop, " => ", value);
            return result;
        });
    }

    addIceCandidate = candidate => {
        this.sendData({
            type: 'iceCandidate',
            iceCandidate: candidate.toJSON ? candidate.toJSON(): candidate,
        });
    }
    
    update = (updates) => {
        return this.doc.set(updates, {merge: true});
    }
    
    setOffer = offer => {
        const k = this.getProp("answer");
        this.setProp("offer", offer.toJSON ? offer.toJSON() : offer);
        return k;
    }

    setAudioMuted = muted => {
        muted = !!muted;
        const value = {};
        value[this.localContact.uid] = muted;
        return this.setProp("audioMuted", value);
    }

    setVideoMuted = muted => {
        muted = !!muted;
        const value = {};
        value[this.localContact.uid] = muted;
        return this.setProp("videoMuted", value);
    }

    observeMuted = () => {
        return doc(this.doc).pipe(map(snap => {
            const data = snap.data();
            return {
                audio: data.audioMuted ? !!data.audioMuted[this.remoteContact.uid] : false,
                video: data.videoMuted ? !!data.videoMuted[this.remoteContact.uid] : false
            };
        }));
    }

    setScreenShare = on => {
        const value = {};
        value[this.localContact.uid] = !!on;
        this.setProp("screenShare", value);
    }

    setGroupScreenShare = contact => {
        const msg = {
            type: "groupScreenShare",
            contact: contact ? contact.toJSON() : null,
        }
        return this.sendData(msg);
    }

    setComposite = uvs => {
        const msg = {
            type: "composite",
        }
        if (uvs) msg.uvs = uvs;
        return this.sendData(msg);
    }

    observeComposite = () => {
        return this.compositeSubject;
    }


    observeScreenShare = () => {
        return doc(this.doc).pipe(filter(snap => snap.data()), map(snap => {
            const data = snap.data();
            if (!data.screenShare) return this.remoteScreenShare = false;
            return this.remoteScreenShare = !!data.screenShare[this.remoteContact.uid];
        }));
    }

    observeGroupScreenShare = () => {
        return this.groupScreenShareSubject;
    }

    onMuted(k) {
        if (this.muteUnsubscribe) {
            this.muteUnsubscribe();
            this.muteUnsubscribe = null;
        }
        this.onMuteHandler = k;
        if (k) {
            this.muteUnsubscribe = this.addDocListener(snap => {
                const data = snap.data();
                if (!data) return;
                if (data.audioMuted) {
                    const value = data.audioMuted[this.localContact.uid];
                    if (value !== undefined) {
                        this.onMuteHandler("audio", value);
                    }
                }
                if (data.videoMuted) {
                    const value = data.videoMuted[this.localContact.uid];
                    if (value !== undefined) {
                        this.onMuteHandler("video", value);
                    }
                }
            });
        }
    }

    getOffer=() => {
        return this.getProp("offer");
    }        

    setAnswer=(answer) => {
        return this.setProp("answer", answer.toJSON ? answer.toJSON() : answer);
    }

    disconnect = (sendHangup) => {
        if (this.disconnected) return;
        this.disconnected = true;
        console.log("hangupSubject.next");
        this.hangupSubject.next(null);
        this.hangupSubject.complete();
        this.groupScreenShareSubject.complete();
        console.log("hangupSubject.complete done");
        if (sendHangup) {
            this.sendHangup();
        }
        if (this.disconnectHandler) this.disconnectHandler();
        const ops = [];
        this.callUnsubscribe();
        this.dataSub.unsubscribe();
        if (sendHangup) {
            this.doc.delete().then(() => {
                console.log("deleted call");
            }).catch(err => {
                console.error("error deleting call document: ", err);
                return Promise.resolve();
            });
        }
    }

    onDisconnect(k) {
        this.disconnectHandler = k;
    }
}


export class Signaling {

    constructor(me, firebase) {
        this.me = me;
        this.firebase = firebase;
        const auth = firebase.auth();
        auth.onAuthStateChanged(this.onAuthStateChanged);
        this.onAuthStateChanged(auth.currentUser);
        this.incomingCalls = {};
    }

    onAuthStateChanged = user => {
        this.self = user ? new Contact(user) : null;
        this.user = user;
        if (user) {
            this.getIceServers();
        }
    }

    getIceServers = () => {
        if (this.iceServers) {
            return Promise.resolve(this.iceServers);
        }
        return this.user.getIdToken(false).then(token => {
            const doit = retries => {
                let url = "getRelayServers?idToken="+token;
                if (window.ice) {
                    window.ice.map(service => {
                        url += "&"+service+"=true";
                    });
                } else {
                    url += "&twilio=true&xirsys=true";
                }
                const getRelayServers = this.firebase.functions().httpsCallable(url);
                console.log("getting ice servers..");
                return getRelayServers().then(response => {
                    this.iceServers = response.data;
                    console.log("got ice servers: ", this.iceServers);
                    return Promise.resolve(this.iceServers);
                }).catch (err => {
                    console.error(err);
                    if (retries < 3) {
                        return doit(retries + 1);
                    }
                    return Promise.reject(err);
                });
            }
            return doit(0);
        })
    }

    callsRef = () => this.firebase.firestore().collection("Calls");

    handleIncomingCall = (localMediaDevice, call) => {
        const ops = [this.getIceServers(), localMediaDevice.getLocalStream()];
        return Promise.all(ops).then(results => {
            const [iceServers, localStream] = results;
            const pc = new PeerConnection(call, localStream, iceServers, this.self.uid);
            return new Call(pc, false);
        });
    }

    handleOutgoingCall = (localMediaDevice, call) => {
        const ops = [this.getIceServers(), localMediaDevice.getLocalStream()];
        return Promise.all(ops).then(results => {
            const [iceServers, localStream] = results;
            const pc = new PeerConnection(call, localStream, iceServers, this.self.uid);
            return new Call(pc, true);
        });
    }


    tryAnswerCall=(docRef) => {
        return this.firebase.firestore().runTransaction(transaction => {
            return transaction.get(docRef).then(doc => {
                const val = doc.data();
                if (val.state == 'received') {
                    transaction.update(docRef, {state: "answered", answeredBy: SIGNALING_DEVICE})
                    return Promise.resolve(true);
                } else {
                    return Promise.resolve(false);
                }
            });
        });
    }

    listenForCalls = (answerCall) => {
        const uid = this.firebase.auth().currentUser.uid;
        this.callListenerUnsubscribe = this.callsRef().where("to", "==", uid).where("state", "==", "call").onSnapshot(snapshot => {
            snapshot.docChanges().forEach(change => {
                console.log("call ", change.type, change.doc.data());
                if (change == 'removed') {
                    const call = this.incomingCalls[change.doc.id];
                    if (call && !call.shouldAnswer) {
                        call.disconnect();
                    }
                    delete this.incomingCalls[change.doc.id];
                } else if (change.type === "added") {
                    const callData = change.doc.data();
                    if (callData.group && callData.group.organizer == this.localContact.uid) {
                        // don't answer
                        return;
                    }
                    change.doc.ref.update({
                        state: "received",
                    }).then(() => {
                        const callData = change.doc.data();
                        console.log("receiving call: ", callData);
                        const call = new CallSignaling(this, false, new Contact(callData.callee), new Contact(callData.caller), callData, change.doc.ref);
                        this.incomingCalls[change.doc.id] = call;
                        const contact = new Contact(callData.caller);
                        answerCall(contact, call, callData.group).then(response => {
                            console.log("call answered: ", response);
                            if (!call.disconnected && response.answer) {
                                return this.tryAnswerCall(change.doc.ref).then(didAnswer => {
                                    call.shouldAnswer = didAnswer;
                                    if (didAnswer) {
                                        const mediaDevice = response.mediaDevice;
                                        return this.handleIncomingCall(mediaDevice, call).then(c => {
                                            response.answer(c);
                                        });
                                    } else {
                                        response.answer();
                                    }
                                });
                            } else {
                                call.sendHangup();
                            }
                        });
                    });
                } 
            });
        })
    }    

    call = (id, localMediaDevice, caller, callee, group) => {
        const now = Date.now();
        const from = caller;
        const to = callee.remoteContact ? callee.remoteContact: callee;
        if (!to.toJSON) {
            //debugger;
        }
        let caller1 = from.toJSON();
        let callee1 = to.toJSON();
        if (group) {
            caller1.group = group;
            callee1.group = group;
        }
        const data = {
            to: to.uid,
            from: from.uid,
            caller: caller1,
            callee: callee1,
        }
        console.log("making call: ", data);
        return this.user.getIdToken(false).then(token => {
            const createCall = this.firebase.functions().httpsCallable("createCall?idToken="+token);
            return createCall(data).then(response => {
                const docId = response.data;
                console.log("created call: ", docId);
                const doc = this.callsRef().doc(docId);
                const call = new CallSignaling(this, true, caller1, callee1, data, doc);
                call.id = id;
                call.shouldAnswer = true;
                return this.handleOutgoingCall(localMediaDevice, call); 
            });
        }).catch(err => {
            console.error(err);
            return Promise.reject(new Error(err));
        });
    }    
}
