import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactSVG } from 'react-svg'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {UIDeviceSetup} from "../DeviceSetup";
import {UIOKCancel} from "../OKCancel";
import {formatNewContactText, UIChat} from "../Chat";
import {UIIcon} from "../Icon";
import {formatDate, formatStartEndTime, renderPaymentStatus, UIAppointment} from "../Appointment";
import Forward from "../../assets/icons/Forward.svg";
import Arrow from "../../assets/icons/PointerDown.svg";
import Search from "../../assets/icons/Search.svg";
import Cross from "../../assets/icons/Cross.svg";
import Save from "../../assets/icons/Save.svg";
import Checkmark from "../../assets/icons/CheckMark.svg";
import Hipaa from "../../assets/icons/Hipaa.svg";
import TeTeLogo from "../../assets/Assets/TeteLogo00.svg";
import LinkAsset from "../../assets/Assets/LinkAsset.png";
import LinkAssetSml from "../../assets/Assets/LinkAssetSml_3x.png";
import LinkBusinessAssetSml from "../../assets/Assets/LinkBusinessAssetSml_3x.png";
import StripeError from "../../assets/Assets/StripeError.png";
import BusinessAsset from "../../assets/Assets/BusinessAsset.png";
import StripeAsset from "../../assets/Assets/TeteStripeAsset_3x.png";
import Link from "../../assets/icons/Link.svg";
import Stripe from "../../assets/icons/StripeS.svg";
import Clipboard from "../../Clipboard";
import {StripeButton} from "../../StripeButton";
import {BAA} from "./BAA";
import {isDesktop, isSafari} from "../../Platform";
import moment from 'moment';
import './index.css'


class UIMeTeTeFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: []
        };
        this.appts = {};
        this.messages = {};
        this.inProgress = {};
        this.waiting = {};
        this.newContacts = {};
        this.scheduled = {};
        this.openContacts = {};
        this.removedContacts = {};
        this.subs = {};
    }

    waitForUpdate = appt => {
        let waiting = this.waiting[appt.id];
        if (!waiting) {
            waiting = [];
            this.waiting[appt.id] = waiting;
        }
        return new Promise((resolve, reject) => {
            waiting.push(resolve);
        });
    }

    showSystemProgressIndicator = (ts, apptId, msg) => {
        this.inProgress = {
            ts: ts,
            apptId: apptId,
        }
        window.showProgressIndicator(msg);
    }

    finishNewContact = newContact => {
        const contact = newContact.contact;
        if (this.newContacts[contact.uid]) {
            const msg = this.newContacts[contact.uid];
            this.deleteMessage(msg);
        }
        return Promise.resolve();
    }

    componentDidMount() {
        this.props.me.hasStripeAuth().then(auth => {
            let completedStripeAuth = !!auth;
            let removeFirstContact = () => {};
            let removeStripeSetup = () => {};
            {
                let text = "Welcome to TeTe";
                if (window.isBusiness == 't') {
                    text ="Welcome to TeTe's HIPAA compliant solution for Teletherapy";
                } else if (window.isBusiness) {
                    text ="Welcome to TeTe, the complete end-to-end-encrypted chat, video conferencing, scheduling and payments solution for your business"
                }
                const ts = Date.now();
                const msg = {
                    system: true,
                    ts: ts,
                    text: text,
                    data: {
                        type: "welcome",
                        welcome: ""
                    }
                }
                this.messages[ts] = msg;
                this.removeWelcome = () => {
                    delete this.messages[ts];
                    this.updateMessagesNow();
                }
                const optedOut = localStorage.getItem("stripe-opt-out")  == this.props.me.self.uid;
                //console.log("opted out: ", optedOut);
                const showBusinessSetup = () => {
                    this.props.showBusinessSetup();
                }
                if (optedOut) {
                    showBusinessSetup();
                }
                const sendContactMessage = () => {
                    const contactMsg = {
                        system: true,
                        ts: ts - 1000,
                        data: {
                            type: 'connect',
                            'connect': () =>  <UIMeContact portal={this.props.portal} me={this.props.me} type='contact' formLinkURL={this.props.formLinkURL} link={this.props.link} copyLink={this.props.copyLink}/>
                                
                        }
                    }
                    this.messages[contactMsg.ts] = contactMsg;
                    removeFirstContact = () => {
                        this.removeWelcome();
                        delete this.messages[contactMsg.ts];
                        this.updateMessagesNow();
                    }
                }
                if (window.isBusiness && !optedOut) {
                    this.startedStripeAuth = true;
                    const optOut = () => {
                        localStorage.setItem("stripe-opt-out", this.props.me.self.uid);
                        removeStripeSetup();
                        showBusinessSetup();
                        sendContactMessage();
                        return Promise.resolve();
                    }
                    ////debugger;
                    const authMsg = {
                        system: true,
                        ts: ts - 1000,
                        data: {
                            type: 'connect',
                            'connect': () => <UIMeConnectToStripe reviewBAA={this.props.reviewBAA} link={this.props.link} optOut={optOut} copyLink={this.props.copyLink} formLinkURL={this.props.formLinkURL}/>
                        }
                    }
                    this.messages[authMsg.ts] = authMsg;
                    removeStripeSetup = () => {
                        this.removeWelcome();
                        delete this.messages[authMsg.ts];
                        this.updateMessagesNow();
                    }
                } else {
                    sendContactMessage();
                }
                this.updateMessagesLater();
            }
            const stripeDone = () => {
                if (!completedStripeAuth) {
                    completedStripeAuth = true;
                    this.removeWelcome();
                    this.props.onCompleted("stripe-setup");                        
                }
            }
            const firstContactDone = () => {
                if (!this.completedFirstContact) {
                    this.completedFirstContact = true;
                    stripeDone();
                    removeFirstContact();
                    removeStripeSetup();
                    this.props.onCompleted("first-contact");
                }
            }
            const scheduleAppointmentDone = () => {
                if (!this.completedScheduleAppointment) {
                    this.completedScheduleAppointment = true;
                    firstContactDone();
                    this.props.onCompleted('schedule-appointment');
                }
            }
            const addMsg = msg => {
                this.messages[msg.ts] = msg;
                if (this.chat) this.chat.markDirty(msg);
                this.updateMessagesLater();
            }
            
            const delMsg = msg => {
                if (this.chat) this.chat.markDirty(msg);
                this.deleteMessage(msg);
            }
            this.props.me.hasContacts().then(result => {
                if (result) firstContactDone();
            });
            this.subSub = this.props.me.observeSubscriptions().subscribe(change => {
                const sub = change.subscription;
                const channel = sub.uid + "-" + sub.client;
                const prev = this.subs[channel];
                if (prev) delMsg(prev);
                if (change.type == 'removed') {
                    return;
                } else {
                    if (sub.state == 'active') {
                        const latestQuestion = sub.latestQuestion || 0;
                        const latestResponse = sub.latestResponse || 0;
                        if (latestQuestion > latestResponse) {
                            const msg = {
                                to: sub.uid,
                                system: true,
                                data: {
                                    type: 'subscription',
                                    subscription: sub,
                                },
                                text: "subscription question",
                                ts: latestQuestion,
                            }
                            this.subs[channel] = msg;
                            addMsg(msg);
                        }
                    } else if (sub.state == 'offer' && (!sub.before || sub.before.state != 'offer')) {
                        const msg = {
                            from: sub.uid,
                            to: sub.client,
                            system: true,
                            data: {
                                type: 'subscription',
                                subscription: sub,
                            },
                            text: "subscription offer",
                            ts: sub.offerTime || Date.now()
                        }
                        this.subs[channel] = msg;
                        addMsg(msg);
                    }
                }
            });
            this.mySubSub = this.props.me.observeMySubscriptions().subscribe(change => {
                const sub = change.subscription;
                const channel = sub.uid + "-" + sub.client;
                const prev = this.subs[channel];
                if (prev) delMsg(prev);
                if (change.type == 'removed') {
                    return;
                } else {
                    if (sub.state == 'offer' && (!sub.before || sub.before.state != 'offer')) {
                        const msg = {
                            from: sub.uid,
                            to: sub.client,
                            system: true,
                            data: {
                                type: 'subscription',
                                subscription: sub,
                            },
                            text: "subscription offer",
                            ts: sub.offerTime || Date.now()
                        }
                        this.subs[channel] = msg;
                        addMsg(msg);
                    }
                }
            });
            
            this.sub = this.props.me.observeSystemMessages().subscribe(change => {
                if (!change) {
                    //console.log("system message change was null");
                    return;
                }
                const msg = change.message;
                //console.log("system message: ", msg);
                if (msg.ts == this.inProgress.ts ||
                    msg.data.appointment && msg.data.appointment.id == this.inProgress.apptId) {
                    window.hideProgressIndicator();
                    this.inProgress = {};
                }
                let updateNow = false;
                if (msg.data && msg.data.appointment) {
                    const appt = msg.data.appointment;
                    const waiting = this.waiting[appt.id];
                    if (waiting) {
                        delete this.waiting[appt.id];
                        waiting.map(resolve => resolve());
                        updateNow = true;
                    }
                }
                if (change.type == 'removed') {
                    delMsg(msg);
                } else {
                    msg.system = true;
                    //console.log(msg);
                    if (msg.data.type == 'joinedTeTe') {
                        // ignore. no display name yet
                        return;
                    }                
                    if (msg.data.type == 'newContact') {
                        ////debugger;
                        const newContact = msg.data.newContact;
                        //console.log("newContact: ", newContact);
                        const contact = newContact.contact;
                        if (!contact) return;
                        if (this.removedContacts[contact.uid]) {
                            const removed = this.removedContacts[contact.uid];
                            if (removed.ts > msg.ts) {
                                return;
                            } else {
                                delMsg(this.removedContacts[contact.uid]);
                                delete this.removedContacts[contact.uid];
                            }
                        }
                        if (contact.uid == this.props.me.self.uid) {
                            return;
                        } else {
                            if (this.openContacts[contact.uid]) {
                                return;
                            }
                            if (this.scheduled[contact.uid]) {
                                return;
                            }
                            this.newContacts[contact.uid] = msg;
                            if (newContact.role != "referer") {
                                firstContactDone();
                            }
                            addMsg(msg);
                        }
                    } else if (msg.data.type == 'removeContact') {
                        const removeContact = msg.data.removeContact;
                        const contact = removeContact.contact;
                        if (this.newContacts[contact.uid]) {
                            const newContact = this.newContacts[contact.uid];
                            if (newContact.ts < msg.ts) {
                                delMsg(newContact);
                                delete this.newContacts[contact.uid];
                            } else {
                                return;
                            }
                        }
                        this.removedContacts[contact.uid] = msg;
                        return;
                     } else if (msg.data.type == 'openContact') {
                        const openContact = msg.data.openContact;
                        const contact = openContact.contact;
                        this.openContacts[contact.uid] = true;
                        if (this.newContacts[contact.uid]) {
                            delMsg(this.newContacts[contact.uid]);
                            delete this.newContacts[contact.uid];
                        }
                        return;
                     } else if (false && msg.data.type == 'subscription') {
                         const sub = msg.data.subscription;
                         const channel = sub.uid + '-' + sub.client;
                         const prev = this.subs[channel];
                         if (prev) {
                             if (prev.ts < msg.ts) {
                                 delMsg(prev);
                             } else {
                                 return;
                             }
                         }
                         if (sub.uid != this.props.me.self.uid) {
                             return;
                         }
                         if (sub.state == 'active') {
                             const latestQuestion = sub.latestQuestion || 0;
                             const latestResponse = sub.latestResponse || 0;
                             if (latestQuestion > latestResponse) {
                                 this.subs[channel] = msg;
                                 addMsg(msg);
                             } else {
                                 return;
                             }
                         } else if (sub.state == 'offer') {
                             this.subs[channel] = msg;
                             addMsg(msg);
                         } else {
                             return;
                         }
                     } else if (msg.data.type == 'appointment') {
                        scheduleAppointmentDone();
                        const appt = msg.data.appointment;
                        //console.log(appt);
                        const uid1 = appt.client;
                        const uid2 = appt.uid;
                        [uid1, uid2].map(uid => {
                            if (this.newContacts[uid]) {
                                delMsg(this.newContacts[uid]);
                                delete this.newContacts[uid];
                            } else {
                                if (uid != this.props.me.uid) {
                                    this.scheduled[uid] = true;
                                }
                            }
                        });
                        //debugger;
                        if (appt.paymentIntentId ) {
                            if (!appt.paymentStatus ||
                                appt.paymentStatus == "requires_confirmation" ||
                                appt.paymentStatus == "requires_payment_method") {
                                return;
                            }
                        }
                        let prev = this.appts[appt.id]
                        if (prev && prev.modified) {
                            delete this.appts[appt.id];
                            delMsg(prev);
                            prev = null;
                        }
                        if (prev) {
                            const prevAppt = prev.data.appointment;
                            if (prevAppt.status == "canceled") {
                                return;
                            }
                            let ignoreTs = false;
                            if (appt.paymentSeq) {
                                if (appt.paymentSeq != prevAppt.paymentSeq) {
                                    if (!prevAppt.paymentSeq || prevAppt.paymentSeq < appt.paymentSeq) {
                                        this.deleteMessage(prev);
                                    } else {
                                        if (prev.paymentSeq && appt.paymentSeq < prev.paymentSeq) {
                                            return;
                                        }
                                    }
                                }
                            }
                            if (appt.sequence > prevAppt.sequence) {
                                this.deleteMessage(prev);
                            } else if (appt.sequence < prevAppt.sequence) {
                                return;
                            }
                            if (!ignoreTs) {
                                if (prev.ts <= msg.ts) {
                                    this.deleteMessage(prev);
                                } else {
                                    return;
                                }
                            }
                        }
                        //console.log("me add appointment: ", msg);
                        this.appts[appt.id] = msg;
                        msg.data.isActive = true;
                        const now = Date.now();
                        const start = appt.start;
                        const end = appt.end;
                        if (end < now) {
                            //console.log("appointment in the past: ", new Date(start), "..",  new Date(end));
                            return;
                        }
                        let status;
                        if (msg.text == "Appointment canceled") {
                            status = 'canceled';
                        } else {
                            if (appt.status) {
                                status = appt.status;
                                if (status == 'accepted') {
                                    if (appt.invoiceAmount && appt.paymentStatus != 'succeeded') {
                                        status = 'awaiting-payment';
                                    } else {
                                    }
                                }
                            } else {
                                status = 'awaiting-accept';
                            }
                        }
                        msg.text = status;
                        msg.status = status;
                        //console.log("appointment status: ", status);
                        if (status == 'declined' && appt.client == this.props.me.self.uid) {
                            delMsg(msg);
                            return;
                        }
                        if (status == 'canceled') {
                            delMsg(msg);
                            return;
                        }
                    } else {
                        //console.log("unsupported case: ", msg);
                        return;
                    }
                    addMsg(msg);
                }
                if (updateNow) {
                    this.updateMessagesNow();
                }
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchTerm != prevProps.searchTerm) {
            this.updateMessagesLater();
        }
        if (this.props.auth && !prevProps.auth) {
            if (this.removeWelcome) this.removeWelcome();
        }
    }

    componentWillUnmount() {
        if (this.sub) this.sub.unsubscribe();
        if (this.subSub) this.subSub.unsubscribe();
    }

    updateMessagesNow = () => {
        clearTimeout(this.updateTimeout);
        let ms = Object.values(this.messages);
        let active = {};
        let complete = {};
        let payments = {};
        ms.map(msg => {
            if (msg.data && msg.data.appointment) {
                const appt = msg.data.appointment;
                if (!appt.contact) {
                    appt.organizer = this.props.me.getContact(appt.uid);
                    const other = appt.uid == this.props.me.self.uid ? appt.client : appt.uid;
                    appt.contact = this.props.remoteContact ? this.props.remoteContact : this.props.me.getContact(other);
                    
                }
                if (appt.paymentStatus == "succeeded") {
                    complete[appt.paymentIntentId] = msg;
                    payments[appt.paymentIntentId] = msg;
                } else {
                    const paymentIntentId = appt.paymentIntentId;
                    if (paymentIntentId) {
                        if (!payments[paymentIntentId] || payments[paymentIntentId].ts < msg.ts) {
                            payments[paymentIntentId] = msg;
                        }
                    }
                }
                //console.log("me message: ", msg);
            }
        });
        const matches = {};
        const searchTerms = this.props.searchTerm ? this.props.searchTerm.toLowerCase().split(/\s+/) : null;
        ms = ms.filter(msg => {
            if (searchTerms) {
                let allTerms;
                let textTerms = msg.text.split(/\s+/);
                if (msg.data) {
                    if (msg.data.appointment) {
                        const appt = msg.data.appointment;
                        let terms = appt.contact.displayName.split(/\s+/);
                        let timeTerms = formatStartEndTime(new Date(appt.start), new Date(appt.end)).split("-").map(x => x.trim()).join("").split(/\s+/);
                        let dateTerms = formatDate(new Date(appt.start)).split(/\s+/);
                        let paymentTerms = renderPaymentStatus(appt.paymentStatus).split(/\s+/);
                        let titleTerms = appt.title ? appt.title.split(/\s+/) : [];
                        let amountTerms = appt.invoiceAmount ? ["$"+appt.invoiceAmount, ""+appt.invoiceAmount] : [];
                        allTerms = [textTerms, terms, timeTerms, dateTerms, paymentTerms, titleTerms, amountTerms];
                    } else if (msg.data.newContact) {
                        const text = formatNewContactText(this.props.me, msg.data.newContact);
                        allTerms = [text.split(/\s+/)];
                    } else if (msg.data.subscription) {
                        const sub = msg.data.subscription;
                        let terms = sub.contact.displayName.split(/\s+/);
                        terms.push("awaiting");
                        terms.push("response");
                        const organizer = this.props.me.getContact(sub.uid);
                        if (organizer.uid != sub.contact.uid) {
                            terms = terms.concat(organizer.displayName.split(/\s+/));
                        }
                        const latestQuestion = sub.latestQuestion || 0;
                        const latestResponse = sub.latestResponse || 0;
                        let start = 0;
                        let end = 0;
                        let timeTerms = [];
                        let dateTerms = [];
                        if (latestQuestion > latestResponse) {
                            start = latestQuestion;
                            end = this.props.me.getNextResponseTime(sub);
                            dateTerms = formatDate(new Date(start)).split(/\s+/);
                            dateTerms.push("respond");
                        }
                        let paymentTerms = ["active", "monthly"];
                        let titleTerms = sub.description ? sub.description.split(/\s+/) : [];
                        let amountTerms = sub.invoiceAmount ? ["$"+sub.invoiceAmount, ""+sub.invoiceAmount] : [];
                        allTerms = [textTerms, terms, timeTerms, dateTerms, paymentTerms, titleTerms, amountTerms];
                        
                    }
                } else {
                    allTerms = [textTerms];
                }
                let matched = 0;
                allTerms.map(terms => {
                    terms.map(term => {
                        if (term) {
                            term = term.toLowerCase();
                            searchTerms.map(searchTerm => {
                                if (searchTerm) {
                                    if (term.startsWith(searchTerm) || searchTerm.startsWith(term)) {
                                        matched++;
                                    }
                                }
                            });
                        }
                    })
                });
                if (matched == 0) return false;
                matches[msg.ts] = matched;
                //console.log("matched ", matched, ": ", msg);
            }
            if (msg.data && msg.data.appointment) {
                const appt = msg.data.appointment;
                const paymentIntentId = appt.paymentIntentId;
                if (paymentIntentId) {
                    return payments[paymentIntentId] == msg;
                }
            }
            return true;
        });
        
        if (ms.length == 0) {
            if (!searchTerms) {
                ms.push({
                    ts: Date.now(),
                    text: "You're all caught up",
                    system: true,
                    to: this.props.me.self.uid,
                });
            }
        } else {
            if (searchTerms) {
                const sort = (x, y) => {
                    const w1 = matches[x.ts];
                    const w2 = matches[y.ts];
                    const cmp1 = w2-w1;
                    if (cmp1 !== 0) {
                        return cmp1;
                    }
                    return y.ts - x.ts;
                }
                ms.sort(sort);
            } else {
                ms.sort((a, b) => b.ts - a.ts);
            }
        }
        ms.map((x, i) => x.sortOrder = i);
        this.setState({
            messages: ms
        });
    }

    updateMessagesLater = () => {
        clearTimeout(this.updateTimeout);
        setTimeout(this.updateMessagesNow, 500);
    }

    deleteMessage = msg => {
        //debugger;
        delete this.messages[msg.ts];
        this.updateMessagesLater();
    }

    onAppointmentEnded = msg => {
        this.deleteMessage(msg);
    }

    onChatCreated = chat => {
        this.chat = chat;
    }

    render() {
        let className = 'uiMeTeTeFeedMessagesContainer';
        if (this.props.isIPad) {
            className += ' uiMeTeTeFeedMessagesContainerIPad';
        }
        return <div className='uiMeTeTeFeed'>
            <div className={className}>
            <div className='uiMeTeTeFeedMessages'>
            <UIChat
        onChatCreated={this.onChatCreated}
        onAppointmentEnded={this.onAppointmentEnded}
        finishNewContact={this.finishNewContact}
        waitForSystemUpdate={this.waitForUpdate}
        hideWith={false}
        openChat={this.props.openChat}
        openSubscription={this.props.openSubscription}
        scheduleAppointmentWith={this.props.scheduleAppointmentWith}
        rescheduleAppointment={this.props.rescheduleAppointment} showSystemProgressIndicator={this.showSystemProgressIndicator} messages={this.state.messages} me={this.props.me} localContact={this.props.me.self} remoteContact={null}/>
            </div>
            </div>
            </div>
    }
}

class UIMeConnectToStripe extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.sub = this.props.me.observeStripeAccount().subscribe(account => {
            this.state.account = account;
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        if (this.sub) this.sub.unsubscribe();
        this.closePopup();
    }

    closePopup = () => {
        clearInterval(this.popupTimer);
        if (this.state.popup) {
            this.state.popup.close();
            this.state.popup = null;
        }
    }

    checkPopupClosed = () => {
        const popup = this.state.popup;
        if (popup.closed) {
            this.closePopup();
            this.forceUpdate();
        }
    }

    okClick = () => {
        if (this.state.popup) {
            this.closePopup();
            this.forceUpdate();
            return Promise.resolve();
        }
        return this.props.me.stripeConnect().then(popup => {
            if (popup.closed) {
                return;
            }
            this.setState({
                popup: popup
            });
            this.popupTimer = setInterval(this.checkPopupClosed, 200);
        });
    }

    reviewBAA = () => {
        return this.props.reviewBAA();
    }
    

    render() {
        if (!this.props.me.self && this.props.me.self.displayName) {
            return;
        }
        let errors = [];
        const seen = {};
        let disabled;
        if (this.state.account && this.state.account.requirements) {
            if (this.state.account.requirements.errors) {
                this.state.account.requirements.errors.map(err => {
                    if (!seen[err.reason]) {
                        errors.push(err.reason);
                        seen[err.reason] = true;
                    }
                });
            }
            if (this.state.account.requirements.disabled_reason) {
                errors.push("Your account is disabled due to missing or incorrect information");
            }
        }
        let stripeLabel; 
        if (this.state.popup) {
            stripeLabel = this.props.auth ? "Close Stripe Dashboard" : "Close Stripe Connect";
        } else {
            //debugger;
            stripeLabel = this.props.auth ? "Open Stripe Dashboard" : "Enable Payments with Stripe"
        }

        const notConnectedToStripe = () => {
                return <div className='uiMeStripeNotConnected'>
                <div className='uiMeContactAssetImage'><img src={StripeAsset}/></div>
                <div className='uiMeStripeConnectMessage'>
                {"You can selectively use TeTe for payments on a per-client or per-session basis. TeTe will keep you apprised of the payment status of your appointments and collect payments on your behalf. Prices can be individualized per client and session."}
                 </div>
                <div className='uiMeStripeButtonContainer'>
                   <UIOKCancel okIcon={Stripe} ok={this.okClick} label={stripeLabel}/>
                </div>
                {this.props.optOut && <div className='uiMeStripeOptOut'>
                 <UIOKCancel okIcon={Forward} ok={this.props.optOut} label={"Not right now"}/>
                </div>}
                </div>;
        }

        const connectedToStripe = () => {
            const reviewBAALabel = this.props.acceptedBAA ? "View BAA" : "Review and Accept BAA";
            return <div className='uiMeStripeConnected'>
                {errors.length > 0 && <div className='uiMeStripeConnectedBlurb'>You are connected with Stripe</div>}
                {errors.length >  0 && <div className='uiMeStripeConnectErrors'>
                 <img src={StripeError}/>
                 <div className='uiMeStripeConnectErrorsText'>Your Stripe account is restricted due to the following errors:</div>
            {errors.map(err => {
                return <div className='uiMeStripeConnectError'>{err}</div>;
            })}
                 <div className='uiMeStripeConnectErrorsText2'>Open your Stripe dashboard and correct them</div>
                 </div>}
                <div className='uiMeStripeButtonContainer'>
                <UIOKCancel background={true} okIcon={Stripe} ok={this.okClick} label={stripeLabel}/>
                </div>
                <div className='uiMeReviewBAA'>
                <UIOKCancel okIcon={Forward} ok={this.reviewBAA} label={reviewBAALabel}/>
                </div>
                <UIMeContact me={this.props.me} formLinkURL={this.props.formLinkURL} type='client' link={this.props.link} copyLink={this.props.copyLink}/>
           </div>
        }
        
        return <div className='uiMeConnectToStripe'>
            {this.props.auth ? connectedToStripe() : notConnectedToStripe()}
        </div>
    }
    
}

export class UIMeBAA extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    close = () => {
        return this.props.cancel();
    }

    accept = () => {
        return this.props.accept();
    }

    downloadBAA = () => {
        return this.props.download();
    }

        
    render() {
        let className = 'uiMeBAA';
        if (!(isDesktop() && isSafari())) {
            className += " uiMeBAANotSafariDesktop";
        }
        return <div className={className}>
            <div className='uiMeBAAHeaderContainer'>
            <div className='uiMeBAAClose' onClick={this.close}><ReactSVG src={Cross}/></div>
            <div className='uiMeBAAHeader'>
            <div className='uiMeBAAHeaderIcon'>
            <ReactSVG src={Hipaa}/>
            </div>
            <div className='uiMeBAAHeaderTitle'>
            {this.props.acceptedBAA ? "Effective "+moment(new Date(this.props.acceptedBAA)).format("Do MMM YYYY") : "Review and Accept"}
            </div>
            <div className='uiMeBAAHeaderSubtitle'>
            TeTe Business Associate Agreement
            </div>
            </div>
            </div>
            <div className='uiMeBAABody'>
            {BAA}
            </div>
            <div className='uiMeBAAFooter'>
            {!this.props.acceptedBAA ?
             <UIOKCancel cancel={this.close} cancelIcon={Cross} okIcon={Checkmark} label="I Accept" ok={this.accept}/>
             :
             <UIOKCancel okIcon={Save} label="Download" ok={this.downloadBAA}/>
            }
            </div>
            </div>
    }
}
    
export class UIMeContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
        this.invites =
            [{
                text: "Invite your clients to connect with you on TeTe by sharing this secure private link:"
            },
             {
                 text: "Refer a colleague to TeTe by sharing this secure private link and get free stuff:"
             },
             {
                 text: "Invite your friends or contacts to connect with you on TeTe by sharing this secure private link:"
             }];
    }

    checkSub() {
        if (!this.sub && this.props.me) {
            this.sub = this.props.me.observeSelf().subscribe(() => {
                this.forceUpdate();
            });
        }
        if (!this.sub1 && this.props.me) {
            this.sub1 = this.props.me.observeStripeAuth().subscribe(auth => {
                this.setState({
                    auth: auth
                });
            });
        }
        if (!this.sub2 && this.props.me) {
            this.sub2 = this.props.me.observeAccount().subscribe(account => {
                this.setState({
                    acceptedBAA: account && account.acceptedBAA
                });
            });
        }
    }

    componentDidMount() {
        this.checkSub();
    }

    componentDidUpdate() {
        this.checkSub();
    }

    componentWillUnmount() {
        if (this.sub) this.sub.unsubscribe();
        if (this.sub1) this.sub1.unsubscribe();
        if (this.sub2) this.sub2.unsubscribe();
    }

    selectTab = tab => {
        this.setState({
            selectedTab: tab
        });
    }

    formLinkURL = () => {
        const link = this.props.link;
        const types = ['client', 'refer', 'contact'];
        const type = types[this.state.selectedTab];
        return window.origin+"/?"+type+"="+link;
    }
    
    copyLink = () => {
        return new Promise((resolve, reject) => {
            const url = this.formLinkURL();
            Clipboard.copy(url);
            setTimeout(resolve, 500);
        });
    }

    stripeConnect = () => {
        return this.props.me.stripeConnect();
    }

    reviewAndAcceptBAA = () => {
        return this.toggleReviewBAA();
    }

    acceptBAA = () => {
        return this.props.me.acceptBAA().then(() => {
            this.toggleReviewBAA();
        })
    }
    
    toggleReviewBAA = () => {
        this.setState({
            reviewBAA: !this.state.reviewBAA
        });
        return Promise.resolve();
    }

    renderBAA = () => {
        if (this.props.portal) {
            return ReactDOM.createPortal(this.renderBAAImpl(), this.props.portal);
        }
        return this.renderBAAImpl();
    }

    skipStripeConnect = () => {
    }

    renderBAAImpl = () => {
        if (!this.state.reviewBAA) return null;
        return <ClickAwayListener onClickAway={this.toggleReviewBAA}><UIMeBAA download={this.props.me.downloadBAA} acceptedBAA={this.state.acceptedBAA} cancel={this.toggleReviewBAA} accept={this.acceptBAA}/></ClickAwayListener>
    }
    
    render() {
        let message;
        if (this.props.type == 'client') {
            if (!this.state.acceptedBAA) {
                return null;
            }
            message = "Invite your clients to connect with you on TeTe by sharing this link:";
            return <div className='uiMeContact uiMeContactBusiness'>
                <div className='uiMeContactBottomLayer'>
                <div className='uiMeStripeConnectedBlurb'>Onboard your clients</div>
                <div className='uiMeContactAssetImage'><img src={LinkBusinessAssetSml}/></div>
                <div className='uiMeContactText'>
                {message}
            </div>
                <div className='uiMeContactLink'>
                {this.props.link ? this.props.formLinkURL(this.props.type, this.props.link) : "Generating link..."}
            </div>
                <div className='uiMeContactButtons'>
                <UIOKCancel okIcon={Link} label="Copy Link" ok={()=>this.props.copyLink(this.props.type)}/>
                </div>
                </div>
                </div>
        } 
        const selected = this.state.selectedTab;
        let message1;
        message = this.invites[selected].text;
        let button;
        let showLink = true;
        const messageAndButton = (message, button) => {
            return [<div className='uiMeContactText'>
                {message}
                 </div>,
                 <div className='uiMeContactButtons'>
                 {button}
                 </div>];
        }
        let button1;
        if (selected == 0) {
            if (!this.state.acceptedBAA) {
                message1 = "For HIPAA compliance please review and accept TeTe's Business Associate Agreement";
                button1 = <UIOKCancel okIcon={Forward} label="Review and Accept BAA" ok={this.reviewAndAcceptBAA}/>;
            }
            if (false) {
                button1 = <div className='uiMeStripeButtonContainer'>
                    <UIOKCancel okIcon={Stripe} ok={this.stripeConnect} label={"Enable Payments with Stripe"}/>
                    </div>;
                showLink = true;
                const text = "Using TeTe for payments is optional, but we think it provides a great user experience. Note: you can selectively use TeTe for payments on a per-client or per-session basis."
                message1 = <div className='uiMeContactText2'>
                    {text}
                </div>;
                if (false) button1 =
                    <div className='uiMeContactSkipStripe'>
                    <UIOKCancel okIcon={Forward} ok={this.skipStripeConnect} label={"Maybe Later"}/>
                    </div>
            }
                    
            
        }
        if (!button) {
            button = <UIOKCancel okIcon={Link} label="Copy Link" ok={()=>this.copyLink()}/>;
        }
        return <div className='uiMeContact'>
            <div className='uiMeContactBottomLayer'>
            <div className='uiMeContactAssetImage'><img src={LinkAssetSml}/></div>
            <div className='uiMeContactLinkTabs'>
            <div onClick={()=>this.selectTab(0)}
        className={'uiMeContactLinkTab uiMeContactLinkTabLeft' + (selected == 0 ? " uiMeContactLinkTabSelected" : "")}>
            <div className='uiMeContactLinkTabText'>Onboard a client</div>
            <div className='uiMeContactLinkArrow'><ReactSVG src={Arrow}/></div>
            </div>
            <div onClick={()=>this.selectTab(1)}
        className={'uiMeContactLinkTab uiMeContactLinkTabMiddle' + (selected == 1 ? " uiMeContactLinkTabSelected" : "")}>
            <div className='uiMeContactLinkTabText'>Refer a colleague</div>
            <div className='uiMeContactLinkArrow'><ReactSVG src={Arrow}/></div>
            </div>
            <div onClick={()=>this.selectTab(2)}
        className={'uiMeContactLinkTab uiMeContactLinkTabRight' + (selected == 2 ? " uiMeContactLinkTabSelected" : "")}>
            <div className='uiMeContactLinkTabText'>Connect a friend</div>
            <div className='uiMeContactLinkArrow'><ReactSVG src={Arrow}/></div>
            </div>
            </div>
            <div className='uiMeContactText'>
            {message}
            </div>
            {showLink ? <div className='uiMeContactLink'>
            {this.props.link ? this.formLinkURL() : "Generating link..."}
             </div> : <div className='uiMeContactNoLink'/>}
            <div className='uiMeContactButtons'>
            {button}
        {message1 &&
            <div className='uiMeContactText2'>
            {message1}
         </div>}
            {button1}
        </div>
            {this.renderBAA()}
           </div>
       </div>
    }
}

export class UIMeCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onSearchChange = e => {
        this.props.onSearch(e.target.value)
    }

    onSearchButtonClick = () => {
        if (this.state.searching) {
            this.props.onSearch("");
            this.input.focus();
            return;
        }
        this.setState({
            searching: true
        }, () => {
            this.input.focus();
        });
    }
    
    setInputRef = x => {
        if (x && x != this.input) {
            this.input = x;
            if (this.state.searching) {
                x.focus();
            }
        }
    }

    stopSearch = () => {
        this.setState({
            searching: false
        });
    }

    render() {
        return <div id={this.props.cardId} key={this.props.key} className='uiMeCard'>
            <div className='uiMeCardContent'>
            {this.props.onSearch && <ClickAwayListener onClickAway={this.stopSearch}>
             <div className='uiSideListHeader'>
            <div key='title' style={this.state.searching ? {display: 'none'} : null} className='uiSideListHeaderTitle'>{this.props.title}</div>
            <div key='searchInput' style={!this.state.searching ? {display: 'none'} : null}
        className='uiSideListHeaderSearchField'><input value={this.props.searchTerm} placeholder={this.props.searchPlaceholder} ref={this.setInputRef} autoFocus={true} onChange={this.onSearchChange}/></div>
            <div key='buttons' className='uiSideListHeaderButtons'>
            <div className='uiMeResultCount'>{this.props.resultCount ? this.props.resultCount + " Items" : ""}</div>
            <div className='uiSideListHeaderSearch' onClick={this.onSearchButtonClick}><UIIcon className='uiSideListHeaderButton' icon={this.state.searching ? Cross : Search}/>
            </div>
            </div>
             </div></ClickAwayListener>}
        {!this.props.onSearch &&
            <div className='uiMeCardHeader'>
         <div className='uiMeCardTitle'>{this.props.title + (this.props.searchTerm  ? (" / " + this.props.searchTerm) : "")}</div>
         </div>}
        
            <div className='uiMeCardBody'>
            {this.props.children}
            </div>
            </div>
            </div>
    }
}

export class UIMe extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.props.me.getContactLink().then(link => {
            const show = this.state.showBusinessSetup || !window.isBusiness;
            this.setState({
                contactLink: link,
                showBusinessSetup: show
            });
        });
        this.props.me.observeAccount().subscribe(account => {
            if (account) {
                console.log("uime acceptedBAA: ", account.acceptedBAA);
            }
            this.setState({
                acceptedBAA: account && account.acceptedBAA
            });
        });
        this.props.me.observeStripeAuth().subscribe(auth => {
            this.setState({
                auth: auth,
            });
        });
        this.portalRef = React.createRef();
    }

    showBusinessSetup = () => {
        this.setState({
            showBusinessSetup: true
        });
    }

    formLinkURL = (type, link) => window.origin+"/?"+type+"="+link;
    
    copyLink = (type) => {
        return new Promise((resolve, reject) => {
            const url = this.formLinkURL(type, this.state.contactLink);
            Clipboard.copy(url);
            setTimeout(resolve, 500);
        });
    }

    onCompleted = step => {
        //console.log("Completed: ", step);
        switch (step) {
        case 'stripe-setup':
            {
                break;
            }
        case 'first-contact':
            {
                this.setState({showBusinessSetup: true, showContactLink: true});
                break;
            }
        case 'schedule-appointment':
            {
                break;
            }
        }
    }

    searchTodo = searchTerm => {
        this.setState({
            searchTerm: searchTerm
        });
    }

    componentDidUpdate() {
        this.checkLayout();
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResized);
        this.checkLayout();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResized);
    }

    onResized = () => {
        this.forceUpdate();
        this.checkLayout();
    }

    checkLayout = () => {
        let markRead = this.props.nav == 'todo';
        if (!this.isIPad() && this.props.nav != 'todo') {
            this.props.navigate('todo');
            markRead = true;
        }
        if (markRead && this.props.visible && this.props.systemUnread > 0) {
            this.props.me.markSystemMessagesRead();
        }
    }

    isIPad() {
        const isIPad = document.documentElement.clientWidth < 1024;
        return isIPad;
    }

    acceptBAA = () => {
        return this.props.me.acceptBAA().then(() => {
            this.toggleReviewBAA();
        })
    }
    

    toggleReviewBAA = () => {
        this.setState({
            reviewBAA: !this.state.reviewBAA
        });
        return Promise.resolve();
    }

    renderTodoList() {
        const isIPad = this.isIPad();
        return <UIMeCard cardId={'todo'} icon={TeTeLogo} searchPlaceholder={"Search To-do List"} searchTerm={this.state.searchTerm} onSearch={this.searchTodo} title={"To-do List" + (this.state.searchTerm ? " / " + this.state.searchTerm :  "")}>
            <UIMeTeTeFeed portal={this.portalRef.current} auth={this.state.auth} acceptedBAA={this.state.acceptedBAA} isIPad={isIPad} searchTerm={this.state.searchTerm} openChat={this.props.openChat} openSubscription={this.props.openSubscription} copyLink={this.copyLink} formLinkURL={this.formLinkURL} link={this.state.contactLink} showBusinessSetup={this.showBusinessSetup}
        scheduleAppointmentWith={this.props.scheduleAppointmentWith}
        rescheduleAppointment={this.props.rescheduleAppointment} me={this.props.me} onCompleted={this.onCompleted}/>
            </UIMeCard>
    }

    renderContactLink() {
        return <UIMeCard cardId={'contactLink'} key='contactLink' title={"Link"}>
            <UIMeContact portal={this.portalRef.current} me={this.props.me} type='contact' formLinkURL={this.formLinkURL} link={this.state.contactLink} copyLink={this.copyLink}/>
            </UIMeCard>
    }

    renderBusinessSetup() {
            return <UIMeCard cardId={'businessSetup'} key='businessSetup' title={"Business Setup"}>
            <div className='uiMeCardInsets'><UIMeConnectToStripe auth={this.state.auth} acceptedBAA={this.state.acceptedBAA} reviewBAA={this.toggleReviewBAA} me={this.props.me} formLinkURL={this.formLinkURL} link={this.state.contactLink} copyLink={this.copyLink}/></div>
            </UIMeCard>;
    }

    renderTab = (name, value) => {
        let className = 'uiMeTab';
        if (value == this.props.nav) {
            className += " uiMeTabSelected";
        }
        let unread = 0;
        if (value == 'todo') {
            unread = this.props.systemUnread;
        }
        console.log('tab value: ', value, " unread: ", this.props.systemUnread);
        return <div className={className} onClick={()=>this.selectTab(value)}>
            <div key='label' className={'uiMeTabLabel'}>{name}</div>
            {unread > 0 && <div key='unread' className='uiSideListContactUnread'>{unread> 99 ? "99+" : unread}</div>}           
        </div>
    }

    selectTab = value => {
        this.props.navigate(value);
    }

    tabs = [
        {
            name: 'To-do List',
            value: 'todo',
            visible: () => true,
            content: () => this.renderTodoList()
        },
        {
            name: 'Device Setup',
            value: 'device',
            visible: () => this.props.visible,
            content: () => <UIDeviceSetup/>
        },
        {
            name: 'Link',
            value: 'link',
            visible: () => this.state.showContactLink,
            content: () => this.renderContactLink()
        },
        {
            name: 'Business Setup',
            value: 'businesss',
            visible: () => this.state.showBusinessSetup,
            content: () => this.renderBusinessSetup(),
        },
    ]
    
    render() {
        if (!this.props.me) return null;
        const displayName = this.props.me.self.displayName || "";
        const isIPad = this.isIPad();      
        const c1 = ()=><div className='uiMeBodyColumn1'>
              {this.renderTodoList()}
              </div>;
        const c2 = ()=> <div className="uiMeBodyColumn2">
            <div id='me-column2' className='uiMeDeviceSetupContainer'>
            {this.props.visible &&<UIDeviceSetup/>}
            </div>
            {this.state.showContactLink && this.renderContactLink()}
        {this.state.showBusinessSetup && this.renderBusinessSetup()}
        </div>;
        let selectedTab = this.tabs.find(tab => tab.visible() && tab.value == this.props.nav);
        return <div className='uiMe' style={this.props.visible ? null : {display: "none"}}>
            <div className='uiMeTitle'>{"Home"}</div>
            {isIPad ?
             <div className='uiMeBodyContainer uiMeBodyContainerNoScroll'>
             <div className='uiMeBody1Column'>
             <div className='uiMeTabs'>
             {this.tabs.filter(tab => tab.visible()).map(tab => this.renderTab(tab.name, tab.value))}
             </div>
             {selectedTab ? selectedTab.content() : null}
             </div>
             </div>:
            <div className='uiMeBodyContainer'>
             <div className='uiMeBody'>
             {c1()}
             {c2()}
             </div>
             </div>}
            <div ref={this.portalRef}>{this.state.reviewBAA &&
                                       <ClickAwayListener onClickAway={this.toggleReviewBAA}><UIMeBAA download={this.props.me.downloadBAA} acceptedBAA={this.state.acceptedBAA} cancel={this.toggleReviewBAA} accept={this.acceptBAA}/></ClickAwayListener>}</div>
                                       
            </div>
    }
}
